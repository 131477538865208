import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { MatchSessions } from './Sessions';

const Update = lazy(() => import('./Update'));
const Rules = lazy(() => import('./Rules'));
const Toss = lazy(() => import('./Toss'));
const Squad = lazy(() => import('./Squad'));
const Play = lazy(() => import('./Play'));
const Reward = lazy(() => import('./Reward'));
const Result = lazy(() => import('./Result'));
const Review = lazy(() => import('./Review'));
const Innings = lazy(() => import('./Innings'));
const AdvancedSettings = lazy(() => import('./AdvancedSettings'));
const BallVersion = lazy(() => import('./scoreEditor/version'));
const Umpire = lazy(() => import('./Umpire'));

export function MatchUpdateRoutes() {
  return (
    <Routes>
      <Route
        path={Pages.sports.cricket.match.update.match.meta.path}
        element={(<Update />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.rules.meta.path}
        element={(<Rules />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.toss.meta.path}
        element={(<Toss />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.umpire.meta.path}
        element={(<Umpire />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.squad.meta.path}
        element={(<Squad />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.sessions.meta.path}
        element={(<MatchSessions />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.play.meta.path}
        element={(<Play />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.reward.meta.path}
        element={(<Reward />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.result.meta.path}
        element={(<Result />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.review.meta.path}
        element={(<Review />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.advancedSettings.meta.path}
        element={(<AdvancedSettings />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.innings.meta.path}
        element={(<Innings />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.innings.ball.update.meta.path}
        element={(<Innings />)}
      />
      <Route
        path={Pages.sports.cricket.match.update.versions.meta.path}
        element={(<BallVersion />)}
      />
    </Routes>
  );
}
