import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'core/UserContext';
import styled from 'styled-components';
import { Box } from 'core/Box';
import { asRem } from 'lib/css';
import { AccountImageView } from 'core/Image';
import {
  Text14Regular, Text16Regular,
} from 'style/typography';
import { ReactComponent as UserIcon } from 'assets/icons/icon-user.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/icon-logout.svg';
import { ReactComponent as MailIcon } from 'assets/icons/icon-mail.svg';
import { NavLink } from 'react-router-dom';
import { Pages } from 'core/pages';
import { StatusError, StatusLoading } from 'core/Status';
import { useQuery } from '@apollo/client';
import { AccountInvitesQuery } from 'core/query';
import { AccountInviteAcceptMutation } from 'account/query';
import { MutationButton } from 'core/Button';
import { UserSettingsHeader } from './UserSettingsHeader';
import { Layout } from '../Layout';

const PendingInvitesWrapper = styled.div`
  .no-invites-wrapper {
    text-align: center;
  }
  .invites-wrapper {
    border-bottom: 1px solid var(--color-border-1);
    margin-bottom: ${asRem(20)};
    padding-bottom: ${asRem(20)};
    display: flex;
    justify-content: space-between; 

    &.account-screen-view {
      margin-bottom: ${asRem(8)};
      padding: ${asRem(16)} ${asRem(20)};    
      background: var(--color-secondary-bg);
      border-radius: ${asRem(8)};
      cursor: pointer;
      gap: ${asRem(30)};
      align-items: center;
      border: none;

      &:last-child {
        padding: ${asRem(16)} ${asRem(20)};
        margin: 0;
      }
    }
    
    &:last-child {
      padding: 0;
      border: none;
      margin: 0;
    }

    > div {
      display: flex;
      align-items: center;
      gap: ${asRem(12)};

      .display-18-regular {
        padding-top: ${asRem(8)};
        text-transform: capitalize;
      }
    }
  }
`;

export function PendingInvites({ isAccountScreen }) {
  const { loading, error, data } = useQuery(AccountInvitesQuery);

  let invites;
  if (data) {
    invites = data.account_invites.accounts;
  }

  return (
    <PendingInvitesWrapper>
      {loading && (<StatusLoading message="Hold on..." />)}
      {error && (<StatusError error={error} />)}
      {invites && invites.length > 0 ? (
        <>
          {invites.map((invite) => (
            <div
              key={invite.resource.key}
              className={`invites-wrapper ${isAccountScreen ? 'account-screen-view' : ''}`}
            >
              <div>
                <AccountImageView
                  account={invite}
                  size={isAccountScreen ? '28' : '40'}
                />
                <Text16Regular>{invite.profile.name}</Text16Regular>
              </div>
              <MutationButton
                mutation={AccountInviteAcceptMutation}
                responseKey="account_invite_accept"
                okayKey="okay"
                errorkey="errors"
                name="Accept"
                variables={{ account: invite.resource.key }}
                className={isAccountScreen ? 'plain bordered small' : 'primary small'}
                refetchQueries={['AccountInvitesQuery', 'AccountsListQuery']}
                onSuccess={() => window.location.reload()}
              />
            </div>
          ))}
        </>
      ) : (
        <div className="no-invites-wrapper">
          <Text16Regular>You&apos;ve no invites</Text16Regular>
        </div>
      )}
    </PendingInvitesWrapper>
  );
}

PendingInvites.propTypes = {
  isAccountScreen: PropTypes.bool,
};

const InvitesWrapper = styled.div`
  .user-details {
    display: flex;
    gap: ${asRem(12)};

    .text-14-regular {
      color: #7F8184;
      &.alert {
        color: var(--color-alert);
      }
    }
  }
`;

export default function Invites() {
  const user = useContext(UserContext);

  let links;

  if (user) {
    links = [
      {
        name: 'User Settings',
        link: Pages.user.dashboard.meta.urlFor({
          key: user.account?.resource.key || user.accountKey,
        }),
        icon: <UserIcon />,
      },
      {
        name: 'Pending Invites',
        link: Pages.user.invites.meta.urlFor({
          key: user.account?.resource.key || user.accountKey,
        }),
        icon: <MailIcon />,
      },
    ];
  }

  return (
    <InvitesWrapper>
      <Layout
        renderHeaderContent={() => (
          <UserSettingsHeader user={user} />
        )}
        renderLeftContent={() => (
          <>
            <Box>
              <ul>
                {links.map((link) => (
                  <li>
                    <NavLink
                      exact="true"
                      to={link.link}
                      key={link.name}
                    >
                      {link.icon}
                      <Text14Regular as="span">{link.name}</Text14Regular>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Box>
            <Box>
              <ul>
                <li>
                  <button onClick={user.logout} type="button">
                    <LogoutIcon />
                    <Text14Regular>Logout</Text14Regular>
                  </button>
                </li>
              </ul>
            </Box>
          </>
        )}
        renderRightContent={() => (
          <div>
            <PendingInvites user={user} />
          </div>
        )}
      />
    </InvitesWrapper>
  );
}

Invites.propTypes = {
};
