import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { TournamentUpdateRoutes } from './update/routes';

const Dashboard = lazy(() => import('./Dashboard'));
// const Create = lazy(() => import('./Create'));
const Create = lazy(() => import('./CreateV2'));
const List = lazy(() => import('./List'));
const Resource = lazy(() => import('./ResourcePage'));
const TourUpdate = lazy(() => import('../tour/Update'));

const TournamentTeam = lazy(() => import('./UpdateTeams'));
const TeamVersions = lazy(() => import('./Version'));
const UpdateStatus = lazy(() => import('./update/UpdateStatus'));

const RoundVersions = lazy(() => import('./rounds/Version'));
const TournamentRound = lazy(() => import('./rounds/UpdateRound'));
const TournamentMatchUpdate = lazy(() => import('./rounds/matches/UpdateTournamentMatch'));

const ImportList = lazy(() => import('./import/List'));
const ImportPreview = lazy(() => import('./import/Preview'));
const ImportReview = lazy(() => import('./import/Review'));
const ImportStatus = lazy(() => import('./import/Status'));
// const AllImportList = lazy(() => import('./import/AllImportList'));

export function TournamentRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route path={Pages.sports.cricket.tournament.meta.path}>
          <Route
            index
            element={(<Dashboard />)}
          />
          <Route
            path={Pages.sports.cricket.tournament.create.meta.path}
            element={(<Create />)}
          />
          <Route
            path={Pages.sports.cricket.tournament.list.meta.path}
            element={(<List />)}
          />
          <Route
            path={Pages.sports.cricket.tournament.read.meta.path}
            element={(<Resource />)}
          />
          <Route path={Pages.sports.cricket.tournament.teams.meta.path}>
            <Route
              path={Pages.sports.cricket.tournament.teams.updateTeam.meta.path}
              element={(<TournamentTeam />)}
            />
            <Route
              path={Pages.sports.cricket.tournament.teams.updateStatus.meta.path}
              element={(<UpdateStatus />)}
            />
            <Route
              path={Pages.sports.cricket.tournament.teams.versions.meta.path}
              element={(<TeamVersions />)}
            />
          </Route>
          <Route path={Pages.sports.cricket.tournament.import.meta.path}>
            <Route
              path={Pages.sports.cricket.tournament.import.list.meta.path}
              element={((
                <ImportList />
              ))}
            />
            <Route
              path={Pages.sports.cricket.tournament.import.preview.meta.path}
              element={((
                <ImportPreview />
              ))}
            />
            <Route
              path={Pages.sports.cricket.tournament.import.review.meta.path}
              element={((
                <ImportReview />
              ))}
            />
            <Route
              path={Pages.sports.cricket.tournament.import.status.meta.path}
              element={((
                <ImportStatus />
              ))}
            />
            {/* <Route
              path={Pages.sports.cricket.tournament.import.allImports.meta.path}
              element={((
                <AllImportList />
              ))}
            /> */}
          </Route>
          <Route
            path={Pages.sports.cricket.tour.update.meta.path}
            element={(<TourUpdate />)}
          />
          <Route path={Pages.sports.cricket.tournament.rounds.meta.path}>
            <Route
              path={Pages.sports.cricket.tournament.rounds.updateRound.meta.path}
              element={(<TournamentRound />)}
            />
            <Route
              path={Pages.sports.cricket.tournament.rounds.updateMatch.meta.path}
              element={(<TournamentMatchUpdate />)}
            />
            <Route
              path={Pages.sports.cricket.tournament.rounds.versions.meta.path}
              element={(<RoundVersions />)}
            />
          </Route>
          <Route
            path={Pages.sports.cricket.tournament.update.meta.starPath}
            element={(<TournamentUpdateRoutes />)}
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
