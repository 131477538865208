/* eslint-disable no-unused-vars */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { UpdatePlayerRankingController } from './Player/updatePlayerRanking';
import { UpdateTeamRankingController } from './Team/updateTeamRanking';

const UpdateRankingWrapper = styled.div``;

export function UpdateRanking() {
  const [params] = useSearchParams();

  const rankingType = params.get('type');

  return (
    <UpdateRankingWrapper>
      {rankingType === 'team' && <UpdateTeamRankingController />}
      {rankingType === 'player' && <UpdatePlayerRankingController />}
    </UpdateRankingWrapper>
  );
}

export default UpdateRanking;
