import React from 'react';
import styled from 'styled-components';
import { asRem } from 'lib/css';

const PacmanWrapper = styled.div`
 .game {
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    min-height: 200px;
    width: 80%;
    overflow: hidden;
  }
 .pacman {
    top: 50%;
    position: absolute;
  }
.game,
.pacman,
.pacman>div {
    position: absolute;
}
.dot:nth-child(1) {
  animation-delay: 2s;
}
.dot:nth-child(2) {
    animation-delay: 2.4s;
}
.dot:nth-child(3) {
    animation-delay: 2.8s;
}
.dot:nth-child(4) {
    animation-delay: 3s;
}
.dot:nth-child(5) {
    animation-delay: 3.4s;
}

.dot:nth-child(6) {
  animation-delay: 3.8s;
}

.dot:nth-child(7) {
  animation-delay: 4.5s;
}

.dot:nth-child(8) {
  animation-delay: 4.9s;
}

.dot:nth-child(9) {
  animation-delay: 5.3s;
}

.dot:nth-child(10) {
  animation-delay: 5.7s;
}
.pacman {
    width: 150px;
    height: 150px;
    transform: translateY(-50%);
    background: 0 0;
    z-index: 1;
}
.pacman>div {
    background-color: var(--color-button-primary-bg);
    height: 50%;
    width: 100%
}
.pacman-top {
    top: 0;
    border-radius: 100px 100px 0 0;
    animation: spinTop 1s infinite linear;
}

.pacman-bottom {
    bottom: 0;
    border-radius: 0 0 100px 100px;
    animation: spinBottom 1s infinite linear;
}
.dot {
  position: absolute;
  top: 50%;
  right: ${asRem(-300)};
  transform: translateY(-50%);
  animation: dot 10s infinite linear;
  font-size: 3rem;
  letter-spacing: ${asRem(5)};
  text-transform: uppercase;
}
@keyframes spinTop {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-35deg);
    }
}
@keyframes spinBottom {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(35deg);
    }
}
@keyframes dot {
    0% {
        right: 10%;
        opacity: 1;
    }
    50% {
      opacity: 1;
    }
    67% {
      opacity: 0;
    }
    100% {
      right: 100%;
      opacity: 0;
    }
}
`;

export function WizardDashBoard() {
  return (
    <PacmanWrapper className="game">
      <div className="pacman">
        <div className="pacman-top" />
        <div className="pacman-bottom" />
      </div>
      <div className="content">
        <div className="dot">C</div>
        <div className="dot">0</div>
        <div className="dot">M</div>
        <div className="dot">I</div>
        <div className="dot">N</div>
        <div className="dot">G</div>
        <div className="dot">S</div>
        <div className="dot">O</div>
        <div className="dot">O</div>
        <div className="dot">N</div>
      </div>
    </PacmanWrapper>
  );
}
