import React from 'react';
import * as Yup from 'yup';
import {
  capitilise,
  dataFromPath,
  idify,
  unKey,
  keyed,
  resourceHashkey,
  // setValueOnPath,
  // unhash,
} from 'lib/utils';
import { Pages } from 'core/pages';
import { ResourceImageView } from 'core/Image';
import { getAccountKey, getSport } from 'core/localStore';
import { removeDeletedValues } from 'core/Form';

export function StringFieldMinMax(minValue, maxValue) {
  return Yup.string()
    .min(minValue, `Use ${minValue} or more characters`)
    .max(maxValue, `Use ${maxValue} or less characters`)
    .required('Required');
}

export function ReqField(msg) {
  return Yup.string().nullable().required(msg);
}

export function ListFieldMinMax(msg, minItem, maxItem) {
  let field = Yup.array();
  if (minItem !== undefined) {
    field = field.min(minItem, msg);
  }
  if (maxItem !== undefined) {
    field = field.max(maxItem, msg);
  }

  return field.required(msg);
}

export function RefField() {
  return Yup.object().shape({
    hashkey: Yup.string().nullable().default(null),
  }).default(() => ({ hashkey: null })).nullable();
}

export function NumberField() {
  return Yup.number().integer()
    .required('Required');
}

export function QueryAction({
  query,
  responsePath,
  name,
  id = null,
  variables = null,
  title = null,
  itemsPath = 'items',
  resourcePath = 'item.resource.hashkey',
  resourceNamePath = null,
  cs = [],
  topicPath = null,
  topicName = null,
  pageKey = null,
  pageParams = null,
}) {
  return {
    query,
    responsePath,
    name,
    id: id || idify(name),
    variables: variables || {},
    title,
    cs,
    itemsPath,
    resourcePath,
    resourceNamePath,
    topicPath,
    topicName,
    pageKey: pageKey || id || idify(name),
    pageParams: pageParams ?? (() => {}),
  };
}

export function MutationAction({
  mutation,
  responsePath,
  name,
  id = null,
  responseStatusPath = null,
  initValues = null,
  title = null,
  variables = null,
  cs = [],
  topicPath = null,
  topicName = null,
  prepareForEdit = null,
  prepareForSave = null,
  pageKey = null,
  pageParams = null,
}) {
  const defaultPrepareForEdit = (self, resource, item) => {
    const data = {};
    const refereces = [];
    self.cs.forEach((vk) => {
      const columnSet = resource.columnSets.find((x) => x.viewKey === vk);
      if (!columnSet) {
        console.log('Column set is missing', resource.columnSets.map((x) => x.viewKey));
      }
      data[vk] = columnSet.prepareForEdit(columnSet, item.item[vk]) ?? {};
      refereces.push(...columnSet.referenceFields.map((x) => `${vk}.${x}`));
    });
    return keyed(data, refereces);
  };

  const defaultPrepareForSave = (self, resource, data) => {
    const prepared = {};
    const refereces = [];
    self.cs.forEach((vk) => {
      const columnSet = resource.columnSets.find((x) => x.viewKey === vk);
      prepared[vk] = columnSet.prepareForSave(columnSet, data[vk]);
      refereces.push(...columnSet.referenceFields.map((x) => `${vk}.${x}`));
    });
    return unKey(prepared, refereces);
  };

  const shape = (self, resource) => {
    const prepared = {};
    self.cs.forEach((vk) => {
      const columnSet = resource.columnSets.find((x) => x.viewKey === vk);
      prepared[vk] = columnSet.shape;
    });
    return Yup.object().shape(prepared);
  };

  return {
    mutation,
    responsePath,
    name,
    id: id || idify(name),
    responseStatusPath,
    variables: variables || {},
    initValues: initValues || {},
    title,
    cs,
    topicPath,
    topicName,
    prepareForEdit: prepareForEdit ?? defaultPrepareForEdit,
    prepareForSave: prepareForSave ?? defaultPrepareForSave,
    defaultPrepareForEdit,
    defaultPrepareForSave,
    shape,
    pageKey: pageKey || id || idify(name),
    isMutation: true,
    pageParams: pageParams ?? (() => {}),
  };
}

function inheritActionFromResource(res, action, id) {
  const resolved = { ...action };
  resolved.responsePath = resolved.responsePath || `sports_${res.resourceId}_${id}`;
  resolved.resourceNamePath = resolved.resourceNamePath || res.resourceNamePath;
  resolved.responseStatusPath = resolved.responseStatusPath || `${resolved.responsePath}.status`;
  resolved.name = resolved.name || capitilise(id);
  resolved.title = resolved.title || resolved.name;
  resolved.id = resolved.id || idify(resolved.name);
  // resolved.topicPath = resolved.topicPath || `${res.topicPath}/${resolved.id}`;
  resolved.topicPath = resolved.topicPath || `${res.topicPath}/editor`;
  resolved.topicName = resolved.topicName || `${res.topicName} ${resolved.name}`;
  resolved.pageKey = resolved.pageKey || id;
  return resolved;
}

export function ColumnSet({
  name,
  viewKey,
  historyKey,
  shape,
  referenceFields,
  prepareForEdit,
  prepareForSave,
}) {
  const defaultPrepareForEdit = (self, cs) => {
    return cs;
  };
  const defaultPrepareForSave = (self, data) => {
    return removeDeletedValues(data);
  };

  return {
    name,
    viewKey,
    historyKey,
    shape: shape ?? Yup.object(),
    referenceFields: referenceFields ?? [],
    prepareForEdit: prepareForEdit ?? defaultPrepareForEdit,
    prepareForSave: prepareForSave ?? defaultPrepareForSave,
    defaultPrepareForEdit,
    defaultPrepareForSave,
  };
}

function inheritColumnSetFromResource(res, cs) {
  const resolved = { ...cs };
  resolved.historyKey = resolved.historyKey || `${capitilise(res.resourceId, 'x')}${resolved.name}`;
  resolved.viewKey = resolved.viewKey || resolved.name.toLowerCase();
  return resolved;
}

export function Resource({
  resourceId,
  resourceKeys = ['key'],
  name,
  // Resource Type Name
  storeId,
  // Dataview Name
  viewId,
  keyVal,
  storeVal,
  topicPath,
  topicName,
  columnSets,
  listPrimaryActions,
  queries,
  mutations,
  pageKey,
  subPagekey,
  imageRender = null,
  accountScope = true,
  sportScope = true,
  resourceNamePath = 'item.item.key',
  renderResourceKey = null,
  renderContentResourceType = null,
  renderResourceURL = null,
}) {
  const pageFor = (self, suffix) => {
    const pageSuffix = suffix ? `.${suffix}` : '';
    const page = dataFromPath(Pages, `${self.pageKey}${pageSuffix}`);
    return page;
  };

  const resolvedKey = (self, resource) => {
    // console.log('key', resource);
    return resource.hashkey;
    // const values = [];
    // const resolvedResource = {};
    // // console.log('resolved key for', resource, self.resourceKeys, resolvedResource);
    // self.resourceKeys.forEach((keyName) => {
    //   const val = dataFromPath(resource, keyName);
    //   resolvedResource[keyName] = val;
    //   values.push([keyName, val]);
    // });
    // if (values.length === 1) {
    //   return `k-${values[0][1]}`;
    // }

    // return `h-${hash(resolvedResource)}`;
  };

  const resourceFromKey = (self, key) => {
    // console.log('Get key from', self.resourceKeys, key);
    if (key.length > 2 && key[1] === '-') {
      return {
        _hashkey: key,
      };
    }

    const resource = {
      key,
    };

    // const resource = { };
    // if (key.startsWith('k-')) {
    //   setValueOnPath(resource, key.substring(2), self.resourceKeys[0], true);
    // } else if (key.startsWith('h-')) {
    //   const raw = unhash(key.substring(2));
    //   self.resourceKeys.forEach((keyName) => {
    //     if (raw[keyName] !== undefined) {
    //       setValueOnPath(resource, raw[keyName], keyName, true);
    //     }
    //   });
    // } else {
    //   resource.key = key;
    // }

    if (self.accountScope && !resource.account) {
      resource.account = getAccountKey();
    }

    if (self.sportScope && !resource.sport) {
      resource.sport = getSport();
    }

    return resource;
  };

  const hashedKey = (self, resource) => {
    return resourceHashkey(resource);
  };

  const urlFor = (self, {
    page = null,
    resource = null,
    resourceKey = null,
  }) => {
    const pageSuffix = page ? `.${page}` : '';
    let pageData;
    if (subPagekey) {
      const subKeySuffix = `.${subPagekey}`;
      pageData = dataFromPath(Pages, `${self.pageKey}${subKeySuffix}${pageSuffix}`);
    } else {
      pageData = dataFromPath(Pages, `${self.pageKey}${pageSuffix}`);
    }
    if (resource || resourceKey) {
      const rkey = resourceKey ?? resolvedKey(self, resource);
      const url = pageData.meta.urlFor({ key: rkey });
      return url;
    }

    return pageData.meta.url;
  };

  const resolved = {
    resourceId,
    resourceKeys,
    name: name || resourceId,
    storeId: storeId || name || resourceId,
    keyVal: keyVal || null,
    storeVal: storeVal || null,
    viewId: viewId || `${name || resourceId}View`,
    topicPath: topicPath || resourceId,
    topicName: topicName || name,
    columnSets,
    queries,
    mutations,
    pageKey: pageKey ?? `sports.${resourceId}`,
    subPagekey,
    pageFor,
    urlFor,
    listPrimaryActions: listPrimaryActions ?? ['Update'],
    hashedKey,
    resolvedKey,
    resourceFromKey,
    accountScope,
    sportScope,
    resourceNamePath,
    renderResourceKey,
    renderContentResourceType,
    renderResourceURL,
    imageRender: imageRender ?? ((item) => (
      <ResourceImageView name={item.resource_name} />
    )),
  };

  const hasStar = resolved.columnSets.indexOf((x) => x.viewKey === '*') > -1;
  if (!hasStar) {
    resolved.columnSets = [
      ColumnSet({ name: 'Resource', viewKey: '*', historyKey: 'Resource' }),
      ...columnSets,
    ];
  }

  Object.keys(resolved.queries).forEach((actionid) => {
    const q = resolved.queries[actionid];
    resolved.queries[actionid] = inheritActionFromResource(resolved, q, actionid);
  });

  Object.keys(resolved.mutations).forEach((actionid) => {
    const q = resolved.mutations[actionid];
    resolved.mutations[actionid] = inheritActionFromResource(resolved, q, actionid);
  });

  resolved.columnSets = resolved.columnSets.map((q) => inheritColumnSetFromResource(resolved, q));

  return resolved;
}
