const MsgSep = ';;';

export const MessageTypes = {
  Text: 't',
  JSON: 'j',
  Bool: 'b',
};

export const Message = {
  build(type, event, val, topic = null) {
    let raw = val;

    if (type === MessageTypes.Bool) {
      raw = val ? '1' : '0';
    } else if (type !== MessageTypes.JSON) {
      raw = `${val}`;
    }

    const resolvedTopic = topic || '-';

    if (type === MessageTypes.JSON) {
      return JSON.stringify({
        type,
        event,
        topic: resolvedTopic,
        message: val,
      });
    }

    return [
      type,
      event,
      resolvedTopic,
      raw,
    ].join(MsgSep);
  },

  parse(raw) {
    // const decoder = new TextDecoder();
    // const rawStr = decoder.decode(raw);
    const rawStr = raw;
    if (rawStr.startsWith('{')) {
      return JSON.parse(rawStr);
    }

    const [type, event, topic, ...msg] = rawStr.split(MsgSep);
    const msgRaw = msg.join(MsgSep);
    let message = msgRaw;
    if (type === MessageTypes.Bool) {
      message = message === '1';
    }

    return {
      type,
      event,
      topic,
      message,
    };
  },

  okay(event, val = true, topic = null) {
    return this.bool(event, val, topic);
  },

  bool(event, val, topic = null) {
    return this.build(MessageTypes.Bool, event, val, topic);
  },

  text(event, msg, topic = null) {
    return this.build(MessageTypes.Text, event, msg, topic);
  },

  obj(event, msg, topic = null) {
    return this.build(MessageTypes.JSON, event, msg, topic);
  },
};
