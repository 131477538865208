import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { UserImageView } from 'core/Image';
import { Label12Regular } from 'style/typography';
import { friendlyDateTime } from 'lib/utils';

const AuthorLineWrapper = styled.div`
  display: flex;
  margin-right: ${asRem(4)};
  align-items: center;
  .rz-image-view {
    --image-width: ${asRem(16)};
  }
`;

export function AuthorLine({ author, showTime, className }) {
  return (
    <AuthorLineWrapper className={`rzuser ${className ?? ''}`}>
      <div className="image">
        <UserImageView
          user={{
            profile: {
              picture_url: author.picture_key,
              first_name: author.name,
            },
          }}
        />
      </div>
      <div className="name">
        <Label12Regular>
          {author.name}
          {showTime && ` @ ${friendlyDateTime(author.ts)}`}
          {author.ip ? ` (${author.ip})` : ''}
        </Label12Regular>
      </div>
    </AuthorLineWrapper>
  );
}

AuthorLine.propTypes = {
  author: PropTypes.object.isRequired,
  showTime: PropTypes.bool,
  className: PropTypes.string,
};
