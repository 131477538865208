import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { Text14Regular, Text16Bold } from 'style/typography';
import { UserImageView } from 'core/Image';
import { SwitchAccountDropdown } from '../SwitchAccountDropdown';

const UserSettingsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 ${asRem(20)} ${asRem(20)};

  .user-details {
    display: flex;
    gap: ${asRem(12)};

    .text-14-regular {
      color: #7F8184;
    }
  }
  .name {
    text-transform: capitalize;
  }
`;

export function UserSettingsHeader({ user }) {
  return (
    <UserSettingsHeaderWrapper>
      <div className="user-details">
        <UserImageView
          user={{
            profile: {
              picture_url: user.user.profile.picture_url,
              first_name: user.user.profile.first_name,
            },
          }}
          size={40}
        />
        <div>
          <Text16Bold className="name">
            {user?.user?.profile?.first_name}
            {' '}
            {user?.user?.profile?.last_name || ''}
          </Text16Bold>
          <Text14Regular>Your Settings</Text14Regular>
        </div>
      </div>
      <SwitchAccountDropdown user={user} />
    </UserSettingsHeaderWrapper>
  );
}

UserSettingsHeader.propTypes = {
  user: PropTypes.object,
};
