import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const StadiumCreateHintQuery = gql`
query StadiumCreateHintQuery($name: String!) {
  sports_stadium_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      stadium {
        name
        internal_name
        sport
        multi_sports
        region {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    key_suggestions
  }
}
`;

export const StadiumTimeZonesQuery = gql`
query StadiumTimeZonesQuery {
  sports_stadium_time_zones {
    time_zones
  }
}
`;

export const StadiumCreateMutation = gql`
mutation StadiumCreate(
  $stadium: StadiumStadiumInput!,
  $locale: StadiumLocaleInput!,
  $key: String,
) {
  sports_stadium_create(
    stadium: $stadium, locale: $locale,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const StadiumReadQuery = gql`
query StadiumReadQuery($resource: StadiumInput!, $withHistory: Boolean) {
  sports_stadium_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      stadium {
        name
        internal_name
        sport
        multi_sports
        region {
          account
          key
          hashkey: _hashkey
        }
      }
      bio {
        desc
        capacity
        established
        floodlights
        end_names
        other_names
      }
      locale {
        timezone
        address
        geo_location
      }
      legacy_data {
        key
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const StadiumUpdateMutation = gql`
mutation StadiumUpdateMutation(
  $resource: StadiumInput!,
  $stadium: StadiumStadiumInput!,
  $bio: StadiumBioInput!,
  $locale: StadiumLocaleInput!,
  $version: RecordStage) {
  sports_stadium_update(resource: $resource, stadium: $stadium, bio: $bio, locale: $locale, version: $version) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const StadiumDeleteMutation = gql`
mutation  StadiumDeleteMutation(
  $resource: StadiumInput!,
  $name: String!) {
  sports_stadium_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const StadiumSearchQuery = gql`
query StadiumSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_stadium_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      stadium {
        name
        internal_name
        sport
        multi_sports
        region {
          account
          key
          hashkey: _hashkey
        }
      }
      locale {
        timezone
        address
        geo_location
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const StadiumNameQuery = gql`
query StadiumNameQuery($resource: StadiumInput!) {
  sports_stadium_name(resource: $resource) {
    name
    internal_name
    region_name
    timezone
  }
}
`;
