import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const CurationReadQuery = gql`
 query CurationReadQuery($resource: CurationInput!, $withHistory: Boolean) {
   sports_cricket_curation_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        sport
        resource
        key
        hashkey: _hashkey
      }
      curation {
        name
      }
      curation_resource {
        resources
      }
      legacy_data {
        resources
      }
    }
    history {
      ${VersionHistoryFields}
    }
   }
 }
`;

export const CurationSearchQuery = gql`
 query CurationSearchQuery($search: String!) {
    sports_cricket_curation_search(search: $search) {
        items {
      resource {
        account
        sport
        resource
        key
        hashkey: _hashkey
      }
      curation {
        name
      }
      curation_resource {
        resources
      }
      legacy_data {
        resources
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
    }
 }
`;

export const CurationCreateMutation = gql`
  mutation CurationCreateMutation($name: String!, $resource: CurationResource!, $legacy_data: CurationLegacyDataInput!) {
    sports_cricket_curation_create(name: $name, resource: $resource, legacy_data: $legacy_data) {
        status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    }
  }
`;

export const CurationUpdateMutation = gql`
 mutation CurationUpdateMutation($resource: CurationInput!, $resource_name: CurationResource! $curation: CurationCurationInput!, $legacy_data: CurationLegacyDataInput!) {
    sports_cricket_curation_update(resource: $resource, resource_name: $resource_name, curation: $curation, legacy_data: $legacy_data){
        status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    }
 }
`;

export const CurationDeleteMutation = gql`
  mutation CurationDeleteMutation($resource: CurationInput!) {
    sports_cricket_curation_delete(resource: $resource) {
        status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    }
  }
`;

export const CurationMatchReadQuery = gql`
  query CurationMatchReadQuery($key: String!) {
    source_match_curation_read(key: $key) {
      item {
      resource {
        account
        sport
        key
        _hashkey
      }
      name
      start_at
      format
      status
      tournament
    }
    }
  }
`;

export const CurationTournamentReadQuery = gql`
  query CurationTournamentReadQuery($key: String!) {
    source_tournament_curation_read(key: $key) {
      item {
      key
      name
      short_name
      association_key
      start_date
      start_month
      resource {
        account
        sport
        association
        competition
        key
        _hashkey
      }
    }
    }
  }
`;
