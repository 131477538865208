import { useContext, useEffect, useState } from 'react';
import { Config } from 'config';
import { UserContext } from 'core/UserContext';
import { getToken } from 'core/localStore';
import { Message } from './message';

export const ConnectionState = {
  Init: 'Init',
  Connecting: 'Connecting',
  Connected: 'Connected',
  Error: 'Error',
  Inactive: 'Inactive',
  Disabled: 'Disabled',
  Stopped: 'Stopped',
};

export class SocketClient {
  constructor(onMessage, onStateChange) {
    this.onMessage = onMessage;
    this.onStateChange = onStateChange;
    this.worker = null;
    this.retryIndex = 0;
    this.retryDelay = 1000;
    this.maxRetry = 60 * 60;
    this.retryHandler = null;
    this.authParams = null;
    this.connectingSince = null;
    this.state = ConnectionState.Init;
    this.closeRequested = false;
    window.addEventListener('unload', () => {
      this.stop();
    });
  }

  start(authParams) {
    console.log('⚡️ Starting Socket Worker');
    this.authParams = authParams;
    this.closeRequested = false;
    this.worker = this.connect();
  }

  stop() {
    console.log('⚡️ Stop Socket Worker');
    this.state = ConnectionState.Stopped;
    this.closeRequested = true;
    if (this.worker && this.worker.readyState === WebSocket.OPEN) {
      this.worker.close();
    }
    this.resetRetry();
    this.worker = null;
    this.authParams = null;
  }

  send(event, msg, topic) {
    if (WebSocket.OPEN) {
      this.worker.send(Message.obj(event, msg, topic));
    } else {
      console.error('error', this.worker.send(Message.obj(event, msg, topic)));
    }
  }

  changeState(state) {
    const oldState = `${this.state}`;
    this.state = state;
    this.onStateChange(state, oldState);
  }

  connect() {
    if (!Config.rtHost) {
      console.log('Real Time activity is disabled');
      this.changeState(ConnectionState.Disabled);
      return {
        send: () => {},
      };
    }

    console.log('Connecting to websocket', Config.rtHost);
    this.connectingSince = new Date();
    this.changeState(ConnectionState.Connecting);
    const worker = new WebSocket(Config.rtHost);

    worker.addEventListener('open', () => {
      this.resetRetry();
      worker.send(Message.obj('auth', this.authParams));
    });

    worker.addEventListener('close', () => {
      if (!this.closeRequested) {
        console.log('Worker Connection Closed');
        this.retry();
      }
    });

    worker.addEventListener('message', ({ data: message }) => {
      const msg = Message.parse(message);
      switch (msg.event) {
        case 'connected':
          console.log('Connected to worker');
          this.changeState(ConnectionState.Connected);
          this.onMessage(msg);
          break;
        default:
          this.onMessage(msg);
          break;
      }
    });

    worker.addEventListener('error', (...args) => {
      console.log('Error on worker', args);
      this.changeState(ConnectionState.Error);
      this.retry();
    });

    return worker;
  }

  resetRetry() {
    clearInterval(this.retryHandler);
    this.retryIndex = 0;
    this.retryHandler = null;
  }

  retry() {
    if (this.retry > this.maxRetry) {
      this.resetRetry();
      this.changeState(ConnectionState.Error);
      return;
    }

    if (!this.retryHandler) {
      this.retryHandler = setInterval(() => {
        console.log('Trying to reconnect', this.retryIndex);
        this.retryIndex += 1;
        this.worker = this.connect();
      }, this.retryDelay);
    }
  }
}

export function useSocket({ onMessage }) {
  const userContext = useContext(UserContext);
  const [socketState, setSocketState] = useState(ConnectionState.Init);
  const [socket, setSocket] = useState(null);

  if (!socket) {
    const newSocket = new SocketClient(onMessage, (newState) => {
      setSocketState(newState);
    });
    setSocket(newSocket);
  }

  useEffect(() => {
    const authParams = {
      rztoken: getToken(),
      rzdevicekey: userContext.deviceKey,
      rzaccountkey: userContext.accountKey,
    };

    if (
      socket
      && (
        socketState === ConnectionState.Init
        || socketState === ConnectionState.Stopped
      )
      && authParams.rztoken
      && userContext.loggedIn
      && authParams.rzaccountkey
    ) {
      socket.start(authParams);
    } else if (
      socket
      && socketState === ConnectionState.Connected
      && authParams.rztoken
      && userContext.loggedIn
      && socket.authParams
      && (
        socket.authParams.rztoken !== authParams.rztoken
        || socket.authParams.rzdevicekey !== authParams.rzdevicekey
        || socket.authParams.rzaccountkey !== authParams.rzaccountkey)
    ) {
      // User login changed
      console.log('⚡️ Reconfig Socket Worker', socket.authParams, authParams);
      socket.stop();
      socket.start(authParams);
    } else if (
      socket
      && socketState === ConnectionState.Connected
      && !userContext.loggedIn
    ) {
      // User logout
      socket.stop();
    }
  }, [
    userContext.accountKey,
    userContext.deviceKey,
    userContext.loggedIn,
    socketState,
    socket,
  ]);

  useEffect(() => {
    if (socket?.state && socketState !== socket?.state) {
      setSocketState(socket.state);
    }
  }, [socket?.state]);

  return {
    socketState,
    socket,
  };
}
