import { gql } from '@apollo/client';

export const MyProfileQuery = gql`
query MyProfileQuery {
  user_profile {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    user {
      resource {
        key
        hashkey: _hashkey
      }
      profile {
        email
        first_name
        last_name
        picture_url
        phone
        username
        segment {
          key
          hashkey: _hashkey
        }
      }
      status {
        active
        created_by_apikey
        segment {
          key
          hashkey: _hashkey
        }
      }
      roles {
        roles_v2
      }
      accounts {
        accounts {
          account {
            key
            hashkey: _hashkey
          }
          role_v2
        }
        invitations_count
      }
    }
  }
}
`;
