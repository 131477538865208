export const Config = {
  appVersion: process.env.REACT_APP_VERSION,
  gqlSrc: process.env.REACT_APP_RZ_GQL_SRC,
  apiUrl: process.env.REACT_APP_RZ_API_URL,
  apiKey: process.env.REACT_APP_RZ_API_KEY,
  IsProd: process.env.NODE_ENV === 'production',
  Locale: process.env.REACT_APP_RZ_LOCALE || 'en-US',
  rtHost: process.env.REACT_APP_RZ_RT_HOST,
  staticUrl: process.env.REACT_APP_RZ_STATIC_URL || '',
  sentryURL: process.env.REACT_APP_SENTRY_CDN,
  enableSentryLogs: (process.env.REACT_APP_RZ_STATIC_URL || '0') !== '0',
  tbc: { name: 'TBC', key: 'TBC' },
  maxPictureSize: 10 * 1024 * 1024,
  currencyFactor: 1000,
  defaultCurrency: 'INR',
  firstYear: 2020,
  fyMonth: 4,
  defaultTheme: 'Dark',
  loginURL: process.env.REACT_APP_RZ_LOGIN_URL || 'https://sports.dev.roanuz.com/backend/business/login/',
  antsPrdouctList: process.env.REACT_APP_CRICKET_HOST || 'https://www.cricketapi.com/',
  blackbirdURL: process.env.REACT_APP_BLACKBIRD_HOST || 'https://ants-blackbird.litzscore.com',
  useLocalLogin: process.env.REACT_APP_RZ_USE_LOCAL_LOGIN === '1',
  falconURL: process.env.REACT_APP_FALCON_HOST || 'https://falcon.sports.dev.roanuz.com/',
  showSandbox: process.env.REACT_APP_SHOW_SANDBOX === '1',
  rzAccountKey: process.env.REACT_APP_RZ_ACCOUNT_KEY,
};
