import { gql } from '@apollo/client';

export const BootQuery = gql`
query RumBootQuery {
  ants_boot {
    version
    api_version
    force_refresh
    force_logout
  }
}
`;

export const UserQuery = gql`
query UserQuery($key: String!) {
  user(key: $key) {
    user {
      resource {
        key, hashkey: _hashkey
      }
      name {
        first_name
        last_name
        picture_url
      }
    } 
  }
}
`;

export const EnumValuesQuery = gql`
query EnumValuesQuery($name: String!) {
   __type(name: $name) {
     enumValues {
       name 
     }
   }
}
`;

export const TypeDefQuery = gql`
query TypeDefQuery($name: String!) {
  __type(name: $name) {
    inputFields {
      name
      description
      type {
        kind
        name
        description
        possibleTypes {
          kind
          name
        }
        enumValues(includeDeprecated: false) {
          name
        }
        inputFields(includeDeprecated: false) {
          name
          type {
            kind
          }
          defaultValue
        }
        ofType {
          kind
          name
          enumValues(includeDeprecated: false) {
            name
          }
        }
      }
      defaultValue
    }
  }
}
`;
export const AccountInvitesQuery = gql`
query AccountInvitesQuery($pageKey: String, $sort_by: [PagedRequestSortFieldInput!]){
  account_invites(page_key: $pageKey, sort_by: $sort_by) {
    accounts {
      resource {
        key
        hashkey: _hashkey
      }
      profile {
        name
        picture_url
      }
      status {
        active
        segment {
          key
          hashkey: _hashkey
        }
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const fetchSportsQuery = gql`
query fetchSportsQuery {
  sports_sports {
    all_sports
  }
}
`;
