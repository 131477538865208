import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const Update = lazy(() => import('./Update'));
const Teams = lazy(() => import('./Teams'));
const Rounds = lazy(() => import('./Rounds'));
const Image = lazy(() => import('../UpdateImage'));
const Results = lazy(() => import('./Results'));
const Rules = lazy(() => import('./Rules'));
const TeamVersions = lazy(() => import('../Version'));
const Status = lazy(() => import('./UpdateStatus'));
const TournamentTeamPlayerRoleUpdate = lazy(() => import('./RoundPlayers'));
const TournamentTeamPlayers = lazy(() => import('./SeasonalTeamPlayersUpdate'));

export function TournamentUpdateRoutes() {
  return (
    <Routes>
      <Route
        path={Pages.sports.cricket.tournament.update.basic.meta.path}
        element={(<Update />)}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.teams.meta.path}
        element={(<Teams />)}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.rounds.meta.path}
        element={(<Rounds />)}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.playerRoles.meta.path}
        element={<TournamentTeamPlayerRoleUpdate />}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.players.meta.path}
        element={<TournamentTeamPlayers />}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.results.meta.path}
        element={(<Results />)}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.status.meta.path}
        element={(<Status />)}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.rules.meta.path}
        element={(<Rules />)}
      />
      <Route
        path={Pages.sports.cricket.tournament.update.image.meta.path}
        element={(<Image />)}
      />
      <Route path={Pages.sports.cricket.tournament.update.results.meta.path}>
        <Route
          path={Pages.sports.cricket.tournament.update.results.versions.meta.path}
          element={(<TeamVersions />)}
        />
      </Route>
      <Route path={Pages.sports.cricket.tournament.update.rules.meta.path}>
        <Route
          path={Pages.sports.cricket.tournament.update.rules.versions.meta.path}
          element={(<TeamVersions />)}
        />
      </Route>
    </Routes>
  );
}
