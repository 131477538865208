import React from 'react';
import PropTypes from 'prop-types';
import { asRem } from 'lib/css';
import styled from 'styled-components';
import { GetController } from 'core/EditController';
import { TeamImageView } from './ResourceImage';
import { TeamResource } from './resource';

const TeamNameViewWrapper = styled.div`
`;

const TeamNameItemWrapper = styled.div`
  display: flex;
  gap: ${asRem(8)};
  align-items: center;
`;

export function TeamNameItem({ team, size }) {
  return (
    <TeamNameItemWrapper className="team-name">
      <TeamImageView item={team} size={size || 16} />
      <div>
        {team.team.name}
        {' '}
        {team.team?.gender ? `(${team.team.gender})` : ''}
      </div>
    </TeamNameItemWrapper>
  );
}

TeamNameItem.propTypes = {
  team: PropTypes.string,
  size: PropTypes.string,
};

export function TeamNameView({ resourceKey, item }) {
  const resolvedKey = resourceKey ?? item.hashkey;
  return (
    <TeamNameViewWrapper>
      <GetController
        resourceDef={TeamResource}
        query={TeamResource.queries.read.query}
        responseKey="sports_team_read"
        resourceKey={resolvedKey}
        render={((resp) => (
          <TeamNameItem team={resp.item} />
        ))}
      />
    </TeamNameViewWrapper>
  );
}

TeamNameView.propTypes = {
  resourceKey: PropTypes.string,
  item: PropTypes.string,
};
