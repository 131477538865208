import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import {
  FormBoundary, Form,
} from 'core/Form';
import * as Yup from 'yup';
import { FormItem } from 'core/FormFieldItem';
import { asRem } from 'lib/css';
import { MyProfileQuery } from 'user/query';
import { ResponseStatus, StatusError, StatusLoading } from 'core/Status';
import { UserContext } from 'core/UserContext';
import { saveToken } from 'core/localStore';
import { Config } from 'config';
import { Pages } from 'core/pages';
import RoanuzLogo from 'assets/landing-page/RoanuzLogo.png';
import AntsLogo from 'assets/landing-page/AntsLogo.svg';
import GoogleIcon from 'assets/landing-page/GoogleIcon.svg';
import { LoginMutation } from './query';

export function CustomerPagesHeader({ title }) {
  const userContext = useContext(UserContext);

  const CustomerPagesHeaderWrapper = styled.div`
    margin-bottom: ${asRem(40)};
    text-align: center;

    .rz-logo {
      background-color: var(--color-white);
      border-radius: 50%;
      width: ${asRem(42)};
      height: ${asRem(42)};
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      img {
        width: ${asRem(22)};
      }
    }
    .product-logo {
      margin-top: ${asRem(40)};
      background: none;
      padding: 0;
    }
    .page-title {
      margin-top: ${asRem(40)};
      font-size: ${asRem(28)};
      line-height: ${asRem(36.46)};
      font-weight: 400;
    }
  `;

  return (
    <CustomerPagesHeaderWrapper>
      <div className="rz-logo">
        <img
          src={RoanuzLogo}
          alt="logo"
        />
      </div>
      <button type="button" className="product-logo" onClick={() => userContext.setShowLogin(false)}>
        <img
          src={AntsLogo}
          alt="logo"
        />
      </button>
      <div className="page-title">
        {title}
      </div>
    </CustomerPagesHeaderWrapper>
  );
}

CustomerPagesHeader.propTypes = {
  title: PropTypes.string,
};

const LoginPageWrapper = styled.div`
  /* common  */
  --color-button-bg: #D2FD49;
  --color-white: #ffffff;
  --color-dark-theme: #0D1116;
  --color-text-dark: #11161B;
  --color-link-blue: #80A9D7;
  
  padding: ${asRem(20)};
  color: var(--color-white);
  font-family: 'DM Sans', sans-serif;
  overflow: hidden;
  background-color: var(--color-dark-theme);

  a, button {
    color: var(--color-white);
    transition: 0.3s ease;
    font-size: ${asRem(14)};
    line-height: ${asRem(18)};
    &:hover {
      color: var(--color-link-blue);
    }
    &.plain {
      color: var(--color-link-blue);
      text-decoration: none;
      &:hover {
        color: var(--color-button-bg);
      }
    }
    &.link-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${asRem(10)};
      margin-bottom: ${asRem(12)};
      font-weight: 500;
      border: ${asRem(1)} solid var(--color-white);
      height: ${asRem(50)};
      border-radius: ${asRem(4)};
      color: var(--color-white);
      transition: 0.3s ease;
      text-decoration: none;
      &:hover {
        background-color: var(--color-white);
        color: var(--color-text-dark);
      }
      &.outlined {
        border: ${asRem(1)} solid var(--color-white);
        background: none;
        width: 100%;
        &:hover {
          color: var(--color-text-1);
        }
      }
      &.g-link {
        background-color: var(--color-white);
        color: var(--color-text-dark);
        &:hover {
          background-color: transparent;
          color: var(--color-white);
        }
      }
      &.filled {
        background-color: var(--color-button-bg);
        border-color: var(--color-button-bg);
        color: var(--color-text-dark);
        width: 100%;
        &:hover {
          background-color: transparent;
          color: var(--color-button-bg);
        }
      }
    }
  }
  /* common  */


  .container {
    margin: ${asRem(80)} auto;
    width: 100%;
    @media screen and (min-width: ${asRem(768)}) {
      width: ${asRem(340)};
    }

    .rz-link {
      margin-top: ${asRem(80)};
      text-align: center;
      a {
        font-size: inherit;
        text-decoration: underline;
        padding: 0;
        background: none;
      }
    }
    .other-links {
      margin-bottom: ${asRem(12)};
    }
    .login-btn {
      margin-bottom: ${asRem(40)};
    }
    .input-box {
      input {
        background-color: #161C23;
      }
    }
  }
`;

export function LoginPage({ onLogin = null }) {
  const userContext = useContext(UserContext);
  const formInitValues = {
    password: '',
    email: '',
  };

  const FormSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Please enter your password'),
    email: Yup.string().email('Invalid email').required('Please enter your email'),
  });

  const [token, setToken] = useState();

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(MyProfileQuery, {
    skip: !token,
  });

  const [
    doLogin,
    { loading: loginLoding, error: loginError, data: loginData },
  ] = useMutation(LoginMutation);

  const loading = queryLoading || loginLoding;
  const error = queryError || loginError;
  let status = null;
  if (queryData) {
    status = queryData.user_profile.status;
  } else if (loginData) {
    status = loginData.user_login.status;
  }

  useEffect(() => {
    if (queryData && queryData.user_profile && !userContext.user) {
      userContext.setUser(queryData.user_profile.user);
      if (onLogin) {
        onLogin(queryData.user_profile.user);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const onSubmit = (values) => {
    doLogin({
      variables: {
        email: values.email,
        password: values.password,
      },
    }).then((resp) => {
      const respData = resp && resp.data && resp.data.user_login;
      if (respData && respData.token) {
        console.log('Performing Login');
        saveToken(respData.token);
        setToken(respData.token);
      } else {
        console.log('Unexpected Response', resp);
      }
    });
  };
  const [showLoginBlock, SetShowLoginBlock] = useState(false);
  const parts = window.location.href.split('/');
  const basePath = `${parts[0]}//${parts[2]}`;
  const nextUrl = `${basePath}${Pages.pages.onLoginCallback.meta.url}?next=${encodeURIComponent(window.location.href)}`;
  const googleLoginUrl = `${Config.apiUrl}user/oauth/google-auth/?json=0&next_url=${encodeURIComponent(nextUrl)}`;

  return (
    <LoginPageWrapper>
      {!showLoginBlock && (
        <div className="container">
          <CustomerPagesHeader title="Let’s get started" />
          <a className="link-button g-link" href={googleLoginUrl}>
            <img
              src={GoogleIcon}
              alt="logo"
            />
            Continue with Google
          </a>
          <button type="button" className="link-button outlined" onClick={() => SetShowLoginBlock(true)}>
            Continue with Email
          </button>
          <p className="rz-link">
            Product of
            {' '}
            <a href="https://roanuz.com/">Roanuz</a>
          </p>
        </div>
      )}
      {showLoginBlock && (
        <div className="container">
          <CustomerPagesHeader title="Sign in with email" />
          <FormBoundary
            initialValues={formInitValues}
            validationSchema={FormSchema}
            onSubmit={onSubmit}
          >
            {({ submitForm }) => (
              <Form>
                <FormItem label="Email" name="email" type="email" placeholder="Enter your email" />
                <FormItem label="Password" name="password" type="password" placeholder="Enter your password" />
                <div className="form-status">
                  {loading && (<StatusLoading message="Hold on..." />)}
                  {error && (<StatusError error={error} />)}
                  {status && (<ResponseStatus status={status} />)}
                </div>
                <button
                  className="link-button filled"
                  type="button"
                  onClick={(event) => {
                    submitForm(event);
                  }}
                >
                  Login
                </button>
              </Form>
            )}
          </FormBoundary>
        </div>
      )}
    </LoginPageWrapper>
  );
}

LoginPage.propTypes = {
  onLogin: PropTypes.func,
};
