import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import PropTypes from 'prop-types';
import { asRem } from 'lib/css';
import { Box } from 'core/Box';

const LayoutWrapper = styled.div`
  .content-container {
    display: flex;
    gap: ${asRem(28)};
  
    .left-container {
      flex: 0 0 ${asRem(320)};

      ul {
        li {
          padding-bottom: ${asRem(16)};
          margin-bottom: ${asRem(16)};
          border-bottom: 1px solid var(--color-border-1);
          cursor: pointer;

          button, > a {
            display: flex;
            align-items: center;
            gap: ${asRem(12)};
            padding: 0;
            color: var(--color-text-1);
            &.active {
              color: var(--color-secondary-cta);
            }
          }

          .text-14-regular {
            display: flex;
          }

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding: 0;
          }
        }
        &.links {
          li {
            >a  {
              gap: ${asRem(20)};
              svg {
                width: ${asRem(20)};
                height: ${asRem(20)};
              }
            }
          }
        }
      }
    }
    .right-container {
      flex: auto;
    }
  }
`;

export function Layout({ renderLeftContent, renderRightContent, renderHeaderContent }) {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setLayoutWidth(AppLayoutWidth.Six);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutWrapper>
      <div className="header-container">
        {renderHeaderContent && renderHeaderContent()}
      </div>
      <div className="content-container">
        <div className="left-container">
          {renderLeftContent && renderLeftContent()}
        </div>
        <div className="right-container">
          <Box>
            {renderRightContent && renderRightContent()}
          </Box>
        </div>
      </div>
    </LayoutWrapper>
  );
}

Layout.propTypes = {
  renderHeaderContent: PropTypes.func,
  renderLeftContent: PropTypes.func,
  renderRightContent: PropTypes.func,
};
