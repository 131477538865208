import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResourceImageView } from 'core/Image';
import { ReactComponent as DefaultPlayerIcon } from 'assets/icons/player-image-dummy.svg';
import { asRem } from 'lib/css';

const StyledResourceImageView = styled(ResourceImageView)`
  .rz-image-view {
    --image-color: #FF95E8;
    --image-border-radius: ${asRem(5)};
  }
`;

export function PlayerImageView({
  item, size, showDefaultImage, ...imageProbs
}) {
  const image = item.image?.image_url || item?.player?.image_url;
  return (
    <div>
      {!showDefaultImage
        ? (
          <StyledResourceImageView
            name={item.resource_name ?? item.player?.name}
            url={image || item?.resource_image_url}
            size={size || 48}
            rounded
            {...imageProbs}
          />
        )
        : <DefaultPlayerIcon className={`size-${size}`} />}
    </div>

  );
}

PlayerImageView.propTypes = {
  item: PropTypes.object,
  size: PropTypes.string,
  showDefaultImage: PropTypes.bool,
};
