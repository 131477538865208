import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ClubCreateHintQuery = gql`
query ClubCreateHintQuery($name: String!) {
  sports_club_create_hint(name: $name) {
  name
    official_name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      club {
        name
        official_name
        region {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    key_suggestions
  }
}
`;

export const ClubCreateMutation = gql`
mutation ClubCreate(
  $club: ClubClubInput!,
  $key: String,
) {
  sports_club_create(
    club: $club, key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ClubReadQuery = gql`
query ClubReadQuery($resource: ClubInput!, $withHistory: Boolean) {
  sports_club_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      club {
        name
        official_name
        region {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ClubUpdateMutation = gql`
mutation ClubUpdateMutation(
  $resource: ClubInput!,
  $club: ClubClubInput!,
  $version: RecordStage) {
  sports_club_update(resource: $resource, club: $club, version: $version) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ClubDeleteMutation = gql`
mutation  ClubDeleteMutation(
  $resource: ClubInput!,
  $name: String!) {
  sports_club_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ClubSearchQuery = gql`
query ClubSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_club_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      club {
        name
        official_name
        region {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ClubNameQuery = gql`
query ClubNameQuery($resource: ClubInput!) {
  sports_club_name(resource: $resource) {
    name
  }
}
`;
