import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Config } from 'config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initApollo } from './core/apolloClient';
import { GlobalResetStyle } from './style/reset';
import { GlobaVariables } from './style/variables';
import { GlobalStyle } from './style/style';

const apolloClient = initApollo();
const root = createRoot(document.getElementById('root'));

if (Config?.enableSentryLogs) {
  Sentry.init({
    dsn: Config.sentryURL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <GlobalResetStyle />
        <GlobaVariables />
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
