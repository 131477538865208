import { gql } from '@apollo/client';

const VersionRecordFields = `
raw
ts
action
att_stage
authors {
  key
  name
  ts
  email
  picture_key
  ip
}
`;

export const VersionHistoryFields = `
cs {
  records {
    ${VersionRecordFields}
  }
  cs_name
  version_key_meta { resource_id, resource_key, cs_name }
  draft {
    ${VersionRecordFields}
  }
  ready_to_publish {
    ${VersionRecordFields}
  }
  next_page_key 
}
`;

export const VersionHistoryQuery = gql`
query VersionHistoryQuery($viewId: String!, $keyHash: String!, $pageKey: RzLong) {
  sports_version_history(view_id: $viewId, key_hash: $keyHash, page_key: $pageKey) {
    ${VersionHistoryFields}
  }
}
`;

export const VersionCreateStage = gql`
mutation VersionCreateStage(
    $version: DocVersionInput!,
    $stage: RecordStage!,
    # $version: RzLong!,
) {
  sports_create_stage(
    version: $version
    stage: $stage
  ) {
    okay
    errors {
      msg
      field_path
      code
    }
    key
  }
}
`;
