import React, {
  useState, useRef, useEffect,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { asRem } from 'lib/css';
import { Text14Regular } from 'style/typography';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-dropdown.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/icon-check-white.svg';
import { ReactComponent as AllAcountsIcon } from 'assets/icons/icon-allAccounts.svg';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Pages } from 'core/pages';
import { useQuery } from '@apollo/client';
import { StatusLoading, StatusError } from 'core/Status';
import { AccountImageView } from 'core/Image';
import { AccountsListQuery } from './query';

export function DropdownOptions({ user }) {
  const navigate = useNavigate();
  const accountKey = useParams().key;

  const onSelected = (key) => {
    if (key) {
      navigate(Pages.account.settings.dashboard.meta.urlFor({ key }));
      window.location.reload();
    }
  };

  const { data, loading, error } = useQuery(AccountsListQuery);
  return (
    <div className="user-dropdown-elements">
      {loading && <StatusLoading message="Loading..." />}
      { error && <StatusError error={error} />}
      <ul>
        { data && data.account_list.accounts.map((account) => (
          <li>
            <button
              type="button"
              onClick={() => onSelected(account.resource.key)}
            >
              <div>
                <AccountImageView
                  account={{
                    profile: {
                      picture_url: (data && account.profile.picture_url) || null,
                      name: data && account.profile.name,
                    },
                  }}
                  size="28"
                />
                <Text14Regular as="span">{account.profile.name}</Text14Regular>
              </div>
              {user.account.resource.key === account.resource.key && (
              <CheckIcon />
              )}
            </button>
          </li>
        ))}
        {(user.user.roles.roles_v2.includes(1) || user.user.roles.roles_v2.includes(50)) && (
        <li>
          <Link to={Pages.account.settings.search.meta.urlFor(
            { key: accountKey },
          )}
          >
            <div>
              <AllAcountsIcon />
              <Text14Regular as="span">All Accounts</Text14Regular>
            </div>
          </Link>
        </li>
        )}
      </ul>
    </div>
  );
}

DropdownOptions.propTypes = {
  user: PropTypes.object,
};

const SwitchAccountDropdownWrapper = styled.div`
  position: relative;

  button {
    display: flex;
    gap: ${asRem(10)};
    align-items: center;
    svg {
      fill: currentColor;
    }
  }

  .user-dropdown-elements {
    position: absolute;
    right: 0;
    top: ${asRem(56)};
    padding: ${asRem(28)};
    border-radius: ${asRem(8)};
    background: var(--color-border-1);
    width: ${asRem(280)};
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    z-index: 999;
  
    &>ul {
      li {
        cursor: pointer;
        padding-bottom: ${asRem(16)};
        border-bottom: ${asRem(1)} solid var(--color-border-2);
        margin-bottom: ${asRem(16)};
        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
        &:empty {
          padding: 0;
          margin: 0;
          border: none;
        }
        
        .text-14-regular {
          display: inline-block;
          text-overflow: ellipsis;
          cursor: pointer;
          max-width: ${asRem(120)};
          white-space: nowrap;
          overflow: hidden;
        }

        &>a, &>button {
          padding: 0;
          background: none;
          color: var(--color-text-1);
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            color: var(--color-button-primary-bg);
          }
          > div {
            display: flex;
            align-items: center;
            gap: ${asRem(12)};
          }
        }
      }
    }
  }
`;

export function SwitchAccountDropdown({ user }) {
  const [userToggle, setUserToggle] = useState(false);
  const container = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (container.current && !container.current.contains(e.target)) {
        setUserToggle(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  return (
    <SwitchAccountDropdownWrapper>
      <button
        className="plain bordered"
        type="button"
        onClick={() => setUserToggle(!userToggle)}
        onKeyPress={() => setUserToggle(!userToggle)}
      >
        Switch Account
        <DropdownIcon />
      </button>
      {userToggle && (
        <div ref={container}>
          <DropdownOptions user={user} />
        </div>
      )}
    </SwitchAccountDropdownWrapper>
  );
}

SwitchAccountDropdown.propTypes = {
  user: PropTypes.object,
};
