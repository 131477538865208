/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { StatusLoading, StatusError } from 'core/Status';
import { Display20Bold, Text14Regular, Text16Bold } from 'style/typography';
import { asRem } from 'lib/css';
import { isDeletedValue } from 'core/Form';

const RankinPopOverWrapper = styled.div`

  padding: ${asRem(20)};

  .title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${asRem(20)};
  }

  .item {
    padding: ${asRem(16)};
    border: 1px solid var(--color-border-1);
    border-radius: ${asRem(12)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(last-child) {
      margin-bottom: ${asRem(10)};
    }
  }

  .container {
    display: flex;
    gap: ${asRem(12)};
    align-items: center;
  }

  .content {
    display: flex;
    gap: ${asRem(20)};
  }
`;

export function RankingPopOver({
  skipKeys = [],
  readQuery,
  title,
  pathName,
  renderConfirmationButton,
  renderImage,
  renderContent,
}) {
  const [skipKey, setSkipKey] = useState(skipKeys
    .filter((item) => !isDeletedValue(item)).map((item) => item[title]?.hashkey));
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState('');

  const {
    data, loading, error, refetch,
  } = useQuery(readQuery, {
    variables: {
      search: search || '',
    },
  });

  let items = [];

  if (data && data[pathName]) {
    items = data[pathName]?.items;
  }

  useEffect(() => {
    refetch();
  }, [search]);

  return (
    <RankinPopOverWrapper>
      {loading && <StatusLoading message="Loading..." />}
      {
        error && <StatusError error={error} />
      }
      <div>
        <div className="title-block">
          <Display20Bold>
            Select
            {' '}
            {title}
          </Display20Bold>
          {renderConfirmationButton && renderConfirmationButton({ selectedItems })}
        </div>
        <div className="content">
          <div>
            <div>
              <input type="search" onChange={(e) => setSearch(e.target.value)} />
            </div>
            {items && items?.length > 0 && items.map((item) => (
              <div className="item">
                <div className="container">
                  {renderImage && renderImage(item)}
                  {renderContent && renderContent(item)}
                </div>
                {!skipKey.includes(item?.resource?.hashkey) ? (
                  <button
                    type="button"
                    className="small plain bordered add"
                    onClick={() => {
                      setSkipKey([...skipKey, item?.resource?.hashkey]);
                      setSelectedItems([...selectedItems, item]);
                    }}
                  >
                    Add
                  </button>
                ) : <Text14Regular>Added</Text14Regular>}
              </div>
            ))}
          </div>
          <div>
            {selectedItems.length > 0 && <Display20Bold>Selected Teams</Display20Bold>}
            {
            selectedItems.length > 0 && selectedItems.map((item) => (
              <div className="item">
                <div className="container">
                  {renderImage && renderImage(item)}
                  <div>
                    <Text16Bold>{item?.team?.name}</Text16Bold>
                    <Text14Regular>{item?.team?.gender}</Text14Regular>
                  </div>
                </div>
                <button
                  type="button"
                  className="small plain bordered remove"
                  onClick={() => {
                    const filteredKeys = skipKey
                      .filter((value) => value !== item?.resource?.hashkey);
                    const filteredItems = selectedItems
                      .filter((value) => value?.resource?.hashkey !== item?.resource?.hashkey);
                    setSkipKey([...filteredKeys]);
                    setSelectedItems([...filteredItems]);
                  }}
                >
                  Remove
                </button>
              </div>
            ))
          }
          </div>
        </div>
      </div>
    </RankinPopOverWrapper>

  );
}

RankingPopOver.propTypes = {
  skipKeys: PropTypes.array,
  readQuery: PropTypes.object,
  title: PropTypes.string,
  pathName: PropTypes.string,
  renderConfirmationButton: PropTypes.func,
  renderImage: PropTypes.func,
  renderContent: PropTypes.func,
};

export default RankingPopOver;
