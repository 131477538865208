import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { asRem, fwsh } from 'lib/css';
import { EnumValuesQuery } from 'core/query';
import { dataFromPath, formatMoney } from 'lib/utils';
import { Config } from 'config';
import { ActiveUserView } from 'core/Image';
import { ReactComponent as DraftIcon } from 'assets/icons/icon-draft.svg';
import { ReactComponent as PublishIcon } from 'assets/icons/icon-publish.svg';
import { RealtimeEditorContext } from 'ants/RealtimeEditorContext';
import { Label12Regular, Text14Regular } from 'style/typography';
import { SlideUpKF, AppearKF } from 'style/keyframes';
import { ReactComponent as HelpIcon } from 'assets/icons/icon-help-text.svg';
import { StatusLoading } from './Status';
import { FormContext, getDeletedValue, isDeletedValue } from './Form';
import { Field, ErrorMessage } from './FormField';
import { Tooltip } from './Tooltip';
import { ResourceNameView } from './ResourceNameView';

export const FormItemWrapper = styled.div`
  margin-bottom: ${asRem(20)};
  .help {
    color: var( --color-text-2);
    font-size: var(--text-md-fontSize);
    line-height: var(--text-md-lineHeight);
    opacity: 0.6;
  }
  
  >.suggestions {
    .item-name {
      padding-right: ${asRem(10)};
    }
  }

  --ac-bg-color: transparent;

  &.au-color-1 { --ac-bg-color: var(--color-au-1); }
  &.au-color-2 { --ac-bg-color: var(--color-au-2); }
  &.au-color-3 { --ac-bg-color: var(--color-au-3); }
  &.au-color-4 { --ac-bg-color: var(--color-au-4); }

  .tooltip-wrapper {
    button {
      padding: 0;
    }
  }

  .input-box {
    display: flex;
    flex-direction: column;

    .label-area {
      position: relative;
      z-index: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tooltip-wrapper {
        padding: 0;
      }

      label {
        display: flex;
        align-items: flex-start;
        gap: ${asRem(4)};
      }

      div {
        animation: ${SlideUpKF} 1s linear;
        line-height: var(--label-md-lineHeight);
        font-size: var(--label-md-fontSize);
        border-radius: ${asRem(4)};
        padding: ${asRem(2)} ${asRem(4)};
        align-self: center;
      }

      .edited-by {
        animation: ${AppearKF} 1s linear;
        opacity: 0.4;
      }

      .user-tag { 
        background-color: var(--ac-bg-color);
        font-weight: 500;
        margin-left: ${asRem(5)};
        margin-bottom: ${asRem(8)};

        &.draft {
          background: var(--color-draft);
          color: var(--color-image-placeholder-text);
        }
      }  
    }
    input, textarea, .input-proxy {
      position: relative;
      z-index: 101;
      border-color: var(--ac-bg-color);
      
      &:focus {
        border-color: var(--color-highlight);
      }
    }    
  }

  .review-box {
    .label-area {
      color: var(--color-label);
      text-align: left;
    }
    .preview-items {
      .preview-item {
        margin-top: ${asRem(8)};
        display: flex;
        gap: ${asRem(8)};
        word-break: break-word;
        svg {
          width: ${asRem(20)};
          height: ${asRem(20)};
        }
        &.publish, &.draft {
          .text-14-regular {
            div {
              color: var(--color-text-1);
            }
          }
        }
      }
    }
  }

  &.one-line {
    margin-bottom: 0;
    .input-box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tooltips-area {
        position: relative;
        z-index: 1000;
        height: 100%;
        >.tooltips-area-content {
          position: absolute;
          right: ${asRem(2)};
          bottom: calc(-50% - ${asRem(8)});
          >button {
            padding: 0;
          }
        }
      }
      .label-area {
        label {
          display: none;
        }

        .user-tag {
          margin: 0;
        }
      }

      .input-area {
        input, select, textarea, .input-proxy {
          margin-bottom: 0;
        }
      }
    }
  }

  &.with-error {
    input, textarea, .input-proxy {
      background-color: var(--color-error-overlay);
    }
  }

  &.with-edited-by {
    input, textarea, .input-proxy {
      border-color: transparent;
    }
  }

  &.with-deleted {
    opacity: 0.3;
    input, textarea, select, .input-proxy {
      display: none;
    }
  }
  &.with-disabled {
    opacity: 0.6;
    cursor: not-allowed;
    color: var(--color-text-1);
  }
`;

export function FormItemPreview({
  formValue, type, ...fieldParams
}) {
  const resolvedType = type || 'text';
  const isCheckbox = resolvedType === 'checkbox';

  return (
    <Text14Regular>
      {isCheckbox && (formValue ? 'Yes' : 'No')}
      {fieldParams.isPicker ? (
        <ResourceNameView
          resourceDef={fieldParams.resourceDef}
          nameQuery={fieldParams.nameQuery}
          resourceKey={formValue}
        />
      ) : formValue}
    </Text14Regular>

  );
}

FormItemPreview.propTypes = {
  formValue: PropTypes.any,
  type: PropTypes.string,
};

const TooltipTriggerButton = styled.div`
  display: block;
  width: ${asRem(8)};
  height: ${asRem(8)};
  overflow: hidden;
  border-radius: 50%;
  ${fwsh(300, 0, 0)}
  padding: 0;
  --tt-bg-color: var(--color-disabled);

  &.state-edit {
    --tt-bg-color: var(--color-realtime);
  }

  &.state-error {
    --tt-bg-color: var(--color-error);
  }

  &.state-help {
    --tt-bg-color: var(--color-label);
  }

  background-color: var(--tt-bg-color);
`;

export function OnelineTooltipTrigger({
  text = '.',
  errorState,
  helpState,
  editState,
  ...buttonProps
}) {
  const classes = [];
  if (helpState) {
    classes.push('state-help');
  }
  if (errorState) {
    classes.push('state-error');
  }
  if (editState) {
    classes.push('state-edit');
  }
  return (
    <div>
      <TooltipTriggerButton
        className={classes.join(' ')}
        {...buttonProps}
      >
        {text}
      </TooltipTriggerButton>
    </div>
  );
}

OnelineTooltipTrigger.propTypes = {
  text: PropTypes.string,
  errorState: PropTypes.bool,
  helpState: PropTypes.bool,
  editState: PropTypes.bool,
};

const TooltipTriggerContentWrapper = styled.div`
  padding: ${asRem(16)};
`;

export function TooltipTriggerContent({
  children,
  ...props
}) {
  return (
    <TooltipTriggerContentWrapper {...props}>
      {children}
    </TooltipTriggerContentWrapper>
  );
}

TooltipTriggerContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function FormItem({
  type, name, label, help, placeholder, options, textAreaRows = 2,
  className,
  showEmpty = true,
  defaultOptionName,
  suggestions = null,
  renderSuggestionItem = null,
  queryLoading = false,
  queryError = null,
  renderView = null,
  showLabel = true,
  onelineStyle = false,
  tooltip,
  ...fieldParams
}) {
  const {
    values,
    setFieldValue,
    errorFields,
    markTouched,
    markLeft,
  } = useContext(FormContext);
  const {
    formFieldState,
    realtimeData,
    topic,
    reviewMode,
    setFocusField,
    userColors,
  } = useContext(RealtimeEditorContext);

  const resolvedLabel = label || name;
  const resolvedType = type || 'text';
  const isSelect = resolvedType === 'select';
  const isRadio = resolvedType === 'radio';
  const isTextArea = resolvedType === 'textarea';
  const isInput = !isTextArea && !isSelect && !isRadio;
  const dataState = formFieldState ? formFieldState[name] : null;
  const [fieldState, setFieldState] = useState(null);
  const [activeUser, setActiveUser] = useState(null);

  const formValue = dataFromPath(values, name);

  const setFormValue = (value) => {
    setFieldValue(name, value);
  };

  const setDeletedValue = () => {
    const newValue = getDeletedValue(formValue);
    setFieldValue(name, newValue);
    // Something weird unable to set empty value
    // if (newValue === '') {
    //   setFieldValue(name, ' ');
    // } else {
    //   setFieldValue(name, newValue);
    // }
  };

  const onFieldFocus = () => {
    if (fieldParams && !fieldParams.disableRealTime) {
      if (setFocusField) {
        setFocusField(name);
      }
      markTouched(name, true);
    }
  };

  const onFieldBlur = () => {
    if (fieldParams && !fieldParams.disableRealTime) {
      if (setFocusField) {
        setFocusField('');
      }
      markLeft(name, true);
    }
  };

  useEffect(() => {
    if (realtimeData) {
      const matchedFieldState = Object.values(realtimeData).find((rt) => rt && rt.path === name);
      if (matchedFieldState) {
        setFieldState(matchedFieldState);
      } else {
        setFieldState(null);
      }
    }
  }, [name, realtimeData]);

  useEffect(() => {
    if (topic && topic.activeUsers.items) {
      const activeUsers = topic.activeUsers.items;
      const focus = activeUsers.find((u) => u.interaction && u.interaction.focus === name);
      if (focus) {
        setActiveUser(focus);
      } else {
        setActiveUser(null);
      }
    }
  }, [name, topic]);

  const isDeleted = isDeletedValue(formValue);
  const deletedValue = isDeleted ? getDeletedValue(formValue) : null;
  const resolvedClassName = [];
  const editedBy = !activeUser
    && dataState
    && dataState.realtime.use
    && fieldState
    && fieldState.author;
  const isDraft = dataState && dataState.draft && dataState.draft.value && dataState.draft.use;
  // const isPublished = dataState && dataState.published
  // && dataState.published.value && dataState.published.use;

  const draftValue = dataState
    && dataState.draft
    && dataState.draft.value;

  const publishedValue = dataState
    && dataState.published
    && dataState.published.value;

  const showEditBy = editedBy
    && formValue !== null
    && formValue !== undefined
    && formValue !== draftValue
    && formValue !== publishedValue;

  const showDraft = draftValue !== null
    && draftValue !== undefined
    && draftValue !== publishedValue;

  const showPublished = publishedValue !== null
    && publishedValue !== undefined;

  if (className) {
    resolvedClassName.push(className);
  }

  if (onelineStyle) {
    resolvedClassName.push('one-line');
  }

  if (activeUser) {
    resolvedClassName.push('with-user');
  }
  if (editedBy) {
    resolvedClassName.push('with-edited-by');
  }
  if (isDraft) {
    resolvedClassName.push('with-draft');
  }
  if (isDeleted) {
    resolvedClassName.push('with-deleted');
  }

  if (errorFields && errorFields[name]) {
    resolvedClassName.push('with-error');
  }

  if (fieldParams.disabled) {
    resolvedClassName.push('with-disabled');
  }

  if (activeUser) {
    resolvedClassName.push(userColors[activeUser.pathKey] ?? userColors[activeUser.user.key]);
  } else if (editedBy) {
    resolvedClassName.push(
      userColors[fieldState.author.editSessionId] ?? userColors[fieldState.author.key],
    );
  }

  const additionalFieldParams = {};

  if (queryLoading || queryError) {
    additionalFieldParams.disabled = true;
  }

  if (queryLoading) {
    additionalFieldParams.placeholder = 'loading...';
  }

  const showLabelView = activeUser || editedBy || isDraft;
  const isErrorState = (errorFields && errorFields[name]) || queryError;
  const showStatusView = isErrorState || help;

  const labelView = (
    <div className="label-area">
      {showLabel && (
        <label htmlFor={name}>
          {resolvedLabel}
          {help && tooltip && (
            <div className="tooltip-wrapper">
              <Tooltip
                text={help}
                renderTrigger={() => (
                  <HelpIcon />
                )}
              />
            </div>
          )}
        </label>
      )}
      {activeUser && (
      <div className="user-tag user">
        {activeUser.user.first_name}
      </div>
      )}
      {editedBy && (
      <div className="user-tag edited-by">
        {isDeleted ? 'deleted by ' : 'edited by '}
        {fieldState.author.firstName}
      </div>
      )}
      {!activeUser && isDraft && (
      <div className="user-tag draft">
        In draft
      </div>
      )}
    </div>
  );

  const statusView = (
    <div className="status-area">
      <div className="help-error-area">
        {queryError && (
          <div>
            Error fetching data
            {queryError}
          </div>
        )}
        <ErrorMessage name={name} />
        {help && !tooltip && (
          <div className="help">
            {help}
          </div>
        )}
      </div>
      <div className="suggestion-area">
        {suggestions && (
        <div className="suggestions">
          <ul>
            {suggestions.filter((s) => s.value !== values[name]).map((item) => (
              <li key={item.value}>
                {renderSuggestionItem && renderSuggestionItem(item, setFormValue)}
                {!renderSuggestionItem && (
                <>
                  <span className="item-name">
                    {item.name || item.value}
                  </span>
                  <button
                    onClick={() => {
                      setFormValue(item.value);
                    }}
                    type="button"
                    className="plain"
                  >
                    Use
                  </button>
                </>
                )}
              </li>
            ))}
          </ul>
        </div>
        )}
      </div>
    </div>

  );

  return (
    <FormItemWrapper className={resolvedClassName.join(' ')}>
      {reviewMode
        ? (
          <div className="review-box">
            {showLabel && (
              <div className="label-area">
                <Text14Regular>
                  {resolvedLabel}
                </Text14Regular>
              </div>
            )}
            <div className="preview-items">
              {activeUser && (
                <div className="preview-item active-user">
                  <ActiveUserView
                    activeUser={activeUser}
                    topic={topic}
                    userColors={userColors}
                    size="20"
                    blink
                  />
                  <FormItemPreview
                    type={type}
                    formValue={formValue}
                    {...fieldParams}
                  />
                </div>
              )}
              {!activeUser && !showEditBy && !showDraft && !showPublished && (
                <div className="preview-item active-user">-</div>
              )}
              {showEditBy && (
                <div className="preview-item edited-by">
                  <ActiveUserView
                    activeUser={{
                      user: {
                        first_name: editedBy.firstName,
                        picture_url: editedBy.url,
                        ...editedBy,
                      },
                    }}
                    topic={topic}
                    userColors={userColors}
                    size="20"
                  />
                  <FormItemPreview
                    type={type}
                    formValue={formValue}
                    {...fieldParams}
                  />
                </div>
              )}
              {showDraft && (
                <div className="preview-item draft">
                  <DraftIcon />
                  <FormItemPreview
                    type={type}
                    formValue={dataState.draft.value}
                    {...fieldParams}
                  />
                </div>
              )}
              {showPublished && (
                <div className="preview-item publish">
                  <PublishIcon />
                  <FormItemPreview
                    type={type}
                    formValue={dataState.published.value}
                    {...fieldParams}
                  />
                </div>
              )}
            </div>
          </div>
        )
        : (
          <div className="input-box">
            {!onelineStyle && labelView}
            <div className="input-area">
              {isInput && (
              <Field
                onFocus={onFieldFocus}
                onBlur={onFieldBlur}
                type={resolvedType}
                name={name}
                placeholder={placeholder}
                {...additionalFieldParams}
                {...fieldParams}
                className="checkbox-large"
                onWheel={(e) => e.target.blur()}
              />
              )}
              {isTextArea && (
              <Field
                onFocus={onFieldFocus}
                onBlur={onFieldBlur}
                name={name}
                as="textarea"
                rows={textAreaRows}
                placeholder={placeholder}
                {...additionalFieldParams}
                {...fieldParams}
              />
              )}
              {isRadio && (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label>
                <Field
                  onFocus={onFieldFocus}
                  onBlur={onFieldBlur}
                  name={name}
                  type="radio"
                  placeholder={placeholder}
                  {...additionalFieldParams}
                  {...fieldParams}
                />
                {label}
              </label>
              )}
              {isSelect && (
              <div className="rz-select">
                <Field
                  onFocus={onFieldFocus}
                  onBlur={onFieldBlur}
                  name={name}
                  as="select"
                  {...additionalFieldParams}
                  {...fieldParams}
                >
                  {queryLoading && (
                    <option value="">loading...</option>
                  )}
                  {!queryLoading && showEmpty && (
                  <option value="">{defaultOptionName || 'None'}</option>
                  )}
                  {options.map((item) => (
                    <option
                      key={item.key}
                      value={item.key || item.value}
                    >
                      {item.name || item.value || item.key}
                    </option>
                  ))}
                </Field>
              </div>
              )}
              {(isDeleted && !renderView) && (
                <div
                  className="deleted-value input-proxy checkbox-large"
                  onDoubleClick={() => setDeletedValue()}
                >
                  {deletedValue}
                </div>
              )}
              {renderView ? renderView({
                name,
                value: formValue,
                error: errorFields[name],
                deletedValue,
                isDeleted,
                setFieldValue,
                onFieldFocus,
                onFieldBlur,
              }) : null }
            </div>
            {!onelineStyle && statusView}
            {onelineStyle && (
              <div className="tooltips-area">
                <div className="tooltips-area-content">
                  {showLabelView && (
                    <Tooltip
                      delayDuration={1}
                      disableFocus
                      renderContent={() => (
                        <TooltipTriggerContent>
                          <Label12Regular>{labelView}</Label12Regular>
                        </TooltipTriggerContent>
                      )}
                      renderTrigger={() => (
                        <div>
                          <OnelineTooltipTrigger editState />
                        </div>
                      )}
                    />
                  )}
                  {showStatusView && (
                    <Tooltip
                      delayDuration={1}
                      renderContent={() => (
                        <TooltipTriggerContent>{statusView}</TooltipTriggerContent>
                      )}
                      renderTrigger={() => (
                        <OnelineTooltipTrigger
                          helpState
                          errorState={isErrorState}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
    </FormItemWrapper>
  );
}

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  onelineStyle: PropTypes.bool,
  label: PropTypes.string,
  defaultOptionName: PropTypes.string,
  help: PropTypes.string,
  reviewMode: PropTypes.bool,
  placeholder: PropTypes.string,
  textAreaRows: PropTypes.number,
  tooltip: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.any,
    name: PropTypes.string,
  })),
  showEmpty: PropTypes.bool,
  showLabel: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string,
  })),
  renderSuggestionItem: PropTypes.func,
  renderView: PropTypes.func,
  realtimeState: PropTypes.object,
  queryLoading: PropTypes.bool,
  queryError: PropTypes.object,
};

export function FormItemEnum({ typeName, ...formOptions }) {
  const { loading, data } = useQuery(EnumValuesQuery, {
    variables: { name: typeName },
  });

  if (!data && loading) return (<StatusLoading />);

  // eslint-disable-next-line no-underscore-dangle
  const options = data.__type.enumValues.map(
    (x) => ({ key: x.name, name: x.name.split('_').join(' ') }),
  );

  return (
    <FormItem
      {...formOptions}
      options={options}
      type="select"
    />
  );
}

FormItemEnum.propTypes = {
  typeName: PropTypes.string.isRequired,
  ...FormItem.propTypes,
};

export function FormItemYesNo({ ...formOptions }) {
  const options = [
    { key: 'yes', value: true, name: 'Yes' },
    { key: 'no', value: false, name: 'No' },
  ];

  return (
    <FormItem
      {...formOptions}
      options={options}
      type="select"
    />
  );
}

FormItemYesNo.propTypes = {
  ...FormItem.propTypes,
};

export function FormItemMoney({
  currency, values, ...formOptions
}) {
  const resolvedCurrency = currency || Config.defaultCurrency;
  let help = null;

  const val = dataFromPath(values, formOptions.name);
  if (val !== undefined) {
    help = formatMoney({ value: val, currency: resolvedCurrency });
  }

  return (
    <FormItem
      {...formOptions}
      help={help}
      type={formOptions.type || 'number'}
    />
  );
}

FormItemMoney.propTypes = {
  currency: PropTypes.string,
  values: PropTypes.object.isRequired,
  ...FormItem.propTypes,
};
