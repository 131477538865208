import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { dataFromPath } from 'lib/utils';
import { StatusError, StatusLoading } from './Status';

const ResourceNameViewWrapper = styled.div`
`;

export function ResourceNameView({
  resourceDef,
  nameQuery,
  resourceKey,
  renderItem,
  onResourceChange = null,
  placeholder,
}) {
  const [displayName, setDisplayName] = useState('');
  const { loading, error, data } = useQuery(
    nameQuery ? nameQuery.query : resourceDef.queries.name.query,
    {
      variables: {
        resource: { _hashkey: resourceKey },
      },
      skip: !resourceKey || resourceKey === '-',
    },
  );

  useEffect(() => {
    if (data) {
      const name = dataFromPath(
        data,
        (nameQuery ? nameQuery.resourceNamePath : resourceDef?.queries?.name?.resourceNamePath),
      );
      setDisplayName(name);
      if (onResourceChange) {
        onResourceChange({ name: displayName, data });
      }
    }
  }, [data, displayName, nameQuery, onResourceChange, resourceDef]);

  return (
    <ResourceNameViewWrapper>
      {loading && (<StatusLoading message="..." />)}
      {error && (<StatusError error={error} />)}
      {!loading && displayName && (
        <div>
          {renderItem
            ? renderItem({ data, displayName })
            : displayName}
        </div>
      )}
      {(!resourceKey || resourceKey === '-') && (placeholder ?? '-')}
    </ResourceNameViewWrapper>
  );
}

ResourceNameView.propTypes = {
  resourceDef: PropTypes.object,
  nameQuery: PropTypes.object,
  renderItem: PropTypes.func,
  onResourceChange: PropTypes.func,
  resourceKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
