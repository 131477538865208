import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const PlayerRankingReadQuery = gql`
  query PlayerRankingReadQuery($resource: CricketPlayerRankingInput!, $withHistory: Boolean) {
    sports_cricket_player_rankings_read(resource: $resource, with_history: $withHistory) {
      item {
        resource {
          account
          sport
          association
          format
          gender
          role
          announced_date
          hashkey: _hashkey
        }
        name {
          name
        }
        computed_searchables {
          association_hashkey
        }
        rank {
          players {
            player {
              account
              sport
              key
              hashkey: _hashkey
            }
            name
            rating
            region {
              account
              key
              hashkey: _hashkey
            }
          }
        }
        status {
          published
          notes
        }
      }
      history {
        ${VersionHistoryFields}
      }
    }
  }
`;

export const PlayerRankingCreateMutation = gql`
  mutation PlayerRankingCreateMutation($resource: CricketPlayerRankingInput!, $rank: CricketPlayerRankingRankInput!) {
    sports_cricket_player_rankings_create(resource: $resource, rank: $rank) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const PlayerRankingSearchQuery = gql`
  query PlayerRankingSearchQuery($search: String!, $filters: RankingsFiltersInput, $page: PagedRequestInput ) {
    sports_cricket_player_rankings_search(search: $search, filters: $filters page: $page) {
      items {
        resource {
          account
          sport
          association
          format
          gender
          role
          announced_date
          hashkey: _hashkey
        }
        name {
          name
        }
        computed_searchables {
          association_hashkey
        }
        rank {
          players {
            player {
              account
              sport
              key
              hashkey: _hashkey
            }
            name
            rating
            region {
              account
              key
              hashkey: _hashkey
            }
          }
          players_count
        }
          status {
            published
            notes
          }
      }
    page {
      next_page_key
      count
      prev_page_key
    }
    }
  }
`;

export const PlayerRankingDeleteMutation = gql`
  mutation PlayerRankingDeleteMutation($resource: CricketPlayerRankingInput!, $name: String!) {
    sports_cricket_player_rankings_delete(resource: $resource, name: $name) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
    }
    }
  }
`;

export const PlayerRankingUpdateMutation = gql`
  mutation PlayerRankingUpdateMutation($resource: CricketPlayerRankingInput!, $rank: CricketPlayerRankingRankInput!, $version: RecordStage) {
    sports_cricket_player_rankings_update(resource: $resource, rank: $rank, version: $version) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const PlayerRankingAutoFillQuery = gql`
  query PlayerRankingAutoFillQuery($search: String!, $page: PagedRequestInput) {
    sports_cricket_player_rankings_auto_fill(search: $search, page: $page) {
      items {
        resource {
          account
          sport
          association
          format
          gender
          announced_date
          _hashkey
        }
        name {
          name
        }
        rank {
          batter {
            key
            name
            rating
            region {
              account
              key
              _hashkey
            }
          }
          bowler {
            key
            name
            rating
            region {
              account
              key
              _hashkey
            }
          }
          all_rounder {
            key
            name
            rating
            region {
              account
              key
              _hashkey
            }
          }
        }
        status {
          published
          notes
        }
      }
      page {
        next_page_key
        count
        prev_page_key
      }
    }
  }
`;
