import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const AssociationListQuery = gql`
query AssociationListQuery {
  sports_association_list {
    items {
      resource { key, hashkey: _hashkey }
      name { name, internal_name }
    }
    page { next_page_key, count }
  }
}
`;

export const AssociationReadQuery = gql`
query AssociationReadQuery($resource: AssociationInput!, $withHistory: Boolean) {
  sports_association_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key,
        hashkey: _hashkey
      }
      association {
        code
        name
        internal_name
        region {
          account
          key
          hashkey: _hashkey
        }
        parent {
          account
          key
          hashkey: _hashkey
        }
      }
      image {
        image_url
      }
      status {
        published
        notes
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const AssociationNameFeedbackQuery = gql`
query AssociationNameFeedbackQuery($name: String!) {
  sports_association_name_feedback(name: $name) {
    similar_items {
      resource { key, hashkey: _hashkey }
      name { name, internal_name }
    }
    key_suggestions
  }
}
`;

export const AssociationCreateHintQuery = gql`
query AssociationCreateHintQuery($name: String!) {
  sports_association_create_hint(name: $name) {
    name
    code
    internal_name
    similar_items {
      resource {
        account
        key
      }
      name {
        name
        internal_name
      }
      image {
        image_url
      }
    }
    key_suggestions
  }
}
`;

export const AssociationCreateMutation = gql`
mutation AssociationCreateMutation($association: AssociationAssociationInput!,
  $status: AssociationStatusInput!, $key: String!) {
  sports_association_create(association: $association, key: $key, status: $status) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
    key_suggestions
  }
}
`;

export const AssociationUpdateMutation = gql`
mutation AssociationUpdateMutation(
  $resource: AssociationInput!,
  $association: AssociationAssociationInput!,
  $status: AssociationStatusInput!,
  $version: RecordStage) {
  sports_association_update(resource: $resource, association: $association, status: $status, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const AssociationUpdateStatusMutation = gql`
mutation AssociationUpdateStatusMutation(
  $resource: AssociationInput!,
  $status: AssociationStatusInput!,
  $version: RecordStage) {
  sports_association_update_status(resource: $resource, status: $status, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const AssociationDeleteMutation = gql`
mutation AssociationDeleteMutation(
  $resource: AssociationInput!,
  $name: String!,
  $imageUrl: String) {
  sports_association_delete(resource: $resource, name: $name, image_url: $imageUrl) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const AssociationSearchQuery = gql`
query AssociationSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_association_search(search: $search, page: $page) {
    items {
      resource {
        account
        key,
        hashkey: _hashkey
      }
      name {
        name
        internal_name
      }
      image {
        image_url
      }
      status {
        published
        notes
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const AssociationNameQuery = gql`
query AssociationNameQuery($resource: AssociationInput!) {
  sports_association_name(resource: $resource) {
    name
  }
}
`;

export const AssociationImageUploadMutation = gql`
mutation AssociationImageUploadMutation(
  $resource: AssociationInput!,
  $filename: String!,
  $version: RecordStage) {
  sports_association_image_upload(resource: $resource, filename: $filename, version: $version) {
    signed_data
    url
    url_suffix
  }
}
`;
