import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dataFromPath } from 'lib/utils';
import { SlideDownKF } from 'style/keyframes';
import { asRem } from 'lib/css';
import { FormContext } from './Form';
import { ErrorLine } from './Status';

export function Field({
  name,
  as: asEle,
  children,
  onChange,
  ...props
}) {
  const {
    setFieldValue,
    values,
  } = useContext(FormContext);

  const onValueChange = (event) => {
    const target = event.target ? event.target : event.currentTarget;
    const {
      value,
      type,
      checked,
    } = target;
    let resolvedValue = value;
    switch (type) {
      case 'checkbox':
        resolvedValue = checked;
        break;
      case 'number':
        resolvedValue = Number.isNaN(value) ? '' : parseFloat(value);
        break;
      default:
        break;
    }
    if (!props.disableRealTime) {
      setFieldValue(name, resolvedValue);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const value = dataFromPath(values, name);
  const fieldProps = { };
  if (props.type === 'checkbox' && (value === true || value === 1)) {
    fieldProps.defaultChecked = true;
  }
  if (props.type === 'select' && value) {
    fieldProps.defaultValue = value;
  }
  // console.log('Props', name, value, fieldProps);

  const field = {
    name,
    value,
    ...fieldProps,
  };
  const asElement = asEle || 'input';

  if (typeof asElement === 'string') {
    const { innerRef, ...rest } = props;
    return React.createElement(
      asElement,
      {
        ref: innerRef,
        ...field,
        ...rest,
        onChange: onValueChange,
      },
      children,
    );
  }

  return React.createElement(
    asElement,
    { ...field, ...props, onChange: onValueChange },
    children,
  );
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  as: PropTypes.any,
  innerRef: PropTypes.any,
  type: PropTypes.string,
  disableRealTime: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const ErrorMessageWrapper = styled.div`
position: relative;
z-index: 99;
max-height: ${asRem(450)};
overflow: scroll;
transition: max-height 1s ease-out;

&.no-errors {
  max-height: 0;
}

ul {
  animation: ${SlideDownKF} 1s linear;
}
`;

export function ErrorMessage({
  name,
  className,
}) {
  const formContext = useContext(FormContext);
  let error = null;
  if (formContext.errors) {
    error = formContext.errors.inner.find((i) => i.params.path === name);
  }

  if (!formContext.errorFields[name]) {
    error = null;
  }

  const classes = [className ?? ''];
  if (!error) {
    classes.push('no-errors');
  }

  return (
    <ErrorMessageWrapper className={classes.join(' ')}>
      {error && error.errors.length > 0 && (
        <ul>
          {error.errors.map((err, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <ErrorLine msg={err} />
            </li>
          ))}
        </ul>
      )}
    </ErrorMessageWrapper>
  );
}

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const FormErrorViewWrapper = styled.div`
`;

export function FormErrorView({
  errors,
  showFromStart = false,
  className = '',
  errorClassName = '',
}) {
  const formContext = useContext(FormContext);
  const [showAllErrors, setShowAllErrors] = useState(false);

  if (!(errors?.message)) {
    return null;
  }

  if (!showFromStart && formContext.submitReqCount === 0) {
    return null;
  }

  return (
    <FormErrorViewWrapper className={`error-line ${className}`}>
      { errors.inner.length > 1 && (
      <ErrorLine
        msg={errors.message}
        className={errorClassName}
        onClick={() => { setShowAllErrors(!showAllErrors); }}
      />

      )}
      {(showAllErrors || errors.inner.length === 1) && (
        <ul>
          {errors.inner.map((errorItem) => (
            <li key={errorItem.params.path}>
              <ErrorLine msg={errorItem.message} className={errorClassName} />
            </li>
          ))}
        </ul>
      )}
    </FormErrorViewWrapper>
  );
}

FormErrorView.propTypes = {
  showFromStart: PropTypes.bool,
  errors: PropTypes.object,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
};
