import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { asRem } from 'lib/css';
import { useNavigate, Link } from 'react-router-dom';
import { ReactComponent as KabaddiIcon } from 'assets/icons/icon-kabaddi.svg';
import { ReactComponent as CricketIcon } from 'assets/icons/icon-cricket.svg';
import { ReactComponent as FootballIcon } from 'assets/icons/icon-football.svg';
import { ReactComponent as CircleJiIcon } from 'assets/icons/icon-circleji.svg';
import { ReactComponent as OtherSportsIcon } from 'assets/icons/icon-other-sports.svg';
import { ReactComponent as FantasyIcon } from 'assets/icons/icon-fantasy.svg';
import { ReactComponent as AddIcon } from 'assets/icons/icon-add.svg';
import arrowIcon from 'assets/icons/icon-arrow-right-filled.svg';
import { fetchSportsQuery } from 'core/query';
import { capitilise } from 'lib/utils';
import { Display18Regular, Text16Medium } from 'style/typography';
import { AvailableSports } from 'modal';
import { UserContext } from '../core/UserContext';

const SelectSportScreen = styled.div`
  text-align: center;
  max-width: ${asRem(320)};
  margin: ${asRem(72)} auto;
  &>button {
    display: block;
    width: 100%;
    text-align: left;
    background: var(--color-secondary-bg);
    border-radius: ${asRem(8)};
    margin-bottom: ${asRem(8)};
    padding: ${asRem(16)} ${asRem(20)};
    cursor: pointer;
    color: var(--color-text-1);
    &>span {
      display: inline-block;
      vertical-align: top;
      padding-top: ${asRem(2)};
      padding-left: ${asRem(10)};
      color: var(--color-text-1);
    }
  }
  &>.display-18-regular {
    padding-bottom: ${asRem(10)};
  }
  >a {
    display: block;
    margin-top: ${asRem(20)};
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${asRem(8)};
    }
  }
  h2 {
    font-size: ${asRem(28)};
    line-height: ${asRem(38)};
    font-weight: var(--bold-fontWeight);
    margin-bottom: ${asRem(40)};
  }
  .arrow {
    float: right;
    padding-top: ${asRem(7)};
  }
  .svg-wrapper {
    display: inline-block;
    vertical-align: top;
    width: ${asRem(23)};
    height: ${asRem(24)};
    border-radius: ${asRem(8)};
    &.cricket-icon {
      background: #F8964C;
    }
    &.football-icon {
      background: #22A447;
    }
    &.kabaddi-icon {
      background: #DC4A87;
    }
    &.other-icon {
      background: #52C0E7;
    }
    &.circleji-icon {
      background: #E7A710;
    }
    &.fantasyeditor-icon {
      background: #FF7C7C;
    }
    svg {
      display: inline-block;
      vertical-align: top;
      text-align: center;
      margin: ${asRem(6)};
      width: ${asRem(11)};
      height: ${asRem(11)};
    }
  }
`;

export function SportIcon({ sport }) {
  switch (sport) {
    case AvailableSports.Cricket.key:
      return (
        <CricketIcon />
      );
    case AvailableSports.Kabaddi.key:
      return (
        <KabaddiIcon />
      );
    case AvailableSports.Football.key:
      return (
        <FootballIcon />
      );
    case AvailableSports.CircleJi.key:
      return (
        <CircleJiIcon />
      );
    case AvailableSports.FantasyEditor.key:
      return (
        <FantasyIcon />
      );
    default:
      return (
        <OtherSportsIcon />
      );
  }
}

SportIcon.propTypes = {
  sport: PropTypes.string,
};

export function SelectSport({
  redirect = true,
}) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { loading, data, error } = useQuery(fetchSportsQuery);
  if (loading) return 'loading...';
  if (error) return `error! ${error.message}`;
  const allSports = data && data.sports_sports && data.sports_sports.all_sports;

  const onSportSelect = (sport) => {
    userContext.setSport(sport);
    if (redirect) {
      navigate('/');
    }
  };

  return (
    <SelectSportScreen>
      <Display18Regular>
        Welcome Back
        {' '}
        {capitilise(userContext.user.profile.first_name) || userContext.user.profile.first_name}
      </Display18Regular>
      <h2>Choose your sport</h2>
      {allSports.map((sport) => (
        <button onClick={() => onSportSelect(sport)} type="button" key={sport}>
          <div className={`svg-wrapper ${sport.toLocaleLowerCase()}-icon`}>
            <SportIcon sport={sport} />
          </div>
          <Text16Medium as="span">{AvailableSports[sport].name ?? sport}</Text16Medium>
          <img src={arrowIcon} alt="arrow icon" className="arrow" />
        </button>
      ))}
      <Link to="/pricing" className="plain bordered">
        <div>
          <AddIcon />
          Add a sport
        </div>
      </Link>
    </SelectSportScreen>
  );
}

SelectSport.propTypes = {
  redirect: PropTypes.bool,
};
