import { keyframes } from 'styled-components';

export const SlideUpKF = keyframes`
0% { transform: translateY(120%); }
16% { transform: translateY(32%); }
28% { transform: translateY(-14%); }
44% { transform: translateY(4%); }
59% { transform: translateY(-2%); }
73% { transform: translateY(0.58%); }
88% { transform: translateY(-0.2%); }
100% { transform: translateY(0); }
`;

export const SlideDownKF = keyframes`
0% { transform: translateY(-120%); }
16% { transform: translateY(-32%); }
28% { transform: translateY(14%); }
44% { transform: translateY(4%); }
59% { transform: translateY(-2%); }
73% { transform: translateY(0.58%); }
88% { transform: translateY(-0.2%); }
100% { transform: translateY(0); }
`;

export const AppearKF = keyframes`
0% { transform: scale(95%); }
44% { transform: scale(105%); }
100% { transform: scale(100%); }
`;

export const BlinkKF = keyframes`
0% { transform: scale(95%); }
44% { transform: scale(105%); }
100% { transform: scale(100%); }
`;

export const SlideDownSimpleKF = keyframes`
0% { transform: translateY(-100%); }
100% { transform: translateY(0); }
`;

export const BackInDown = keyframes`
  0% {
    transform: translateY(-120%) scale(0.7);
    opacity: 0.7;
  }

  60% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const SlideUpAndFadeKF = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10%);
  },
  100% {
    opacity: 1;
    transform: translateY(0);
  },
`;

export const SlideDownAndFadeKF = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10%);
  },
  100% {
    opacity: 1;
    transform: translateY(0);
  },
`;

export const SlideRightAndFadeKF = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10%);
  },
  100% {
    opacity: 1;
    transform: translateY(0);
  },
`;

export const SlideLeftAndFadeKF = keyframes`
  0% {
    opacity: 0;
    transform: translateX(10%);
  },
  100% {
    opacity: 1;
    transform: translateY(0);
  },
`;

export const SlideInKF = keyframes`
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  80% {
    transform: scaleY(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
`;

export const ContentShowKF = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.95);
  },
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  },
`;

export const SimpleAppearKF = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;
