import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { asRem } from 'lib/css';
import { Text16Bold } from 'style/typography';
import { ContentShowKF, SimpleAppearKF } from 'style/keyframes';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';
import { StatusError } from './Status';

export const StyledDialogContent = styled(DialogPrimitive.Content)`
  --color-temp: var(--color-text-1);
  --color-text-1: var(--color-text-rev);
  --color-text-rev: var(--color-temp);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* width: 90vw; */
  /* max-width: var(--layout-dailog-box-max-width); */
  min-width: ${asRem(300)};
  max-height: 100vh;

  z-index: 10024;  
  animation: ${ContentShowKF} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;

  &:focus {
    outline: none;
  }

  >.container-wrapper {
    position: relative;

    display: flex;
    flex-direction: column;
    /* color: var(--color-text-rev); */
    /* margin: auto var(--layout-padding);
    margin: auto; */
    justify-content: center;
    align-items: center;

    >.container {
      background-color: var(--color-secondary-bg);
      border: 1px solid var(--color-border-1);
      border-radius: ${asRem(20)};
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 90vh;
      max-width: 90vw;

      >.header {
        display: flex;
        justify-content: space-between;
        border-bottom: ${asRem(1)} solid var(--color-disabled);
        padding: ${asRem(32)};
        h4 {
          display: block;
          margin-right: ${asRem(10)};
        }
        .close-btn {
          background: none;
          padding: 0;
          color: #fff;
          min-height: auto;
        }
      }

      .footer-wrapper {
        display: flex;
        justify-content: center;
        gap: ${asRem(12)};
        padding: 0 0 ${asRem(32)};
      }

      >.content {
        padding: ${asRem(32)};
        flex: 1;
        height: fit-content;
        overflow-y: auto;
        /* max-width: ${asRem(490)}; */
        margin: 0 auto;

        .dailog-button-wrapper {
          text-align: right;
        }
      }
    }
  }
`;
export const RDialog = DialogPrimitive.Root;
export const RDialogTrigger = DialogPrimitive.Trigger;
export const RDialogOverlay = styled(DialogPrimitive.Overlay)`
  background-color: var(--color-overlay);
  position: fixed;
  inset: 0;
  animation: ${SimpleAppearKF} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  z-index: 10024;
`;

export const RDialogContent = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => (
    <DialogPrimitive.Portal>
      <RDialogOverlay />
      <StyledDialogContent {...props} ref={forwardedRef}>
        {children}
      </StyledDialogContent>
    </DialogPrimitive.Portal>
  ),
);

export function Dailog({
  title, children, footer,
  onClose,
  showHeader = true,
  open = false,
}) {
  const doClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <RDialog
      open={open}
      onOpenChange={(shouldOpen) => (!shouldOpen) && doClose()}
    >
      <RDialogContent>
        <div className="container-wrapper">
          <div className="container">
            {showHeader && (
              <div className="header">
                <Text16Bold as="h4">{title}</Text16Bold>
                <div className="header-actions">
                  <DialogPrimitive.Close aria-label="Close" className="close-btn">
                    <CloseIcon />
                  </DialogPrimitive.Close>
                </div>
              </div>
            )}
            <div className="content">
              { children }
            </div>
            {footer && (
              <div className="footer">
                {footer}
              </div>
            )}
          </div>
        </div>
      </RDialogContent>
    </RDialog>
  );
}

Dailog.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showHeader: PropTypes.bool,
  open: PropTypes.bool,
};

const ConfirmDailogContentWrapper = styled.div`
  text-align: center;
`;

const ConfirmDailogFooterWrapper = styled.div`
  text-align: center;
  div {
    display: block;
    width: 100%;
    margin-bottom: ${asRem(20)};
    &:last-child {
      margin-bottom: 0;
    }
  }
  .actions {
    padding: 0  0 ${asRem(32)};
    display: flex;
    justify-content: center;
    gap: ${asRem(12)};
  
    button {
      margin-bottom: 0;
      flex: 0 0 40%;
    }
  }
`;

export function ConfirmDailog({
  onConfirm,
  message = 'Are you sure?',
  confirmText = 'Confirm',
  confirmButtonClass,
  cancelText = 'Close',
  onCancel = null,
  open = false,
  buttonDisabled = false,
  showLoadingState = false,
  stateText = null,
  error = null,
  confirmDisabled,
}) {
  const doConfirm = () => {
    onConfirm();
  };

  const doCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Dailog
      title=""
      showHeader={false}
      onClose={doCancel}
      open={open}
      footer={(
        <ConfirmDailogFooterWrapper>
          {showLoadingState && (stateText || error) && (
            <div>
              {stateText && <div>{stateText}</div> }
              {error && (<StatusError error={error} />)}
            </div>
          )}
          <div className="actions">
            <button
              type="button"
              className="plain bordered small"
              onClick={doCancel}
              disabled={buttonDisabled}
            >
              {cancelText}
            </button>
            <button
              type="button"
              onClick={doConfirm}
              className={confirmButtonClass || 'alert small'}
              disabled={confirmDisabled || buttonDisabled}
            >
              {confirmText}
            </button>
          </div>
        </ConfirmDailogFooterWrapper>
      )}
    >
      <ConfirmDailogContentWrapper>
        {message}
      </ConfirmDailogContentWrapper>
    </Dailog>
  );
}

ConfirmDailog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  confirmButtonClass: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  showLoadingState: PropTypes.bool,
  stateText: PropTypes.string,
  error: PropTypes.object,
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  confirmDisabled: PropTypes.bool,
};
