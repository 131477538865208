import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { asRem, Breakpoint } from 'lib/css';
import { useQuery } from '@apollo/client';
import { AccountInvitesQuery } from 'core/query';
import { Label12Regular, Text14Regular } from 'style/typography';
import { RealtimeContext } from 'ants/RealtimeContext';
import * as UserDropdownMenu from '@radix-ui/react-dropdown-menu';
import { AppContext } from './AppContext';
import { AccountImageView, UserImageView } from './Image';
import { Pages } from './pages';

const UserDropdownWrapper = styled.div`
  position: relative;
  .profile-wrapper {
    cursor: pointer;
    padding: ${asRem(8)};
    border-radius: ${asRem(8)};
    background: var(--color-border-1);
    margin-left: ${asRem(16)};

    display: flex;
    gap: ${asRem(8)};
    .account-name {
      align-self: center;
      max-width: ${asRem(80)};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--color-text-1);
    }
  }
  .account-name-wrapper {
    display: flex;
    gap: ${asRem(6)};

    .account-name {
      display: none;
      @media screen and (min-width: ${Breakpoint.sm}) {
        display: flex;
      }
    }
  }
`;
const UserDropdownMenuTrigger = styled(UserDropdownMenu.Trigger)`
  outline: none;
  padding: 0;
`;

const UserDropdownMenuContent = styled(UserDropdownMenu.Content)`
  width: ${asRem(260)};
  margin: ${asRem(20)} ${asRem(10)} 0 0;
  padding: ${asRem(28)};
  border-radius: ${asRem(8)};
  background: var(--color-border-1);
  display: flex;
  gap: ${asRem(20)};
  flex-direction: column;
 
  button, a {
    padding: 0;
  }
`;

const UserDropdownMenuItem = styled(UserDropdownMenu.Item)`
  outline: none;
  a {
    display: flex;
    gap: ${asRem(12)};
    align-items: center;
    border: none;
  }

  .text-14-regular {
    color: var(--color-text-1);
  }

  span {
    &: hover {
      color: var(--color-button-primary-bg);
    }
  }

  hr {
    margin: 0;
    background: var(--color-border-2);
    height: ${asRem(1)};
    border: 0;
  }

  .rz-image-view {
    min-height: ${asRem(28)};
    min-width: ${asRem(28)};

    > * {
      width: 100%;
      height: 100%;
    }
  }

  .email {
    color: var(--color-text-2);
    max-width: ${asRem(205)};
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis; 
    overflow: hidden;
  }
  .label-text {
    color: var(--color-label);
  }
`;

export function UserDropdown({ user, showAccountDetails = true }) {
  const antsContext = useContext(RealtimeContext);
  const appContext = useContext(AppContext);

  const { loading, error, data } = useQuery(AccountInvitesQuery);
  const invites = data && data.account_invites.accounts.length;

  if (loading) return '';
  if (error) return `error! ${error.message}`;
  return (
    <UserDropdownMenu.Root modal={false}>
      <UserDropdownMenuTrigger>
        <UserDropdownWrapper>
          <div
            className="profile-wrapper"
            role="button"
            tabIndex="0"
          >
            <UserImageView
              user={user.user}
              withStatus
              connectionStatus={antsContext && antsContext.socketState}
            />
            { showAccountDetails && user.account && user.account.profile && (
              <div className="account-name-wrapper">
                <AccountImageView
                  account={user.account}
                />
                <Label12Regular as="div" className="account-name">{user.account.profile.name}</Label12Regular>
              </div>
            )}
          </div>
        </UserDropdownWrapper>
      </UserDropdownMenuTrigger>
      <UserDropdownMenuContent>
        { showAccountDetails && user && user.account && appContext.viewState.layoutWidth !== 'Eight' && (
          <>
            <UserDropdownMenuItem>
              <Link to={Pages.account.settings.meta.urlFor(
                { key: user.user.resource.key },
              )}
              >
                <AccountImageView
                  account={user.account}
                />
                {user.account && user.account.profile && (
                  <Text14Regular as="span">{user.account.profile.name}</Text14Regular>
                )}
              </Link>
            </UserDropdownMenuItem>
            <UserDropdownMenuItem>
              <Link to="/account/picker">
                <Text14Regular as="span">Switch Account</Text14Regular>
              </Link>
            </UserDropdownMenuItem>
            <UserDropdownMenuItem>
              <Link to={Pages.account.settings.dashboard.meta.urlFor(
                { key: user.account.resource.key },
              )}
              >
                <Text14Regular as="span">Account Settings</Text14Regular>
              </Link>
            </UserDropdownMenuItem>
            <UserDropdownMenuItem>
              <Link to={Pages.user.invites.meta.urlFor(
                { key: user.account.resource.key },
              )}
              >
                <Text14Regular as="span">
                  Pending Invites
                  {invites.length > 0 ? `(${invites})` : ''}
                </Text14Regular>
              </Link>
            </UserDropdownMenuItem>
            <UserDropdownMenuItem>
              <Link to={Pages.user.dashboard.meta.urlFor(
                { key: user.account.resource.key },
              )}
              >
                <Text14Regular as="span">User Settings</Text14Regular>
              </Link>
            </UserDropdownMenuItem>
            <UserDropdownMenuItem>
              <hr />
            </UserDropdownMenuItem>
          </>
        )}
        { showAccountDetails
        && (user.user.roles.roles_v2.includes(1) || user.user.roles.roles_v2.includes(50)) && (
          <>
            <UserDropdownMenuItem>
              <Link to="/admin/dashboard">
                <Text14Regular as="span">Super Admin</Text14Regular>
              </Link>
            </UserDropdownMenuItem>
            <UserDropdownMenuItem>
              <hr />
            </UserDropdownMenuItem>
          </>
        )}
        <UserDropdownMenuItem>
          <Text14Regular className="email">{user.user.profile.email}</Text14Regular>
        </UserDropdownMenuItem>
        <UserDropdownMenuItem>
          <button onClick={user.logout} type="button">
            <Text14Regular as="span">Logout </Text14Regular>
          </button>
        </UserDropdownMenuItem>
      </UserDropdownMenuContent>
    </UserDropdownMenu.Root>
  );
}

UserDropdown.propTypes = {
  user: PropTypes.object,
  showAccountDetails: PropTypes.bool,
};
