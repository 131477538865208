import React, { useState, useEffect, useContext } from 'react';
import { AntEditor } from 'ants/AntEditor';
import styled from 'styled-components';
import { Label12Medium, Text14Regular } from 'style/typography';
import { KeyBox } from 'core/KeyBox';
import { MutationButton } from 'core/Button';
import { Box } from 'core/Box';
import PropTypes from 'prop-types';
import { ScreenPopover } from 'core/ScreenPopover';
import { TeamSearchQuery } from 'sports/team/query';
import { TeamImageView } from 'sports/team/ResourceImage';
import { FormItem } from 'core/FormFieldItem';
import { useLazyQuery } from '@apollo/client';
import { StatusLoading, StatusError } from 'core/Status';
import { GridFormItem } from 'sports/GridFormItem';
import { TeamNameView } from 'sports/team/TeamNameView';
import { asRem } from 'lib/css';
import { getDeletedValue, isDeletedValue } from 'core/Form';
import { objectAsArray } from 'lib/utils';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { TeamRankingResource } from './resource';
import { RankingPopOver } from '../RankingPopOver';
import { TeamRankingAutoFillQuery } from './query';

const UpdateRankingWrapper = styled.div`

  table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0 ${asRem(20)};

    td {
      vertical-align: middle;

      div {
        margin: 0;
      }
    }

    tr td {
      padding: ${asRem(20)} ${asRem(6)};
    }

    .form-list-item {
      border-bottom: ${asRem(1)}  solid var(--color-border-1);
    }

    thead {
      tr {
        td {
          font-size: ${asRem(16)};
          font-weight: 500;
          text-align: left;
          padding: 0;
          padding-bottom: ${asRem(16)};
          border-bottom: 1px solid var(--color-border-1);
        }
      }
    }
  }

  .colgroup {
    width: ${asRem(30)};
  }

  .deleted-value {
    opacity: 0.4;
  }
`;
export function UpdateTeamRanking({ values, setFieldValue, queryResponse }) {
  const [showPopOver, setShowPopOver] = useState(false);
  const [doAutofill, { data, loading, error }] = useLazyQuery(TeamRankingAutoFillQuery);
  const [selectedTeams, setSelectedTeams] = useState(objectAsArray(values?.rank?.teams));

  const appContext = useContext(AppContext);

  const onConfirm = (selectedItems) => {
    setShowPopOver(false);
    const modifiedTeams = selectedItems.map((items) => ({
      ...items,
      team: {
        hashkey: items?.resource?.hashkey,
      },
    }));
    const newlyAddedValues = [];
    const prevValues = objectAsArray(values?.rank?.teams).map((team) => {
      if (isDeletedValue(team)) {
        return getDeletedValue(team)?.team?.hashkey;
      }
      return team?.team?.hashkey;
    });
    modifiedTeams.forEach((team) => {
      if (prevValues.includes(team?.team?.hashkey)) {
        const index = prevValues.indexOf(team?.team?.hashkey);
        setFieldValue(`rank.teams.${index}`, team);
      } else {
        newlyAddedValues.push(team);
      }
    });
    const totalTeams = [...objectAsArray(values?.rank?.teams), ...newlyAddedValues];
    setSelectedTeams([...totalTeams]);
    setFieldValue('rank.teams', totalTeams);
  };

  const autoFillTeams = () => {
    doAutofill({
      variables: {
        association: {
          key: queryResponse?.resource?.association,
          account: queryResponse?.resource?.account,
        },
        gender: queryResponse?.resource?.gender,
        format: queryResponse?.resource?.format,
      },
    });
  };

  useEffect(() => {
    if (data?.sports_cricket_team_rankings_auto_fill?.item) {
      const items = data?.sports_cricket_team_rankings_auto_fill?.item?.rank?.teams;
      const prepareData = items.map((team) => ({
        team: {
          hashkey: team?.team?.hashkey,
        },
        matches: team?.matches,
        points: team?.points,
        rating: team?.rating,
      }));
      setFieldValue('rank.teams', [...objectAsArray(values?.rank?.teams), ...prepareData]);
      setSelectedTeams([...selectedTeams, prepareData]);
    }
  }, [data]);

  useEffect(() => {
    appContext.setLayoutWidth(AppLayoutWidth.Nine);
  }, []);

  return (
    <UpdateRankingWrapper>
      {
        showPopOver && (
          <ScreenPopover onClose={() => setShowPopOver(false)}>
            <RankingPopOver
              readQuery={TeamSearchQuery}
              pathName="sports_team_search"
              skipKeys={selectedTeams}
              title="team"
              renderImage={(value) => (
                <TeamImageView item={value} size={12} />
              )}
              renderContent={(item) => (
                <div>
                  <Text14Regular>{item?.team?.name}</Text14Regular>
                  <Text14Regular>{item?.team?.gender}</Text14Regular>
                </div>
              )}
              renderConfirmationButton={({ selectedItems }) => (
                <button
                  type="button"
                  onClick={() => onConfirm(selectedItems)}
                  disabled={selectedItems.length <= 0}
                  className="primary small"
                >
                  Confirm
                </button>
              )}
            />
          </ScreenPopover>
        )
      }
      <Box>
        <button type="button" className="bordered plain full-width" onClick={() => setShowPopOver(true)}>
          Add Teams
        </button>
        <FormItem
          type="checkbox"
          name="autoFill"
          label="AutoFill"
          onChange={(e) => {
            if (e.target.checked) {
              autoFillTeams();
            }
          }}
        />
        {loading && <StatusLoading />}
        {error && <StatusError error={error} />}
        <GridFormItem
          name="rank.teams"
          label="Team Ranking"
          showIndex={false}
          showAddNew={false}
          colgroupStyle="colgroup"
          labels={[
            { key: 'team.name', name: 'Team Name' },
            { key: 'matches', name: 'Total Matches' },
            {
              key: 'points', name: 'Points',
            },
            { key: 'rating', name: 'Rating' },

          ]}
          renderItem={(item) => {
            return (
              <div className={`${isDeletedValue(item.item) && 'deleted-value'}`}>
                {item.labelItem.key === 'team.name'
                 && (
                 <TeamNameView
                   resourceKey={isDeletedValue(item.item)
                     ? getDeletedValue(item.item)?.team?.hashkey : item?.item?.team?.hashkey}
                 />
                 ) }
                {item.labelItem.key === 'matches' && (
                <FormItem
                  name={`rank.teams.${item.index}.matches`}
                  type="text"
                  placeholder={0.00}
                  showLabel={false}
                />
                )}
                {item.labelItem.key === 'points'
                && (
                <FormItem
                  name={`rank.teams.${item.index}.points`}
                  type="text"
                  placeholder={0.00}
                  showLabel={false}
                />
                )}
                {item.labelItem.key === 'rating'
                && (
                <FormItem
                  name={`rank.teams.${item.index}.rating`}
                  type="text"
                  placeholder={0.00}
                  showLabel={false}
                />
                )}
              </div>
            );
          }}
        />
      </Box>
    </UpdateRankingWrapper>
  );
}

UpdateTeamRanking.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  queryResponse: PropTypes.object,
};

const UpdateTeamRankingWrapper = styled.div`
  .full-width {
    width: 100%;
    margin-bottom: ${asRem(20)};
  }
`;

export function UpdateTeamRankingController() {
  return (
    <UpdateTeamRankingWrapper>
      <AntEditor
        resourceDef={TeamRankingResource}
        readAction={TeamRankingResource.queries.read}
        updateAction={TeamRankingResource.mutations.update}
        versionKey="teamRankingVersion"
        isErrorMode={() => {
          return true;
        }}
        renderSubTitle={(queryResponse) => (
          <Label12Medium>
            <span className="key-box">
              {'Key: '}
              <KeyBox resource={queryResponse.queryResponse.resource.hashkey} />
            </span>
          </Label12Medium>
        )}
        renderFields={({ values, setFieldValue, queryResponse }) => (
          <UpdateTeamRanking
            values={values}
            setFieldValue={setFieldValue}
            queryResponse={queryResponse}
          />
        )}
        renderDeleteAction={(_, key, resp) => (
          <MutationButton
            mutation={TeamRankingResource.mutations.delete.mutation}
            responseKey="sports_cricket_team_rankings_delete"
            okayKey="status.okay"
            errorKey="status.error"
            name="Delete"
            className="alert"
            variables={{
              resource: {
                _hashkey: key,
              },
              name: resp.name.name,
            }}
            onSuccess={(res) => {
              if (res.status.okay) {
                window.location.reload();
              }
            }}
          />
        )}
      />
    </UpdateTeamRankingWrapper>
  );
}

export default UpdateTeamRankingController;
