import React from 'react';
import {
  Resource, ColumnSet, QueryAction, MutationAction,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import * as Yup from 'yup';
import {
  CurationReadQuery, CurationSearchQuery,
  CurationCreateMutation, CurationDeleteMutation, CurationUpdateMutation,
  CurationMatchReadQuery, CurationTournamentReadQuery,
} from './query';
import { CuratedImageView } from './ResourceImage';

export const CuratedMatchesResource = Resource({
  name: 'Curation',
  resourceId: 'curation',
  columnSets: [
    ColumnSet({
      name: 'Curation',
      shape: Yup.object().shape({
        name: Yup.string(),
        resources: Yup.mixed(),
      }),
      viewKey: 'curation',
      historyKey: 'CurationCuration',
    }),
    ColumnSet({
      name: 'LegacyData',
      shape: Yup.object().shape({
        resources: Yup.mixed(),
      }),
      viewKey: 'legacy_data',
      historyKey: 'CurationLegacyData',
    }),
    ColumnSet({
      name: 'curation_resource',
      shape: Yup.object().shape({
        resources: Yup.mixed(),
      }),
      viewKey: 'curation_resource',
      historyKey: 'CurationCurationResources',
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  pageKey: 'fantasy.curation',
  imageRender: (item) => (<CuratedImageView item={item} />),
  queries: {
    read: QueryAction(
      {
        query: CurationReadQuery,
        resourcePath: 'item.resource.hashkey',
        responsePath: 'sports_cricket_curation_read',
        resourceNamePath: 'item.curation.name',
      },
    ),
    search: QueryAction(
      {
        query: CurationSearchQuery,
        responsePath: 'sports_cricket_curation_search',
        resourceNamePath: 'items.curation.name',
        resourcePath: 'items.resource.hashkey',
      },
    ),
    curatedmatchread: QueryAction(
      {
        query: CurationMatchReadQuery,
        responsePath: 'source_match_curation_read',
        resourceNamePath: 'item.name',
      },
    ),
    curatedtournamentRead: QueryAction(
      {
        query: CurationTournamentReadQuery,
        responsePath: 'source_tournament_curation_read',
        resourceNamePath: 'items.name',
      },
    ),
  },
  mutations: {
    update: MutationAction({
      mutation: CurationUpdateMutation,
      cs: ['curation', 'legacy_data', 'curation_resource'],
      responsePath: 'sports_cricket_curation_update',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.legacy_data.resources = arrayAsObject(resp.legacy_data.resources);
        resp.resource_name = data?.item?.resource?.resource;
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.legacy_data.resources = objectAsArray(resp.legacy_data.resources);
        resp.resource_name = data.resource_name;
        const postVariables = {
          ...resp,
          resource_name: data.resource_name,
        };
        return postVariables;
      },
    }),
    delete: MutationAction({ mutation: CurationDeleteMutation, responsePath: 'sports_cricket_curation_delete' }),
    create: MutationAction({ mutation: CurationCreateMutation, responsePath: 'sports_cricket_curation-create' }),
  },
});
