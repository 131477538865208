// import React, { lazy, Suspense, useContext } from 'react';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { Breadcrumb } from 'core/Breadcrumb';
// import { AppContext } from 'core/AppContext';

const OnLoginCallback = lazy(() => import('./OnLoginCallback'));

export function PagesRoutes() {
  // const appContext = useContext(AppContext);
  // appContext.toggleTopMenu({ primary: true, secondary: false });

  return (
    <Suspense fallback={<>...</>}>
      <div>
        <Breadcrumb className="hide-on-fullscreen" />
        <Routes>
          <Route
            path={Pages.pages.onLoginCallback.meta.path}
            element={(<OnLoginCallback />)}
          />
        </Routes>
      </div>
    </Suspense>
  );
}
