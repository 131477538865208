import React, {
  // useContext,
  // useEffect,
  lazy,
  Suspense,
} from 'react';

import { Pages } from 'core/pages';
import { Route, Routes } from 'react-router-dom';
// import { Route, Routes, useParams } from 'react-router-dom';
// import { useQuery } from '@apollo/client';
// import { AccountsGetQuery } from 'account/query';
// import { UserContext } from 'core/UserContext';
// import { StatusError, StatusLoading } from 'core/Status';
// import { UserSettingsRoutes } from 'account/UserSettings/routes';
import AccountSearchAccessGate from './AccountSearchAccessGate';

const AccountPage = lazy(() => import('./Page'));
// const Dashboard = lazy(() => import('./AccountSettings'));
// const LiveCollaborators = lazy(() => import('./LiveCollaborators'));
// const Members = lazy(() => import('./Members'));
// const Search = lazy(() => import('./AccountSearch'));
const UserSettings = lazy(() => import('../UserSettings/UserSettings'));
const Invites = lazy(() => import('../UserSettings/AccountInvites'));
const ApplicationAccess = lazy(() => import('./ApplicationAccess'));

export function AccountSettingsRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route path={Pages.user.meta.path}>
          <Route
            path={Pages.user.dashboard.meta.path}
            element={<UserSettings />}
          />
          <Route
            path={Pages.user.invites.meta.path}
            element={<Invites />}
          />
        </Route>
        <Route
          path={Pages.account.settings.meta.starPath}
          element={<AccountPage />}
        />
        <Route
          path={Pages.account.settings.applicationAccess.meta.path}
          element={<AccountSearchAccessGate><ApplicationAccess /></AccountSearchAccessGate>}
        />
      </Routes>
      {/* <UserSettingsRoutes /> */}
    </Suspense>
  );
}
