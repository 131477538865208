const ActiveUserClasses = [
  'au-color-1',
  'au-color-2',
  'au-color-3',
  'au-color-4',
];

export function getActiveUserClass(index) {
  return ActiveUserClasses[index % ActiveUserClasses.length];
}
