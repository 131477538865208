import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const RegionCountryList = gql`
query RegionCountryList {
  sports_region_country_list {
    countries {
      short_code
      code
      name
      official_name
      is_region
    }
  }
}
`;

export const RegionStateList = gql`
query RegionStateList($country: String!) {
  sports_region_state_list(country: $country) {
    state_list {
      short_code
      name
      code
      official_name
    }
  }
}
`;

export const RegionCreateHintQuery = gql`
query RegionCreateHintQuery($name: String!, $kind: Kind!, $state: String, $country: String) {
  sports_region_create_hint(name: $name, kind: $kind, country: $country, state: $state ) {
    name
    key
    code
    short_code
    official_name
    similar_items {
      resource {
        account
        key,
        hashkey: _hashkey
      }
      region {
        code
        short_code
        name
        kind
        country
        state
        official_name
        internal_name
      }
    }
    key_suggestions
    country
    state
  }
}
`;

export const RegionCreateMutation = gql`
mutation RegionCreate(
  $region: RegionRegionInput!,
  $status: RegionStatusInput!,
  $key: String) {
  sports_region_create(
    region: $region,
    status: $status,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const RegionReadQuery = gql`
query RegionReadQuery($resource: RegionInput!, $withHistory: Boolean) {
  sports_region_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      region {
        code
        short_code
        name
        kind
        country
        state
        official_name
        internal_name
      }
      status {
        published
        notes
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const RegionListQuery = gql`
query RegionListQuery {
  sports_region_list {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      region {
        code
        short_code
        name
        kind
        country
        state
        official_name
        internal_name
      }
    }
    page {
      next_page_key
      count
    }
  }
}
`;

export const RegionUpdateMutation = gql`
mutation RegionUpdateMutation(
  $resource: RegionInput!,
  $region: RegionRegionInput!,
  $status: RegionStatusInput!,
  $version: RecordStage) {
  sports_region_update(resource: $resource, region: $region, status: $status, version: $version) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const RegionUpdateStatusMutation = gql`
mutation RegionUpdateStatusMutation(
  $resource: RegionInput!,
  $status: RegionStatusInput!,
  $version: RecordStage) {
  sports_region_update_status(resource: $resource, status: $status, version: $version) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const RegionDeleteMutation = gql`
mutation RegionDeleteMutation(
  $resource: RegionInput!,
  $name: String!) {
  sports_region_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const RegionSearchQuery = gql`
query RegionSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_region_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      region {
        code
        short_code
        name
        kind
        country
        state
        official_name
        internal_name
      }
      status {
        published
        notes
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const RegionNameQuery = gql`
query RegionNameQuery($resource: RegionInput!) {
  sports_region_name(resource: $resource) {
    name
    internal_name
  }
}
`;
