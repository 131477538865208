import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const TourSearchQuery = gql`
query TourSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_cricket_tour_search(search: $search, page: $page) {
    items {
      resource {
        account
        sport
        association
        key
        hashkey: _hashkey
      }
      tour {
        internal_name
        name
        team_a { hashkey: _hashkey }
        team_b { hashkey: _hashkey }
        home_team { hashkey: _hashkey }
        tournaments { hashkey: _hashkey }
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const TourReadQuery = gql`
query TourReadQuery($resource: CricketTourInput!, $withHistory: Boolean) {
  sports_cricket_tour_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        sport
        association
        key
        hashkey: _hashkey
      }
      tour {
        internal_name
        name
        team_a { hashkey: _hashkey }
        team_b { hashkey: _hashkey }
        home_team { hashkey: _hashkey }
        tournaments { hashkey: _hashkey }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const TourUpdateMutation = gql`
mutation TourUpdateMutation(
  $resource: CricketTourInput!,
  $tour: CricketTourTourInput!,
  $version: RecordStage) {
    sports_cricket_tour_update(resource: $resource, tour: $tour, version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const TourNameQuery = gql`
query TourNameQuery($resource: CricketTourInput!) {
  sports_cricket_tour_name(resource: $resource) {
    name
  }
}
`;

export const TourDeleteMutation = gql`
mutation TourDeleteMutation(
  $resource: CricketTourInput!,
  $name: String!) {
  sports_cricket_tour_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;
