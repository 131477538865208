import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, RefField, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { TourImageView } from './ResourceImage';
import {
  TourSearchQuery,
  TourReadQuery,
  TourUpdateMutation,
  TourNameQuery,
} from './query';

export const TourResource = Resource({
  resourceId: 'tour',
  name: 'Tour',
  storeId: 'Tour',
  pageKey: 'sports.cricket.tour',
  columnSets: [
    ColumnSet({
      name: 'Tour',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        internal_name: StringFieldMinMax(3, 120),
        home_team: RefField(),
      }),
      referenceFields: ['team_a', 'team_b'],
      historyKey: 'CricketTourTour',
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<TourImageView item={item} />),
  queries: {
    read: QueryAction({
      query: TourReadQuery,
      responsePath: 'sports_cricket_tour_read',
      resourceNamePath: 'item.tour.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: TourSearchQuery, responsePath: 'sports_cricket_tour_search' }),
    search: QueryAction({
      query: TourSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'tour.name',
      responsePath: 'sports_cricket_tour_search',
    }),
    name: QueryAction({ query: TourNameQuery, resourceNamePath: 'sports_cricket_tour_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: TourUpdateMutation,
      cs: ['tour'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.tour.tournaments = arrayAsObject(
          resp.tour.tournaments,
        ) ?? {};
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.tour.home_team = data?.tour?.home_team?.hashkey
          ? { _hashkey: data?.tour?.home_team?.hashkey } : null;
        resp.tour.tournaments = objectAsArray(resp.tour.tournaments).map((item) => {
          return { _hashkey: item.hashkey };
        });
        return {
          ...resp,
        };
      },
    }),
  },
});
