import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as WifiOfflineIcon } from 'assets/icons/icon-wifi-offline.svg';
import { Label12Regular } from 'style/typography';
import { SlideUpKF } from 'style/keyframes';
import { asRem } from 'lib/css';
import { RealtimeContext } from './RealtimeContext';
import { ConnectionState } from './socket';

const RealtimeConnectionStatusWrapper = styled.div`
  position: fixed;
  bottom: ${asRem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  z-index: 10000;

  animation: ${SlideUpKF} 1s linear;

  >.status-msg {
    transition: all 1s ease-in-out;
    color: var(--color-offline-status);
    border-radius: ${asRem(4)};
    padding: ${asRem(10)};

    &.state-Connecting {
      background: var(--color-secondary-bg);
    }

    &.state-Connected {
      background: var(--color-secondary-bg);
    }
  }
  &:empty {
    padding: 0;
  }
  svg {
    margin-right: ${asRem(10)};
  }
  span {
    display: inline-block;
    vertical-align: top;
  }
`;

export function RealtimeConnectionStatus() {
  const antsContext = useContext(RealtimeContext);
  // if (antsContext.socketState === ConnectionState.Init
  //   || antsContext.socketState === ConnectionState.Connected) {
  //   return null;
  // }
  if (antsContext.socketState === ConnectionState.Connected) {
    return null;
  }

  const className = `status-msg state-${antsContext.socketState}`;
  const retryIndex = (antsContext.socket) ? antsContext.socket.retryIndex : 0;
  const isConnecting = antsContext.socketState === ConnectionState.Connecting
    && retryIndex === 0
    && (new Date() - antsContext.socket.connectingSince) > 3000;
  const isReconnecting = retryIndex > 0;
  const isError = antsContext.socketState === ConnectionState.Error;

  return (
    <RealtimeConnectionStatusWrapper>
      <div className={className}>
        {isConnecting && (
          <Label12Regular>
            Connecting to realtime editor...
          </Label12Regular>
        )}
        {(isError || isReconnecting) && (
          <>
            <WifiOfflineIcon />
            {' '}
            <Label12Regular>
              You are not connected to realtime editor.
              {' '}
              {isReconnecting
                ? 'Reconnecting...'
                : 'Your changes may not be saved!'}
            </Label12Regular>
          </>
        )}
      </div>
    </RealtimeConnectionStatusWrapper>
  );
}

RealtimeConnectionStatus.propTypes = {
};
