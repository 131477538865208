import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as HoverPrimitive from '@radix-ui/react-hover-card';
import { asRem } from 'lib/css';
import {
  SlideUpAndFadeKF,
  SlideDownAndFadeKF,
  SlideRightAndFadeKF,
  SlideLeftAndFadeKF,
} from 'style/keyframes';
import { MutationButton } from './Button';

export const Hover = styled(HoverPrimitive.Root)`
z-index: 10024;
`;
Hover.Trigger = styled(HoverPrimitive.Trigger)`
  padding: 0;
  margin: 0;
  display: block;
  &[data-state="open"] {
    color: var(--color-highlight);
    fill: var(--color-highlight);
  }
`;

Hover.ContentWrapper = styled(HoverPrimitive.Content)`
  min-width: ${asRem(40)};
  background-color: var(--color-border-1);
  color: var(--color-text-1);
  border-radius: ${asRem(6)};
  border: 1px solid var(--color-border-2);
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  padding: ${asRem(10)} ${asRem(10)};

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: 'forwards';

  &:hover {
    color: var(--color-button-primary-bg);
  }

  &[data-state="open"] {
    animation-name: ${SlideUpAndFadeKF};
    &[data-side="top"] {
      animation-name: ${SlideDownAndFadeKF};
    }
    &[data-side="right"] {
      animation-name: ${SlideLeftAndFadeKF};
    }
    &[data-side="bottom"] {
      animation-name: ${SlideUpAndFadeKF};
    }
    &[data-side="left"] {
      animation-name: ${SlideRightAndFadeKF};
    }
  }

  &[data-align="end"] {
    /* border-top-right-radius: 0; */
    svg {
      margin-left: ${asRem(8)};
    }
  }

  &[data-align="start"] {
    /* border-top-left-radius: 0; */
    svg {
      margin-right: ${asRem(8)};
    }
  }
`;

Hover.Item = styled.div`
  all: unset;
  line-height: ${asRem(24)};
  font-size: ${asRem(12)};
  padding: 0 ${asRem(6)};
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg, span {
    display: block;
  }

  svg {
    fill: var(--color-secondary-bg);
    margin-right: ${asRem(12)};
  }

  &:focus {
    background-color: var(--color-button-primary-bg);
    border-radius: ${asRem(5)};
  }
`;
Hover.Arrow = styled(HoverPrimitive.Arrow)`
  fill: var(--color-border-1);
`;
Hover.Separator = styled.div`
  height: 1px;
  background-color: var(--color-disabled-text);
  margin: ${asRem(5)};
`;

Hover.Content = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, forwardedRef) => {
    return (
      <Hover.ContentWrapper {...props} ref={forwardedRef}>
        {children}
        <Hover.Arrow />
      </Hover.ContentWrapper>
    );
  },
);

export function HoverButtons({
  name,
  iconView,
  buttonClassName,
  mutations,
  contentAlign = 'center',
}) {
  const [keepOpen, setKeepOpen] = useState(false);
  const onButtonHandled = () => {
    setKeepOpen(false);
  };

  return (
    <Hover
      open={keepOpen}
      onOpenChange={(openChange) => setKeepOpen(openChange)}
    >
      <Hover.Trigger className={buttonClassName}>
        {iconView}
        {name || ''}
      </Hover.Trigger>
      <Hover.Content align={contentAlign}>
        {mutations.map((mutation, index) => (
          <React.Fragment key={mutation.name}>
            <Hover.Item>
              <MutationButton
                {...mutation}
                onSuccess={onButtonHandled}
              />
            </Hover.Item>
            {index < (mutations.length - 1) && (
              <Hover.Separator />
            )}
          </React.Fragment>
        ))}
      </Hover.Content>
    </Hover>
  );
}

HoverButtons.propTypes = {
  mutations: PropTypes.arrayOf(PropTypes.shape({
    ...MutationButton.propTypes,
  })),
  name: PropTypes.string,
  contentAlign: PropTypes.string,
  iconView: PropTypes.node,
  buttonClassName: PropTypes.string,
};
