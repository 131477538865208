import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ListController } from 'core/ListController';
// import { Pages } from 'core/pages';
import { UserContext } from 'core/UserContext';
import { asRem } from 'lib/css';
import { AccountImageView } from 'core/Image';
import { ReactComponent as MailIcon } from 'assets/icons/icon-mail.svg';
import { Display18Regular, Text16Bold, Text16Regular } from 'style/typography';
import { ReactComponent as ArrowIcon } from 'assets/icons/icon-arrow-right-filled.svg';
import { AccountsListQuery } from './query';
import { PendingInvites } from './UserSettings/AccountInvites';

const AccountPickerWrapper = styled.div`
  max-width: ${asRem(340)};
  margin: 0 auto;

  > div {
    padding: 0;
  }

  .listview-title-bar {
    border-bottom: none;
    text-align: center;
    display: block;
    .actions {
      display: none;
    }
  }

  .title-container {
    text-align: center;

    .display-18-regular {
      margin-bottom: ${asRem(10)};
      text-transform: capitilize;
    }
    h2 {
      font-size: ${asRem(28)};
      line-height: ${asRem(38)};
      font-weight: var(--bold-fontWeight);
      margin-bottom: ${asRem(40)};
    }
  }

  .accounts {
    margin-bottom: ${asRem(20)};
    li {
      >div {
        margin-bottom: ${asRem(8)};
        padding: ${asRem(16)} ${asRem(20)};    
        background: var(--color-secondary-bg);
        border-radius: ${asRem(8)};
        cursor: pointer;
        display: flex;
        gap: ${asRem(30)};
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;
          align-items: center;
          gap: ${asRem(12)};
        }
      }
    }
  }

  a {
    &.plain {
      display: block;
      text-align: center;
      width: 100%;
      margin-top: ${asRem(20)};
    }
  }

  .accounts-invites {
    margin-top: ${asRem(40)};
    .title-wrapper {
      display: flex;
      gap: ${asRem(8)};
      align-items: center;
      margin-bottom: ${asRem(12)};
    }
  }
`;

export function AccountPicker({
  redirect = true,
}) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const onAccountSelect = (account) => {
    userContext.setAccountKey(account.resource.key);
    userContext.setAccount(account);
    if (redirect) {
      navigate('/');
      window.location.reload();
    }
  };

  return (
    <AccountPickerWrapper>
      <ListController
        query={AccountsListQuery}
        responseKey="account_list"
        itemsKey="accounts"
        renderTitle={() => (
          <div className="title-container">
            <Display18Regular>
              Welcome Back
              {', '}
              {userContext?.user?.profile?.first_name}
              !
            </Display18Regular>
            <h2>Select Account</h2>
          </div>
        )}
        useTable={false}
        useSearch={false}
        emptyMessage="You have no active accounts"
        renderRows={(items) => (
          <ul className="accounts">
            {items.map((item, i) => (
              <li
                key={item.resource.key}
              >
                <div
                  role="button"
                  onClick={() => onAccountSelect(item)}
                  onKeyDown={() => onAccountSelect(item)}
                  tabIndex={i + 1}
                >
                  <div>
                    <AccountImageView account={item} size={28} />
                    <Text16Regular>{item.profile.name}</Text16Regular>
                  </div>
                  <ArrowIcon />
                </div>
              </li>
            ))}
          </ul>
        )}
        renderRowFoot={() => (
          <div>
            {userContext.user.accounts.invitations_count > 0 && (
              <div className="accounts-invites">
                <div className="title-wrapper">
                  <MailIcon />
                  <Text16Bold>You are invited</Text16Bold>
                </div>
                <PendingInvites user={userContext} isAccountScreen />
              </div>
            )}
            {/* <div>
              <Link to={Pages.account.create.meta.url}
               className="bordered plain">Create Account</Link>
            </div> */}
          </div>
        )}
        useInlineTools
      />
    </AccountPickerWrapper>
  );
}

AccountPicker.propTypes = {
  redirect: PropTypes.bool,
};

export default AccountPicker;
