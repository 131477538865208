/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.errorInfo) {
      // Error path
      if (this.props.renderCustomError) {
        return this.props.renderCustomError();
      }
      return (
        <div style={{ marginTop: '60px' }}>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
