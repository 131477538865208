import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const PersonReadQuery = gql`
query PersonReadQuery ($resource: PersonInput!, $withHistory: Boolean ) {
  sports_person_read(resource: $resource, with_history: $withHistory ) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      person {
        name
        full_name
        sports
        date_of_birth
        player {
          account
          sport
          key
          hashkey: _hashkey
        }
        country {
          account
          key
          hashkey: _hashkey
        }
        roles
      }
      image {
        image_url
      }
    }
    history {
      ${VersionHistoryFields}
        
    }
  }

}
`;

export const PersonCreateHintQuery = gql`
 query PersonCreateHintQuery ($name: String!) {
  sports_person_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        sport
        hashkey: _hashkey
      }
      person {
        name
        full_name
        sports
        date_of_birth
        roles
        player {
          account
          sport
          key
          hashkey: _hashkey
        }
        country {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    key_suggestions
  }
 }
`;

export const PersonCreateMutation = gql`
mutation PersonCreateMutation (
  $person: PersonPersonInput!,
  $image: PersonImageInput,
  $key: String

 ){
   sports_person_create(person: $person, image: $image, key: $key) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
 }
`;

export const PersonUpdateMutation = gql`
 mutation PersonUpdateMutation (
  $resource: PersonInput!,
  $person: PersonPersonInput!,
  $version: RecordStage
 ){
  sports_person_update(resource: $resource, person: $person, version: $version) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
 }
`;

export const PersonDeleteMutation = gql`
mutation PersonDeleteMutation($resource: PersonInput!, $name: String, $image_url: String) {
  sports_person_delete ( resource: $resource, name: $name, image_url: $image_url) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const PersonSearchQuery = gql`
 query PersonSearchQuery (
  $search: String!, $page: PagedRequestInput 
 ){
  sports_person_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      person {
        name
        full_name
        sports
        date_of_birth
        player {
          account
          sport
          key
          hashkey: _hashkey
        }
        country {
          account
          key
          hashkey: _hashkey
        }
        roles
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }

  }
 }
`;

export const PersonListQuery = gql`
 query PersonListQuery ($page: PagedRequestInput) {
  sports_person_list(page: $page) {
    items {
      resource {
        account
        key
        hashkey:_hashkey
      }
      person {
        name
        full_name
        sports
        date_of_birth
        roles
        player {
          account
          sport
          key
          hashkey: _hashkey
        }
        country {
          account
          key
          hashkey: _hashkey
        }
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }

  }

 }
`;

export const PersonImageUpdateMutation = gql`
 mutation PersonImageUpdateMutation($resource: PersonInput!, $filename: String!, $version: RecordStage) {
  sports_person_image_upload (resource: $resource, filename: $filename, version: $version) {
    signed_data
    url
    url_suffix
  }
 }
`;

export const PersonNameQuery = gql`
query PersonNameQuery($resource: PersonInput!) {
  sports_person_name(resource: $resource) {
    name
    image_url
  }
}
`;
