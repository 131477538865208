import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const TeamRankingReadQuery = gql`
  query TeamRankingReadQuery($resource: CricketTeamRankingInput!, $withHistory: Boolean) {
    sports_cricket_team_rankings_read(resource: $resource, with_history: $withHistory) {
      item {
        resource {
          account
          sport
          association
          format
          gender
          announced_date
          hashkey: _hashkey
        }
        name {
          name
        }
        rank {
          teams {
            team {
              account
              sport
              key
             hashkey:  _hashkey
            }
            matches
            points
            rating
            region {
              account
              key
              hashkey: _hashkey
            }
          }
       }
        status {
          published
          notes
        }
      }
      history {
        ${VersionHistoryFields}
      }
    } 
  }
`;

export const TeamRankingCreateMutation = gql`
  mutation TeamRankingCreateMutation($resource: CricketTeamRankingInput!, $rank: CricketTeamRankingRankInput!) {
    sports_cricket_team_rankings_create(resource: $resource, rank: $rank) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const TeamRankingSearchQuery = gql`
  query TeamRankingSearchQuery($search: String!, $filters: RankingsFiltersInput, $page: PagedRequestInput) {
    sports_cricket_team_rankings_search(search: $search, filters: $filters, page: $page) {
      items {
      resource {
        account
        sport
        association
        format
        gender
        announced_date
        hashkey:_hashkey
      }
      name {
        name
      }
      computed_searchables {
        association_hashkey
      }
      rank {
        teams {
          team {
            account
            sport
            key
            hashkey:_hashkey
          }
          matches
          points
          rating
          region {
            account
            key
            hashkey: _hashkey
          }
        }
      }
      status {
        published
        notes
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
    top_counts {
      genders {
        key
        name
        count
      }
      associations {
        key
        name
        count
      }
      formats {
        key
        name
        count
      }
    }
    }
  }
`;

export const TeamRankingUpdateMutation = gql`
  mutation TeamRankingUpdateMutation($resource: CricketTeamRankingInput!, $rank: CricketTeamRankingRankInput!, $version: RecordStage) {
    sports_cricket_team_rankings_update(resource: $resource, rank: $rank, version: $version) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const TeamRankingDeleteMutation = gql`
  mutation TeamRankingDeleteMutation($resource: CricketTeamRankingInput!, $name: String!) {
    sports_cricket_team_rankings_delete(resource: $resource, name: $name) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const TeamRankingAutoFillQuery = gql`
  query TeamRankingAutoFillQuery($association: AssociationInput!, $gender: Gender!, $format: CricketMatchFormat!) {
    sports_cricket_team_rankings_auto_fill(association: $association, gender: $gender, format: $format) {
      item {
      resource {
        account
        sport
        association
        format
        gender
        announced_date
        hashkey: _hashkey
      }
      name {
        name
      }
      computed_searchables {
        association_hashkey
      }
      rank {
        teams {
          team {
            account
            sport
            key
            hashkey: _hashkey
          }
          matches
          points
          rating
          region {
            account
            key
            hashkey: _hashkey
          }
        }
      }
      status {
        published
        notes
      }
    }
    }
  }
`;
