import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResourceImageView } from 'core/Image';
import { asRem } from 'lib/css';

const StyledResourceImageView = styled(ResourceImageView)`
  .rz-image-view {
    --image-color: #FF95E8;
    --image-border-radius: ${asRem(5)}
  }
`;

export function PersonImageView({ item, size, ...imageProbs }) {
  return (
    <StyledResourceImageView
      name={item.resource_name ?? item.person?.name}
      url={item.image ? item.image.image_url : item.resource_image_url}
      size={size || 24}
      rounded
      {...imageProbs}
    />
  );
}

PersonImageView.propTypes = {
  item: PropTypes.object,
  size: PropTypes.string,
};
