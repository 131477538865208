import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const Home = lazy(() => import('./Home'));
const List = lazy(() => import('./List'));
const EditRoles = lazy(() => import('./EditRoles'));
const EditCredits = lazy(() => import('./EditCredits'));
const CreditVersions = lazy(() => import('./CreditVersions'));
const RoleVersions = lazy(() => import('./RoleVersions'));

export function FantasyEditorRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route path={Pages.fantasy.match.meta.path} element={<Home />} />
      </Routes>
      <Routes>
        <Route path={Pages.fantasy.match.list.meta.path} element={<List />} />
      </Routes>
      <Routes>
        <Route
          path={Pages.fantasy.match.creditVersions.meta.path}
          element={<CreditVersions />}
        />
      </Routes>
      <Routes>
        <Route
          path={Pages.fantasy.match.roleVersions.meta.path}
          element={<RoleVersions />}
        />
      </Routes>
      <Routes>
        <Route
          path={Pages.fantasy.match.updateCredits.meta.path}
          element={<EditCredits />}
        />
      </Routes>
      <Routes>
        <Route path={Pages.fantasy.match.updateRoles.meta.path} element={<EditRoles />} />
      </Routes>
    </Suspense>
  );
}
