import styled from 'styled-components';

// Display Fonts Typography

export const Display48Regular = styled.h1.attrs(() => ({
  className: 'display-48-regular',
}))`
  font-size: var(--display-xl-fontSize);
  line-height:  var(--display-xl-lineHeight);
  font-weight: var(--regular-fontWeight);
`;

export const Display34Bold = styled.h1.attrs(() => ({
  className: 'display-34-bold',
}))`
  font-size: var(--display-lg-fontSize);
  line-height: var(--display-lg-lineHeight);
  font-weight: var(--bold-fontWeight);
`;

export const Display24Bold = styled.h2.attrs(() => ({
  className: 'display-24-bold',
}))`
  font-size: var(--display-md-fontSize);
  line-height: var(--display-md-lineHeight);
  font-weight: var(--bold-fontWeight);
`;

export const Display20Bold = styled.h3.attrs(() => ({
  className: 'display-20-bold',
}))`
  font-size: var(--display-sm-fontSize);
  line-height: var(--display-sm-lineHeight);
  font-weight: var(--bold-fontWeight);
`;

export const Display18Bold = styled.h4.attrs(() => ({
  className: 'display-18-bold',
}))`
  font-size: var(--display-xs-fontSize);
  line-height: var(--display-xs-lineHeight);
  font-weight: var(--bold-fontWeight);
`;
export const Display18Regular = styled.h4.attrs(() => ({
  className: 'display-18-regular',
}))`
  font-size: var(--display-xs-fontSize);
  line-height: var(--display-xs-lineHeight);
  font-weight: var(--regular-fontWeight);
`;

// Text Fonts Typography

export const Text16Bold = styled.p.attrs(() => ({
  className: 'text-16-bold',
}))`
  font-size: var(--text-lg-fontSize);
  line-height: var(--text-lg-lineHeight);
  font-weight: var(--bold-fontWeight);
`;

export const Text16Medium = styled.p.attrs(() => ({
  className: 'text-16-medium',
}))`
  font-size: var(--text-lg-fontSize);
  line-height: var(--text-lg-lineHeight);
  font-weight: var(--medium-fontWeight);
`;

export const Text16Regular = styled.p.attrs(() => ({
  className: 'text-16-regular',
}))`
  font-size: var(--text-lg-fontSize);
  line-height: var(--text-lg-lineHeight);
  font-weight: var(--regular-fontWeight);
`;

export const Heading3 = styled.h3.attrs(() => ({
  className: 'heading-3',
}))`
  font-size: var(--text-lg-fontSize);
  line-height: var(--text-lg-lineHeight);
  font-weight: var(--regular-fontWeight);
`;

export const Text14Bold = styled.p.attrs(() => ({
  className: 'text-14-bold',
}))`
  font-size: var(--text-md-fontSize);
  line-height: var(--text-md-lineHeight);
  font-weight: var(--bold-fontWeight);
`;

export const Text14Medium = styled.p.attrs(() => ({
  className: 'text-14-medium',
}))`
  font-size: var(--text-md-fontSize);
  line-height: var(--text-md-lineHeight);
  font-weight: var(--medium-fontWeight);
`;

export const Text14Regular = styled.p.attrs(() => ({
  className: 'text-14-regular',
}))`
  font-size: var(--text-md-fontSize);
  line-height: var(--text-md-lineHeight);
  font-weight: var(--regular-fontWeight);
`;

// Label Fonts Typography

export const Label12Medium = styled.span.attrs(() => ({
  className: 'label-12-medium',
}))`
  font-size: var(--label-lg-fontSize);
  line-height: var(--label-lg-lineHeight);
  font-weight: var(--medium-fontWeight);
`;

export const Label12Regular = styled.span.attrs(() => ({
  className: 'label-12-regular',
}))`
  font-size: var(--label-lg-fontSize);
  line-height: var(--label-lg-lineHeight);
  font-weight: var(--regular-fontWeight);
`;
export const Label10Medium = styled.span.attrs(() => ({
  className: 'label-8-medium',
}))`
  font-size: var(--label-md-fontSize);
  line-height: var(--label-md-lineHeight);
  font-weight: var(--medium-fontWeight);
`;
export const Label8Medium = styled.span.attrs(() => ({
  className: 'label-8-medium',
}))`
  font-size: var(--label-sm-fontSize);
  line-height: var(--label-sm-lineHeight);
  font-weight: var(--medium-fontWeight);
`;
