import { MenuBar } from 'core/MenuBar';
import React from 'react';
import { ReactComponent as IconFantasy } from 'assets/icons/icon-fantasy.svg';
import { Pages } from 'core/pages';

export function FantasyMenu() {
  const links = [
    {
      name: 'Fantasy Credit Editor',
      link: Pages.fantasy.match,
      icon: <IconFantasy />,
    },
    {

      name: 'Curated Matches',
      link: Pages.fantasy.curation,
      icon: <IconFantasy />,

    },
    {
      name: 'Widget',
      link: Pages.fantasy.wizard,
      icon: <IconFantasy />,
    },

  ];

  return (
    <MenuBar links={links} />
  );
}

export default FantasyMenu;
