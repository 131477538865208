import React from 'react';
import * as Yup from 'yup';
import {
  Resource, ColumnSet, StringFieldMinMax, QueryAction, MutationAction,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { isDeletedValue } from 'core/Form';
import {
  TeamRankingReadQuery,
  TeamRankingAutoFillQuery,
  TeamRankingCreateMutation,
  TeamRankingSearchQuery,
  TeamRankingDeleteMutation,
  TeamRankingUpdateMutation,
} from './query';
import { RankingImageView } from '../RankingImageView';

function TeamRankValidator() {
  const validateRank = (value, context) => {
    const teams = objectAsArray(value);
    if (teams) {
      for (let i = 0; i < teams.length; i += 1) {
        if (!isDeletedValue(teams[i])) {
          if (!teams[i].matches || parseInt(teams[i].matches, 10) === 0) {
            return context.createError({ message: `Invalid value for matches on row ${i + 1}` });
          }
          if (!teams[i].points || parseFloat(teams[i].points, 2) === 0) {
            return context.createError({ message: `Invalid value for points on row ${i + 1}` });
          }
          if (!teams[i].rating || parseFloat(teams[i].rating, 10) === 0) {
            return context.createError({ message: `Invalid value for rating on row ${i + 1}` });
          }
        }
      }
    }
    return true;
  };

  return Yup.mixed()
    .test(
      'team-rank-validator',
      validateRank,
    );
}

export const TeamRankingResource = Resource({
  resourceId: 'cricketTeamRanking',
  name: 'CricketTeamRanking',
  storedId: 'CricketTeamRanking',
  pageKey: 'sports.cricket.ranking',
  keyVal: 'Ranking',
  storeVal: 'Ranking',
  columnSets: [
    ColumnSet({
      name: 'name',
      shape: Yup.object().shape({
        name: StringFieldMinMax(2, 120).required,
      }),
      viewKey: 'name',
      historyKey: 'CricketTeamRankingName',
    }),
    ColumnSet({
      name: 'rank',
      shape: Yup.object().shape({
        teams: TeamRankValidator(),
        teams_count: Yup.number(),
      }),
      viewKey: 'rank',
      historyKey: 'CricketTeamRankingRank',
    }),
    ColumnSet({
      name: 'status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(true),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
      viewKey: 'status',
      historyKey: 'CricketTeamRankingStatus',
    }),
    ColumnSet({
      name: 'ComputedSearchables',
      shape: Yup.object().shape({
        association_hashkey: Yup.string(),
      }),
      viewKey: 'computed_searchables',
      historyKey: 'CricketTeamRankingComputedSearchables',
    }),
  ],
  renderImage: (item) => (<RankingImageView item={item} />),
  listPrimaryActions: [
    { action: 'updateTeam', name: 'update' },
  ],
  queries: {
    read: QueryAction({
      query: TeamRankingReadQuery,
      resourceNamePath: 'item.name.name',
      responsePath: 'sports_cricket_team_rankings_read',
    }),
    search: QueryAction({
      query: TeamRankingSearchQuery,
      resourceNamePath: 'item.name.name',
      resourcePath: 'resource.hashkey',
      responsePath: 'sports_cricket_team_rankings_search',
    }),
    autoFill: QueryAction({
      query: TeamRankingAutoFillQuery,
      resourceNamePath: 'item.name.name',
      resourcePath: 'resource.hashkey',
      responsePath: 'sports_cricket_team_rankings_auto_fill',
    }),
  },
  mutations: {
    update: MutationAction({
      mutation: TeamRankingUpdateMutation,
      responsePath: 'sports_cricket_team_rankings_update',
      cs: ['rank'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.rank.teams = arrayAsObject(resp?.rank?.teams);
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.rank.teams = objectAsArray(resp?.rank?.teams);
        const teamsArray = [];
        resp.rank.teams.forEach((team) => {
          teamsArray.push({
            team: {
              _hashkey: team?.team?.hashkey,
            },
            matches: parseInt(team.matches, 10),
            points: parseFloat(team.points, 2),
            rating: parseFloat(team.rating, 2),
          });
        });
        resp.rank.teams = teamsArray;
        resp.rank.teams_count = teamsArray.length;
        return resp;
      },
    }),
    create: MutationAction({
      mutation: TeamRankingCreateMutation,
      responsePath: 'sports_cricket_team_rankings_create',
    }),
    delete: MutationAction({
      mutation: TeamRankingDeleteMutation,
      responsePath: 'sports_cricket_team_rankings_delete',
    }),
  },
});

export default TeamRankingResource;
