import React from 'react';
import styled from 'styled-components';
import { Display18Regular } from 'style/typography';
import { asRem } from 'lib/css';
import { useNavigate } from 'react-router-dom';

const PasswordChangedWrapper = styled.div`
  max-width:  ${asRem(460)};
  border: ${asRem(1)} solid var(--color-border-1);
  background: var(--color-secondary-bg);
  padding: ${asRem(20)};
  margin: ${asRem(150)} auto;

  p {
    margin: ${asRem(10)} 0 ${asRem(20)} 0;
  }

  button {
    min-width : 100%;
  }
`;

export default function PasswordChanged() {
  const navigate = useNavigate();
  const location = window.location.href;
  const parts = location.split('/');
  const path = parts.slice(3, 4).join('/');
  return (

    <PasswordChangedWrapper>
      <Display18Regular>Password Changed</Display18Regular>
      <p>Your Password has been changed Successfully</p>
      <button
        className="primary"
        type="button"
        onClick={() => {
          navigate(`/${path}/dashboard`);
        }}
      >
        okay

      </button>
    </PasswordChangedWrapper>

  );
}
