/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import PricingImg from 'assets/pricing-page/PricingImg.png';
import RoanuzLogo from 'assets/landing-page/RoanuzLogo.png';
import { Link } from 'react-router-dom';
import { asRem } from 'lib/css';
import {
  Display18Bold, Display34Bold, Display24Bold, Text14Regular, Label12Regular, Text14Bold,
} from 'style/typography';
import { Box } from 'core/Box';
import { useQuery } from '@apollo/client';
import { StatusLoading, StatusError } from 'core/Status';
import ReactMarkdown from 'react-markdown';
import { Config } from 'config';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { UserContext } from 'core/UserContext';
import { UserDropdown } from 'core/UserDropdown';
import CheckIcon from '../assets/icons/icon-check-primary.svg';
import { AnBusinessProductList } from './query';

const PricingListWrapper = styled.div`
  
  --color-cricket: #75DBFF;
  --color-fantasy: #FF9595;
  --color-child3: #B46060;
  --color-text-disabled: #7F8184;
  --color-username-bg: #0055BF;
  
  .inner-wrapper {
    display: flex;
    flex-direction: row;
  }


  >div {
    height: 100vh;
  }

  .back-button-wrapper {
    display: flex;
    justify-content: end;
    padding: ${asRem(10)} 0;

    a {
      text-decoration: underline;
    }
  }

  >.text-14-bold {
    padding: ${asRem(8)} ${asRem(12)};
    background: #CC2DA9;
    text-align: center;
  }
  .pricing-image {
    flex: 0.40;
    background: url(${PricingImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .logo-container {
      display: flex;
      align-items: center;
      gap: ${asRem(10)};
      margin-left: ${asRem(20)};

      .logo {
        height: ${asRem(42)};
        width: ${asRem(42)};
        border: 1px solid white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;

        img {
          height: ${asRem(22.5)};
          width: ${asRem(22.5)};
        }
      }

      .display-18-bold {
        color: #fff;
        font-weight: 800;
      }
    }

    .title {
      font-size: ${asRem(140)};
      font-weight: 500;
      margin-left: ${asRem(80)};
      margin-bottom: ${asRem(50)};
    }
  }

 .content {
  flex: 0.60;
  display: flex;
  flex-direction: column;
  align-items : center;
  justify-content: center;
  overflow-y: scroll;

  .price {
    margin-top: ${asRem(100)};
  }
  

  .user-name {
    position: absolute;
    top: ${asRem(48)};
    right: ${asRem(20)};

    .profile-wrapper {
      background: transparent;
    }
  }

  .label-12-regular {
    color: var(--color-text-2);
    font-size: ${asRem(14)};
    line-height: var(--text-md-lineHeight);
  }

  .region-option {
    max-width: max-content;
    margin: ${asRem(8)} 0 ${asRem(16)} 0;
    border-radius: ${asRem(4)};

    select {
      padding: ${asRem(12)} ${asRem(16)};
      padding-right: ${asRem(40)};
      appearance: none;
      background-position: calc(100% - 16px) center;
    }
  }

  .content-header {
    margin-bottom: ${asRem(20)};
  }

  .pricing-content {
    max-width: ${asRem(540)};

    .rz-box {
      margin-bottom: ${asRem(16)};

    &:nth-child(n) {
      p {
        color: var(--color-cricket);
      }
    }

    &:nth-child(2n) {
      p {
        color: var(--color-fantasy);
      }
    }

      &:nth-child(3n) {
        p {
          color: var(--color-child3);
        }
      }
    }

    > div {
      padding: ${asRem(32)};
    }

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      gap: ${asRem(40)};

      .product-title {
        .display-34-bold {
          strong {
            display: block;
            color: var(--color-text-1);
          }
        }
      }

      .region {
        display: flex;
        gap: ${asRem(4)};
        flex-direction: column;

        .display-24-bold {
          align-self: flex-end;
          font-weight: 700;
        }

        .text-14-regular {
          text-align: right;
        }

        span, .text-14-regular {
          color: var(--color-text-disabled);
        }

        a {
          padding: 0;
          font-weight: 400;
        }

        span {
          border-bottom: 1px solid var(--color-text-disabled);
        }
      }
    }

      .display-34-bold {
        font-size: ${asRem(40)};
        font-weight: 500;
        text-transform: capitalize;
      }

      .content-desc {
        display: flex;
        justify-content: space-between;
        gap:${asRem(60)};

        .desc-container {
          ul {
            display: flex;
            flex-direction: column;
            gap: ${asRem(4)};
          }

          ul li {
            font-size: ${asRem(16)};
            position: relative;
            left: 10px;
            line-height: ${asRem(24)};
            word-break: break-word;

            strong {
              font-weight: bolder;
              display: inline;
            }

            &::before {
              content: url(${CheckIcon});
              position: absolute;
              left: -22px;
            }
          }

          img {
            height: ${asRem(12)};
            width: ${asRem(12)};
          }
        }

        a {
          align-self: flex-end;
          padding: ${asRem(12)} ${asRem(40)};
        }
      }
    }
  }

  @media  screen and (min-width: ${asRem(320)}) and (max-width: ${asRem(767)}){
    display: flex;
    flex-direction : column;

    .pricing-image {
      .title {
        font-size: ${asRem(40)};
        margin-left: ${asRem(20)};
        margin-bottom: ${asRem(5)};
      }
    }

    .price {
      margin-top: 0;
    }
    
   .content {
      margin-top: ${asRem(35)};
      .region-option, .label-12-regular {
        margin-left: ${asRem(18)};
      }
      .pricing-content {
        min-width: ${asRem(30)};
        padding: 0 ${asRem(10)};

        .content-header {
          .display-34-bold, .display-24-bold {
            font-size: ${asRem(20)};
            font-weight: 100;
            line-height: 25px;
          }

          .region {
            .display-24-bold {
              font-size: ${asRem(16)};
              line-height: 10px;
              margin-top: ${asRem(8)};
            }

            .text-14-regular {
              margin-left: ${asRem(18)};
            }
          }
        }
      }

      .content-desc {
        flex-direction: column;

        a {
          width: 100%;
        }
      }

      .desc-container {
         .text-16-regular {
            font-size: ${asRem(13)};
         }
      }
    }
 }

  @media screen and (min-width: ${asRem(768)}) and (max-width: ${asRem(1024)}) {

    display: flex;
    flex-direction: row;

    .price {
      margin-top: ${asRem(80)};
    }

    .pricing-image {
      flex: 0.5;

      .title {
        font-size: ${asRem(80)};
        margin-bottom: 10px;
        margin-left: 20px;
      }
    }

    .content {
       .pricing-content {
        min-width: ${asRem(100)};
        padding: 0 ${asRem(20)};

        .display-34-bold {
          font-size: ${asRem(32)};
          line-height: 30px;
        }
       }

       .region-option, .label-12-regular {
        margin-left: ${asRem(22)};
       }

       .content-desc {
        flex-direction: column;

        a {
          width: 100%;
        }
      }

      .region {
        .display-24-bold {
          font-size: ${asRem(20)};
          line-height: ${asRem(25)};
        }

        .text-14-regular {
          margin-left: ${asRem(22)};
        }
      }
    }
  }

`;

export function Pricing() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const pricingRegions = [{ key: 'usd', value: '$ International' }, { key: 'inr', value: '₹ India' }, { key: 'eur', value: '€ Europe' }];

  const [billingRegion, setBillingRegion] = useState();

  const pricingSymbols = {
    usd: 'US$', inr: '₹', eur: '€',
  };

  const { data, loading, error } = useQuery(AnBusinessProductList, {
    variables: {
      showActive: true,
      showVisible: false,
      useFresh: true,
    },
  });

  let productList;

  if (data) {
    productList = data?.anbusiness_anbusiness_product_list?.data;
  }

  function setCookies(val) {
    localStorage.setItem('billing-region', val);
    setBillingRegion(val);
  }

  useEffect(() => {
    appContext.setLayoutWidth(AppLayoutWidth.Pricing);
    setCookies('usd');
    setBillingRegion('usd');
  }, []);

  return (
    <PricingListWrapper className="not-top-bar">
      <Text14Bold>Please note: Ants is invite-only beta mode. Pricing and features are subject to change</Text14Bold>
      <div className="inner-wrapper">
        <div className="pricing-image">
          <Link to="/" className="logo-container">
            <div className="logo">
              <img
                src={RoanuzLogo}
                alt="logo"
              />
            </div>
            <Display18Bold>Roanuz Ants</Display18Bold>
          </Link>
          <div className="title">
            <p>Get</p>
            <p>Started</p>
          </div>
        </div>
        <div className="content">
          {userContext.loggedIn
            ? (
              <div className="user-name">
                <UserDropdown user={userContext} showAccountDetails={false} />
              </div>
            ) : <a href={`${Config.loginURL}?show_ants_product=true&next_url=${encodeURIComponent(window.location.href)}`} className="website-btn user-name">Login</a>}
          <div className="price">
            {loading && <StatusLoading message="Loading..." />}
            {error && <StatusError error={error} />}
            {productList && (
              <>
                <Label12Regular>Billing Region</Label12Regular>
                <div className="region-option rz-select">
                  <select onChange={(e) => setCookies(e.target.value)}>
                    {pricingRegions.map((region) => (
                      <option value={region.key} key={region.value}>{region.value}</option>
                    ))}
                  </select>
                </div>
                <div className="pricing-content">
                  {productList.map((content) => (
                    <Box key="content.name">
                      <div className="content-header">
                        <div className="product-title">
                          <Display34Bold>
                            <ReactMarkdown>{content.name}</ReactMarkdown>
                          </Display34Bold>
                        </div>
                        <div className="region">
                          <Display24Bold>
                            {pricingSymbols[billingRegion]}
                            {content.price.unit_amount[billingRegion]}
                          </Display24Bold>
                          <Text14Regular>
                            {content.type !== 'FreePlan' ? (content.price.unit_label || 'Per User, Per Month') : (
                              <>
                                with any
                                {' '}
                                <a href={Config.antsPrdouctList}><span>Cricket API Plan</span></a>
                              </>
                            )}
                          </Text14Regular>
                        </div>
                      </div>
                      <div className="content-desc">
                        <div className="desc-container">
                          <ReactMarkdown>{content.description}</ReactMarkdown>
                        </div>
                        {userContext.loggedIn ? (
                          <a
                            href={`${Config.antsPrdouctList}v5/user/project/create?package=${content.key}&show_ants_product=true&ants_currency=${billingRegion}`}
                            className="website-btn small"
                          >
                            Select
                          </a>
                        ) : (
                          <a
                            href={`${Config.antsPrdouctList}v5/user/project/create?package=${content.key}&show_ants_product=true&forceLogin=true&ants_currency=${billingRegion}`}
                            className="website-btn small"
                          >
                            Select
                          </a>
                        )}
                      </div>
                    </Box>
                  ))}
                  {userContext.loggedIn && userContext.user?.accounts?.accounts?.length > 0 && (
                    <div className="back-button-wrapper">
                      <a href="/" className="plain small">Go to console</a>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PricingListWrapper>
  );
}

export default Pricing;
