import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const Dashboard = lazy(() => import('./Dashboard'));
const Create = lazy(() => import('./Create'));
const ResourcePage = lazy(() => import('./ResourcePage'));
const Versions = lazy(() => import('./Versions'));
const Update = lazy(() => import('./Update'));
const UpdateStatus = lazy(() => import('./UpdateStatus'));
const List = lazy(() => import('./List'));

export function RegionRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.region.meta.path}>
        <Route
          index
          element={(<Dashboard />)}
        />
        <Route
          path={Pages.sports.region.create.meta.path}
          element={(<Create />)}
        />
        <Route
          path={Pages.sports.region.list.meta.path}
          element={(<List />)}
        />
        <Route
          path={Pages.sports.region.read.meta.path}
          element={(<ResourcePage />)}
        />
        <Route
          path={Pages.sports.region.versions.meta.path}
          element={(<Versions />)}
        />
        <Route
          path={Pages.sports.region.update.meta.path}
          element={(<Update />)}
        />
        <Route
          path={Pages.sports.region.updateStatus.meta.path}
          element={(<UpdateStatus />)}
        />
      </Route>
    </Routes>
  );
}
