import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Config } from 'config';
import { asRem } from 'lib/css';
import { AppContext } from './AppContext';

const BottomBarWrapper = styled.div`
  padding-top: ${asRem(20)};
  padding-bottom: ${asRem(15)};
  nav {
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: ${asRem(15)};
        &:last-child {
          margin-right: 0;
        }
        padding-bottom: var(--layout-padding-contained);
        font-size: ${asRem(14)};
        line-height: ${asRem(18)};

        a {
          background-color: transparent;
          color: var(--color-text-1);
        }
      }
    }
  }
  .version-name {
    opacity: 0.6;
    text-align: right;
    font-size: ${asRem(10)};
    color: #fff;
  }
`;

export function BottomBar() {
  const appContext = useContext(AppContext);
  const links = [
  ];

  return (
    <BottomBarWrapper>
      <nav>
        <ul>
          {links.map((link) => (
            <li key={link.to}>
              <Link to={link.to} className="primary">{link.name}</Link>
            </li>
          ))}
        </ul>
      </nav>
      {appContext.boot && (
        <div className="version-name">
          <span>
            © Roanuz
            {` ${new Date().getFullYear()} | `}
          </span>
          <span>
            {`v ${Config.appVersion}`}
            {` (api v${appContext.boot.api_version})`}
          </span>
        </div>
      )}
    </BottomBarWrapper>
  );
}

BottomBar.propTypes = {
};
