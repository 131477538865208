/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { Tooltip } from 'core/Tooltip';
import { Text14Regular } from 'style/typography';
import moment from 'moment';
import { StatusError, StatusLoading } from 'core/Status';
import { TournamentRoundList } from '../query';

const Wrapper = styled.div`
  p {
    color: var(--color-text-2);
    max-width: ${asRem(300)};
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    padding: 0;
    font-family: var(--font-family);
  }
`;

export function MatchView({ queryResponse }) {
  const matchday = queryResponse.match.start_at;
  const [groupName, setGroupName] = useState('');
  const { error, loading, data } = useQuery(
    TournamentRoundList,
    { variables: { tournament: { _hashkey: queryResponse.match.tournament.hashkey } } },
  );

  useEffect(() => {
    if (data?.sports_cricket_tournament_round_list?.items) {
      let currentGroup;
      data?.sports_cricket_tournament_round_list?.items.forEach((tournament) => {
        if (tournament.resource.hashkey === queryResponse?.tournament_details?.round_key) {
          const groupVal = tournament?.tournament_round?.groups.filter((group) => group.key === queryResponse?.tournament_details?.group_key);
          currentGroup = groupVal;
        }
        return '';
      });
      setGroupName(currentGroup[0]);
    }
  }, [data, queryResponse]);
  return (
    <Wrapper>
      {loading && (<StatusLoading message="Hold on..." />)}
      {error && (<StatusError error={error} />)}
      {groupName && (
        <>
          <Tooltip
            text={`${groupName?.name}, ${queryResponse?.match?.sub_title && `${queryResponse?.match?.sub_title},`} ${queryResponse?.match?.internal_name}`}
            renderTrigger={() => (
              <Text14Regular>
                {`${groupName?.name}, ${queryResponse?.match?.sub_title && `${queryResponse?.match?.sub_title},`} ${queryResponse?.match?.internal_name}`}
              </Text14Regular>
            )}
          />
          <Text14Regular>
            {moment.unix(matchday).local().format('MMM DD, h:mm a')}
            (
            {moment.tz.guess()}
            )
          </Text14Regular>
        </>
      )}
    </Wrapper>
  );
}

MatchView.propTypes = {
  queryResponse: PropTypes.object,
};
