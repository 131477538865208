import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, RefField, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { TeamImageView } from './ResourceImage';
import {
  TeamSearchQuery,
  TeamReadQuery,
  TeamNameQuery,
  TeamCreateMutation,
  TeamUpdateMutation,
  TeamPreviewQuery,
  TeamImageUploadMutation,
  HeadlessTeamSearchQuery,
} from './query';

export const TeamResource = Resource({
  resourceId: 'team',
  name: 'Team',
  storeId: 'Team',
  columnSets: [
    ColumnSet({
      name: 'Team',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        short_name: StringFieldMinMax(2, 120),
        region: RefField(),
        gender: StringFieldMinMax(3, 120),
      }),
      referenceFields: ['region'],
    }),
    ColumnSet({
      name: 'Detail',
      shape: Yup.object().shape({
        gender: StringFieldMinMax(3, 120),
        legal_name: Yup.string().nullable().default(),
        home: RefField(),
        founded: Yup.number().nullable().default(),
        club: RefField(),
      }),
      referenceFields: ['home', 'club'],
    }),
    ColumnSet({
      name: 'Personnel',
      shape: Yup.object().shape({
        captain: Yup.string().nullable().default(),
        vice_captain: Yup.string().nullable().default(),
        coach_name: Yup.string().nullable().default(),
        owner_name: Yup.string().nullable().default(),
        chairman_name: Yup.string().nullable().default(),
        manager_name: Yup.string().nullable().default(),
      }),
    }),
    ColumnSet({
      name: 'Bio',
      shape: Yup.object().shape({
        desc: Yup.string().max(1024, 'Too big').nullable().default(''),
        official_website: Yup.string().nullable().default(),
      }),
    }),
    ColumnSet({
      name: 'Play',
      shape: Yup.object().shape({
        associations: Yup.array().nullable().default(),
      }),
    }),
    ColumnSet({
      name: 'SocialNetwork',
      shape: Yup.object().shape({
        facebook: Yup.string().nullable().default(''),
        twitter: Yup.string().nullable().default(''),
        instagram: Yup.string().nullable().default(''),
        youtube: Yup.string().nullable().default(''),
      }),
      viewKey: 'social_network',
    }),
    ColumnSet({
      name: 'Image',
      shape: Yup.object().shape({
        image_url: Yup.string().nullable().default(''),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
    { action: 'updateImage' },
  ],
  imageRender: (item) => (<TeamImageView item={item} size="24" />),
  queries: {
    read: QueryAction({ query: TeamReadQuery, resourceNamePath: 'item.team.name', resourcePath: 'item.resource.hashkey' }),
    list: QueryAction({ query: TeamSearchQuery, responsePath: 'sports_team_search' }),
    search: QueryAction({
      query: TeamSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'team.name',
    }),
    headlessSearch: QueryAction({
      query: HeadlessTeamSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'team.name',
      responsePath: 'sports_team_review',
    }),
    name: QueryAction({
      query: TeamNameQuery,
      responsePath: 'sports_team_name',
      resourceNamePath: 'sports_team_name.name',
    }),
    preview: QueryAction({
      query: TeamPreviewQuery,
      responsePath: 'sports_cricket_team_preview',
      resourceNamePath: 'sports_cricket_team_preview.team.team.name',
    }),
  },
  mutations: {
    update: MutationAction({ mutation: TeamUpdateMutation, cs: ['team', 'detail', 'play', 'bio', 'personnel', 'social_network'] }),
    updateImage: MutationAction({ mutation: TeamImageUploadMutation, cs: ['image'] }),
    create: MutationAction({ mutation: TeamCreateMutation }),
  },
});
