import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const PlayerReadQuery = gql`
query PlayerReadQuery($resource: PlayerInput!, $withHistory: Boolean) {
  sports_player_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      player {
        name
        gender
        jersey_name
        internal_name
        jersey_number
        birth_place {
          account
          key
          hashkey: _hashkey
        }
        date_of_birth
      }
      bio {
        legal_name
        date_of_birth
        height
        weight
        other_names
        summary
        official_website
        retired
      }
      cricket {
        playing_role
        alltime_roles
        batting_style
        bowling_style {
          arm
          pace
          type
        }
        frequent_bowling_deliveries
      }
      social_network {
        twitter
        facebook
        instagram
        youtube
      }
      image {
        image_url
      }
      computed {
        associations {
          key
          code
          name
          internal_name
        }
        teams {
          key
          name
          short_name
        }
        last_updated
        tournament_teams {
          tournament_team
          end_date
        }
      }
      data {
        keys {
          src
          key
        }
        tags
        properties
      }
      legacy_data {
        key
      }
      review {
        private_notes
        review_status
        hide_from_search
        replaced_with {
          hashkey: _hashkey
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const PlayerCreateHintQuery = gql`
query PlayerCreateHintQuery($name: String!) {
  sports_player_create_hint(name: $name) {
    name
    jersey_name
    legal_name
    similar_items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      player {
        name
        jersey_name
        internal_name
        gender
        jersey_number
        birth_place {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    key_suggestions
  }
}
`;

export const PlayerCreateMutation = gql`
mutation PlayerCreateMutation(
  $player: PlayerPlayerInput!,
  $cricket: CricketPlayerCricketInput!,
  $key: String
) {
  sports_player_create(player: $player,
    cricket: $cricket, key: $key) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
    key_suggestions
  }
}
`;

export const PlayerUpdateMutation = gql`
mutation PlayerUpdateMutation(
  $resource: PlayerInput!,
  $player: PlayerPlayerInput!,
  $bio: PlayerBioInput!,
  $socialNetwork: PlayerSocialNetworkInput!,
  $legacyData: PlayerLegacyDataInput!
  $cricket: CricketPlayerCricketInput!,
  $version: RecordStage) {
  sports_player_update(resource: $resource, player: $player, bio: $bio, social_network: $socialNetwork,
    cricket: $cricket, legacy_data: $legacyData, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const PlayerUpdateStatusMutation = gql`
mutation PlayerUpdateStatusMutation(
  $resource: PlayerInput!,
  $status: PlayerStatusInput!,
  $version: RecordStage) {
  sports_player_update_status(resource: $resource, status: $status, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const PlayerBasicDataUpdateMutation = gql`
mutation PlayerBasicDataUpdateMutation(
  $player: PlayerPlayerInput!,
  $cricket: CricketPlayerCricketInput!,
  $resource: PlayerInput!,
  $version: RecordStage) {
  sports_player_basic_data_update(resource: $resource, player: $player, cricket: $cricket, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const PlayerDeleteMutation = gql`
mutation PlayerDeleteMutation(
  $resource: PlayerInput!,
  $name: String!,
  $imageUrl: String) {
  sports_player_delete(resource: $resource, name: $name, image_url: $imageUrl) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const PlayerSearchQuery = gql`
query PlayerSearchQuery($search: String!, $page: PagedRequestInput, $show_hidden: Boolean) {
  sports_player_search(search: $search, page: $page, show_hidden: $show_hidden) {
    items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      player {
        name
        gender
        internal_name
        jersey_name
        jersey_number
        birth_place {
          account
          key
          hashkey: _hashkey
        }
        date_of_birth
      }
      cricket {
        playing_role
        batting_style
        bowling_style {
          arm
          pace
          type
        }
        frequent_bowling_deliveries
      }
      computed {
        associations {
          key
          code
          name
          internal_name
        }
        teams {
          key
          name
          short_name
        }
        recent_matches {
          key
          start_date
          card_name
          name
          format
          association_key
        }
        last_updated
      }
      image {
        image_url
      }

      legacy_data {
        key
      }

      bio {
        legal_name
      }
      review {
        private_notes
        review_status
        hide_from_search
        replaced_with {
          account
          sport
          key
          hashkey: _hashkey
        }
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const PlayerNameQuery = gql`
query PlayerNameQuery($resource: PlayerInput!) {
  sports_player_name(resource: $resource) {
    name
    image_url
  }
}
`;

export const PlayerImageUploadMutation = gql`
mutation PlayerImageUploadMutation(
  $resource: PlayerInput!,
  $filename: String!) {
  sports_player_image_upload(resource: $resource, filename: $filename) {
    signed_data
    url
    url_suffix
  }
}
`;

export const PlayerHeadlessSearchQuery = gql`
query PlayerHeadlessSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_player_review(search: $search, page: $page) {
    items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      player {
        name
        gender
        jersey_name
        jersey_number
        birth_place {
          account
          key
          hashkey: _hashkey
        }
      }
      cricket {
        playing_role
        batting_style
        bowling_style {
          arm
          pace
          type
        }
        frequent_bowling_deliveries
      }
      computed {
        associations {
          account
          key
          _hashkey
        }
        teams
        last_matches
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const MultiPlayerCreateHintQuery = gql`
query MultiPlayerCreateHintQuery($names: [String!]!) {
  sports_player_multiple_create_hint(names: $names) {
    players {
      name
      similar_items {
        resource {
          account
          sport
          key
          _hashkey
        }
        player {
          name
          gender
          jersey_name
          internal_name
          jersey_number
          birth_place {
            account
            key
            _hashkey
          }
          date_of_birth
        }
      }
    }
  }
}
`;

export const MultiPlayerCreateMutation = gql`
mutation MultiePlayerCreateMutation(
  $players: [MultiPlayerCreatePlayersInput!]
) {
  sports_player_multiple_create(players: $players) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const PlayerReviewMutation = gql`
mutation PlayerReviewMutation(
  $resource: PlayerInput!,
  $name: String!,
  $review: PlayerReviewInput!,
  $version: RecordStage
) {
  sports_player_review_update(
    resource: $resource,
    name: $name,
    review: $review,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;
