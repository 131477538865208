import React, { Suspense } from 'react';
import { Breadcrumb } from 'core/Breadcrumb';
import styled from 'styled-components';
import { FantasyEditorRoutes } from './fantasyCreditEditor/routes';
import { WizardRoutes } from './Wizard/routes';
import { CuratedMatchesRoute } from './CuratedMatches/routes';

const FantasyRouteWrapper = styled.div`
`;

export function FantasyRoutes() {
  return (
    <FantasyRouteWrapper>
      <Suspense fallback={<>...</>}>
        <Breadcrumb className="fantasy-breadcrumb" />
        <FantasyEditorRoutes />
        <CuratedMatchesRoute />
        <WizardRoutes />
      </Suspense>
    </FantasyRouteWrapper>
  );
}
