import { Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { Pages } from 'core/pages';

const List = lazy(() => import('./List'));
const CurationCreate = lazy(() => import('./Create'));
const CurationUpdate = lazy(() => import('./Update'));
const Dashboard = lazy(() => import('./Home'));
const VersionController = lazy(() => import('./version'));

export function CuratedMatchesRoute() {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          path={Pages.fantasy.curation.meta.path}
        >
          <Route index element={(<Dashboard />)} />
          <Route
            path={Pages.fantasy.curation.create.meta.path}
            element={(<CurationCreate />)}
          />
          <Route
            path={Pages.fantasy.curation.update.meta.path}
            element={(<CurationUpdate />)}
          />
          <Route
            path={Pages.fantasy.curation.list.meta.path}
            element=<List />
          />
          <Route
            path={Pages.fantasy.curation.versions.meta.path}
            element=<VersionController />
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
