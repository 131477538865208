import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RealtimeContext } from 'ants/RealtimeContext';

export function ResourceBoundary({
  resourceKey,
  resourceName,
  resourceDef,
  action,
  actionId,
  pageKey,
  pageParams,
  children,
}) {
  const resolvedPage = pageKey ?? action?.pageKey;
  const rtContext = useContext(RealtimeContext);
  useEffect(() => {
    if (rtContext.socketState === 'Connected') {
      rtContext.updateRootInteraction({
        action: action ? action.id : `_${actionId}`,
        resourceKey,
        resourceName,
        isMutation: action?.isMutation,
        resourceType: resourceDef.resourceId,
        page: resourceDef.pageFor(resourceDef, resolvedPage)?.meta.dataPath,
        pageParams: pageParams ?? {},
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rtContext.socketState,
    resourceDef.resourceId,
    resourceKey,
    action,
    pageKey,
  ]);

  return children;
}

ResourceBoundary.propTypes = {
  resourceKey: PropTypes.string,
  resourceName: PropTypes.string,
  resourceDef: PropTypes.object,
  pageKey: PropTypes.string,
  actionId: PropTypes.string,
  action: PropTypes.object,
  pageParams: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function ResourceTypeBoundary({
  resourceDef,
  pageKey,
  action,
  pageParams,
  actionId,
  children,
}) {
  return (
    <ResourceBoundary
      resourceDef={resourceDef}
      pageKey={pageKey}
      action={action}
      pageParams={pageParams}
      actionId={actionId}
      resourceKey={null}
      resourceName={null}
    >
      {children}
    </ResourceBoundary>
  );
}

ResourceTypeBoundary.propTypes = {
  resourceDef: PropTypes.object,
  pageKey: PropTypes.string,
  action: PropTypes.object,
  pageParams: PropTypes.object,
  actionId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
