import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax, RefField,
} from 'ants/resource';
import {
  AssociationCreateMutation,
  AssociationImageUploadMutation,
  AssociationNameQuery,
  AssociationReadQuery,
  AssociationSearchQuery,
  AssociationUpdateMutation,
  AssociationUpdateStatusMutation,
} from './query';
import { AssociationImageView } from './ResourceImage';

export const AssociationResource = Resource({
  resourceId: 'association',
  name: 'Association',
  storeId: 'Association',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Association',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        internal_name: StringFieldMinMax(3, 120),
        code: StringFieldMinMax(2, 24),
        region: RefField(),
        parent: RefField(),
      }),
      referenceFields: ['parent', 'region'],
    }),
    ColumnSet({
      name: 'Status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(true),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
    }),
    ColumnSet({
      name: 'Image',
      shape: Yup.object().shape({
        image_url: Yup.string().nullable().default(''),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
    { action: 'updateStatus' },
    { action: 'updateImage' },
  ],
  imageRender: (item) => (<AssociationImageView item={item} />),
  queries: {
    read: QueryAction({ query: AssociationReadQuery, resourceNamePath: 'item.association.internal_name' }),
    list: QueryAction({ query: AssociationSearchQuery }),
    search: QueryAction({
      query: AssociationSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.internal_name',
    }),
    name: QueryAction({ query: AssociationNameQuery, resourceNamePath: 'sports_association_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: AssociationUpdateMutation, cs: ['association', 'status'] }),
    updateStatus: MutationAction({ mutation: AssociationUpdateStatusMutation, cs: ['status'] }),
    updateImage: MutationAction({ mutation: AssociationImageUploadMutation, cs: ['image'] }),
    create: MutationAction({ mutation: AssociationCreateMutation }),
  },
});
