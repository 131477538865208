import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const Dashboard = lazy(() => import('./Dashboard'));
const List = lazy(() => import('./List'));
const Create = lazy(() => import('./Create'));
const ResourcePage = lazy(() => import('./ResourcePage'));
const Versions = lazy(() => import('./Versions'));
const Update = lazy(() => import('./Update'));
const UpdateStatus = lazy(() => import('./UpdateStatus'));
const UpdateImage = lazy(() => import('./UpdateImage'));

export function AssociationRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.association.meta.path}>
        <Route
          index
          element={(<Dashboard />)}
        />
        <Route
          path={Pages.sports.association.list.meta.path}
          element={(<List />)}
        />
        <Route
          path={Pages.sports.association.create.meta.path}
          element={(<Create />)}
        />
        <Route
          path={Pages.sports.association.read.meta.path}
          element={(<ResourcePage />)}
        />
        <Route
          path={Pages.sports.association.versions.meta.path}
          element={(<Versions />)}
        />
        <Route
          path={Pages.sports.association.update.meta.path}
          element={(<Update />)}
        />
        <Route
          path={Pages.sports.association.updateStatus.meta.path}
          element={(<UpdateStatus />)}
        />
        <Route
          path={Pages.sports.association.updateImage.meta.path}
          element={(<UpdateImage />)}
        />
      </Route>
    </Routes>
  );
}
