import { useState, useEffect } from 'react';
import moment from 'moment';
import { Config } from 'config';
import { useLocation, useParams } from 'react-router-dom';
import { getToken } from './localStore';

export function useMonthPicker({ includeFuture = false } = {}) {
  let { month: monthKey } = useParams();
  if (!monthKey) {
    monthKey = 'current';
  }

  const viewMonth = (monthKey === 'current' ? moment() : moment(monthKey, 'YYYY-M-DD')).startOf('M');

  let currentMonthIndex = 0;
  const months = [];
  const lastMonth = moment(`${Config.firstYear}-01-01`);
  let runner = moment();
  if (includeFuture) {
    runner = runner.add(1, 'year');
  }
  do {
    if (runner.diff(viewMonth, 'days') === 0) {
      currentMonthIndex = months.length;
    }
    months.push({
      name: runner.format('MMM - YYYY'),
      month: runner.format('YYYY-MM-DD'),
    });
    runner = runner.startOf('M').add(-1, 'month');
  } while (runner >= lastMonth);

  const [month, setMonth] = useState(months[currentMonthIndex]);

  return {
    month,
    setMonth,
    months,
  };
}

export function useYearPicker() {
  const { year: yearKey } = useParams();

  const today = moment();
  const yearNumber = (yearKey === undefined || yearKey === 'current') ? today.year() : parseInt(yearKey, 10);
  const viewYear = moment(`${yearNumber + 1}-1-01`, 'YYYY-M-DD');

  let currentYearIndex = 0;
  const years = [];
  const lastYear = moment(`${Config.firstYear}-1-01`, 'YYYY-M-DD');
  let runner = viewYear.clone();
  do {
    const name = runner.year();
    const end = runner.clone().add(12, 'months').add(-1, 'days');
    if (today >= runner && today <= end) {
      currentYearIndex = years.length;
    }
    years.push({
      name,
      year: runner.year(),
      start: runner.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    });
    runner = runner.startOf('M').add(-1, 'year');
  } while (runner > lastYear);

  const [year, setYear] = useState(years[currentYearIndex]);

  return {
    year,
    setYear,
    years,
  };
}

function fnGetFileNameFromContentDispostionHeader(header) {
  let fileName = 'report.csv';
  if (header) {
    const contentDispostion = header.split(';');
    const fileNameToken = 'filename=';

    // eslint-disable-next-line no-restricted-syntax
    for (const thisValue of contentDispostion) {
      if (thisValue.trim().indexOf(fileNameToken) === 0) {
        fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
        break;
      }
    }
  }
  return fileName;
}

export function useFetch(path, { variables = null } = {}) {
  const [data] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let token = null;
  if (typeof window !== 'undefined') {
    token = getToken();
  }

  const doFetch = ({ variables: moreVariables }) => {
    const postData = {
      ...variables,
      ...moreVariables,
    };
    if (loading) {
      return null;
    }

    const fullUrl = `${Config.apiUrl}${path}`;

    setLoading(true);
    const result = fetch(fullUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        rzapikey: Config.apiKey,
        rztoken: token || '',
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      body: JSON.stringify(postData), // body data type must match "Content-Type" header
    })
      .then((res) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const pair of res.headers.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }
        const filename = fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition'));
        const contentType = res.headers.get('content-type') || 'text/csv';
        return res.blob().then((blob) => ({
          filename,
          contentType,
          blob,
        }));
      })
      .then((respObj) => {
        setLoading(false);
        // It is necessary to create a new blob object with
        // mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([respObj.blob], { type: respObj.contentType });

        // MS Edge and IE don't allow using a blob object directly as link href,
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = objUrl;
          link.download = respObj.filename;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        if (loading) {
          setLoading(false);
        }
      });
    return result;
  };

  return [
    doFetch,
    {
      loading,
      error,
      data,
    },
  ];
}

export function useQueryParam(param, {
  defaultValue = null,
  asBool = false,
  asInt = false,
} = {}) {
  const { search } = useLocation();
  const parse = (qs) => {
    const params = new URLSearchParams(qs);
    const raw = params.get(param);
    if (raw === undefined || raw === null) {
      return defaultValue;
    }

    if (asBool) {
      return raw === '1';
    }

    if (asInt) {
      return parseInt(raw, 10);
    }

    return raw;
  };

  const [value, setValue] = useState(parse(search));
  useEffect(() => {
    setValue(parse(search));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    value,
  };
}
