import React, { Suspense } from 'react';
import { CompetitionRoutes } from './competition/routes';
import { TournamentRoutes } from './tournament/routes';
import { TourRoutes } from './tour/routes';
import { PlayersRoutes } from './players/routes';
import { MatchRoutes } from './match/routes';
import { SeasonTeamRoutes } from './seasonTeam/routes';
import { PersonRoutes } from './Person/routes';
import { RankingRoutes } from './Ranking/routes';

export function CricketRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <CompetitionRoutes />
      <TournamentRoutes />
      <TourRoutes />
      <PlayersRoutes />
      <MatchRoutes />
      <SeasonTeamRoutes />
      <PersonRoutes />
      <RankingRoutes />
    </Suspense>
  );
}
