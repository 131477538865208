import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DraftIcon } from 'assets/icons/icon-draft.svg';
import { ReactComponent as PublishIcon } from 'assets/icons/icon-publish.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/icon-time.svg';
import { ReactComponent as BuildIcon } from 'assets/icons/icon-build.svg';
import {
  Label12Regular,
} from 'style/typography';
import {
  friendlyDateTime,
} from 'lib/utils';
import { asRem } from 'lib/css';
import { AuthorLine } from './AuthorLine';

const VersionRecordsViewWrapper = styled.div`
  .cs-title {
    padding: 0 var(--layout-padding-box);
  }
  .record {
    padding: 0 var(--layout-padding-box);
    cursor: pointer;
    color: var(--color-label);

    &:hover, &.active {
      color: var(--color-text);
      background-color: var(--color-border-1);
    }

    .record-container {
      padding: ${asRem(12)} 0;
      border-bottom: 1px solid var(--color-border-1);
    }

    .record-item {
      display: flex;
      gap: ${asRem(8)};
      align-items: center;
      padding-bottom: ${asRem(8)};

      svg {
        width: ${asRem(16)};
        height: ${asRem(16)};
      }

      span {
        display: block;
      }
    }
  }

  .see-all {
    text-align: right;
    margin-top: ${asRem(12)};
    margin-right: ${asRem(12)};
  }
`;

export function VersionRecordsView({
  resourceDef,
  history,
  resourceKey,
  maxItem = null,
  showReviewLink = true,
  onClick = null,
  selectedKey = null,
  versionKey,
}) {
  const navigate = useNavigate();
  if (!history) return null;

  let items = [...history.records];

  if (maxItem !== null) {
    items = items.slice(0, maxItem);
  }

  const versionsUrl = resourceDef.pageFor(resourceDef, versionKey);

  const handleOnClick = (item) => {
    if (onClick) {
      onClick(item);
    } else {
      navigate(`${versionsUrl.meta.urlFor({ key: resourceKey })}?v=${item.key}`);
    }
  };

  return (
    <VersionRecordsViewWrapper>
      {items.map(({ key, cs, record }) => (
        <div
          key={key}
          className={`record ${selectedKey === key ? 'active' : ''}`}
          onClick={() => handleOnClick({ key, cs, record })}
          onKeyPress={() => handleOnClick({ key, cs, record })}
          role="button"
          tabIndex="0"
        >
          <div className="record-container">
            <div className={`record-item stage stage-${record.att_stage}`}>
              {record.att_stage === 'Published' && (<PublishIcon />)}
              {record.att_stage === 'Draft' && (<DraftIcon />)}
              {record.att_stage === 'Versioned' && (<DraftIcon />)}
              <Label12Regular>
                {record.att_stage}
                {` (${history.columnSetLookup[cs.cs_name] && history.columnSetLookup[cs.cs_name].name})`}
              </Label12Regular>
            </div>
            <div className="record-item action">
              <BuildIcon />
              <Label12Regular>{record.action}</Label12Regular>
            </div>
            <div className="record-item time">
              <TimeIcon />
              <Label12Regular>{friendlyDateTime(record.ts)}</Label12Regular>
            </div>
            {record?.authors?.map((author) => (
              <AuthorLine
                author={author}
                key={author.key}
                showTime={record?.authors?.length > 1}
                className="record-item"
              />
            ))}
          </div>
        </div>
      ))}
      {showReviewLink && versionsUrl && (
        <div className="see-all">
          <Link
            className="plain"
            to={versionsUrl.meta.urlFor({ key: resourceKey })}
          >
            Review All & Restore
          </Link>
        </div>
      )}
    </VersionRecordsViewWrapper>
  );
}

VersionRecordsView.propTypes = {
  resourceDef: PropTypes.object,
  history: PropTypes.object,
  resourceKey: PropTypes.any,
  maxItem: PropTypes.number,
  showReviewLink: PropTypes.bool,
  onClick: PropTypes.func,
  selectedKey: PropTypes.string,
  versionKey: PropTypes.string,
};
