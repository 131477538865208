import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const Dashboard = lazy(() => import('./Dashboard'));
const Create = lazy(() => import('./Create'));
const List = lazy(() => import('./List'));
const Versions = lazy(() => import('./Versions'));
const ResourcePage = lazy(() => import('./ResourcePage'));
const UpdateImage = lazy(() => import('./UpdateImage'));
const Update = lazy(() => import('./Update'));
export function PersonRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.cricket.person.meta.path}>
        <Route
          index
          element={(<Dashboard />)}
        />
        <Route
          path={Pages.sports.cricket.person.create.meta.path}
          element={(<Create />)}
        />
        <Route
          path={Pages.sports.cricket.person.list.meta.path}
          element={(<List />)}
        />
        <Route
          path={Pages.sports.cricket.person.read.meta.path}
          element={(<ResourcePage />)}
        />
        <Route
          path={Pages.sports.cricket.person.updateImage.meta.path}
          element={(<UpdateImage />)}
        />
        <Route
          path={Pages.sports.cricket.person.versions.meta.path}
          element={(<Versions />)}
        />
        <Route
          path={Pages.sports.cricket.person.update.meta.path}
          element={(<Update />)}
        />
      </Route>
    </Routes>
  );
}
