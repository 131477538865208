import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { Label12Regular } from 'style/typography';
import { resourceDecodeHashkey } from 'lib/utils';

const KeyBoxWrapper = styled.div`
  display: flex;
  gap: ${asRem(8)};

  button {
    margin: 0;
    padding: 0;
    color: var(--color-label);
    border: none;

    .label-12-regular {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      max-width: ${asRem(120)};
      transition: color 0.2s linear;

      &:hover {
        color: var(--color-secondary-cta);
      }
    }
  }
`;

export function KeyBox({ resource }) {
  const [showCopiedText, setShowCopiedText] = useState(false);

  let resourceKey;

  if (typeof resource === 'object') {
    // eslint-disable-next-line no-underscore-dangle
    resourceKey = resourceDecodeHashkey(resource.resource_key)._hashkey;
  } else {
    resourceKey = resource;
  }
  return (
    <KeyBoxWrapper>
      <button
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(resourceKey);
          setShowCopiedText(true);
          setTimeout(() => {
            setShowCopiedText(false);
          }, 1500);
        }}
      >
        <Label12Regular>{resourceKey}</Label12Regular>
      </button>
      {showCopiedText && (
        <Label12Regular>Copied</Label12Regular>
      )}
    </KeyBoxWrapper>
  );
}

KeyBox.propTypes = {
  resource: PropTypes.any,
};
