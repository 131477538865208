import React from 'react';
import * as Yup from 'yup';
import {
  Resource, ColumnSet, RefField, StringFieldMinMax, QueryAction, MutationAction,

} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import {
  PersonReadQuery, PersonCreateMutation,
  PersonListQuery, PersonSearchQuery, PersonImageUpdateMutation,
  PersonUpdateMutation, PersonNameQuery,
} from './query';
import { PersonImageView } from './ResourceImage';

export const PersonResource = Resource({
  resourceId: 'person',
  name: 'Person',
  storeId: 'Person',
  pageKey: 'sports.cricket.person',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Person',
      shape: Yup.object().shape({
        name: StringFieldMinMax(2, 120).required(),
        full_name: StringFieldMinMax(2, 120).required(),
        sports: Yup.mixed().test('is-minimum-one', 'Select atleast 1 sport', (value) => {
          return value.length !== 0;
        }),
        date_of_birth: Yup.string().nullable(),
        player: RefField(),
        country: RefField(),
        roles: Yup.mixed().test('is-minimum-one', 'Select atleast 1 Role', (value) => {
          return value.length !== 0;
        }),
      }),
      referenceFields: ['player', 'country'],
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
    { action: 'updateImage' },
  ],
  imageRender: (item) => (<PersonImageView item={item} />),
  queries: {
    read: QueryAction({
      query: PersonReadQuery,
      resourceNamePath: 'item.person.name',
      resourcePath: 'item.resource.hashkey',
      responsePath: 'sports_person_read',
    }),
    list: QueryAction({ query: PersonListQuery, responsePath: 'sports_person_list' }),
    search: QueryAction({
      query: PersonSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'person.name',
      responsePath: 'sports_person_search',
    }),
    name: QueryAction({ query: PersonNameQuery, responsePath: 'sports_umpire_name', resourceNamePath: 'sports_person_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: PersonUpdateMutation,
      responsePath: 'sports_person_update',
      cs: ['person'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.person.sports = arrayAsObject(
          resp.person.sports,
        ) ?? {};
        resp.person.roles = arrayAsObject(
          resp.person.roles,
        ) ?? {};
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.person.sports = objectAsArray(resp.person.sports);
        resp.person.roles = objectAsArray(resp.person.roles);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          ...resp,
        };
        return postVariables;
      },
    }),
    updateImage: MutationAction({ mutation: PersonImageUpdateMutation, cs: ['image'] }),
    create: MutationAction({ mutation: PersonCreateMutation, responsePath: 'sports_person_create' }),
  },
});
