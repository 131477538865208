import React, { lazy, Suspense } from 'react';

import { Pages } from 'core/pages';
import { Route, Routes } from 'react-router-dom';
import AccountSearchAccessGate from '../account/AccountSettings/AccountSearchAccessGate';

const UserList = lazy(() => import('./UserList'));
const AdminDashboard = lazy(() => import('./AdminDashboard'));
const AccountList = lazy(() => import('./AccountList'));
const DataProviders = lazy(() => import('./DataProviders'));
const AccountCreate = lazy(() => import('./AccountCreate'));

export function AdminSettingsRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          path={Pages.admin.dashboard.meta.path}
          element={(<AccountSearchAccessGate><AdminDashboard /></AccountSearchAccessGate>)}
        />
        <Route
          path={Pages.admin.users.meta.path}
          element={(<AccountSearchAccessGate><UserList /></AccountSearchAccessGate>)}
        />
        <Route
          path={Pages.admin.accounts.meta.path}
          element={(<AccountSearchAccessGate><AccountList /></AccountSearchAccessGate>)}
        />
        <Route
          path={Pages.admin.dataProviders.meta.path}
          element={(<AccountSearchAccessGate><DataProviders /></AccountSearchAccessGate>)}
        />
        <Route
          path={Pages.admin.create.meta.path}
          element={(<AccountSearchAccessGate><AccountCreate /></AccountSearchAccessGate>)}
        />
      </Routes>
    </Suspense>
  );
}
