import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const UpdatePlayers = lazy(() => import('./Update'));
const PlayerVersions = lazy(() => import('./Version'));
const ResourcePage = lazy(() => import('./ResourcePage'));

export function SeasonTeamRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.cricket.seasonTeam.meta.path}>
        <Route
          path={Pages.sports.cricket.seasonTeam.update.meta.path}
          element={<UpdatePlayers />}
        />
        <Route
          path={Pages.sports.cricket.seasonTeam.versions.meta.path}
          element={(<PlayerVersions />)}
        />
        <Route
          path={Pages.sports.cricket.seasonTeam.read.meta.path}
          element={<ResourcePage />}
        />
      </Route>
    </Routes>
  );
}
