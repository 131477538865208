import { Config } from 'config';
import { isDeletedValue } from 'core/Form';
import { getAccountKey } from 'core/localStore';
import moment from 'moment';

const momentTz = require('moment-timezone');

export function dataFromPath(obj, path, defaultValue) {
  if (!obj) {
    return obj;
  }
  let childObj = obj;
  const parts = path.split('.');
  let part = parts.shift();
  while (part) {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(part)) {
      part = parseInt(part, 10);
    }
    childObj = childObj[part];
    if (childObj === undefined) {
      return defaultValue;
    }
    if (childObj === null) {
      return null;
    }
    part = parts.shift();
  }

  if (childObj === undefined) {
    return defaultValue;
  }
  return childObj;
}

export function isDigit(val) {
  return !Number.isNaN(parseInt(val, 10));
}

export function objectAsArray(val, {
  defaultValue = undefined,
  nullAs = null,
} = {}) {
  if (val instanceof Array) {
    return val;
  }
  if (!val) {
    return [];
  }
  const keys = Object.keys(val)
    .filter((x) => isDigit(x))
    .sort((x, y) => parseInt(x, 10) - parseInt(y, 10));

  if (keys.length === 0) {
    return [];
  }

  const len = keys[keys.length - 1];
  const items = [];
  for (let i = 0; i <= len; i += 1) {
    let itemVal = val[i];
    if (itemVal === undefined) {
      itemVal = val[`${i}`];
    }
    if (itemVal === undefined) {
      itemVal = defaultValue;
    }
    if (itemVal === null) {
      itemVal = nullAs;
    }

    if (itemVal !== undefined) {
      items.push(itemVal);
    }
  }
  return items;
}

export function arrayAsObject(val) {
  if (!(val instanceof Array)) {
    return val;
  }
  const items = {};
  val.forEach((item, i) => items[i] = item);
  return items;
}

export function setValueOnPath(obj, value, path, createNew) {
  let childObj = obj ?? {};
  if (!obj && !createNew) {
    return obj;
  }

  let parent = obj ?? {};
  let parentPath = null;
  const parts = path.split('.');
  let part = parts.shift();
  const isThisList = (currentParts) => {
    if (currentParts.length > 1) {
      if (isDigit(currentParts[1])) {
        return true;
      }
    }
    return false;
  };
  // const isThisList = () => {
  //   return false;
  // };
  let isArray = false;
  if (!childObj && createNew) {
    childObj = isThisList(parts) ? [] : {};
  }

  while (part) {
    isArray = childObj instanceof Array;
    if (isArray && isDigit(part)) {
      part = parseInt(part, 10);
    }
    parent = childObj;
    parentPath = part;
    childObj = childObj[part];
    if (childObj === undefined && isArray) {
      for (let i = parent.length; i <= part; i += 1) {
        parent.push(i === part ? value : null);
      }
      childObj = parent[part];
    }

    if ((childObj === undefined || childObj === null) && !isDeletedValue(parent)) {
      if (!createNew) {
        return obj;
      }

      parent[part] = isThisList(parts) ? [] : {};
      childObj = parent[part];
    }

    if (isDeletedValue(parent)) {
      return obj;
    }

    if (isDeletedValue(childObj)
    && (isDeletedValue(value) || (!value || value === 0))) {
      return obj;
    }
    part = parts.shift();
  }

  if (childObj === undefined && !createNew) {
    return obj;
  }

  parent[parentPath] = value;
  // console.log('value', obj, value, path);
  return obj;
}

export function stripKeys(obj, keys) {
  if (!obj) return obj;
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (keys && keys.indexOf(key) > -1) return;
    if (key.startsWith('_')) return;
    newObj[key] = obj[key];
    if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
      newObj[key] = stripKeys(obj[key]);
    }
  });
  return newObj;
}

export function setValueOnPaths(obj, paths, valFn) {
  const newObj = JSON.parse(JSON.stringify(obj));

  paths.forEach((path) => {
    const val = dataFromPath(obj, path);
    if (val !== undefined) {
      const newVal = valFn(val);
      setValueOnPath(newObj, newVal, path);
    }
  });

  return newObj;
}

export function createResourceInput(value) {
  if (!value || value === '') return null;
  return { _hashkey: value };
}

export function keyed(obj, paths, fromStr) {
  // return setValueOnPaths(obj, paths, (val) => (val === '' ? null : { key: val }));
  return setValueOnPaths(obj, paths, (val) => {
    if (val?.hashkey) {
      return { hashkey: val.hashkey };
    }
    /* eslint-disable no-underscore-dangle */
    if (val?._hashkey) {
      return { hashkey: val._hashkey };
    }

    if (fromStr) {
      return createResourceInput(val);
    }

    return { hashkey: null };
  });
}

export function unKey(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => {
    if (val?.hashkey) {
      return { _hashkey: val.hashkey };
    }
    if (val?.key) {
      return val.key;
    }
    return undefined;
  });
}

export function flatObject(obj, parentName = null) {
  let flat = {};
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    const path = (parentName ? `${parentName}.` : '') + key;

    if (val === null || val === undefined) {
      flat[path] = val;
    } else if (typeof val === 'object') {
      flat = { ...flat, ...flatObject({ ...val }, path) };
    } else {
      flat[path] = val;
    }
  });

  return flat;
}

export function objectDifference(objA, objB, {
  flat = true,
  changeSet = null,
  includeRemoved = false,
  author,
}) {
  const a = flat ? flatObject({ ...objA }) : { ...objA };
  const b = flat ? flatObject({ ...objB }) : { ...objB };
  const oldChangeSet = changeSet || {};

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  const newKeys = bKeys.filter((bKey) => aKeys.indexOf(bKey) === -1);
  const removedKeys = aKeys.filter((aKey) => bKeys.indexOf(aKey) === -1);
  const updateKeys = aKeys.filter((aKey) => bKeys.indexOf(aKey) > -1);
  const defaultAuthor = author || {
    key: '',
    editSessionId: '',
    firstName: '',
    ts: null,
  };
  const changes = newKeys.map((key) => {
    let fieldAuthor = { ...defaultAuthor };
    if (oldChangeSet[key] && oldChangeSet[key].author) {
      fieldAuthor = oldChangeSet[key].author;
    }
    return {
      path: key,
      value: b[key],
      oldValue: null,
      author: fieldAuthor,
    };
  });
  updateKeys.forEach((key) => {
    const aVal = a[key];
    const bVal = b[key];
    let fieldAuthor = { ...defaultAuthor };
    if (aVal !== bVal) {
      if (oldChangeSet[key]
        && oldChangeSet[key].value === bVal
        && oldChangeSet[key].author.editSessionId) {
        fieldAuthor = oldChangeSet[key].author;
      }
      changes.push({
        path: key,
        value: bVal,
        oldValue: aVal,
        author: fieldAuthor,
      });
    }
  });

  if (includeRemoved) {
    removedKeys.forEach((key) => {
      let fieldAuthor = { ...defaultAuthor };
      if (oldChangeSet[key] && oldChangeSet[key].author) {
        fieldAuthor = oldChangeSet[key].author;
      }
      changes.push({
        path: key,
        value: null,
        oldValue: b[key],
        author: fieldAuthor,
      });
    });
  }

  return {
    changes,
    newKeys,
    removedKeys,
  };
}

export function currencyAsInt(val) {
  return parseInt(parseFloat(val) * Config.currencyFactor, 10);
}

export function intAsCurrency(val) {
  return parseInt(val, 10) / Config.currencyFactor;
}

export function setCurrencyAsInt(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => currencyAsInt(val));
}

export function setIntAsCurrency(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => intAsCurrency(val));
}
export function getLocal() {
  if (typeof window === 'undefined') {
    return Config.Locale;
  }

  if (Config.PreferUserLocal === 1 && navigator && navigator.language) {
    return navigator.language;
  }

  return Config.Locale;
}

export function formatCurrency(amount, currency) {
  const lCurrency = currency || Config.defaultCurrency;

  // currencyDisplay: 'narrowSymbol' - is not supported in Safari 14.0
  // Best approach is to use polyfills https://formatjs.io/docs/polyfills/intl-numberformat
  // But that will add ~15KB to build size. So its better to go for text replace in this case.
  const formater = new Intl.NumberFormat(
    getLocal(),
    { style: 'currency', currency: lCurrency },
  );

  let formatted = formater.format(Math.round(amount));

  if (getLocal() === 'is-IS' && formatted.startsWith(lCurrency)) {
    // Temporary Bad logic on chrome 🤧 - Since we know only doing for IS and no decimal.
    // In other browsers its working fine. So only for chrome manually swapping and
    // replacing , to . in price.
    // eslint-disable-next-line no-unused-vars
    const [_, price] = formatted.split(lCurrency);
    formatted = [price.trimStart(), lCurrency].join(' ').replace(/,/g, '.');
  }

  if (Config.CurrencySymbol) {
    return formatted.replace(lCurrency, Config.CurrencySymbol);
  }

  return formatted;
}

export function formatMoney({ value, currency }) {
  return formatCurrency(value, currency).replace('.00', '');
}

export function formatIntAsMoney({ value, currency }) {
  return formatMoney({ value: intAsCurrency(value), currency });
}

export function parseDate(date) {
  if (typeof date === 'number') {
    return moment(Number(date) * 1000);
  }

  if (!Number.isNaN(date)) {
    let aint = Number(date);
    if (date.length > 18) {
      // Nano seconds
      aint /= (1000 * 1000);
    } else {
      aint /= 1000;
    }
    return moment(aint);
  }
  return moment(date);
}
export function friendlyDate(date, defaultValue, format) {
  if (!date) {
    return defaultValue || '-';
  }
  const m = parseDate(date);
  if (m.year() === moment().year()) {
    return m.format(format ?? 'ddd, DD MMM');
  }

  return m.format(format ?? 'DD MMM YYYY');
}

export function friendlyDateTime(date, defaultValue) {
  if (!date) {
    return defaultValue || '-';
  }
  const m = parseDate(date);
  return m.format('ddd, DD MMM YYYY, h:mma');
}

export function formatDate(date, defaultValue) {
  if (!date) {
    return defaultValue || '-';
  }
  const m = parseDate(date);
  return m.format('MMM DD, h:mm a');
}

export function resourceHashkey({
  resource = null,
  key = null,
  includeAccount = true,
}) {
  let payload = (resource) ? { ...resource } : {};
  if (!resource) {
    payload = { key };
    if (includeAccount) {
      payload.account = getAccountKey();
    }
  }

  const raw = JSON.stringify(payload);
  return btoa(raw);
}

export function resourceDecodeHashkey(resource) {
  const raw = JSON.parse(atob(resource));
  return raw;
}

export function hash(payload) {
  const raw = JSON.stringify(payload);
  return btoa(raw);
}

export function unhash(payload) {
  const raw = JSON.parse(atob(payload));
  return raw;
}

export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => {
    // eslint-disable-next-line no-mixed-operators, no-bitwise
    const v = (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4);
    return v.toString(16);
  });
}

export function idify(name) {
  if (!name) { return name; }
  return name.replace(/[\W_]+/g, '').toLowerCase();
}

export function capitilise(name, joinChar = ' ') {
  if (!name) { return name; }

  const parts = name.trim().split(/(?=[A-Z])/).join('_').split(/[\W_]+/g);
  return parts.map(([firstChar, ...wordRest]) => `${firstChar.toUpperCase()}${wordRest.join('')}`).join(joinChar);
}

export function timestamp() {
  const now = new Date();
  let ts = now.getTime();

  // Change to second
  ts /= 1000;

  return ts;
}

export function dateFromTsTimezone(val, key, withDay, chosenTimezone, format) {
  const dateFormat = withDay ? (format || 'ddd, Do MMM YYYY, h:mm:ss a') : 'DD/M/YYYY hh:mm a';
  const unix = moment.unix(val);
  switch (key) {
    case 'UTC':
      return unix.utc().format(dateFormat);
    case 'Local':
    case null:
    case undefined:
      return unix.format(dateFormat);
    default:
      return momentTz.tz(unix, chosenTimezone).format(dateFormat);
  }
}

export function changeTimeZone(key, userContext) {
  switch (key) {
    case 'UTC':
      return userContext.setPreferredTimeZone('Local');
    case 'Local':
    case null:
    case undefined:
      return userContext.setPreferredTimeZone('Stadium');
    default:
      return userContext.setPreferredTimeZone('UTC');
  }
}

export function createApolloCacheKey(rt, resource) {
  const key = Object.values(stripKeys(resource, ['account'])).join(':');
  return `${rt}:${key}`;
}

export function searchMatch(obj, text, paths) {
  if (text) {
    const ltext = text.toLowerCase();
    for (let i = 0; i < paths.length; i += 1) {
      const val = dataFromPath(obj, paths[i]);
      if (val && val.toLowerCase().indexOf(ltext) > -1) {
        return true;
      }
    }

    return false;
  }
  return true;
}
