import { asRem } from 'lib/css';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { breadcrumb } from './pages';

const BreadcrumbWrapper = styled.div`
  visibility: hidden;
>ul {
  display: flex;
  margin-top: ${asRem(20)};
  margin-bottom: ${asRem(40)};
  a {
    font-size: ${asRem(12)};
    line-height: ${asRem(16)};
    padding: 1px 0;
    border: 0;
    color: var(--color-text-3);
  }

  li {
    &:after {
      content: '/';
      display: inline-block;
      color: var(--color-text-3);
      margin: 0 ${asRem(8)};

      font-size: ${asRem(14)};
      line-height: ${asRem(18)};
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
`;

export function Breadcrumb({ className }) {
  const location = useLocation();
  const branches = breadcrumb(location.pathname);
  return (
    <BreadcrumbWrapper className={`rz-breadcrumb ${className}`}>
      <ul>
        {branches.map((branch) => (
          <li key={branch.page.meta.url}>
            <Link
              to={branch.page.meta.urlFor(branch.match.params)}
              className="plain"
            >
              {branch.page.meta.name}
            </Link>
          </li>
        ))}
      </ul>
    </BreadcrumbWrapper>
  );
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
};
