import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const TeamCreateHintQuery = gql`
query TeamCreateHintQuery($sport: Sports!, $name: String!) {
  sports_team_create_hint(sport: $sport, name: $name) {
    name
    short_name
    legal_name
    similar_items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      team {
        name
        short_name
        region {
          account
          key
          hashkey: _hashkey
        }
      }
    }
    key_suggestions
  }
}
`;

export const TeamCreateMutation = gql`
mutation TeamCreateMutation(
  $sport: Sports!,
  $team: TeamTeamInput!,
  $detail: TeamDetailInput!,
  $key: String) {
    sports_team_create(sport: $sport, team: $team,
      detail: $detail,
      key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const TeamSearchQuery = gql`
query TeamSearchQuery($search: String!, $page: PagedRequestInput, $sport: Sports) {
  sports_team_search(search: $search, page: $page, sport: $sport) {
    items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      team {
        name
        short_name
        gender
        region {
          account
          key
          hashkey: _hashkey
        }
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const TeamFields = `

  resource {
    account
    sport
    key
    hashkey: _hashkey
  }
  team {
    name
    short_name
    region {
      account
      key
      hashkey: _hashkey
    }
    gender
  }
  detail {
    gender
    legal_name
    home {
      account
      key
      hashkey: _hashkey
    }
    other_homes {
      account
      key
      hashkey: _hashkey
    }
    founded
    club {
      account
      key
      hashkey: _hashkey
    }
    kit_colors
    other_names
  }
  bio {
    desc
    official_website
  }
  play {
    associations {
      account
      key
      hashkey: _hashkey
    }
  }
  social_network {
    twitter
    facebook
    instagram
    youtube
  }
  personnel {
    captain
    vice_captain
    coach_name
    owner_name
    chairman_name
    manager_name
  }
  image {
    image_url
  }
  data {
    keys {
      src
      key
    }
    tags
    properties
  }
  computed {
    last_updated
  }
  legacy_data {
    key
  }
`;

export const TeamReadQuery = gql`
query TeamReadQuery($resource: TeamInput!, $withHistory: Boolean) {
  sports_team_read(resource: $resource, with_history: $withHistory) {
    item {
      ${TeamFields}
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const TeamUpdateMutation = gql`
mutation TeamUpdateMutation(
  $resource: TeamInput!,
  $team: TeamTeamInput!,
  $detail: TeamDetailInput!,
  $personnel: TeamPersonnelInput!,
  $bio: TeamBioInput!,
  $play: TeamPlayInput!,
  $socialNetwork: TeamSocialNetworkInput!,
  $version: RecordStage) {
    sports_team_update(resource: $resource, team: $team,
     detail: $detail, personnel: $personnel, bio: $bio, play: $play, social_network: $socialNetwork,
     version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const TeamUpdateStatusMutation = gql`
mutation TeamUpdateStatusMutation(
  $resource: TeamInput!,
  $status: TeamStatusInput!,
  $version: RecordStage) {
    sports_team_update_status(resource: $resource,
     status: $status, version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const TeamDeleteMutation = gql`
mutation TeamDeleteMutation(
  $resource: TeamInput!,
  $name: String!,
  $imageUrl: String) {
  sports_team_delete(resource: $resource, name: $name, image_url: $imageUrl) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TeamImageUploadMutation = gql`
mutation TeamImageUploadMutation(
  $resource: TeamInput!,
  $filename: String!) {
  sports_team_image_upload(resource: $resource, filename: $filename) {
    signed_data
    url
    url_suffix
  }
}
`;

export const TeamNameQuery = gql`
query TeamNameQuery($resource: TeamInput!) {
  sports_team_name(resource: $resource) {
    name
    image_url
  }
}
`;

export const HeadlessTeamSearchQuery = gql`
query HeadlessTeamSearchQuery($search: String!, $page: PagedRequestInput, $sport: Sports) {
  sports_team_review(search: $search, page: $page, sport: $sport) {
    items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      team {
        name
        short_name
        region {
          account
          key
          hashkey: _hashkey
        }
        gender
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const TeamPreviewQuery = gql`
query TeamPreviewQuery($team: TeamInput!,
) {
  sports_cricket_team_preview(team: $team) {
    team {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      team {
        name
        short_name
        region {
          account
          key
          hashkey: _hashkey
        }
        gender
      }
      detail {
        gender
        legal_name
        home {
          account
          key
          hashkey: _hashkey
        }
        other_homes {
          account
          key
          hashkey: _hashkey
        }
        founded
        club {
          account
          key
          hashkey: _hashkey
        }
        kit_colors
        other_names
      }
      bio {
        desc
        official_website
      }
      play {
        associations {
          account
          key
          hashkey: _hashkey
        }
      }
      social_network {
        twitter
        facebook
        instagram
        youtube
      }
      personnel {
        captain
        vice_captain
        coach_name
        owner_name
        chairman_name
        manager_name
      }
      image {
        image_url
      }
      data {
        keys {
          src
          key
        }
        tags
        properties
      }
      computed {
        last_updated
      }
      legacy_data {
        key
      }
    }
    matches {
      upcoming {
        resource {
          account
          sport
          key
          hashkey: _hashkey
        }
        match {
          name
          internal_name
          start_at
          format
          status
          short_name
          sub_title
          stadium {
            account
            key
            hashkey: _hashkey
          }
          tournament {
            account
            sport
            association
            competition
            key
            hashkey: _hashkey
          }
          headless
        }
        teams {
          team_a {
            account
            sport
            key
            hashkey: _hashkey
          }
          team_b {
            account
            sport
            key
            hashkey: _hashkey
          }
        }
        merge_with {
          tournament_key
          tournament_round_key
          tournament_group_key
          review_status
        }
        rules {
          format
          mg
          overs_per_innings
          drs_per_innings
          balls_per_over
          player_per_team
          bench_per_team
          bowler_per_innings
          batsman_per_innings
          validate_players_count_per_team
          allow_induvidual_team_squad_update
          enable_ball_of_dismissed_stats
          allow_same_player_in_both_teams
          allow_squad_update_without_toss
          is_captain_wicketkeeper_required
          runs_per_no_ball
          runs_per_wide
        }
        tournament_details {
          round_key
          group_key
        }
        status {
          published
          notes
        }
        deleted_status {
          mark_as_deleted
          delete_after
        }
        data {
          tags
          properties
        }
        create_computed {
          incremental_id
        }
        computed_searchables {
          gender
          year_month
          association {
            account
            key
            hashkey: _hashkey
          }
          competition {
            account
            sport
            association
            key
            hashkey: _hashkey
          }
        }
        result {
          winner
          typ
          dl_applied
          win_by
        }
      }
      live {
        resource {
          account
          sport
          key
          hashkey: _hashkey
        }
        match {
          name
          internal_name
          start_at
          format
          status
          short_name
          sub_title
          stadium {
            account
            key
            hashkey: _hashkey
          }
          tournament {
            account
            sport
            association
            competition
            key
            hashkey: _hashkey
          }
          headless
        }
        teams {
          team_a {
            account
            sport
            key
            hashkey: _hashkey
          }
          team_b {
            account
            sport
            key
            hashkey: _hashkey
          }
        }
        merge_with {
          tournament_key
          tournament_round_key
          tournament_group_key
          review_status
        }
        rules {
          format
          mg
          overs_per_innings
          drs_per_innings
          balls_per_over
          player_per_team
          bench_per_team
          bowler_per_innings
          batsman_per_innings
          validate_players_count_per_team
          allow_induvidual_team_squad_update
          enable_ball_of_dismissed_stats
          allow_same_player_in_both_teams
          allow_squad_update_without_toss
          is_captain_wicketkeeper_required
          runs_per_no_ball
          runs_per_wide
        }
        tournament_details {
          round_key
          group_key
        }
        status {
          published
          notes
        }
        deleted_status {
          mark_as_deleted
          delete_after
        }
        data {
          tags
          properties
        }
        create_computed {
          incremental_id
        }
        computed_searchables {
          gender
          year_month
          association {
            account
            key
            hashkey: _hashkey
          }
          competition {
            account
            sport
            association
            key
            hashkey: _hashkey
          }
        }
        result {
          winner
          typ
          dl_applied
          win_by
        }
      }
      completed {
        resource {
          account
          sport
          key
          hashkey: _hashkey
        }
        match {
          name
          internal_name
          start_at
          format
          status
          short_name
          sub_title
          stadium {
            account
            key
            hashkey: _hashkey
          }
          tournament {
            account
            sport
            association
            competition
            key
            hashkey: _hashkey
          }
          headless
        }
        teams {
          team_a {
            account
            sport
            key
            hashkey: _hashkey
          }
          team_b {
            account
            sport
            key
            hashkey: _hashkey
          }
        }
        merge_with {
          tournament_key
          tournament_round_key
          tournament_group_key
          review_status
        }
        rules {
          format
          mg
          overs_per_innings
          drs_per_innings
          balls_per_over
          player_per_team
          bench_per_team
          bowler_per_innings
          batsman_per_innings
          validate_players_count_per_team
          allow_induvidual_team_squad_update
          enable_ball_of_dismissed_stats
          allow_same_player_in_both_teams
          allow_squad_update_without_toss
          is_captain_wicketkeeper_required
          runs_per_no_ball
          runs_per_wide
        }
        tournament_details {
          round_key
          group_key
        }
        status {
          published
          notes
        }
        deleted_status {
          mark_as_deleted
          delete_after
        }
        data {
          tags
          properties
        }
        create_computed {
          incremental_id
        }
        computed_searchables {
          gender
          year_month
          association {
            account
            key
            hashkey: _hashkey
          }
          competition {
            account
            sport
            association
            key
            hashkey: _hashkey
          }
        }
        result {
          winner
          typ
          dl_applied
          win_by
        }
      }
    }
    tournaments {
      tournament_list {
        tournament {
          resource {
            account
            sport
            association
            competition
            key
            hashkey: _hashkey
          }
          tournament {
            name
            internal_name
            short_name
            gender
            start_date
            related_tournament_key
            tour_key
            enable_stats
            enable_association_stats
            enable_competition_stats
            play_kind
          }
          bio {
            gender
            administrators {
              account
              key
              hashkey: _hashkey
            }
            hosts {
              account
              key
              hashkey: _hashkey
            }
          }
          cricket {
            format
            mg
            v2_resource_key
            v4_resource_key
          }
          cricket_tournament_team {
            teams
          }
          rules {
            overs_per_innings
            drs_per_innings
            balls_per_over
            player_per_team
            bench_per_team
            bowler_per_innings
            batsman_per_innings
            runs_per_no_ball
            runs_per_wide
          }
          result {
            winner
            runner_up
            players_of_the_series
          }
          status {
            published
            notes
          }
          image {
            image_url
          }
          computed {
            start_date
            end_date
            first_match
            start_date_updated_round
            end_date_updated_round
          }
          total_matches_count {
            total_matches
          }
        }
        tournament_team_hashkey
      }
    }
  }
}
`;

export const TeamPreviewJsonQuery = gql`
  query TeamPreviewJsonQuery($team: TeamInput! , $tournamentTeam: TournamentTeamInput!) {
    sports_cricket_team_preview(team: $team, tournament_team: $tournamentTeam) {
      team {
        resource {
          account
          sport
          key
          _hashkey
        }
        team {
          name
          short_name
          region {
            account
            key
            _hashkey
          }
          gender
        }
        detail {
          gender
          legal_name
          unofficial_name
          home {
            account
            key
            _hashkey
          }
          other_homes {
            account
            key
            _hashkey
          }
          founded
          club {
            account
            key
            _hashkey
          }
          kit_colors
          other_names
        }
        bio {
          desc
          official_website
        }
        play {
          associations {
            account
            key
            _hashkey
          }
        }
        social_network {
          twitter
          facebook
          instagram
          youtube
        }
        personnel {
          captain
          vice_captain
          coach_name
          owner_name
          chairman_name
          manager_name
        }
        image {
          image_url
        }
        data {
          keys {
            src
            key
          }
          tags
          properties
        }
        computed {
          last_updated
        }
        legacy_data {
          key
          duplicate_keys
        }
      }
      matches {
        upcoming {
          resource {
            account
            sport
            key
            _hashkey
          }
          match {
            name
            internal_name
            start_at
            format
            status
            short_name
            sub_title
            stadium {
              account
              key
              _hashkey
            }
            tournament {
              account
              sport
              association
              competition
              key
              _hashkey
            }
            query_date_hash
            query_date_range
            date_hash
            date_range
            query_tournament_hash
            account_date_hash
            account_date_range
            headless
          }
          play {
            play_status
            break_type
            break_seconds
            break_note
            internal_notes
            public_msg
            day
            weather {
              temperature
              condition
              icon
            }
            expected_start_time
          }
          teams {
            team_a {
              account
              sport
              key
              _hashkey
            }
            team_b {
              account
              sport
              key
              _hashkey
            }
          }
          merge_with {
            tournament_key
            tournament_round_key
            tournament_group_key
            review_status
          }
          rules {
            format
            mg
            overs_per_innings
            drs_per_innings
            balls_per_over
            player_per_team
            bench_per_team
            bowler_per_innings
            batsman_per_innings
            validate_players_count_per_team
            allow_induvidual_team_squad_update
            enable_ball_of_dismissed_stats
            allow_same_player_in_both_teams
            allow_squad_update_without_toss
            is_captain_wicketkeeper_required
            runs_per_no_ball
            runs_per_wide
          }
          tournament_details {
            round_key
            group_key
          }
          status {
            published
            notes
          }
          deleted_status {
            mark_as_deleted
            delete_after
          }
          data {
            tags
            properties
          }
          create_computed {
            incremental_id
          }
          computed_searchables {
            gender
            year_month
            association {
              account
              key
              _hashkey
            }
            competition {
              account
              sport
              association
              key
              _hashkey
            }
          }
          result {
            winner
            typ
            win_by
            dl_applied
          }
          scores {
            team_a {
              runs
              wickets
            }
            team_b {
              runs
              wickets
            }
          }
          legacy_data {
            key
            other_players
          }
        }
        live {
          resource {
            account
            sport
            key
            _hashkey
          }
          match {
            name
            internal_name
            start_at
            format
            status
            short_name
            sub_title
            stadium {
              account
              key
              _hashkey
            }
            tournament {
              account
              sport
              association
              competition
              key
              _hashkey
            }
            query_date_hash
            query_date_range
            date_hash
            date_range
            query_tournament_hash
            account_date_hash
            account_date_range
            headless
          }
          play {
            play_status
            break_type
            break_seconds
            break_note
            internal_notes
            public_msg
            day
            weather {
              temperature
              condition
              icon
            }
            expected_start_time
          }
          teams {
            team_a {
              account
              sport
              key
              _hashkey
            }
            team_b {
              account
              sport
              key
              _hashkey
            }
          }
          merge_with {
            tournament_key
            tournament_round_key
            tournament_group_key
            review_status
          }
          rules {
            format
            mg
            overs_per_innings
            drs_per_innings
            balls_per_over
            player_per_team
            bench_per_team
            bowler_per_innings
            batsman_per_innings
            validate_players_count_per_team
            allow_induvidual_team_squad_update
            enable_ball_of_dismissed_stats
            allow_same_player_in_both_teams
            allow_squad_update_without_toss
            is_captain_wicketkeeper_required
            runs_per_no_ball
            runs_per_wide
          }
          tournament_details {
            round_key
            group_key
          }
          status {
            published
            notes
          }
          deleted_status {
            mark_as_deleted
            delete_after
          }
          data {
            tags
            properties
          }
          create_computed {
            incremental_id
          }
          computed_searchables {
            gender
            year_month
            association {
              account
              key
              _hashkey
            }
            competition {
              account
              sport
              association
              key
              _hashkey
            }
          }
          result {
            winner
            typ
            win_by
            dl_applied
          }
          scores {
            team_a {
              runs
              wickets
            }
            team_b {
              runs
              wickets
            }
          }
          legacy_data {
            key
            other_players
          }
        }
        completed {
          resource {
            account
            sport
            key
            _hashkey
          }
          match {
            name
            internal_name
            start_at
            format
            status
            short_name
            sub_title
            stadium {
              account
              key
              _hashkey
            }
            tournament {
              account
              sport
              association
              competition
              key
              _hashkey
            }
            query_date_hash
            query_date_range
            date_hash
            date_range
            query_tournament_hash
            account_date_hash
            account_date_range
            headless
          }
          play {
            play_status
            break_type
            break_seconds
            break_note
            internal_notes
            public_msg
            day
            weather {
              temperature
              condition
              icon
            }
            expected_start_time
          }
          teams {
            team_a {
              account
              sport
              key
              _hashkey
            }
            team_b {
              account
              sport
              key
              _hashkey
            }
          }
          merge_with {
            tournament_key
            tournament_round_key
            tournament_group_key
            review_status
          }
          rules {
            format
            mg
            overs_per_innings
            drs_per_innings
            balls_per_over
            player_per_team
            bench_per_team
            bowler_per_innings
            batsman_per_innings
            validate_players_count_per_team
            allow_induvidual_team_squad_update
            enable_ball_of_dismissed_stats
            allow_same_player_in_both_teams
            allow_squad_update_without_toss
            is_captain_wicketkeeper_required
            runs_per_no_ball
            runs_per_wide
          }
          tournament_details {
            round_key
            group_key
          }
          status {
            published
            notes
          }
          deleted_status {
            mark_as_deleted
            delete_after
          }
          data {
            tags
            properties
          }
          create_computed {
            incremental_id
          }
          computed_searchables {
            gender
            year_month
            association {
              account
              key
              _hashkey
            }
            competition {
              account
              sport
              association
              key
              _hashkey
            }
          }
          result {
            winner
            typ
            win_by
            dl_applied
          }
          scores {
            team_a {
              runs
              wickets
            }
            team_b {
              runs
              wickets
            }
          }
          legacy_data {
            key
            other_players
          }
        }
      }
      tournaments {
        tournament_list {
          tournament {
            resource {
              account
              sport
              association
              competition
              key
              _hashkey
            }
            tournament {
              name
              internal_name
              short_name
              gender
              start_date
              related_tournament_key
              tour_key
              enable_stats
              enable_association_stats
              enable_competition_stats
              play_kind
              end_date
            }
            bio {
              gender
              administrators {
                account
                key
                _hashkey
              }
              hosts {
                account
                key
                _hashkey
              }
            }
            cricket {
              format
              mg
              v2_resource_key
              v4_resource_key
            }
            cricket_tournament_team {
              teams
            }
            rules {
              overs_per_innings
              drs_per_innings
              balls_per_over
              player_per_team
              bench_per_team
              bowler_per_innings
              batsman_per_innings
              runs_per_no_ball
              runs_per_wide
            }
            result {
              winner
              runner_up
              players_of_the_series
            }
            status {
              published
              notes
            }
            image {
              image_url
            }
            computed {
              start_date
              end_date
              first_match
              start_date_updated_round
              end_date_updated_round
              tournament_month
            }
            legacy_data {
              key
            }
            total_matches_count {
              total_matches
            }
          }
          tournament_team_hashkey
        }
      }
      tournament_team {
        resource {
          tournament
          team
          _hashkey
        }
        team {
          captain {
            account
            sport
            key
            _hashkey
          }
          name
          short_name
        }
        legacy_data {
          key
        }
        squad {
          squad {
            player {
              account
              sport
              key
              _hashkey
            }
            playing_role
            name
            jersey_name
            state
            batting_style
            bowling_style {
              arm
              pace
              type
            }
            frequent_bowling_deliveries
            region {
              account
              key
              _hashkey
            }
            date_of_birth
            legacy_data {
              key
            }
            legal_name
            image_url
            notes
            reason
            replaced_by {
              account
              sport
              key
              _hashkey
            }
            gender
            alltime_roles
          }
        }
      }
    }
  } 
`;
