import { gql } from '@apollo/client';

export const MatchScorecardReadQuery = gql`
query MatchScorecardReadQuery($resource: CricketMatchInningsInput!, $withHistory: Boolean) {
  sports_cricket_scorecard_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        match
        innings_index
        hashkey:_hashkey
      }
      scorecard {
        batters {
          order
          player
          runs
          balls
          fours
          sixes
          strike_rate
          is_dismissed
          wicket_type
          bowler
          non_striker
          fall_of_wicket {
            score
            overs
            ball
          }
          fielders {
            fielder {
              account
              sport
              key
              hashkey: _hashkey
            }
            role
            assist_index
          }
        }
        bowlers {
          order
          player
          runs
          overs
          wickets
          maidens
          fours
          sixes
          dots
          wides
          no_balls
          economy
        }
        wicket_order {
          player
          ballindex
        }
        overs
        team_wide
        team_extra_runs
        team_penality
        team_bye
        team_legbye
        team_noball
        team_fours
        team_sixes
      }
    }
    related {
      computed {
        resource {
          match
          innings_index
          hashkey: _hashkey
        }
        runs {
          runs
          fours
          sixes
          wickets
          over_value
          dots
          run_rate
          balls
        }
        extra_runs {
          extras
          bye
          legbye
          wide
          noball
          penality
        }
        req_runs {
          runs
          balls
          runs_rate
        }
        play {
          batting_order {
            player
            ballindex
          }
          bowling_order {
            player
            ballindex
          }
          wicket_order {
            player
            ballindex
          }
          batting_team {
            account
            sport
            key
            _hashkey
          }
          bowling_team {
            account
            sport
            key
            _hashkey
          }
          batting_team_squad
          bowling_team_squad
          tournament
          status
          inactive_balls {
            match
            ball_index
            _hashkey
          }
        }
        rules {
          format
          balls_per_over
        }
        partnership {
          partnership {
            partner_index
            innings_index
            first_ball_index
            last_ball_index
            score {
              runs
              balls
              fours
              sixes
              run_rate
            }
            player_a {
              runs
              balls
              fours
              sixes
              run_rate
              player
            }
            player_b {
              runs
              balls
              fours
              sixes
              run_rate
              player
            }
            dismissed
          }
        }
        extras {
          wides
          noballs
          bye
          legbye
        }
      }
      innings_summary {
        resource {
          match
          innings_index
          hashkey: _hashkey
        }
        runs {
          runs
          fours
          sixes
          wickets
          over_value
          dots
          run_rate
          balls
        }
        extra_runs {
          extras
          bye
          legbye
          wide
          noball
          penality
        }
        req_runs {
          runs
          balls
          runs_rate
        }
        extras {
          wides
          noballs
          bye
          legbye
        }
      }
    }
    history {
      cs {
        records {
          raw
          ts
          action
          authors {
            key
            name
            ts
            email
            picture_key
            ip
          }
          att_stage
        }
        cs_name
        version_key_meta {
          resource_id
          resource_key
          cs_name
        }
        draft {
          raw
          ts
          action
          authors {
            key
            name
            ts
            email
            picture_key
            ip
          }
          att_stage
        }
        ready_to_publish {
          raw
          ts
          action
          authors {
            key
            name
            ts
            email
            picture_key
            ip
          }
          att_stage
        }
        next_page_key
      }
    }
  }
}
`;

export const MatchScorecardUpdateMutation = gql`
mutation MatchScorecardUpdateMutation(
  $resource: CricketMatchInningsInput!,
  $scorecard: ScorecardInput!
  $matchName: String,
  $version: RecordStage,
) {
  sports_cricket_scorecard_update(
    resource: $resource,
    scorecard: $scorecard,
    match_name: $matchName,
    version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchInningsDeleteMutation = gql`
mutation MatchInningsDeleteMutation(
  $resource: CricketMatchInningsInput!,
  $name: String!,
) {
  sports_cricket_match_innings_delete (
    resource: $resource,
    name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;
