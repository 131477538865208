/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { Pages } from 'core/pages';
import { MutationButton } from 'core/Button';
import { MatchResource } from '../resource';
import { MatchDeleteMutation } from '../query';
import { MatchView } from './MatchView';

function createLink(name, key, resourceKey, resource) {
  return {
    name,
    link: Pages.sports.cricket.match.update[key],
    url: MatchResource.urlFor(MatchResource, {
      page: `update.${key}`,
      resourceKey,
      resource,
    }),
  };
}

export function secondaryMenuItems({ resourceKey, resource, queryResponse }) {
  return [
    !queryResponse?.match?.headless
      ? {
        name: <MatchView queryResponse={queryResponse} />,
        link: Pages.sports.cricket.match,
        url: MatchResource.pageFor(MatchResource, 'read')?.meta.urlFor({ key: queryResponse?.resource?.hashkey || queryResponse?.resource?.key }) ?? '',
      } : null,
    createLink('Match', 'match', resourceKey, resource),
    createLink('Rules', 'rules', resourceKey, resource),
    createLink('Umpire', 'umpire', resourceKey, resource),
    createLink('Toss', 'toss', resourceKey, resource),
    createLink('Play', 'play', resourceKey, resource),
    createLink('Squad', 'squad', resourceKey, resource),
    createLink('Innings', 'innings', resourceKey, resource),
    queryResponse?.match?.format === 'Test' ? createLink('Sessions', 'sessions', resourceKey, resource) : null,
    createLink('Review', 'review', resourceKey, resource),
    createLink('Result', 'result', resourceKey, resource),
    createLink('Reward', 'reward', resourceKey, resource),
    queryResponse.match.headless ? createLink('Advanced Settings', 'advancedSettings', resourceKey, resource) : null,
  ];
}

export function deleteAction(name, key) {
  return (
    <MutationButton
      mutation={MatchDeleteMutation}
      responseKey="sports_cricket_match_delete"
      okayKey="status.okay"
      errorkey="status.errors"
      name="Delete"
      className="alert"
      variables={
        {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: key,
          },
          name,
        }
      }
    />
  );
}
