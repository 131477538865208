import React from 'react';
import { AntEditor } from 'ants/AntEditor';
import styled from 'styled-components';
import { FormScreenLayoutStyle } from 'ants/AntForm';
import { GridFormItem } from 'sports/GridFormItem';
import { asRem } from 'lib/css';
import PropTypes from 'prop-types';
import { isDeletedValue } from 'core/Form';
import { Label12Regular } from 'style/typography';
import { FormItem } from 'core/FormFieldItem';
import { objectAsArray } from 'lib/utils';
import { MatchResource } from '../resource';
import { secondaryMenuItems } from './utils';

export const sessionInfo = (queryResponse) => {
  const teams = {
    A: queryResponse?.team_a?.team_code,
    B: queryResponse?.team_b?.team_code,
  };

  const sessionItem = {
    day: Array(6).fill(0).map((_, index) => ({
      key: index + 1,
      name: `Day ${index + 1}`,
    })),

    innings: objectAsArray(queryResponse?.innings?.innings)?.map((inn) => ({
      key: inn.index,
      name: `${teams[inn.batting]} - Inn ${inn.index % 2 === 0 ? inn.index / 2 : (inn.index + 1) / 2}`,
    })),

    session: Array(3).fill(0).map((_, index) => ({
      key: index + 1,
      name: `Session ${index + 1}`,
    })),
  };

  return sessionItem;
};

function MatchSessionUpdate({
  name, labelItem, item, queryResponse,
}) {
  const sessionItems = sessionInfo(queryResponse);
  if (isDeletedValue(item)) {
    return <Label12Regular>Deleted</Label12Regular>;
  }

  if (labelItem.key === 'day' || labelItem.key === 'session' || labelItem.key === 'innings') {
    return (
      <FormItem
        name={name}
        options={sessionItems[labelItem.key]}
        type="select"
        showLabel={false}
        onelineStyle
      />
    );
  }

  if (labelItem.key === 'first_over_str' || labelItem.key === 'last_over_str' || labelItem.key === 'session_start_day_remaining_overs_est') {
    return (
      <FormItem
        name={name}
        type="text"
        showLabel={false}
        onelineStyle
        placeholder={labelItem.name}
      />
    );
  }
}

MatchSessionUpdate.propTypes = {
  name: PropTypes.string,
  labelItem: PropTypes.object,
  item: PropTypes.string,
  queryResponse: PropTypes.object,
};

export const MatchSessionWrapper = styled.div`
  max-width: var(--layout-container-width-2);
  margin: ${asRem(80)} auto;

  .colgroup {
    width: ${asRem(20)};
  }

  table {
    border-collapse: separate;
    border-spacing: ${asRem(0)};
    table-layout: fixed;
    width: 100%;

    thead {
      tr {
        td {
          color: var(--color-text-3);
          font-size: var(--text-md-fontSize);
          line-height: var(--text-md-lineHeight);
          font-weight: var(--medium-fontWeight);
          padding-bottom: ${asRem(16)};
          padding-left: ${asRem(12)};
        }
      }
    }

    tbody {
      tr {
        td {
          padding: ${asRem(12)};
          font-size: var(--text-md-fontSize);
          line-height: var(--text-md-lineHeight);
          font-weight: var(--medium--fontWeight);
          margin-top: auto;
          vertical-align: middle;

          &:last-child {
            border: none;
          }
        }

        &:first-child {
          td {
            &:nth-child(1) {
              border-top-left-radius: ${asRem(10)};
            }

            &:nth-last-child(2) {
              border-top-right-radius: ${asRem(10)};
            }
          }
        }

        &:last-child {
          td {
            &:not(:last-child) {
              border-bottom: ${asRem(1)} solid var(--color-border-1);
            }
            &:nth-child(1) {
              border-bottom-left-radius: ${asRem(10)};
            }

            &:nth-last-child(2) {
              border-bottom-right-radius: ${asRem(10)};
            }
          }
        }

        td {
          border-top: ${asRem(1)} solid var(--color-border-1);
          border-left: ${asRem(1)} solid var(--color-border-1);

          &:nth-last-child(2) {
            border-right: ${asRem(1)} solid var(--color-border-1);
          }
        }

        td {
          > div {
            margin-bottom: 0;

            .input-area {
              width: 100%;

              .rz-select, select, input {
                background-color: transparent;

                option {
                  background-color: var(--color-border-1);
                }
              }

              select, input {
                padding: 0;
              }
            }

            .input-box  {
              .tooltips-area {
                .tooltips-area-content {
                  bottom: calc(-50% - 0.25rem) !important;
                }
             }
           }
          }

          &.form-list-item-action {
            padding: ${asRem(12)} 0 0 ${asRem(12)};
            > div {
              display: inline-flex;
              button {
                padding: 0;
                color: var(--color-label);
                svg {
                  width: ${asRem(16)};
                  height: ${asRem(16)};
                }
              }
            }
          }
        }
      }
    }
  }

  .form-list-action {
    max-width: calc(100% - 20px);
    margin-top: ${asRem(8)};
    button {
      width: 100%;
      color: var(--color-secondary-cta);
      border-radius: ${asRem(8)};
      border: 1px solid var(--color-border-1);
      background: transparent;
    }
  }
 
`;

export function MatchSessions() {
  return (
    <MatchSessionWrapper>
      <AntEditor
        resourceDef={MatchResource}
        readAction={MatchResource.queries.read}
        updateAction={MatchResource.mutations.updateSession}
        setSecondaryMenu={secondaryMenuItems}
        layout={FormScreenLayoutStyle.Two}
        showHistory
        showReviewIcons
        renderFields={({
          values,
          setFieldValue,
          queryResponse,
        }) => (
          <div>
            <GridFormItem
              name="sessions.sessions"
              label="Sessions"
              showIndex={false}
              colgroupStyle="colgroup"
              labels={[
                { key: 'day', name: 'Day' },
                { key: 'session', name: 'Session' },
                { key: 'innings', name: 'Innings' },
                { key: 'first_over_str', name: 'FirstBall' },
                { key: 'last_over_str', name: 'LastBall' },
                { key: 'session_start_day_remaining_overs_est', name: 'Remaining Overs' },
              ]}
              renderItem={(props) => (
                <MatchSessionUpdate
                  {...props}
                  values={values}
                  setFieldValue={setFieldValue}
                  queryResponse={queryResponse}
                />
              )}
              buildDefaultValue={() => ({
                day: null,
                session: null,
                innings: null,
                first_over_str: null,
                last_over_str: null,
                session_start_day_remaining_overs_est: null,
              })}
            />
          </div>
        )}
        versionKey="versions"
        isErrorMode={() => {
          return true;
        }}
      />
    </MatchSessionWrapper>
  );
}

export default MatchSessions;
