import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as DefaultAccountImage } from 'assets/icons/account-image.svg';
import { Label10Medium, Text14Regular } from 'style/typography';
import { SlideUpKF, BlinkKF } from 'style/keyframes';
import { asRem } from 'lib/css';
import { Config } from 'config';

const ImageViewWrapper = styled.div`
--image-width: ${asRem(22)};
--status-width: ${asRem(8)};
--image-border-width: ${asRem(2)};
--image-border-radius: 50%;
--image-color: var(--color-image-placeholder-bg);

&.size-16 {
  --image-width: ${asRem(16)};
}

&.size-20 {
  --image-width: ${asRem(20)};
}

&.size-24 {
  --image-width: ${asRem(24)};
}

&.size-28 {
  --image-width: ${asRem(28)};
}

&.size-32 {
  --image-width: ${asRem(32)};
}

&.size-34 {
  --image-width: ${asRem(34)};
}

&.size-48 {
  --image-width: ${asRem(48)};
}

&.size-40 {
  --image-width: ${asRem(40)};
}

&.size-36 {
  --image-width: ${asRem(36)};
}

&.size-128 {
  --image-width: ${asRem(128)};
}

&.size-40 {
  --image-width: ${asRem(40)};
}

&.size-56 {
  --image-width: ${asRem(56)};
}

&.size-60 {
  --image-width: ${asRem(60)};
}

&.size-64 {
  --image-width: ${asRem(64)};
}

&.size-65 {
  --image-width: ${asRem(65)};
}

&.size-80 {
  --image-width: ${asRem(80)};
}

max-width: var(--image-width);
max-height: var(--image-width);
overflow: hidden;
transition: all 0.5s ease-out;
position: relative;

color: var(--color-image-placeholder-text);
text-align: center;
font-weight: 500;
font-size: calc(var(--image-width) * 0.55);
line-height: var(--image-width);
display: flex;
align-items: center;

.image {
  opacity: 0;
}

.image, .default-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-image {
  width: var(--image-width);
  height: var(--image-width);
}

span {
  display: inline-block;
  line-height: var(--image-width);
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: var(--image-color);
}

&.rounded {
  span, img {
    border-radius: var(--image-border-radius);
  }
}

&.with-circle {
  border: 1px solid var(--image-color);
  --new-width: calc(var(--image-width) - ((var(--image-border-width) + 1px) * 2));
  border-radius: var(--image-border-radius);
  img, span {
    border: var(--image-border-width) solid var(--color-primary-bg);
    border-radius: var(--image-border-radius);
  }
  img {
    /* height: var(--new-width); */
  }
  span {
    line-height: var(--new-width);
  }
}

&.image-loaded {
  span {
    opacity: 0;
  }
  .image {
    opacity: 1;
  }
}

&.blink {
  animation: ${BlinkKF} 1s infinite;
}

&.use-render {
  background-color: transparent;
  span {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  .image {
    display: none;
  }
}


.status-active {
  position: absolute;
  right: 0;
  bottom: 0;
  width: var(--status-width);
  height: var(--status-width);
  border-radius: 50%;
  background: var(--color-active-status);
  border: ${asRem(1)} solid var(--color-border-1);
}

`;

export function ImageView({
  url,
  showInitial = false,
  defaultImageUrl = null,
  renderDefaultView = null,
  name = null,
  rounded = false,
  withCircle = false,
  withStatus = false,
  blink = false,
  size = null,
  className = null,
}) {
  let resolvedUrl = url;
  if (resolvedUrl && !resolvedUrl.startsWith('https:') && !resolvedUrl.startsWith('http:')) {
    resolvedUrl = `${Config.staticUrl}${url}`;
  }

  const [imageLoad, setImageLoad] = useState(false);
  const classes = ['rz-image-view'];

  if (rounded) classes.push('rounded');
  if (withCircle) classes.push('with-circle');
  if (size) classes.push(`size-${size}`);
  if (imageLoad) classes.push('image-loaded');
  if (!imageLoad && renderDefaultView) classes.push('use-render');
  if (blink) classes.push('blink');
  if (className) classes.push(className);

  return (
    <ImageViewWrapper className={classes.join(' ')}>
      {showInitial && (
        <span>{(name ?? 'x')[0].toUpperCase()}</span>
      )}
      <img
        className="image"
        src={resolvedUrl}
        alt={name}
        onError={() => setImageLoad(false)}
        onLoad={() => setImageLoad(true)}
      />
      {!imageLoad && defaultImageUrl && (
        <img
          className="default-image"
          src={defaultImageUrl}
          alt={name}
        />
      )}
      {!imageLoad && renderDefaultView && renderDefaultView()}
      {withStatus && (
        <div className="status-active" />
      )}
    </ImageViewWrapper>
  );
}

ImageView.propTypes = {
  url: PropTypes.string,
  defaultImageUrl: PropTypes.string,
  renderDefaultView: PropTypes.func,
  name: PropTypes.string,
  showInitial: PropTypes.bool,
  rounded: PropTypes.bool,
  withCircle: PropTypes.bool,
  withStatus: PropTypes.bool,
  blink: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

const AccountImageViewWrapper = styled.div`
.rz-image-view {
  span, img {
    border-radius: ${asRem(4)};
  }
  span {
    background-color: var(--image-color);
  }
}
`;

export function AccountImageView({ account, ...imageProps }) {
  return (
    <AccountImageViewWrapper>
      <ImageView
        url={account?.profile?.picture_url}
        renderDefaultView={() => (<DefaultAccountImage className="default-image" />)}
        name={account?.profile?.name}
        showInitial
        rounded
        {...imageProps}
      />
    </AccountImageViewWrapper>
  );
}

AccountImageView.propTypes = {
  account: PropTypes.object,
  ...ImageView.propTypes,
};

const UserImageViewWrapper = styled.div`
.rz-image-view {
  --image-color: var(--color-button-primary-bg);
  span {
    background-color: var(--image-color);
  }
}
`;

export function UserImageView({
  user,
  withStatus,
  connectionStatus,
  ...imageProps
}) {
  return (
    <UserImageViewWrapper>
      <ImageView
        url={user?.profile?.picture_url}
        name={user?.profile?.first_name}
        showInitial
        rounded
        size="size-24"
        withStatus={withStatus && connectionStatus === 'Connected'}
        {...imageProps}
      />
    </UserImageViewWrapper>
  );
}

UserImageView.propTypes = {
  user: PropTypes.object,
  withStatus: PropTypes.bool,
  connectionStatus: PropTypes.string,
  ...ImageView.propTypes,
};

const ResourceImageWrapper = styled.div`
height: 100%;
.rz-image-view {
  height: var(--image-width);
  --image-color: var(--color-button-primary-bg);
  img {
    vertical-align: middle;
  }
}
&.with-name {
  display: flex;
  gap: ${asRem(8)};
  align-items: center;
}
`;

export function ResourceImageView({
  withName = false,
  name,
  size,
  rounded = false,
  ...imageProps
}) {
  return (
    <ResourceImageWrapper
      className={`resource-image-view ${imageProps.className} ${withName ? 'with-name' : ''}`}
    >
      <ImageView
        showInitial
        rounded={rounded}
        size={size || '24'}
        name={name}
        {...imageProps}
      />
      {withName && (
        <Text14Regular>{name}</Text14Regular>
      )}
    </ResourceImageWrapper>
  );
}

ResourceImageView.propTypes = {
  resource: PropTypes.object,
  withName: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.string,
  ...ImageView.propTypes,
};
const ActiveUserViewWrapper = styled.div`

--ac-image-color: var(--color-image-placeholder-bg);

&.au-color-1 { --ac-image-color: var(--color-au-1); }
&.au-color-2 { --ac-image-color: var(--color-au-2); }
&.au-color-3 { --ac-image-color: var(--color-au-3); }
&.au-color-4 { --ac-image-color: var(--color-au-4); }


.rz-image-view {
  --image-color: var(--ac-image-color);
}

.name {
  display: block;
  text-align: center;
  background-color: var(--color-primary-bg);
  color: var(--color-text-1);
  /* background-color: blue; */
  padding: ${asRem(2)} ${asRem(4)};
  border-radius: ${asRem(4)};
}

&.as-tooltip {
  position: relative;
  cursor: pointer;

  .name {
    position: absolute;
    bottom: ${asRem(-5)};
    left: 0;
    right: 0;
    opacity: 0;
  }

  &:hover {
    .name {
      opacity: 1;
      animation: ${SlideUpKF} 1s linear;
    }
  }
}
`;

export function ActiveUserView({
  activeUser,
  colorClass,
  size,
  nameAsTooltip = true,
  showName = true,
  ...imageProps
}) {
  const classes = ['active-users'];
  if (nameAsTooltip) classes.push('as-tooltip');
  if (colorClass) classes.push(colorClass);
  if (!activeUser.user) {
    console.log('User missing', activeUser);
    return null;
    // return (
    //   <ActiveUserViewWrapper className={classes.join(' ')}>
    //     X
    //   </ActiveUserViewWrapper>
    // );
  }

  return (
    <ActiveUserViewWrapper className={classes.join(' ')}>
      <UserImageView
        user={{
          profile: activeUser.user,
        }}
        size={size || '34'}
        withCircle
        {...imageProps}
      />
      {showName && <Label10Medium className="name">{activeUser.user.first_name}</Label10Medium> }
    </ActiveUserViewWrapper>
  );
}

ActiveUserView.propTypes = {
  activeUser: PropTypes.object.isRequired,
  size: PropTypes.string,
  colorClass: PropTypes.string,
  nameAsTooltip: PropTypes.bool,
  ...UserImageView.propTypes,
};
