import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import PasswordChanged from './PasswordChanged';

const UserSettings = lazy(() => import('./UserSettings'));
const Invites = lazy(() => import('./AccountInvites'));
const ResetPassword = lazy(() => import('./ResetPassword'));

export function UserSettingsRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          path={Pages.user.dashboard.meta.path}
          element={<UserSettings />}
        />
        <Route
          path={Pages.user.invites.meta.path}
          element={<Invites />}
        />
        <Route
          path={Pages.user.forgotpassword.meta.path}
          element={<ResetPassword />}
        />
        <Route
          path={Pages.user.passwordChanged.meta.path}
          element={<PasswordChanged />}
        />
      </Routes>
    </Suspense>
  );
}
