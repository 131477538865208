import React from 'react';
import * as Yup from 'yup';
import {
  Resource, ColumnSet, StringFieldMinMax, QueryAction, MutationAction,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { isDeletedValue } from 'core/Form';
import {
  PlayerRankingReadQuery,
  PlayerRankingAutoFillQuery,
  PlayerRankingCreateMutation,
  PlayerRankingSearchQuery,
  PlayerRankingDeleteMutation,
  PlayerRankingUpdateMutation,
} from './query';
import { RankingImageView } from '../RankingImageView';

function PlayerRankValidator() {
  const validateRank = (value, context) => {
    const players = objectAsArray(value);
    if (players) {
      for (let i = 0; i < players.length; i += 1) {
        if (!isDeletedValue(players[i])) {
          if (!players[i].rating || parseFloat(players[i].rating, 10) === 0) {
            return context.createError({ message: `Invalid value for rating on row ${i + 1}` });
          }
        }
      }
    }
    return true;
  };

  return Yup.mixed()
    .test(
      'player-rank-validator',
      validateRank,
    );
}

export const PlayerRankingResource = Resource({
  resourceId: 'cricketPlayerRanking',
  name: 'CricketPlayerRanking',
  storedId: 'CricketPlayerRanking',
  pageKey: 'sports.cricket.ranking',
  columnSets: [
    ColumnSet({
      name: 'name',
      shape: Yup.object().shape({
        name: StringFieldMinMax(2, 120).required,
      }),
      viewKey: 'name',
      historyKey: 'CricketPlayerRankingName',

    }),
    ColumnSet({
      name: 'rank',
      shape: Yup.object().shape({
        players: PlayerRankValidator(),
        players_count: Yup.number(),
      }),
      viewKey: 'rank',
      historyKey: 'CricketPlayerRankingRank',
    }),
    ColumnSet({
      name: 'status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(true),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
      viewKey: 'status',
      historyKey: 'CricketPlayerRankingStatus',
    }),
    ColumnSet({
      name: 'ComputedSearchables',
      shape: Yup.object().shape({
        association_hashkey: Yup.string(),
      }),
      viewKey: 'computed_searchables',
      historyKey: 'CricketPlayerRankingComputedSearchables',
    }),

  ],
  renderImage: (item) => (<RankingImageView item={item} />),
  listPrimaryActions: [
    { action: 'updatePlayer', name: 'Update' },
  ],
  queries: {
    read: QueryAction({
      query: PlayerRankingReadQuery,
      resourceNamePath: 'item.name.name',
      responsePath: 'sports_cricket_player_rankings_read',
    }),
    search: QueryAction({
      query: PlayerRankingSearchQuery,
      resourceNamePath: 'item.name.name',
      resourcePath: 'resource.hashkey',
      responsePath: 'sports_cricket_player_rankings_search',
    }),
    autoFill: QueryAction({
      query: PlayerRankingAutoFillQuery,
      resourceNamePath: 'item.name.name',
      resourcePath: 'resource.hashkey',
      responsePath: 'sports_cricket_player_rankings_auto_fill',
    }),
  },
  mutations: {
    update: MutationAction({
      mutation: PlayerRankingUpdateMutation,
      responsePath: 'sports_cricket_player_rankings_update',
      cs: ['rank'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.rank.players = arrayAsObject(resp?.rank?.players);
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.rank.players = objectAsArray(resp?.rank?.players);
        const playersArray = [];
        resp.rank.players.forEach((player) => {
          playersArray.push({
            player: {
              _hashkey: player?.player?.hashkey,
            },
            name: player.name,
            rating: parseFloat(player.rating, 2),
          });
        });
        resp.rank.players = playersArray;
        resp.rank.players_count = playersArray.length;
        return resp;
      },
    }),
    create: MutationAction({
      mutation: PlayerRankingCreateMutation,
      responsePath: 'sports_cricket_player_rankings_create',
    }),
    delete: MutationAction({
      mutation: PlayerRankingDeleteMutation,
      responsePath: 'sports_cricket_player_rankings_delete',
    }),
  },
});

export default PlayerRankingResource;
