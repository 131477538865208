import React from 'react';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import PropTypes from 'prop-types';

const LoaderWrapper = styled.div`
  width: ${(props) => props.width || asRem(24)};
  height: ${(props) => props.height || asRem(24)};
  display: flex;
  align-items: center;
  justify-content: center;

  >div{
    border: 2px solid var(--color-button-primary-bg); /* Light grey */
    border-top: 2px solid rgb(17 22 27 / 100%); /* Blue */
    border-radius: 50%;
    width: ${(props) => props.width / 2 || asRem(12)};
    height: ${(props) => props.height / 2 || asRem(12)};
    animation: spin 2s linear infinite;
  }
 
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

function Loader({
  height,
  width,
}) {
  return (
    <LoaderWrapper height={height} width={width}>
      <div />
    </LoaderWrapper>
  );
}

export default Loader;

Loader.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
