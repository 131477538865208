import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import RoanuzLogo from 'assets/landing-page/RoanuzLogo.png';
import Member1 from 'assets/landing-page/Member1.png';
import Member2 from 'assets/landing-page/Member2.png';
import Member3 from 'assets/landing-page/Member3.png';
import Member4 from 'assets/landing-page/Member4.png';
import FeaturesImage1 from 'assets/landing-page/features-img-1.png';
import FeaturesImage2 from 'assets/landing-page/features-img-2.png';
import FeaturesImage3 from 'assets/landing-page/features-img-3.png';
import FeaturesImage4 from 'assets/landing-page/features-img-4.png';
import FeaturesImage5 from 'assets/landing-page/features-img-5.png';
import FeaturesImage6 from 'assets/landing-page/features-img-6.png';
import LiveCollaborators from 'assets/landing-page/collaborators.png';
import { ReactComponent as InstaIcon } from 'assets/landing-page/insta.svg';
import { ReactComponent as FbIcon } from 'assets/landing-page/fb.svg';
import { ReactComponent as LinkedInIcon } from 'assets/landing-page/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/landing-page/twitter.svg';
import { ReactComponent as BurgerMenu } from 'assets/icons/icon-menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';
import Version from 'assets/landing-page/version.png';
import { Link } from 'react-router-dom';
import { asRem, Breakpoint } from 'lib/css';
import PropTypes from 'prop-types';
import MG100Editor from 'assets/landing-page/editor.png';
import ReactMarkdown from 'react-markdown';
import { UserContext } from 'core/UserContext';
import { Display18Bold } from 'style/typography';
import { Config } from 'config';
import RawData from './LandingPageContent.json';

export function FeaturesImage({ keyVal }) {
  switch (keyVal) {
    case 0:
      return (
        <img src={FeaturesImage1} alt="feature" />
      );
    case 1:
      return (
        <img src={FeaturesImage2} alt="feature" />
      );
    case 2:
      return (
        <img src={FeaturesImage3} alt="feature" />
      );
    case 3:
      return (
        <img src={FeaturesImage4} alt="feature" />
      );
    case 4:
      return (
        <img src={FeaturesImage5} alt="feature" />
      );
    default:
      return (
        <img src={FeaturesImage6} alt="feature" />
      );
  }
}

FeaturesImage.propTypes = {
  keyVal: PropTypes.string,
};

const LandingPageWrapper = styled.div`
  /* common  */
  --color-button-bg: #D2FD49;
  --color-link: #75DBFF;
  --color-highlight: #FF683F;
  --color-white: #ffffff;
  --color-member1: #EC2761;
  --color-member2: #75DBFF;
  --color-member3: #087756;

  button {
    font-size: ${asRem(14)};
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
    color: var(--color-link);
    cursor: pointer;
    transition: 0.2s linear;

    &:hover {
      color: var(--color-button-bg);
    }
    &.plain {
      text-decoration: none;
    }
  }

  .highlight {
    color: #A58EFF;
    display: block;
  }
  /* common  */

  background-color: #030410;
  color: var(--color-white);
  font-family: 'DM Sans', sans-serif;
  overflow: hidden;

  .nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${asRem(10)};
    background: none;
    padding: 0;
    .nav-logo-img {
      background-color: var(--color-white);
      border-radius: 50%;
      width: ${asRem(42)};
      height: ${asRem(42)};
      color: var(--color-text-1);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: ${asRem(22)};
      }
    }
    
    .nav-logo-title {
      font-family: 'Archivo', sans-serif;
      color: var(--color-text-1);
    }
  }

  .nav-bar {
    .text-14-bold {
      padding: ${asRem(8)} ${asRem(12)};
      background: #CC2DA9;
      text-align: center;
    }
    .wrapper {
      max-width: 100%;
      margin: 0 auto;
      padding: ${asRem(15)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3 linear;

      @media screen and (min-width: ${Breakpoint.sm}) {
        max-width: ${asRem(650)};
        padding: ${asRem(10)} ${asRem(15)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        max-width: ${asRem(1200)};
      }
    }

    .website-btn {
      color: var(--color-website-btn-text);
      transition: color 0.3s linear, background 0.3s linear;

      &:hover {
        color: var(--color-website-btn);
      }
    }

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      background-color: rgb(3 4 16 / 80%);
      z-index: 1000;
    }

    .nav-links {
      &.desktop-view {
        display: none;
        @media screen and (min-width: ${Breakpoint.sm}) {
          display: flex;
          align-items: center;
          gap: ${asRem(28)};
        }
      }
      .cricketapi-link {
        color: var(--color-link);
      }
      &.mobile-view {
        display: flex;
        @media screen and (min-width: ${Breakpoint.sm}) {
          display: none;
        }

        .dropdown-content {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: ${asRem(20)} ${asRem(20)} 0;
          background: #000000;
          z-index: 10001;
          display: flex;
          flex-direction: column;
          transition: opacity 0.3s linear;

          &.add-animation {
            opacity: 1;
            height: 100%;
            animation: fadein 0.3s linear;
            @keyframes fadeIn {
              0 {
                opacity: 0;
                height: 0;
              }
              100% {
                opacity: 1;
                height: 100%;
              }
            }
          }
          &.remove-animation {
            opacity: 0;
            height: 0;
            animation: fadein 0.3s linear;
            @keyframes fadeIn {
              0 {
                opacity: 1;
                height: 100%;
              }
              100% {
                opacity: 0;
                height: 0;
              }
            }
          }

          > button {
            display: flex;
            justify-content: end;
          }

          .links-wrapper {
            display: flex;
            gap: ${asRem(12)};
            align-items: start;
            flex-direction: column;
          }
        }
        
      }
    }
  }

  .header {
    text-align: center;
    margin: ${asRem(40)} auto ${asRem(80)};
    max-width: ${asRem(320)};
    @media screen and (min-width: ${Breakpoint.sm}) {
      margin: ${asRem(100)} auto ${asRem(80)};
      max-width: ${asRem(900)};
    }
    .header-top {
      position: relative;
      display: inline-block;
      .header-title {
        font-family: 'Archivo', sans-serif;
        font-weight: 900;
        font-size: ${asRem(40)};
        line-height: ${asRem(50)};
        @media screen and (min-width: ${asRem(768)}) {
          font-size: ${asRem(60)};
          line-height: ${asRem(72)};
        }
        @media screen and (min-width: ${asRem(992)}) {
          font-size: ${asRem(120)};
          line-height: ${asRem(131)};
        }
      }
      .member-list {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: none;
        
        & > div {
          &:nth-child(3n + 1) {
            .member-img {
              border-color: var(--color-member1);
            }
            .member-game span {
              background-color: var(--color-member1);
            }
          } 
          &:nth-child(2) {
            color: #11161B;
            .member-img {
              border-color: var(--color-member2);
            }
            .member-game span {
              background-color: var(--color-member2);
            }
          } 
          &:nth-child(3) {
            .member-img {
              border-color: var(--color-member3);
            }
            .member-game span {
              background-color: var(--color-member3);
            }
          } 
        }
        .member-container {
          position: absolute;
          display: flex;
          gap: ${asRem(12)};
          align-items: center;
          .member-detail {
            text-align: left;
            position: relative;
            overflow: hidden;
            height: ${asRem(32)};
            .member-name {
              font-size: ${asRem(14)};
              line-height: ${asRem(18)};
            }
            .member-game {
              font-size: ${asRem(12)};
              line-height: ${asRem(16)};
              &.game-change {
                animation: none;
              }
              span {
                display: inline-block;
                padding: ${asRem(8)};
                border-radius: ${asRem(8)};
                max-width: ${asRem(160)};
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .member-img {
            width: ${asRem(56)};
            height: ${asRem(56)};
            border-radius: 50%;
            background-color: #161C23;
            border: ${asRem(1)} solid var(--color-link);
            padding: ${asRem(6)};
            box-sizing: border-box;
            img {
              width: 100%;
            }
          }
          &:nth-child(3n + 1) {
            flex-direction: row-reverse;
            .member-detail {
              text-align: right;
            }
          }
        }
      }

      /* Animation Starts  */
      .member-list {
        @media screen and (min-width: ${asRem(1194)}) {
          display: block;
        }
        > div {
          opacity: 0;
          transform: scale(0.5);
          transform-origin: center;
      
          &:nth-child(1) {
            animation: fadeIn 0.5s 0.5s linear forwards, slideIn1 4s ease 1.5s forwards, fadeOut 0.5s ease 8s forwards;
            bottom: ${asRem(90)};
            left: ${asRem(-180)};
            .member-game {
              animation: slideUp 0.5s ease 6s forwards;
            }
          }
          &:nth-child(2) {
            animation: fadeIn 0.5s 0.5s linear forwards, slideIn2 8s ease 3s forwards;
            top: ${asRem(100)};
            right: ${asRem(260)};
            .member-game {
              animation: slideUp 0.5s ease 11.5s forwards;
            }
          }
          &:nth-child(3) {
            animation: fadeIn 0.5s 0.5s linear forwards, slideIn3 3s ease 2.5s forwards;
            bottom: ${asRem(80)};
            right: ${asRem(-150)};
          }
          &:nth-child(4) {
            animation: fadeIn 0.3s 9.5s linear forwards;
            top: 0;
            left: ${asRem(70)};
          }
          @keyframes fadeIn {
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
          @keyframes fadeOut {
            100% {
              opacity: 0;
              transform: scale(0.5) translate(${asRem(30)}, ${asRem(79)});
            }
          }
          @keyframes slideUp {
            100% {
              opacity: 0;
              margin-top: -${asRem(35)};
            }
          }
          @keyframes slideIn1 {
            40% {
              transform: translate(${asRem(320)}, ${asRem(100)});
            }
            80% {
              transform: translate(${asRem(320)}, ${asRem(100)});
            }
            100% {
              transform: translate(${asRem(30)}, ${asRem(79)});
            }
          }
          @keyframes slideIn2 {
            10% {
              transform: translate(${asRem(160)}, -${asRem(100)});
            }
            90% {
              transform: translate(${asRem(160)}, -${asRem(100)});
            }
            100% {
              transform: translate(${asRem(230)}, ${asRem(20)});
            }
          }
          @keyframes slideIn3 {
            35% {
              transform: translate(-${asRem(250)}, ${asRem(70)});
            }
            100% {
              transform: translate(-${asRem(250)}, ${asRem(70)});
            }
          }

          &:nth-child(1) .member-game {
            margin-left: auto;
          }
        }
      }
      /* Animation Ends  */
    }

    .header-subtitle {
      font-weight: 400;
      font-size: ${asRem(18)};
      line-height: 1.2;
      max-width: ${asRem(720)};
      margin: ${asRem(24)} auto;
      @media screen and (min-width: ${Breakpoint.sm}) {
        font-size: ${asRem(24)};
        line-height: ${asRem(31)};
        margin: ${asRem(32)} auto;
      }
    }

    > a {
      display: inline-block;
    }

    .header-btn {
      color: var(--color-website-btn-text);
      font-size: ${asRem(16)};
      transition: color 0.3s linear, background 0.3s linear;

      &:hover {
        color: var(--color-website-btn);
      }
    }
    
  }

  section {
    margin-bottom: ${asRem(80)};
    .wrapper {
      font-family: 'Hero New', hero-new, sans-serif;
      max-width: ${asRem(320)};
      margin: 0 auto;

      @media screen and (min-width: ${Breakpoint.sm}) {
        padding: 0;
        max-width: ${asRem(650)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        max-width: ${asRem(1200)};
      }
    }
    &.nav-bar {
      padding-bottom: 0;
    }
  }

  .section-0 {
    .wrapper {
      padding-top: 0;
      @media screen and (min-width: ${Breakpoint.sm}) {
        padding: ${asRem(60)} 0;
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        max-width: ${asRem(1200)};
      }
    }
    
  }

  .section-0, .section-1 {
    text-align: center;
    .title {
      font-size: ${asRem(24)};
      line-height: ${asRem(30)};
      padding-bottom: ${asRem(24)};
      font-weight: 600;
      max-width: ${asRem(676)};
      margin: 0 auto;

      @media screen and (min-width: ${Breakpoint.sm}) {
        font-size: ${asRem(30)};
        line-height: ${asRem(42)};
      }

      @media screen and (min-width: ${Breakpoint.md}) {
        font-size: ${asRem(60)};
        line-height: ${asRem(72)};
      }

  
      strong {
        color: #A58EFF;
      }
    }

    .content {
      font-size: ${asRem(16)};
      line-height: ${asRem(22)};
      color: var(--color-text-2);
      padding-bottom: ${asRem(20)};

      @media screen and (min-width: ${Breakpoint.sm}) {
        font-size: ${asRem(24)};
        line-height: ${asRem(31)};
      }

      @media screen and (min-width: ${Breakpoint.md}) {
        padding-bottom: ${asRem(40)};
      }
    }

    img {
      max-height: ${asRem(230)};
      @media screen and (min-width: ${Breakpoint.sm}) {
        max-height: ${asRem(450)};
      }
      @media screen and (min-width: ${Breakpoint.lg}) {
        max-height: ${asRem(840)};
      }
    }
  }

  .section-1 {
    text-align: center;

    .title {
      max-width: ${asRem(900)};
  
      strong {
        color: #A58EFF;
      }
    }

    .subtitle {
      color: #7F8184;
      font-family: 'DM Sans', sans-serif;
      font-size: ${asRem(16)};
      line-height: ${asRem(22)};
      padding-bottom: ${asRem(20)};

      @media screen and (min-width: ${Breakpoint.sm}) {
        font-size: ${asRem(24)};
        line-height: ${asRem(32)};
      }

      @media screen and (min-width: ${Breakpoint.md}) {
        padding-bottom: ${asRem(40)};
      }
    }

    .sub-content-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: ${asRem(24)};

      .block {
        flex: 0 0 ${asRem(300)};
        min-height: ${asRem(400)};
        background: linear-gradient(180deg, #171C22 0%, rgba(23, 28, 34, 0) 100%);
        border-radius: ${asRem(35)};
        padding: ${asRem(20)};
  
        @media screen and (min-width: ${Breakpoint.lg}) {
          flex: 0 0 ${asRem(383)};
          min-height: ${asRem(500)};
        }

        img {
          width: ${asRem(269)};
          margin-top: ${asRem(40)};
        }

        .max-width-265 {
          max-width: ${asRem(265)};
          margin: 0 auto;
        }

        &:nth-child(2) {
          img {
            width: ${asRem(262)};
            margin-bottom: ${asRem(25)};
          }
        }

        &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          padding: 0;
          padding-top: ${asRem(20)};
          img {
            padding-left: ${asRem(50)};
            width: 98%;
            padding-top: ${asRem(20)};
          }
        }

        &:nth-child(4) {
          .block-title {
            @media screen and (min-width: ${Breakpoint.lg}) {
              margin-top: ${asRem(35)};
            }
          }
        }
        &:nth-child(3) {
          .block-title {
            @media screen and (min-width: ${Breakpoint.sm}) {
              margin-top: ${asRem(20)};
            }
            
            @media screen and (min-width: ${Breakpoint.lg}) {
              margin-top: 0;
            }
          }
        }

        .block-title {
          font-size: ${asRem(18)};
          font-weight: 600;
          line-height: ${asRem(24)};
          color: #F5FF78;
          padding-bottom: ${asRem(24)};
          @media screen and (min-width: ${Breakpoint.sm}) {
            font-size: ${asRem(24)};
            line-height: ${asRem(27)};
          }
        }

        .block-content {
          color: #ddd;
          font-family: 'DM Sans', sans-serif;
          font-size: ${asRem(16)};
          line-height: ${asRem(22)};
          
          @media screen and (min-width: ${Breakpoint.sm}) {
            font-size: ${asRem(18)};
            line-height: ${asRem(24)};
          }
        }
      }
    }

  }

  .section-2 {
    .content {
      font-size: ${asRem(24)};
      line-height: ${asRem(32)};
      font-weight: 700;

      @media screen and (min-width: ${Breakpoint.sm}) {
        font-size: ${asRem(30)};
        line-height: ${asRem(42)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        font-size: ${asRem(64)};
        line-height: ${asRem(90)};
      }

      strong {
        &:nth-child(1) {
          color: #FFC145;
        }
        &:nth-child(2) {
          color: #FF9595;
        }
        &:nth-child(3) {
          color: #F5FF78;
        }
        &:nth-child(4) {
          color: #A58EFF;
        }
      }
    }
  }

  .section-4 {
    background: #D2FD49;
    padding: ${asRem(60)} 0;
    @media screen and (min-width: ${Breakpoint.md}) {
      padding: ${asRem(120)} 0;
    }
    .title {
      font-size: ${asRem(55)};
      line-height: 1.1;
      color: #37440B;
      font-weight: 800;
      opacity: 0.3;
      padding-bottom: ${asRem(20)};

      @media screen and (min-width: ${Breakpoint.sm}) {
        font-size: ${asRem(50)};
        line-height: ${asRem(56)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        font-size: ${asRem(120)};
        line-height: ${asRem(134)};
        padding-bottom: ${asRem(40)};
      }
    }

    .subtitle {
      font-size: ${asRem(18)};
      line-height: ${asRem(22)};
      color: var(--color-secondary-bg);
      padding-bottom: ${asRem(14)};

      @media screen and (min-width: ${Breakpoint.lg}) {
        font-size: ${asRem(24)};
        line-height: ${asRem(27)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        padding-bottom: ${asRem(40)};
      }
    }

    .content {
      font-size: ${asRem(18)};
      line-height: ${asRem(24)};
      font-weight: 900;
      color: var(--color-text-rev);
  
      @media screen and (min-width: ${Breakpoint.md}) {
        font-size: ${asRem(30)};
        line-height: ${asRem(42)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        font-size: ${asRem(75)};
        line-height: ${asRem(98)};
      }
    }
  }
  
  .section-3 {
    .wrapper {
      display: block;

      @media screen and (min-width: ${Breakpoint.sm}) {
        display: flex;
        gap: ${asRem(24)};
      }

      .special-features {
        background: #171C22;
        min-height: ${asRem(420)};
        border-radius: ${asRem(50)};
        padding-bottom: ${asRem(24)};
        text-align: center;
        margin-bottom: ${asRem(30)};

        @media screen and (min-width: ${Breakpoint.md}) {
          min-height: ${asRem(430)};
          margin-bottom: 0;
        }

        @media screen and (min-width: ${Breakpoint.lg}) {
          min-height: ${asRem(667)};
        }

        .title {
          font-weight: 700;
          font-size: ${asRem(18)};
          line-height: ${asRem(22)};
          padding-bottom: ${asRem(24)};
          @media screen and (min-width: ${Breakpoint.lg}) {
            font-size: ${asRem(40)};
            line-height: ${asRem(44)};
          }
          strong {
            color: #F5FF78;
          }
        }

        .content {
          font-size: ${asRem(16)};
          line-height: ${asRem(20)};
          font-family: 'DM Sans', sans-serif;
          color: #7F8184;
          @media screen and (min-width: ${Breakpoint.lg}) {
            font-size: ${asRem(24)};
            line-height: ${asRem(31)};
          }
        }

        &:first-child {
          width: 100%;
          background-image: url(${LiveCollaborators});
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: top;

          @media screen and (min-width: ${Breakpoint.sm}) {
            flex: 0 0 60%;
          }

          @media screen and (min-width: ${Breakpoint.lg}) {
            flex: 0 0 ${asRem(723)};
          }

          > div {
            padding: ${asRem(180)} ${asRem(30)} ${asRem(30)};
            @media screen and (min-width: ${Breakpoint.sm}) {
              padding: ${asRem(180)} ${asRem(40)} ${asRem(40)};
            }
            @media screen and (min-width: ${Breakpoint.lg}) {
              padding: ${asRem(300)} ${asRem(80)} ${asRem(80)};
            }
          }
        }
        &:last-child {
          width: 95%;
          background-size: 100%;
          background-image: url(${Version});
          background-repeat: no-repeat;
          background-position: top 0 left ${asRem(20)};

          @media screen and (min-width: ${Breakpoint.sm}) {
            flex: 0 0 40%;
          }

          @media screen and (min-width: ${Breakpoint.lg}) {
            flex: 0 0 ${asRem(452)};
            background-position: top 0 left ${asRem(40)};
          }

          > div {
            padding: ${asRem(200)} ${asRem(30)} ${asRem(30)};
            @media screen and (min-width: ${Breakpoint.sm}) {
              padding: ${asRem(180)} ${asRem(40)} ${asRem(40)};
            }
            @media screen and (min-width: ${Breakpoint.lg}) {
              padding: ${asRem(322)} ${asRem(40)} ${asRem(40)};
            }
          }

          .title {
            strong {
              color: #A58EFF;
            }
          }
        }
      }
    }
  }

  section {
    &.footer {
      padding: ${asRem(30)};
      background: #030410;
      @media screen and (min-width: ${Breakpoint.sm}) {
        padding: ${asRem(60)};
      }

      @media screen and (min-width: ${Breakpoint.lg}) {
        padding: ${asRem(120)};
      }

      .footer-wrapper {
        max-width: ${asRem(320)};
        margin: 0 auto;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;

        @media screen and (min-width: ${Breakpoint.sm}) {
          max-width: ${asRem(650)};
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        @media screen and (min-width: ${Breakpoint.lg}) {
          max-width: ${asRem(1200)};
        }

        >div {
          display: flex;
          flex-direction: column;
          gap: ${asRem(15)};

          @media screen and (min-width: ${Breakpoint.sm}) {
            gap: ${asRem(30)};
          }

          @media screen and (min-width: ${Breakpoint.lg}) {
            gap: ${asRem(50)};
          }

          div {
            display: flex;
            gap: ${asRem(15)};
          }
        }

        .nav-logo {
          justify-content: start;
        }

        .social-media-links {
          gap: ${asRem(20)};
        }

        .links {
          div {
            justify-content: start;
            flex-direction: column;
            margin-top: ${asRem(20)};
            @media screen and (min-width: ${Breakpoint.sm}) {
              justify-content: end;
              flex-direction: row;
              margin-top: 0;
            }

            &.about-us {
              gap: ${asRem(20)};
              a {
                font-size: ${asRem(14)};
                line-height: ${asRem(16)};
                color: #F5FF78;

                @media screen and (min-width: ${Breakpoint.lg}) {
                  font-size: ${asRem(20)};
                  line-height: ${asRem(22)};
                }
              }
            }

            &.terms {
              a {
                font-size: ${asRem(14)};
                line-height: ${asRem(26)};
                color: var(--color-text-2);
              }
            }
          }
        }

        a {
          background: none;
          padding: 0;
          font-weight: normal;
        }
      }
    }
  }
`;

export function LandingPage() {
  const members = [
    {
      name: 'Abishek',
      game: 'Tamil Nadu Premier League 2022',
      newGame: 'Shriram Capital',
      img: Member3,
    },
    {
      name: 'Vishal',
      game: 'T20 world cup 2022',
      newGame: 'Tamil Nadu Premier League 2022',
      img: Member2,
    },
    {
      name: 'Sruthy',
      game: 'Shriram Capital TNPL 2022',
      newGame: 'ICC IPL 2022',
      img: Member1,
    },
    {
      name: 'Member 4',
      game: 'Shriram Capital TNPL 2022',
      img: Member4,
    },
  ];

  const [show, setShow] = useState(false);
  const userContext = useContext(UserContext);

  const toggleMenu = () => {
    setShow(!show);
  };

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const showLocalLogin = () => {
    userContext.setShowLogin(true);
  };

  return (
    <LandingPageWrapper>
      <section className={`nav-bar ${scrollY > 0 ? 'sticky' : ''}`}>
        {/* <Text14Bold>ANTS is in exclusive invite-only beta mode</Text14Bold> */}
        <div className="wrapper">
          <Link to="/" className="nav-logo">
            <div className="nav-logo-img">
              <img
                src={RoanuzLogo}
                alt="logo"
              />
            </div>
            <Display18Bold className="nav-logo-title">Roanuz Ants</Display18Bold>
          </Link>
          <div className="nav-links desktop-view">
            {/* <button className="plain" type="button" onClick={() =>
              navigate('/pricing')}>Pricing</button> */}
            <button className="plain cricketapi-link" type="button" onClick={() => showLocalLogin()}>Login</button>
            <a className="plain cricketapi-link" href={`${Config.loginURL}?show_ants_product=true&next_url=${encodeURIComponent(window.location.href)}`}>Login with CricketAPI</a>
            <a className="website-btn" href="mailto:support@cricketapi.com">Get Started</a>
          </div>
          <div className="nav-links mobile-view">
            {!show && (
              <button type="button" className="plain" onClick={() => toggleMenu()}>
                <BurgerMenu />
              </button>
            )}
            <div className={`dropdown-content ${show ? 'add-animation' : 'remove-animation'}`}>
              <button type="button" className="plain" onClick={() => toggleMenu()}>
                <CloseIcon />
              </button>
              <div className="links-wrapper">
                {/* <button className="plain" type="button" onClick={
                  () => navigate('/pricing')}>Pricing</button> */}
                <a className="plain cricketapi-link" href={`${Config.loginURL}?show_ants_product=true&next_url=${encodeURIComponent(window.location.href)}`}>Login</a>
                <a className="plain cricketapi-link" href={`${Config.loginURL}?show_ants_product=true&next_url=${encodeURIComponent(window.location.href)}`}>Login with CricketAPI</a>
                <a className="website-btn" href="mailto:support@cricketapi.com">Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="header">
        <div className="header-top">
          <p className="header-title">
            Real-time
            <span className="highlight">Collaborative</span>
            {' '}
            Sports Editor
          </p>
          <div className="member-list">
            {members.map((member) => (
              <div className="member-container">
                <img
                  src={member.img}
                  alt="Member Img"
                  className="member-img"
                />
                <p className="member-detail">
                  <p className="member-game">
                    <span>{member.game}</span>
                  </p>
                  {member.newGame && (
                    <p className="member-game game-change"><span>{member.newGame}</span></p>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="header-subtitle">Scorecard editor • Manage tournaments • Player profiles • Statistical Analysis • Match widgets</div>
        <a type="button" href="mailto:support@cricketapi.com" className="website-btn header-btn">Get Started</a>
      </section>
      {Object.values(RawData).map((content, key) => (
        <section className={`section-${key}`} key={content.key}>
          {typeof content === 'object' && !Array.isArray(content) ? (
            <div className="wrapper">
              {content.title && (
                <ReactMarkdown className="title">{content.title}</ReactMarkdown>
              )}
              {content.subtitle && (
                <ReactMarkdown className="subtitle">{content.subtitle}</ReactMarkdown>
              )}
              {content.content && !Array.isArray(content.content) && (
                <ReactMarkdown className="content">{content.content}</ReactMarkdown>
              )}
              {content.content && Array.isArray(content.content) && (
                <div className="sub-content-wrapper">
                  {content.content.map((item, id) => (
                    <div key={item.key} className="block">
                      <ReactMarkdown className="block-title max-width-265">{item.title}</ReactMarkdown>
                      <ReactMarkdown className="block-content max-width-265">{item.content}</ReactMarkdown>
                      {item.hasImage && (
                        <FeaturesImage keyVal={id} />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {content.hasImage && (
                <img
                  src={MG100Editor}
                  alt="Cricket Editor"
                />
              )}
            </div>
          ) : (
            <div className="wrapper">
              {content.map((item) => (
                <div className="special-features">
                  <div>
                    {item.title && (
                    <ReactMarkdown className="title">{item.title}</ReactMarkdown>
                    )}
                    {item.subtitle && (
                    <ReactMarkdown className="subtitle">{item.subtitle}</ReactMarkdown>
                    )}
                    {item.content && (
                    <ReactMarkdown className="content">{item.content}</ReactMarkdown>
                    )}
                  </div>

                </div>
              ))}
            </div>
          )}
        </section>
      ))}
      <section className="footer">
        <div className="footer-wrapper">
          <div className="logo">
            <Link to="/" className="nav-logo">
              <div className="nav-logo-img">
                <img
                  src={RoanuzLogo}
                  alt="logo"
                />
              </div>
              <Display18Bold className="nav-logo-title">Roanuz Ants</Display18Bold>
            </Link>
            <div className="social-media-links">
              <a href="https://www.instagram.com/roanuz/">
                <InstaIcon />
              </a>
              <a href="https://www.facebook.com/roanuz/">
                <FbIcon />
              </a>
              <a href="https://in.linkedin.com/company/roanuz">
                <LinkedInIcon />
              </a>
              <a href="https://twitter.com/theroanuz">
                <TwitterIcon />
              </a>
            </div>
          </div>
          <div className="links">
            <div className="about-us">
              <a href="https://roanuz.com/about-us/">
                About us
              </a>
              {/* <Link to="/pricing">
                Pricing
              </Link> */}
              <a href="https://roanuz.com/contact-us/">
                Contact us
              </a>
            </div>
            <div className="terms">
              <a href="https://www.cricketapi.com/legal/Terms-of-use/">
                Terms and conditions
              </a>
              <a href="https://www.cricketapi.com/legal/Privacy-Policy/">
                Privacy policy
              </a>
            </div>
          </div>
        </div>
      </section>
    </LandingPageWrapper>
  );
}
