import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const SourceAssociationListQuery = gql`
query  SourceAssociationListQuery {
  source_association_list {
    items {
      key
      name
    }
  }
}
`;

export const SourceAssociationTournamentQuery = gql`
query SourceAssociationTournamentQuery($association: String!, $yearMonth: String) {
  source_association_tournaments(association: $association, year_month: $yearMonth) {
    items {
      key
      name
      metric_group
    }
  }
}
`;

export const SourceTournamentFeaturedListQuery = gql`
query SourceTournamentFeaturedListQuery {
  source_featured_tournament_list {
    items {
     key
     name
     short_name
     association_key
     start_date
     start_month
     resource {
        account
        sport
        association
        competition
        key
        hashkey: _hashkey
      }
    }
  }
}
`;

export const SourceTournamentMatchesListQuery = gql`
query SourceTournamentMatchesListQuery($tournament: String!) {
  source_tournament_matches_list(tournament: $tournament) {
    items {
      resource {
        account
        sport
        key
        _hashkey
      }
      match {
        name
        start_at
        format
        status
        short_name
        teams
        sub_title
      }
      legacy_data {
        key
      }
    }
  }
}
`;

export const FantasyCricketReadQuery = gql`
query FantasyCricketReadQuery ($resource: FantasyMatchInput!,
  $withCreditsEditHistory: Boolean, $withRolesEditHistory: Boolean) {
  fantasy_cricket_read(resource: $resource,
    with_credits_edit_history : $withCreditsEditHistory, with_roles_edit_history: $withRolesEditHistory) {
      item {
      resource {
        account
        sport
        key
        _hashkey
      }
      match {
        name
        start_at
        format
        status
        short_name
        teams
      }
      cricket_players_credit {
        cricket_player_credit_item {
          player_key
          name
          team {
            code
          }
          credits
          credits_override
          role
        }
      }
      cricket_players_role {
        cricket_player_role_item {
          player_key
          name
          team {
            code
          }
          role
          role_override
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const FantasyCreditsUpdateMutation = gql`
mutation FantasyCreditsUpdateMutation(
  $playerCredits: FantasyCricketPlayersCreditInput!,
  $resource: FantasyMatchInput!,
  $version: RecordStage) {
  fantasy_cricket_update_credits(resource: $resource,
    player_credits: $playerCredits, version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const FantasyRolesUpdateMutation = gql`
mutation FantasyRolesUpdateMutation(
  $cricketPlayersRole: FantasyCricketPlayersRoleInput!,
  $resource: FantasyMatchInput!,
  $version: RecordStage) {
  fantasy_cricket_update_role(resource: $resource,
    cricket_players_role: $cricketPlayersRole, version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const FantasyResetCreditsMutation = gql`
mutation FantasyResetCreditsMutation(
  $resource: FantasyMatchInput!,
  $version: RecordStage) {
  fantasy_cricket_reset_credits(resource: $resource,
    version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const FantasyResetRolesMutation = gql`
mutation FantasyResetRolesMutation(
  $resource: FantasyMatchInput!,
  $version: RecordStage) {
  fantasy_cricket_reset_role(resource: $resource,
    version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;
