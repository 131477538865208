import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import {
  SlideUpAndFadeKF,
  SlideDownAndFadeKF,
  SlideRightAndFadeKF,
  SlideLeftAndFadeKF,
} from 'style/keyframes';
import { asRem } from 'lib/css';
import { Label12Regular } from 'style/typography';

const StyledTooltipRoot = styled(TooltipPrimitive.Root)`
  cursor: pointer;
`;

const StyledTooltipContent = styled(TooltipPrimitive.Content)`
  border-radius: ${asRem(6)};
  background: var(--color-border-1);
  color: var(--color-text-1);
  border: 1px solid var(--color-border-2);
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  user-select: none;
  z-index: 10001;

  will-change: transform, opacity;
  animation-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-fill-mode: 'forwards';

  >.content {
    padding: ${asRem(6)};
  }

  .tooltip-content-wrapper {
    padding: ${asRem(4)};
  }

  &[data-state="open"], &[data-state="delayed-open"] {
    animation-name: ${SlideUpAndFadeKF};
    &[data-side="top"] {
      animation-name: ${SlideDownAndFadeKF};
    }
    &[data-side="right"] {
      animation-name: ${SlideLeftAndFadeKF};
    }
    &[data-side="bottom"] {
      animation-name: ${SlideUpAndFadeKF};
    }
    &[data-side="left"] {
      animation-name: ${SlideRightAndFadeKF};
    }
  }
`;

const StyledTooltipArrow = styled(TooltipPrimitive.Arrow)`
  fill: var(--color-border-1);
`;

export function Tooltip({
  renderTrigger,
  renderContent,
  text,
  disableFocus = false,
  ...tooltipProps
}) {
  const tprops = {};
  if (disableFocus) {
    tprops.tabIndex = -1;
  }
  return (
    <TooltipPrimitive.Provider>
      <StyledTooltipRoot {...tooltipProps} delayDuration={0}>
        <TooltipPrimitive.Trigger asChild {...tprops}>
          {renderTrigger()}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <StyledTooltipContent>
            {renderContent
              ? renderContent()
              : (<div className="content"><Label12Regular>{text}</Label12Regular></div>)}
            <StyledTooltipArrow />
          </StyledTooltipContent>
        </TooltipPrimitive.Portal>
      </StyledTooltipRoot>
    </TooltipPrimitive.Provider>
  );
}

Tooltip.propTypes = {
  renderTrigger: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  text: PropTypes.string,
  disableFocus: PropTypes.bool,
};
