import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormItem } from 'core/FormFieldItem';
import {
  createDeletedValue,
  getDeletedValue,
  isDeletedValue,
  FormContext,
} from 'core/Form';
import { RealtimeEditorContext } from 'ants/RealtimeEditorContext';
import { dataFromPath, objectAsArray } from 'lib/utils';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';
import { ReactComponent as UndoIcon } from 'assets/icons/icon-restart.svg';
import { asRem } from 'lib/css';

const GridFormItemWrapper = styled.div`
  .form-list-item {
  }
  .form-list-action {
    margin-bottom: ${asRem(18)};
  }
  .form-list-item-action {
    button {
      display: block;
    }
  }
  table {
    tr {
      td {
        padding-bottom: ${asRem(10)};
        padding-right: ${asRem(10)};
        &.row-index {
          vertical-align: middle;
        }
      }
    }
  }
  .index {
    a {
      color: var(--color-text-1);
      font-weight: normal;
    }
  }
`;

export function GridFormItem({
  name,
  label = null,
  showRemove = true,
  showAddNew = true,
  showRenderItems = true,
  defaultNewValue = '',
  showRemoveIcon = true,
  showUndoIcon = true,
  labels,
  renderRowHead,
  renderItem = null,
  showIndex = true,
  showHeader = null,
  renderIndexItem = null,
  renderRemoveItem = null,
  renderAddButton = null,
  buildDefaultValue = null,
  renderRemoveFunc,
  ...formOptions
}) {
  const { values, setFieldValue } = useContext(FormContext);
  const { reviewMode } = useContext(RealtimeEditorContext);
  const value = dataFromPath(values, name) ?? {};
  const items = objectAsArray(value);
  const addItem = (defaultValue) => {
    let newDefaultValue = defaultValue ?? defaultNewValue;
    if (buildDefaultValue) {
      const valueItems = dataFromPath(values, name) ?? [];
      if (typeof valueItems === 'object') {
        newDefaultValue = buildDefaultValue(Object.values(valueItems).length);
      } else {
        newDefaultValue = buildDefaultValue(valueItems.length);
      }
    }
    if (newDefaultValue instanceof Date) {
      newDefaultValue = newDefaultValue.getTime();
    }

    setFieldValue(`${name}.${items.length}`, newDefaultValue);
  };

  const removeItem = (index) => {
    const liveItems = dataFromPath(values, name) ?? {};
    if (renderRemoveFunc) {
      renderRemoveFunc(liveItems[index]);
    }
    setFieldValue(`${name}.${index}`, createDeletedValue(liveItems[index]));
  };

  const undoRemoveItem = (index) => {
    const newValue = getDeletedValue(dataFromPath(values, `${name}.${index}`));
    setFieldValue(`${name}.${index}`, newValue);
    // Something weird unable to set empty value
    // if (newValue === '') {
    //   newValue = '';
    // }
    // setFieldValue(`${name}.${index}`, newValue);
  };

  const resolvedShowHeader = showHeader === null ? (labels.length > 1) : showHeader;

  return (
    <GridFormItemWrapper className={`form-list ${items.length > 0 ? 'form-items' : 'no-items'}`}>
      <table>

        <colgroup>
          {showIndex && <col className={formOptions.colgroupStyle} />}
          { labels?.length > 1 && labels.map(() => (
            <col />
          ))}
          {showRemove && !reviewMode && <col className={formOptions.colgroupStyle} />}
        </colgroup>
        {resolvedShowHeader && (
          <thead>
            {renderRowHead
              ? renderRowHead({ items })
              : (
                <tr>
                  {showIndex && (
                    <td>#</td>
                  )}
                  {labels.map((labelItem) => (
                    <td key={labelItem.key}>{labelItem.name}</td>
                  ))}
                  {showRemove && !reviewMode && (
                    <td />
                  )}
                </tr>
              )}
          </thead>
        )}
        <tbody>
          {items.map((item, index) => (
            <tr className="form-list-item">
              {showRenderItems && !labels && (
                <td>
                  {renderItem
                    ? renderItem({
                      formOptions,
                      name: `${name}.${index}`,
                      label: label ? `${label} ${index + 1}` : null,
                      index,
                      item,
                    })
                    : (
                      <FormItem
                        {...formOptions}
                        name={`${name}.${index}`}
                        label={label ? `${label} ${index + 1}` : null}
                        onelineStyle
                        showLabel={false}
                        className="form-list-item-input"
                      />
                    )}
                </td>
              )}
              {showIndex && (
                <td className="row-index">
                  {renderIndexItem
                    ? renderIndexItem({
                      formOptions,
                      index,
                      item,
                    })
                    : (
                      <div>
                        {index + 1}
                      </div>
                    )}
                </td>
              )}
              {showRenderItems && labels && labels.map((labelItem) => (
                <td key={labelItem.key}>
                  {renderItem
                    ? renderItem({
                      formOptions,
                      name: `${name}.${index}.${labelItem.key}`,
                      label: label ? `${label} ${index + 1} ${labelItem.name}` : null,
                      index,
                      item,
                      labelItem,
                    })
                    : (
                      <FormItem
                        {...formOptions}
                        name={`${name}.${index}.${labelItem.key}`}
                        label={label ? `${label} ${index + 1} ${labelItem.name}` : null}
                        onelineStyle
                        showLabel={false}
                        className="form-list-item-input"
                      />
                    )}
                </td>
              ))}
              {showRemove && !reviewMode && (
                <td className="form-list-item-action">
                  {renderRemoveItem
                    ? renderRemoveItem({
                      isDeleted: isDeletedValue(dataFromPath(values, `${name}.${index}`)),
                      undoRemoveItem,
                      removeItem,
                      index,
                    })
                    : (
                      <div>
                        {isDeletedValue(dataFromPath(values, `${name}.${index}`))
                          ? (
                            <button
                              type="button"
                              className={`plain small ${showUndoIcon ? '' : 'bordered'}`}
                              onClick={() => undoRemoveItem(index)}
                            >
                              {showUndoIcon ? <UndoIcon /> : 'Undo Remove'}
                            </button>
                          )
                          : (
                            <button
                              type="button"
                              className={`plain small ${showRemoveIcon ? '' : 'bordered'}`}
                              onClick={() => removeItem(index)}
                            >
                              {showRemoveIcon ? <CloseIcon /> : 'Remove'}
                            </button>
                          )}
                      </div>
                    )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {showAddNew && !reviewMode && (
        <div className="form-list-action">
          {renderAddButton
            ? renderAddButton({ addItem })
            : (
              <button
                type="button"
                className="style-3"
                onClick={() => addItem()}
              >
                Add
                {label ? ` new ${label}` : null}
              </button>
            )}
        </div>
      )}
    </GridFormItemWrapper>
  );
}

GridFormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  showRemove: PropTypes.bool,
  showAddNew: PropTypes.bool,
  showRenderItems: PropTypes.bool,
  renderItem: PropTypes.func,
  showIndex: PropTypes.bool,
  renderIndexItem: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  })),
  renderRowHead: PropTypes.func,
  showRemoveIcon: PropTypes.bool,
  showHeader: PropTypes.bool,
  showUndoIcon: PropTypes.bool,
  renderRemoveItem: PropTypes.func,
  renderAddButton: PropTypes.func,
  buildDefaultValue: PropTypes.func,
  colgroupStyle: PropTypes.string,
  renderRemoveFunc: PropTypes.func,
  ...FormItem.propTypes,
};
