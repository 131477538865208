import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';
import { TeamFields } from '../../team/query';

export const TournamentTeamReadQuery = gql`
query TournamentTeamReadQuery($resource: TournamentTeamInput!,
  $withHistory: Boolean,
) {
  sports_cricket_tournament_team_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        tournament
        team
        hashkey: _hashkey
      }
      team {
        captain {
          account
          sport
          key
          hashkey: _hashkey
        }
        name
        short_name
      }
      legacy_data {
        key
      }
      squad {
        squad {
          player {
            account
            sport
            key
            hashkey: _hashkey
          }
          playing_role
          name
          jersey_name
          state
          batting_style
          bowling_style {
            arm
            pace
            type
          }
          frequent_bowling_deliveries
          region {
            account
            key
            hashkey: _hashkey
          }
          date_of_birth
          legacy_data {
            key
          }
          legal_name
          image_url
          notes
          reason
          replaced_by {
            account
            sport
            key
            hashkey: _hashkey
          }
          gender
          alltime_roles
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const TournamentTeamUpdatePlayersMutation = gql`
mutation TournamentTeamUpdatePlayersMutation(
  $resource: TournamentTeamInput!,
  $squad: CricketTournamentTeamSquadInput!,
  $version: RecordStage) {
  sports_cricket_tournament_team_update_players(resource: $resource,
    squad: $squad, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentTeamAddPlayersMutation = gql`
mutation TournamentTeamAddPlayersMutation(
  $resource: TournamentTeamInput!,
  $newPlayers: [CricketTournamentTeamPlayerInput!]!) {
  sports_cricket_tournament_team_add_players(resource: $resource,
    new_players: $newPlayers) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentTeamEditPlayerMutation = gql`
mutation TournamentTeamEditPlayerMutation(
  $resource: TournamentTeamInput!,
  $player: CricketTournamentTeamPlayerInput!,
  $newPlayer: CricketTournamentTeamPlayerInput) {
  sports_cricket_tournament_team_edit_player(resource: $resource,
    player: $player, new_player: $newPlayer) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentTeamDeleteMutation = gql`
mutation TournamentTeamDeleteMutation(
  $resource: TournamentTeamInput!,
  $name: String!) {
  sports_cricket_tournament_team_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TeamFromTournamentTeamQuery = gql`
query TeamFromTournamentTeamQuery($resource: TournamentTeamInput!) {
  sports_cricket_tournament_team_from_team(resource: $resource) {
    item {
      ${TeamFields}
    }
  }
}
`;

export const ImportLegacyPlayerSearchQuery = gql`
query ImportLegacyPlayerSearchQuery($search: String!, $page: PagedRequestInput) {
  imports_legacy_player_search(search: $search, page: $page) {
    items {
      resource {
        account
        sport
        key
       hashkey: _hashkey
      }
      player {
        name
        gender
        jersey_name
        legal_name
        birth_place {
          account
          key
          _hashkey
        }
        date_of_birth
        other_names
      }
      cricket {
        playing_role
        alltime_roles
        batting_style
        bowling_style {
          arm
          pace
          type
        }
        recent_matches {
          key
          name
          start_date
        }
        recent_teams {
          key
          name
        }
      }
      meta_data {
        mark_as_imported
        ants_hashkey
      }
      legacy_data {
        key
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }

}
`;

export const ImportsLegacyPlayerImportMutation = gql`
mutation ImportsLegacyPlayerImportMutation(
$legacyImportKeys: [String!]!,
$playerKeys: [String!]!,
$addToTournamentTeam: Boolean,
$tournamentTeam: TournamentTeamInput!) {
  imports_legacy_player_import(
  legacy_import_keys: $legacyImportKeys,
  player_keys: $playerKeys,
  add_to_tournament_team: $addToTournamentTeam,
  tournament_team: $tournamentTeam) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;
