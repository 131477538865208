import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResourceImageView } from 'core/Image';

const StyledResourceImageView = styled(ResourceImageView)`
  .rz-image-view {
    --image-color: #FF95E8;
  }
`;

export function TournamentImageView({ item, size }) {
  return (
    <StyledResourceImageView
      name={item.resource_name ?? item.tournament?.name}
      url={item.image ? item.image.image_url : item.resource_image_url}
      size={size || 24}
    />
  );
}

TournamentImageView.propTypes = {
  item: PropTypes.object,
  size: PropTypes.string,
};
