import { gql } from '@apollo/client';

export const LoginMutation = gql`
mutation LoginMutation($email: String!, $password: String!, $nextUrl: String,) {
  user_login(email: $email, password: $password, next_url: $nextUrl) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    token
    require_change_password
    next_url
  }
}
`;

export const UserForgotPassWordMutation = gql`
mutation UserForgotPassWordMutation($email: String!, $next_password_url: String!){
  ants_acc_sports_account_user_forgot_password(email: $email, next_password_url: $next_password_url){
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const UserVerifyForgotPassword = gql`
mutation UserVerifyForgotPassword($email: String!, $code: String!, $password: String!){
  ants_acc_sports_account_user_verify_forgot_password(
      email: $email,
      code: $code,
      password: $password,
    ) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
}
`;

export const RegisterMutation = gql`
mutation RegisterMutation(
  $firstName: String!,
  $lastName: String,
  $email: String!,
  $password: String!,
  $useRandomPassword: Boolean,
  $rolesV2: [UserRole!],
  $invitedBy: UserInput,
  $nextUrl: String,
) {
  user_create(
    first_name: $firstName,
    last_name: $lastName,
    email: $email,
    password: $password,
    use_random_password: $useRandomPassword,
    role_v2: $rolev2,
    next_url: $nextUrl,
    invited_by: $invitedBy,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    user_key
    next_url
  }
}
`;

export const AccountsListQuery = gql`
query AccountsListQuery($pageKey: String){
  account_list(page_key: $pageKey) {
    accounts {
      resource { key, hashkey: _hashkey }
      profile { name, picture_url } 
      status {
        active
        segment { key }
      } 
    }
    segments {
      key
      segment { name, base_path, region, zone }
      config_overrides { raw }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const AccountsGetQuery = gql`
query AccountsGetQuery($key: String!){
  account_get(key: $key) {
    account {
      resource { key, hashkey: _hashkey }
      profile { name, picture_url } 
      status {
        active
        segment { key }
      } 
    }
  }
}
`;

export const AccountInviteUserMutation = gql`
mutation AccountInviteUserMutation($name: String!, $email: String!, $roleV2: Int) {
  account_invite_user(name: $name , email: $email, role_v2: $roleV2) {
    okay
    errors { msg, field_path, code }
    key
  }
}
`;

export const AccountInviteAcceptMutation = gql`
mutation AccountInviteAcceptMutation($account: String!) {
  account_invite_accept(account: $account) {
    okay
    errors { msg, field_path, code }
    key
  }
}
`;

export const AccountUninviteMutation = gql`
mutation AccountUninviteMutation($key: String!) {
  account_uninvite(key: $key) {
    okay
    errors { msg, field_path, code }
    key
  }
}
`;

export const AccountUpdateUserRoleMutation = gql`
mutation AccountUpdateUserRoleMutation($user: UserInput!, $roleV2: Int!) {
  account_update_user_role(user: $user, role_v2: $roleV2) {
    okay
    errors { msg, field_path, code }
    key
  }
}
`;

export const AccountRemoveUserMutation = gql`
mutation AccountRemoveUserMutation($user: UserInput!) {
  account_remove_user(user: $user) {
    okay
    errors { msg, field_path, code }
    key
  }
}
`;

export const AccountInvitesQuery = gql`
query AccountInvitesQuery($sort_by: [PagedRequestSortFieldInput!]){
  account_invites(sort_by: $sort_by) {
    accounts {
      resource { key, hashkey: _hashkey }
      profile { name, picture_url }
      status {
        active
        segment { key }
      }
    }
    page {
      next_page_key
      count
    }
  }
}
`;

export const AccountUsersQuery = gql`
query AccountUsersQuery($pageKey: String){
  account_users(page_key: $pageKey) {
    account_users {
      resource {
        account
        key
        hashkey: _hashkey
      }
      invitation {
        first_name
        email
        phone
        user {
          key
          hashkey: _hashkey
        }
        invite_key
        invited_by {
          key
          hashkey: _hashkey
        }
        invited_at
        role_v2
      }
      access {
        first_name
        user {
          key
          hashkey: _hashkey
        }
        role_v2
        joined
        joined_at
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const SportsAccountGet = gql`
query SportsAccountGet ($account: AccountInput!){
  ants_acc_sports_account_get(account: $account){
    sports_account {
      resource {
        key
        hashkey: _hashkey
      }
      profile {
        name
        picture_url
      }
      status {
        active
        segment {
          key
          hashkey: _hashkey
        }
      }
      config {
        allowed_apps
        primary_project_key
        primary_project_key_type
        preview_links {
          cricket
        }
        is_roanuz_mode
      }
    }
    segment {
      key
      segment {
        name
        base_path
        region
        zone
      }
      config_overrides {
        raw
      }
    }
  }
}
`;

export const AccountImageUploadMutation = gql`
mutation AccountImageUploadMutation($filename: String!) {
  filestore_account_picture(filename: $filename) {
    signed_data
    url
    url_suffix
  }
}
`;

export const UserImageUploadMutation = gql`
mutation UserImageUploadMutation($filename: String!) {
  filestore_user_picture(filename: $filename) {
    signed_data
    url
    url_suffix
  }
}
`;

export const SportsAccountUpdate = gql`
mutation SportsAccountUpdate($account: AccountInput!, $applicationAccess:  SportsAccountApplicationAccessInput!){
  ants_acc_sports_account_update_application_access(account: $account, application_access: $applicationAccess) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const AccountDeleteMutation = gql`
mutation AccountDeleteMutation($account: String!) {
  account_delete(account: $account) {
    status {
      okay
      errors { msg, field_path, code }
      key
    }
  }
}
`;

export const AccountSearchQuery = gql`
query AccountSearchQuery($search: String!, $page: PagedRequestInput ) {
  ants_acc_sports_account_search(search: $search, page: $page) {
    items {
      resource {
        key
        _hashkey
      }
      account {
        name
        created_by {
          key
          _hashkey
        }
      }
      status {
        active
        segment {
          key
          _hashkey
        }
      }
      config {
        allowed_apps
        primary_project_key
        primary_project_key_type
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const AnBusinessProductList = gql`
  query AnBusinessProductList($showActive: Boolean , $showVisible: Boolean, $useFresh: Boolean) {
    anbusiness_anbusiness_product_list(show_active: $showActive, show_visible: $showVisible, use_fresh: $useFresh ) {
      data {
      key
      name
      resource_key
      allowed_endpoints
      access_type
      common_name
      description
      price {
        billing_scheme
        unit_amount {
          inr
          eur
          usd
        }
        unit_label
        recurring {
          interval
          interval_count
        }
        price_by_bill_percentage
        unit_amount_display_precision {
          inr
          eur
          usd
        }
        volume_discounts_price_variation {
          min_quantity
          discount_percentage
          license_plan
          name
        }
        price_by_volume_discounts
      }
      type
      show_in_website
      show_resource_page
      early_bird_pricing_notes
      discount_percentage
      days_of_access
      show_early_bird_pricing
      active
      index
      is_ants_product
    }
    }
  }
`;

export const AccountProjectKey = gql`
mutation AccountProjectKey($account: AccountInput!, $project: SportsAccountProjectInput!) {
  ants_acc_sports_account_update_application_project_key(account: $account, project: $project) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const AccountPreviewLink = gql`
mutation AccountPreviewLink($account: AccountInput!, $sportsPreviewLink: SportsAccountSportsPreviewLinkInput!) {
  ants_acc_sports_account_update_application_sport_preview_link(account: $account, sports_preview_link: $sportsPreviewLink) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const GetPushConfiguration = gql`
query GetPushConfiguration($account: AccountInput!) {
  ants_acc_sports_account_push_config_get(account: $account) {
    push_config {
      resource {
        key
        hashkey: _hashkey
      }
      webhook_push_config {
        dev_url
        prod_url
      }
      firebase_push_config {
        dev {
          database_url
          push_auth_code
          service_data
        }
        prod {
          database_url
          push_auth_code
          service_data
        }
      }
    }
  }
}
`;

export const WebhookConfigUpdate = gql`
mutation WebhookConfigUpdate ($account: AccountInput!, $webhookPushConfig: SportsAccountWebHookPushConfigInput!) {
  ants_acc_sports_account_webhook_config_update(
    account: $account,
    webhook_push_config: $webhookPushConfig
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const FirebaseConfigUpdate = gql`
mutation FirebaseConfigUpdate ($account: AccountInput!, $firebasePushConfig: SportsAccountFireBasePushConfigInput!) {
  ants_acc_sports_account_firebase_config_update(
    account: $account,
    firebase_push_config: $firebasePushConfig
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const RoanuzModeMutation = gql`
mutation RoanuzModeMutation($account: AccountInput!, $adminSettings: SportsAccountAdminSettingsInput!){
  ants_acc_sports_account_update_admin_settings(
    account: $account,
    admin_settings: $adminSettings,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const AccountFeaturesMutation = gql`
  mutation  AccountFeaturesMutation(
    $account: AccountInput!,
    $account_feature: SportsAccountFeaturesInput!,

  ) {
    ants_acc_sports_account_update_features(
      account: $account,
      account_feature: $account_feature,
    ) {
        status {
          okay
          errors {
            msg
            field_path
            code
          }
          key
      }
    }
  }
`;

export const AccountFeaturesGetQuery = gql`
  query AccountFeaturesGetQuery(
    $account: AccountInput!,
  ) {
    ants_acc_sports_account_features_get(account: $account) {
      item {
      resource {
        key
        _hashkey
      }
      account_features {
        simulate_match
      }
    }
    }
  }
`;
