import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { UserContext } from 'core/UserContext';
import {
  createApolloCacheKey,
  dataFromPath,
  resourceDecodeHashkey,
  setValueOnPath,
} from 'lib/utils';
import { getResourceDef } from 'sports/resources';
import { getAccountKey, getToken } from 'core/localStore';
import { RecordStage, recordStageFromNumber } from './ants';
import { useSocket } from './socket';
import { getActiveUserClass } from './colorClass';

export function clearQueryCache(apolloClient, queryName) {
  apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: queryName });
}

export function clearResourceCache(apolloClient, resourceType, resourceKey) {
  if (resourceKey) {
    const apolloCacheKey = createApolloCacheKey(resourceType, resourceKey);
    console.log('rz/cache/Clearing Cache', apolloCacheKey);
    apolloClient.cache.modify({
      id: apolloCacheKey,
      fields(fieldValue, { DELETE }) {
        return DELETE;
      },
    });
  }

  // Refresh all resource type queries
  const rtDef = getResourceDef((resourceType));
  if (rtDef) {
    Object.keys(rtDef.queries).forEach((actionid) => {
      const query = rtDef.queries[actionid];
      const queryName = query.responsePath.split('.')[0];
      console.log('rz/cache/Root Cache clear', queryName);
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: queryName });
    });
  }
}

const defaultContext = {
  socket: null,
  socketState: null,
  topics: {},
  realtimeView: {},
  realtimeData: {},
  realtimeUpdates: {},
  resourceVersionUpdates: {
    publishedResourceType: {},
    publishedResource: {},
    resourceVersion: {},
    csVersion: {},
  },
};

export function topicPrefix() {
  const accountKey = getAccountKey();
  return `rz/account/${accountKey}`;
}

function TopicMessage(userContext, {
  topic, topicName, interaction, payload,
  realtimePath, realtimeData,
}) {
  const prefix = topicPrefix(userContext);
  const topicPath = topic ? `${prefix}/${topic}` : prefix;
  const data = {
    topic: topicPath,
    deviceKey: userContext.deviceKey,
    topicName,
    interaction,
    payload,
    realtimePath,
    realtimeData,
  };
  return data;
}

export function Interaction({
  resourceKey,
  kind = 'edit',
  action = '',
  focus = '',
}) {
  return {
    resourceKey,
    kind,
    action,
    focus,
  };
}

export const RealtimeContext = React.createContext({ ...defaultContext });
export const RealtimeContextConsumer = RealtimeContext.Consumer;

export function RealtimeContextProvider({ value, children }) {
  const apolloClient = useApolloClient();
  const userContext = useContext(UserContext);
  const [realtimeContext, setRealtimeContext] = useState({
    setSocketState(socketState) {
      setRealtimeContext((state) => ({
        ...state,
        socketState,
      }));
    },
    setSocket(socket) {
      setRealtimeContext((state) => ({
        ...state,
        socket,
      }));
    },
    setTopicState(topic, topicState) {
      setRealtimeContext((state) => {
        const userColors = {};
        topicState.activeUsers.items.forEach((activeUser, index) => {
          const ci = getActiveUserClass(index);
          userColors[activeUser.pathKey] = ci;
          if (activeUser.user) {
            userColors[activeUser.user.key] = ci;
          }
        });

        return {
          ...state,
          topics: {
            ...state.topics,
            [topic]: {
              topic,
              ...topicState,
              userColors,
            },
          },
        };
      });
    },
    removTopicState(topic) {
      setRealtimeContext((state) => {
        const { [topic]: _, ...topics } = { ...state.topics };
        return {
          ...state,
          topics,
        };
      });
    },
    setRealtimeData: ({ realtimePath, data }) => {
      if (!realtimePath) { return; }
      setRealtimeContext((state) => {
        return {
          ...state,
          realtimeData: {
            ...state.realtimeData,
            [realtimePath]: data,
          },
        };
      });
    },
    setRealtimeDataBatch: (data) => {
      if (!data.data) { return; }
      const updates = {};
      data.data.items.forEach((item) => {
        updates[item.csPath] = item.data;
      });
      setRealtimeContext((state) => {
        return {
          ...state,
          realtimeData: {
            ...state.realtimeData,
            [data.realtimePath]: updates,
          },
        };
      });
    },
    setResetRealtime: (topic, { realtimePaths }) => {
      if (realtimePaths) {
        setRealtimeContext((state) => {
          const updates = {};
          realtimePaths.forEach((rp) => updates[rp] = { data: { removed: true } });
          return {
            ...state,
            realtimeUpdates: {
              ...state.realtimeUpdates,
              ...updates,
            },
          };
        });
      }
    },
    setRealtimeUpdates: (topic, { realtimePath, ...data }) => {
      if (realtimePath) {
        setRealtimeContext((state) => {
          return {
            ...state,
            realtimeUpdates: {
              ...state.realtimeUpdates,
              [realtimePath]: data,
            },
          };
        });
      }

      if (data.interaction) {
        const userKey = data.user.user.key;
        const { deviceKey } = data.user;
        const { interaction } = data;
        setRealtimeContext((state) => {
          const topicState = state.topics[topic];
          if (topicState) {
            let haveUpdate = false;
            topicState.activeUsers.items.forEach((item, i) => {
              const incomingDeviceKey = item.pathKey.split('#').pop();
              if (item.user && userKey === item.user.key && deviceKey === incomingDeviceKey) {
                topicState.activeUsers.items[i].interaction = interaction;
                haveUpdate = true;
              }
            });
            if (haveUpdate) {
              state.setTopicState(topic, topicState);
            }
          }

          return {
            ...state,
          };
        });
      }
    },
    setRealtimeViewItem: (topic, {
      path,
      clientCacheKey,
      key,
      item,
    }) => {
      if (topic) {
        setRealtimeContext((state) => {
          apolloClient.cache.modify({
            id: clientCacheKey,
            fields(fieldValue, { DELETE }) {
              return DELETE;
            },
          });

          const dataPath = `realtimeView.${path}.items.${key}`;
          const newState = { ...state };
          setValueOnPath(newState, item, dataPath, true);
          // console.log('Update', dataPath, newState);
          return {
            ...state,
            realtimeView: {
              ...state.realtimeView,
              [path]: {
                ...(state.realtimeView[path] || {}),
                items: {
                  ...(state.realtimeView[path].items || {}),
                  [key]: item,
                },
              },
            },
          };
        });
      }
    },
    setRealtimeViewSummary: (topic, {
      path,
      clientCacheKey,
      summary,
    }) => {
      if (topic) {
        setRealtimeContext((state) => {
          apolloClient.cache.modify({
            id: clientCacheKey,
            fields(fieldValue, { DELETE }) {
              return DELETE;
            },
          });
          const dataPath = `realtimeView.${path}.summary`;
          const newState = { ...state };
          setValueOnPath(newState, summary, dataPath, true);
          return {
            ...state,
            realtimeView: {
              ...state.realtimeView,
              [path]: {
                ...(state.realtimeView[path] || {}),
                summary,
              },
            },
          };
        });
      }
    },
    joinTopic: (topic) => {
      setRealtimeContext((state) => {
        const msg = TopicMessage(userContext, topic);
        state.socket.send('join', msg);
        return state;
      });
    },
    joinTopics: (topics) => {
      setRealtimeContext((state) => {
        const msg = {
          topics: topics.map((topic) => TopicMessage(userContext, topic)),
        };
        state.socket.send('joinTopics', msg);
        return state;
      });
    },
    topicKeepAlive: (topic) => {
      setRealtimeContext((state) => {
        state.socket.send('keepActive', TopicMessage(userContext, { topic }));
        return state;
      });
    },
    leaveTopic: (topic) => {
      setRealtimeContext((state) => {
        const msg = TopicMessage(userContext, { topic });
        state.socket.send('leave', msg);
        state.removTopicState(msg.topic);
        return state;
      });
    },
    leaveTopics: (topics) => {
      setRealtimeContext((state) => {
        const msg = {
          topics: topics.map((topic) => TopicMessage(userContext, topic)),
        };
        state.socket.send('leaveTopics', msg);
        msg.topics.forEach((topic) => {
          state.removTopicState(topic);
        });
        return state;
      });
    },
    updateInteraction: (topic) => {
      setRealtimeContext((state) => {
        const msg = TopicMessage(userContext, topic);
        state.socket.send('updateInteraction', msg);
        return state;
      });
    },
    updateRootInteraction: ({
      kind,
      action,
      resourceKey,
      resourceName,
      resourceType,
      isMutation,
      page,
      pageParams,
    }) => {
      setRealtimeContext((state) => {
        const msg = TopicMessage(userContext, {
          topic: null,
          interaction: {
            kind,
            action,
            resourceKey,
            resourceName,
            resourceType,
            isMutation,
            page,
            pageParams,
          },
        });
        state.socket.send('updateInteraction', msg);
        return state;
      });
    },
    updateInteractions: (topics) => {
      setRealtimeContext((state) => {
        if (!state.socket) { return state; }
        const msg = {
          topics: topics.map((topic) => TopicMessage(userContext, topic)),
        };
        state.socket.send('updateInteractions', msg);
        return state;
      });
    },
    updateRealtime: (topic, data) => {
      setRealtimeContext((state) => {
        const topicData = TopicMessage(userContext, { topic });
        const msg = {
          ...topicData,
          data,
        };
        state.socket.send('updateRealtime', msg);
        return state;
      });
    },
    resetRealtime: (req) => {
      setRealtimeContext((state) => {
        const msg = TopicMessage(userContext, req);
        state.socket.send('resetRealtime', msg);
        return state;
      });
    },
    refreshProfile: () => {
      setRealtimeContext((state) => {
        const msg = { rztoken: getToken() };
        state.socket.send('refreshProfile', msg);
        return state;
      });
    },
    resourceVersionPublished: (message) => {
      // console.log('Version Published', message.publishedResourceType, message.publishedResource);
      setRealtimeContext((state) => {
        const {
          publishedResourceType,
          publishedResource,
          resourceVersion,
          csVersion,
        } = state.resourceVersionUpdates;

        const {
          resource_id: resourceType,
          resource_key: resourceKeyHash,
          cs_name: csName,
        } = message;

        const resourceKey = resourceDecodeHashkey(resourceKeyHash);
        // const apolloCacheKey = createApolloCacheKey(resourceType, resourceKey);
        const resourcePath = `${resourceType}.${resourceKey}`;
        const csPath = `${resourcePath}.${csName}`;

        const incomingVersion = {
          resourceType,
          resourceKey,
          columns: [csName],
          ts: parseInt(message.ts, 10),
          stage: recordStageFromNumber(message.version),
          version: parseInt(message.version, 10),
          author: message.author,
        };
        const isPublished = incomingVersion.stage === RecordStage.Published;

        const updateIfLatest = (versions, path, params) => {
          let version = dataFromPath(versions, path, { ts: 0 });

          if (version.ts <= incomingVersion.ts) {
            // Append columns if ts same
            if (version.ts === incomingVersion.ts) {
              if (version.columns.indexOf(csName) === -1) {
                version.columns.push(csName);
              } else {
                return;
              }
            }

            version = {
              ...value,
              ...incomingVersion,
              ...params,
            };

            setValueOnPath(versions, version, path, true);
          } else {
            console.log('Skipping old updates', version, incomingVersion);
          }
        };

        updateIfLatest(csVersion, csPath, {});
        updateIfLatest(resourceVersion, resourcePath, {});

        if (isPublished) {
          updateIfLatest(publishedResourceType, resourceType, {});
          updateIfLatest(publishedResource, resourcePath, {});
        }

        clearResourceCache(apolloClient, resourceType, resourceKey);
        // console.log('rz/cache/Clearing Cache', apolloCacheKey);
        // apolloClient.cache.modify({
        //   id: apolloCacheKey,
        //   fields(fieldValue, { DELETE }) {
        //     return DELETE;
        //   },
        // });

        // // Refresh all resource type queries
        // const rtDef = getResourceDef((resourceType));
        // if (rtDef) {
        //   Object.keys(rtDef.queries).forEach((actionid) => {
        //     const query = rtDef.queries[actionid];
        //     const queryName = query.responsePath.split('.')[0];
        //     console.log('rz/cache/Root Cache clear', queryName);
        //     apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: queryName });
        //   });
        // }

        if (isPublished) {
          // Referesh resource dashboard
          apolloClient.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'ants_sports_latest_activity_list',
            args: {
              account: userContext.accountKey,
              topic: ['RT', resourceType],
            },
          });

          // Referesh dashboard
          apolloClient.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'ants_sports_latest_activity_list',
            args: {
              account: userContext.accountKey,
            },
          });

          if (resourceType === 'PinResource') {
            apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'sports_pin_resource_read' });
            apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'sports_pin_resource_list' });
          }
        } else {
          console.log('not refreshing');
        }

        apolloClient.cache.gc();
        // apolloClient.cache.gc({
        //   resetResultCache: true,
        //   resetResultIdentities: true,
        // });

        return {
          ...state,
          resourceVersionUpdates: {
            ...state.resourceVersionUpdates,
            publishedResourceType,
            publishedResource,
            resourceVersion,
            csVersion,
          },
        };
      });
    },
    ...defaultContext,
    ...value,
  });

  const onMessage = (message) => {
    switch (message.event) {
      case 'joined':
        // console.log('Joined', message.topic, message.message);
        realtimeContext.setTopicState(message.topic, message.message);
        break;
      case 'left':
        realtimeContext.setTopicState(message.topic, message.message);
        break;
      case 'connected':
        break;
      case 'meJoined':
        realtimeContext.setRealtimeDataBatch(message.message.realtimeData);
        break;
      case 'meLeft':
        realtimeContext.removTopicState(message.message.topic);
        break;
      case 'realtimeUpdates':
        // console.log('RT', message.topic, message.message);
        realtimeContext.setRealtimeUpdates(message.topic, message.message);
        break;
      case 'rtview_item':
        console.log('RTView Item', message.topic, message.message);
        realtimeContext.setRealtimeViewItem(message.topic, message.message);
        break;
      case 'rtview_summary':
        console.log('RTView Summary', message.topic, message.message);
        realtimeContext.setRealtimeViewSummary(message.topic, message.message);
        break;
      case 'resetRealtimeCompleted':
        realtimeContext.setResetRealtime(message.topic, message.message);
        break;
      case 'resource_version_published':
        realtimeContext.resourceVersionPublished(message.message);
        break;
      case 'resource_version_created':
        realtimeContext.resourceVersionPublished(message.message);
        break;
      default:
        console.log('Unhandled event', message.event, message.message);
        break;
    }
  };

  const { socket, socketState } = useSocket({
    onMessage,
  });

  useEffect(() => {
    realtimeContext.setSocket(socket);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    realtimeContext.setSocketState(socketState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState]);

  return (
    <RealtimeContext.Provider value={realtimeContext}>
      {children}
    </RealtimeContext.Provider>
  );
}

RealtimeContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
