import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const Dashboard = lazy(() => import('./Dashboard'));
const List = lazy(() => import('./List'));
const DuplicatePlayerList = lazy(() => import('./DuplicateList'));
const Create = lazy(() => import('./Create'));
const ResourcePage = lazy(() => import('./ResourcePage'));
const Versions = lazy(() => import('./Versions'));
const Update = lazy(() => import('./Update'));
const UpdateStatus = lazy(() => import('./UpdateStatus'));
const UpdateBasicData = lazy(() => import('./UpdateBasicData'));
const UpdateImage = lazy(() => import('./UpdateImage'));
const UpdateDuplicate = lazy(() => import('./UpdateDuplicatePlayer'));

export function PlayersRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.cricket.players.meta.path}>
        <Route
          index
          element={(<Dashboard />)}
        />
        <Route
          path={Pages.sports.cricket.players.list.meta.path}
          element={(<List />)}
        />
        <Route
          path={Pages.sports.cricket.players.duplicatePlayerList.meta.path}
          element={(<DuplicatePlayerList />)}
        />
        <Route
          path={Pages.sports.cricket.players.create.meta.path}
          element={(<Create />)}
        />
        <Route
          path={Pages.sports.cricket.players.read.meta.path}
          element={(<ResourcePage />)}
        />
        <Route
          path={Pages.sports.cricket.players.versions.meta.path}
          element={(<Versions />)}
        />
        <Route
          path={Pages.sports.cricket.players.update.meta.path}
          element={(<Update />)}
        />
        <Route
          path={Pages.sports.cricket.players.updateStatus.meta.path}
          element={(<UpdateStatus />)}
        />
        <Route
          path={Pages.sports.cricket.players.updateBasicData.meta.path}
          element={(<UpdateBasicData />)}
        />
        <Route
          path={Pages.sports.cricket.players.updateDuplicate.meta.path}
          element={(<UpdateDuplicate />)}
        />
        <Route
          path={Pages.sports.cricket.players.updateImage.meta.path}
          element={(<UpdateImage />)}
        />
      </Route>
    </Routes>
  );
}
