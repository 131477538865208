import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'core/pages';
import { UpdateRanking } from './UpdateRanking';
import { RankingList } from './RankingList';

const RankingDashboard = lazy(() => import('./Dashboard'));
const RankingCreate = lazy(() => import('./CreateRanking'));
const TeamRankingUpdate = lazy(() => import('./Team/updateTeamRanking'));
const PlayerRankingUpdate = lazy(() => import('./Player/updatePlayerRanking'));
const TeamRankingVersion = lazy(() => import('./Team/TeamRankingVersion'));
const PlayerRankingVersion = lazy(() => import('./Player/PlayerRankingVersion'));

export function RankingRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.cricket.ranking.meta.path}>
        <Route
          index
          element={<RankingDashboard />}
        />
        <Route
          path={Pages.sports.cricket.ranking.create.meta.path}
          element={<RankingCreate />}
        />
        <Route
          path={Pages.sports.cricket.ranking.update.meta.path}
          element={<UpdateRanking />}
        />
        <Route
          path={Pages.sports.cricket.ranking.list.meta.path}
          element={<RankingList />}
        />
        <Route
          path={Pages.sports.cricket.ranking.updateTeam.meta.path}
          element={<TeamRankingUpdate />}
        />
        <Route
          path={Pages.sports.cricket.ranking.updatePlayer.meta.path}
          element={<PlayerRankingUpdate />}
        />
        <Route
          path={Pages.sports.cricket.ranking.playerRankingVersion.meta.path}
          element={<PlayerRankingVersion />}
        />
        <Route
          path={Pages.sports.cricket.ranking.teamRankingVersion.meta.path}
          element={<TeamRankingVersion />}
        />
      </Route>
    </Routes>
  );
}
