import { stripKeys, keyed } from 'lib/utils';

export const RecordStage = {
  Draft: 'Draft',
  Published: 'Published',
  ReadyToPublish: 'ReadyToPublish',
  Versioned: 'Versioned',
};

export function recordStageFromNumber(value) {
  switch (value) {
    case 1:
      return RecordStage.Published;
    case 2:
      return RecordStage.ReadyToPublish;
    case 3:
      return RecordStage.Draft;
    default:
      return RecordStage.Versioned;
  }
}

export const mergeDeep = (target, source, isMergingArrays = false) => {
  const newTarget = ((obj) => {
    let cloneObj;
    try {
      cloneObj = JSON.parse(JSON.stringify(obj));
    } catch (err) {
      // If the stringify fails due to circular reference, the merge defaults
      //   to a less-safe assignment that may still mutate elements in the target.
      // You can change this part to throw an error for a truly safe deep merge.
      cloneObj = { ...obj };
    }
    return cloneObj;
  })(target);

  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(newTarget) || !isObject(source)) { return source; }

  Object.keys(source).forEach((key) => {
    const targetValue = newTarget[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      if (isMergingArrays) {
        newTarget[key] = targetValue.map((x, i) => (sourceValue.length <= i
          ? x
          : mergeDeep(x, sourceValue[i], isMergingArrays)));
        if (sourceValue.length > targetValue.length) {
          newTarget[key] = newTarget[key].concat(sourceValue.slice(targetValue.length));
        }
      } else {
        newTarget[key] = targetValue.concat(sourceValue);
      }
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      newTarget[key] = mergeDeep({ ...targetValue }, sourceValue, isMergingArrays);
    } else {
      newTarget[key] = sourceValue;
    }
  });

  return newTarget;
};

export function mergeViewWithHistoryDraft(columnSets, view, history) {
  const { cs } = history ?? { cs: [] };

  const viewData = stripKeys(JSON.parse(JSON.stringify(view)), []);
  const newView = {
    ...viewData,
  };

  const viewVersion = { };
  cs.forEach((historyColumn) => {
    columnSets.forEach((columnSet) => {
      const { viewKey, historyKey } = columnSet;
      // const [viewName, columnName] = column;
      if (historyColumn.cs_name === historyKey && historyColumn.draft) {
        const parsed = JSON.parse(historyColumn.draft.raw);
        const keyedValue = keyed(parsed, columnSet.referenceFields);
        viewVersion[viewKey] = historyColumn;
        if (view[viewKey]) {
          newView[viewKey] = mergeDeep({ ...view[historyKey] }, keyedValue);
        } else {
          newView[viewKey] = keyedValue;
        }
      }
    });
  });

  const columnSetLookup = {};
  columnSets.forEach((columnSet) => {
    columnSetLookup[columnSet.historyKey] = columnSet;
  });

  let records = [];
  cs.filter((c) => c.records.length > 0).forEach((c) => {
    records.push(...c.records.map((record) => ({
      key: `${c.cs_name}_${record.ts}`,
      cs: c,
      record,
    })));
  });

  records = records.sort((x, y) => y.record.ts - x.record.ts);

  return {
    view: newView,
    history: {
      versions: viewVersion,
      history,
      columnSetLookup,
      records,
    },
  };
}
