import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { Button } from 'core/Button';
import {
  StatusError, StatusLoading,
} from 'core/Status';
import { Link } from 'react-router-dom';
import { FormErrorView } from 'core/FormField';
import { ReactComponent as HistoryIcon } from 'assets/icons/icon-history-lg.svg';
import { ReactComponent as CreateHistoryIcon } from 'assets/icons/icon-create-history.svg';
import { ReactComponent as ReviewIcon } from 'assets/icons/icon-review.svg';
import { ReactComponent as PinActions } from 'assets/icons/icon-pin.svg';
import { SportsPinResourceDeleteMutation, SportsPinResourceReadQuery, SportsPinResourceCreateMutation } from 'sports/query';
import { PopoverButtons } from 'core/Popover';
import { useQuery } from '@apollo/client';
import { Tooltip } from 'core/Tooltip';
import { ActiveUsers } from './ActiveUsers';
import { RecordStage } from './ants';

export function PinnedOptions({
  resourceDef, resourceKey, resourceName, imageUrl,
}) {
  const hashedKey = resourceKey;
  const {
    loading,
    error,
    data,
  } = useQuery(SportsPinResourceReadQuery, {
    variables: {
      resourceType: resourceDef.storeId,
      resourceKey: hashedKey,
    },
  });
  let resp = null;
  let userLevel = null;
  let accountLevel = null;
  const options = [];
  let userLevelOptions = {};
  let accountLevelOptions = {};

  if (data) {
    resp = data && data.sports_pin_resource_read;
    userLevel = resp && resp.user_level;
    accountLevel = resp && resp.account_level;
    userLevelOptions = {
      className: 'clear',
      mutation: userLevel ? SportsPinResourceDeleteMutation : SportsPinResourceCreateMutation,
      responseKey: userLevel ? 'sports_pin_resource_delete.status' : 'sports_pin_resource_create.status',
      name: userLevel ? 'Unpin' : 'Pin',
      variables: {
        userLevel: true,
        accountLevel: false,
        resourceType: resourceDef && resourceDef.storeId,
        resourceName,
        resourceImageUrl: imageUrl,
        resourceKey,
      },
      reqConfirm: false,
    };
    options.push({
      ...userLevelOptions,
    });

    accountLevelOptions = {
      className: 'clear',
      mutation: accountLevel ? SportsPinResourceDeleteMutation : SportsPinResourceCreateMutation,
      responseKey: accountLevel ? 'sports_pin_resource_delete.status' : 'sports_pin_resource_create.status',
      name: accountLevel ? 'Unpin for everyone' : 'Pin for everyone',
      variables: {
        userLevel: false,
        accountLevel: true,
        resourceType: resourceDef && resourceDef.storeId,
        resourceName,
        resourceImageUrl: imageUrl,
        resourceKey,
      },
      reqConfirm: false,
    };
    options.push({
      ...accountLevelOptions,
    });
  }

  return (
    <div>
      {loading && (<StatusLoading />)}
      {error && (<StatusError error={error} />)}
      {resp && (
      <PopoverButtons
        iconView={<PinActions />}
        buttonClassName="clear"
        contentAlign="end"
        mutations={options}
      />
      )}
    </div>
  );
}

PinnedOptions.propTypes = {
  resourceDef: PropTypes.object,
  resourceKey: PropTypes.string,
  resourceName: PropTypes.string,
  imageUrl: PropTypes.string,
};

const SaveToolbarWrapper = styled.div`
  .active-users-view {
    .active-users {
      margin-bottom: ${asRem(16)};
    }
  }
  .actions {
    .validation-error {
      margin-bottom: ${asRem(12)};
      svg {
        transform: rotate(-90deg);
      }
    }
    .save-error {
      margin-top: ${asRem(14)};
      margin-bottom: ${asRem(12)};
      word-break: break-word;
    }
    button {
      display: block;
      width: 100%;
      margin-bottom: ${asRem(12)};
    }
  }

  &.float-bar {
    position: fixed;
    /* background: red; */
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    z-index: 1000;
    width: max-content;
    margin: 0 auto;
    
    
    .save-toolbar-container {
      transition: all 3s ease-out;
      
      border: 1px solid var(--color-border-1);
      background: var(--color-border-2);
      border-radius: ${asRem(4)};
      padding: ${asRem(11)} ${asRem(16)};

      .save-toolbar-view {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${asRem(80)};
      }

      a {
        border-color: transparent;
        padding-bottom: ${asRem(10)};
        padding-top: ${asRem(10)};
        display: block;
        margin: 1px;

        &:hover {
          color: var(--color-button-text-2);
          fill: var(--color-button-text-2);
        }
      }
      
      .actions {
        align-items: center;
        gap: ${asRem(20)};
        a {
          border: none;
          padding: 0;
          &:hover {
            background: none;
            svg {
              fill: none;
              color: var(--color-secondary-cta);
            }
          }
        }
        button {
          &.plain {
            &.version-btn {
              background: none;
              padding: 0;
            }
          }
        }
      }

      button {
        margin-bottom: 0;
      }

      .active-users {
        margin-bottom: 0;
      }

      .actions {
        display: flex;
        gap: ${asRem(10)};
      }
    }

    .errors {
      text-align: right;
      margin-top: ${asRem(10)};

      .validation-error, .error-line {
        justify-content: end;
      }
    }
  }
`;

export function SaveToolbar({
  errors,
  submitForm,
  isSubmitting,
  formCanSave,
  loading,
  activeVersion,
  saveError,
  isValid,
  data,
  topic,
  imageUrl,
  userColors,
  resourceDef,
  resourceKey,
  versionKey,
  reviewMode,
  resourceName,
  showActions = true,
  floatBar = false,
  showHistory = false,
  showReviewIcons = false,
  showPinActions = false,
  versionUrl,
  renderAdditionalLinks,
  disableSaveAction,
}) {
  const classes = [];
  if (floatBar) {
    classes.push('float-bar');
  }
  const versionsUrl = resourceDef.pageFor(resourceDef, versionKey);
  return (
    <SaveToolbarWrapper className={classes.join(' ')}>
      <div className="save-toolbar-container">
        <div className="save-toolbar-view">
          <div className="active-users-view">
            <ActiveUsers
              topic={topic}
              userColors={userColors}
              size="34"
              maxUsers={4}
            />
          </div>
          {showActions && (
            <div className="actions">
              {(!floatBar) && (
                <FormErrorView errors={errors} showFromStart className="validation-error" />
              )}
              {resourceDef && showPinActions && (
                <Tooltip
                  text="Pin"
                  renderTrigger={() => (
                    <div>
                      <PinnedOptions
                        resourceDef={resourceDef}
                        resourceName={resourceName}
                        resourceKey={resourceKey}
                        imageUrl={imageUrl}
                      />
                    </div>
                  )}
                />
              )}
              {showHistory && (
                <Link
                  className="plain bordered"
                  to={versionUrl || versionsUrl.meta.urlFor({ key: resourceKey })}
                >
                  <Tooltip
                    text="Version history"
                    renderTrigger={() => (
                      <HistoryIcon />
                    )}
                  />
                </Link>
              )}
              {showReviewIcons && (
                <div>
                  {reviewMode
                    ? (
                      <Link className="plain" to="?review=0">
                        <Tooltip
                          text="Review"
                          renderTrigger={() => (
                            <ReviewIcon />
                          )}
                        />
                      </Link>
                    )
                    : (
                      <Link className="plain" to="?review=1">
                        <Tooltip
                          text="Review"
                          renderTrigger={() => (
                            <ReviewIcon />
                          )}
                        />
                      </Link>
                    )}
                </div>
              )}
              <Tooltip
                text="Create a version history"
                renderTrigger={() => (
                  <div>
                    <Button
                      submitForm={submitForm}
                      disabled={isSubmitting || !formCanSave}
                      state={{
                        loading: loading && activeVersion === 'Draft',
                        saveError: saveError && activeVersion === 'Draft',
                        data: data && activeVersion === 'Draft',
                      }}
                      title={floatBar ? <CreateHistoryIcon /> : 'Create a version history'}
                      buttonType={floatBar ? 'plain version-btn' : 'secondary version-btn'}
                      postVariables={{ version: RecordStage.Draft }}
                    />
                  </div>
                )}
              />
              <Button
                submitForm={submitForm}
                disabled={disableSaveAction ?? (isSubmitting || !isValid)}
                state={{
                  loading: loading && activeVersion === 'Published',
                  saveError: saveError && activeVersion === 'Published',
                  data: data && activeVersion === 'Published',
                }}
                title="Save"
                buttonType="primary"
                postVariables={{ version: RecordStage.Published }}
              />
              {(!floatBar) && (
                <div className="save-error form-status">
                  {saveError && (<StatusError error={saveError} />)}
                </div>
              )}
              <div>
                {renderAdditionalLinks && renderAdditionalLinks()}
              </div>
            </div>
          )}
        </div>
        {floatBar && (errors || saveError) && (
        <div>
          <div className="errors">
            <FormErrorView errors={errors} showFromStart className="validation-error" />
            <div className="save-error form-status">
              {saveError && (<StatusError error={saveError} />)}
            </div>
          </div>
        </div>
        )}
      </div>
    </SaveToolbarWrapper>
  );
}

SaveToolbar.propTypes = {
  errors: PropTypes.object,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  formCanSave: PropTypes.bool,
  loading: PropTypes.bool,
  activeVersion: PropTypes.string,
  saveError: PropTypes.bool,
  isValid: PropTypes.bool,
  data: PropTypes.object,
  topic: PropTypes.object,
  userColors: PropTypes.object,
  resourceDef: PropTypes.object,
  resourceKey: PropTypes.string,
  versionKey: PropTypes.string,
  showActions: PropTypes.bool,
  showPinActions: PropTypes.bool,
  imageUrl: PropTypes.string,
  floatBar: PropTypes.bool,
  showHistory: PropTypes.bool,
  reviewMode: PropTypes.bool,
  showReviewIcons: PropTypes.bool,
  resourceName: PropTypes.string,
  versionUrl: PropTypes.string,
  renderAdditionalLinks: PropTypes.func,
  disableSaveAction: PropTypes.bool,
};
