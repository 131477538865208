import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, RefField, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { ClubImageView } from './ResourceImage';
import {
  ClubSearchQuery,
  ClubReadQuery,
  ClubCreateMutation,
  ClubUpdateMutation,
  ClubNameQuery,
} from './query';

export const ClubResource = Resource({
  resourceId: 'club',
  name: 'Club',
  storeId: 'Club',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Club',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        official_name: Yup.string().nullable().default(''),
        region: RefField(),
      }),
      referenceFields: ['region'],
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ClubImageView item={item} />),
  queries: {
    read: QueryAction({ query: ClubReadQuery, resourceNamePath: 'item.club.name', resourcePath: 'item.resource.hashkey' }),
    list: QueryAction({ query: ClubSearchQuery, responsePath: 'sports_club_search' }),
    search: QueryAction({
      query: ClubSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'club.name',
    }),
    name: QueryAction({ query: ClubNameQuery, resourceNamePath: 'sports_club_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ClubUpdateMutation, cs: ['club'] }),
    create: MutationAction({ mutation: ClubCreateMutation }),
  },
});
