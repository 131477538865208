import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const RoundCreateMutation = gql`
mutation RoundCreateMutation(
  $tournament: TournamentInput!
  $name: String
) {
  sports_cricket_tournament_round_create(tournament: $tournament, name: $name) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const RoundReadQuery = gql`
query RoundReadQuery($resource: TournamentRoundInput!,
  $withHistory: Boolean,
) {
  sports_cricket_tournament_round_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        tournament
        key
        hashkey: _hashkey
      }
      tournament_round {
        name
        index
        groups {
          key
          name
          index
          teams
          matches {
            resource {
              account
              sport
              key
              hashkey: _hashkey
            }
            start_at
            teams {
              team_a {
                tournament
                team
                hashkey: _hashkey
              }
              team_b {
                tournament
                team
                hashkey: _hashkey
              }
            }
            sub_title
            internal_name
            status
            stadium {
              account
              key
              hashkey: _hashkey
            }
            published
            notes
            legacy_key
          }
          legacy_data {
            key
            synced
          }
          is_group_published
        }
        type
        enable_points
        group_based_points
      }
      legacy_data {
        key
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const RoundUpdateMutation = gql`
mutation RoundUpdateMutation(
  $resource: TournamentRoundInput!, 
  $tournamentRound: TournamentRoundTournamentRoundInput!,
  $version: RecordStage) {
  sports_cricket_tournament_round_update(resource: $resource, tournament_round: $tournamentRound, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
    new_group_keys
  }
}
`;

export const RoundDeleteMutation = gql`
mutation RoundDeleteMutation(
  $resource: TournamentRoundInput!,
  $name: String!) {
  sports_cricket_tournament_round_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const RoundGroupDeleteMutation = gql`
mutation RoundGroupDeleteMutation(
  $resource: TournamentRoundInput!,
  $groupKey: String!) {
  sports_cricket_tournament_round_delete_group(resource: $resource, group_key: $groupKey) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const MatchDeleteMutation = gql`
mutation MatchDeleteMutation(
  $resource: MatchInput!,
  $name: String!) {
  sports_cricket_match_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;
