import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Portal from '@radix-ui/react-portal';
import { asRem } from 'lib/css';
import { SlideLeftAndFadeKF } from 'style/keyframes';
import { Display20Bold } from 'style/typography';

const ScreenPopoverWrapper = styled(Portal.Root)`
  z-index: 10000;
  position: fixed;
  inset: 0;
  animation: ${SlideLeftAndFadeKF} 300ms linear;

  >.sp-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    >.sp-overlay {
      flex: 1;
      background-color: rgba(13, 17, 22, 0.6);
    }
    >.sp-body {
      min-width: ${asRem(280)};
      background-color: var(--color-secondary-bg);

      &.with-content {
        display: flex;
        flex-direction: column;
        height: 100vh;
        
        >.title-block {
          padding: ${asRem(20)} ${asRem(20)} 0;
          margin-bottom: ${asRem(8)};
          display: flex;
          align-items: center;
          gap: ${asRem(20)};
          h1, h2 {
            color: var(--color-text-1);
          }
          >.title {
            flex: 1;
          }
        }
    
        >.content-block {
          padding: ${asRem(20)};
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          .content-body-block {
          }
        }

      }
    }
  }
`;

export function ScreenPopover({
  children,
  onClose,
  renderTitle,
  title,
  renderActions,
}) {
  return (
    <ScreenPopoverWrapper>
      <div className="sp-container">
        <div
          className="sp-overlay"
          onClick={() => { onClose(); }}
          onKeyPress={() => { onClose(); }}
          role="button"
          tabIndex="0"
          aria-label="click to close"
        />
        {(renderTitle || title) && (
          <div className="sp-body with-content">
            <div className="title-block">
              <div className="title">
                {title && (
                  <Display20Bold>{title}</Display20Bold>
                )}
                {renderTitle && renderTitle()}
              </div>
              {renderActions ? renderActions() : (
                <div className="action-buttons">
                  <button
                    type="button"
                    className="plain bordered small"
                    onClick={() => { onClose(); }}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
            <div className="content-block">
              {children}
            </div>
          </div>
        )}
        {(!renderTitle && !title) && (
          <div className="sp-body">
            {children}
          </div>
        )}
      </div>
    </ScreenPopoverWrapper>
  );
}

ScreenPopover.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  renderTitle: PropTypes.func,
  title: PropTypes.string,
  renderActions: PropTypes.func,
};
