import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, RefField, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { StadiumImageView } from './ResourceImage';
import {
  StadiumSearchQuery,
  StadiumReadQuery,
  StadiumCreateMutation,
  StadiumUpdateMutation,
  StadiumNameQuery,
} from './query';

export const StadiumResource = Resource({
  resourceId: 'stadium',
  name: 'Stadium',
  storeId: 'Stadium',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Stadium',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        sport: Yup.string().nullable().default(''),
        region: RefField(),
      }),
      referenceFields: ['region'],
    }),
    ColumnSet({
      name: 'Bio',
      shape: Yup.object().shape({
        desc: Yup.string().max(1024, 'Too big').nullable().default(''),
        capacity: Yup.number().integer().nullable().default(),
        established: Yup.number().integer().nullable().default(0),
        floodlights: Yup.boolean().default(),
      }),
    }),
    ColumnSet({
      name: 'Locale',
      shape: Yup.object().shape({
        timezone: Yup.string().required(),
        address: Yup.string().max(1024, 'Too big').nullable().default(''),
        geo_location: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<StadiumImageView item={item} />),
  queries: {
    read: QueryAction({ query: StadiumReadQuery, resourceNamePath: 'item.stadium.name', resourcePath: 'item.resource.hashkey' }),
    list: QueryAction({ query: StadiumSearchQuery, responsePath: 'sports_stadium_search' }),
    search: QueryAction({
      query: StadiumSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'stadium.name',
    }),
    name: QueryAction({ query: StadiumNameQuery, resourceNamePath: 'sports_stadium_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: StadiumUpdateMutation, cs: ['stadium', 'bio', 'locale'] }),
    create: MutationAction({ mutation: StadiumCreateMutation }),
  },
});
