import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { AccountSettingsRoutes } from './AccountSettings/routes';

const AccountPicker = lazy(() => import('./AccountPicker'));
const ActiveUsers = lazy(() => import('./ActiveUsers'));
const SelectSport = lazy(() => import('sports/SelectSport'));

export function AccountRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <AccountSettingsRoutes />
      <Routes>
        <Route
          path={Pages.account.picker.meta.path}
          element={(<AccountPicker />)}
        />
        <Route
          path={Pages.sports.meta.path}
          element={(<SelectSport />)}
        />
        <Route
          path={Pages.account.activeUsers.meta.path}
          element={(<ActiveUsers />)}
        />
      </Routes>
    </Suspense>
  );
}
