import { uuid } from 'lib/utils';

export function getLocalData(key, defaultValue) {
  const val = localStorage.getItem(key);

  if (val === undefined) {
    return defaultValue;
  }

  return val;
}

export function getToken() {
  return localStorage.getItem('rztoken');
}

export function saveToken(token) {
  return localStorage.setItem('rztoken', token);
}

export function removeToken() {
  return localStorage.removeItem('rztoken');
}

export function getAccountKey() {
  return localStorage.getItem('rzaccountkey');
}

export function getPreferredTimeZone() {
  return localStorage.getItem('preferredTimeZone');
}

export function getSport() {
  return localStorage.getItem('rzsport');
}

export function saveSport(sport) {
  if (!sport) {
    return localStorage.removeItem('rzsport');
  }
  return localStorage.setItem('rzsport', sport);
}

export function getRoanuzMode() {
  return localStorage.getItem('roanuzMode');
}

export function saveRoanuzMode(roanuzMode) {
  return localStorage.setItem('roanuzMode', roanuzMode);
}

export function saveAccountKey(accountKey) {
  if (!accountKey) {
    return localStorage.removeItem('rzaccountkey');
  }
  return localStorage.setItem('rzaccountkey', accountKey);
}

export function savePreferredTimeZone(timezone) {
  if (!timezone) {
    return localStorage.removeItem('preferredTimeZone');
  }
  return localStorage.setItem('preferredTimeZone', timezone || 'UTC');
}

export function setToggleState(key, value) {
  if (!key) return null;
  // May be best way is to use IndexedDB
  return localStorage.setItem(`rz-toggle-state-${key}`, value ? '1' : '0');
}

export function getToggleState(key, defaultValue) {
  if (!key) return defaultValue;

  // May be best way is to use IndexedDB
  const value = localStorage.getItem(`rz-toggle-state-${key}`);
  if (value === undefined || value === null) return defaultValue;
  return value === '1';
}

export function getDeviceId() {
  return sessionStorage.getItem('rzdeviceid');
}

export function saveNewDeviceId() {
  const id = uuid();
  sessionStorage.setItem('rzdeviceid', id);
  return id;
}
