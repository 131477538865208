import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoginPage } from 'account/Login';
import { Box } from 'core/Box';
import { LandingPage } from 'account/LandingPage';
import { UserContext } from './UserContext';

const NotLoggedInWrapper = styled.div`

`;

export function NotLoggedIn({ message }) {
  const userContext = useContext(UserContext);
  return (
    <NotLoggedInWrapper>
      {message && (
      <Box center>
        <Box.Title center>{message}</Box.Title>
      </Box>
      )}
      {userContext.showLogin
        ? <LoginPage />
        : <LandingPage />}
    </NotLoggedInWrapper>
  );
}

NotLoggedIn.propTypes = {
  message: PropTypes.string,
};

const AccessGateWrapper = styled.div`
`;

export function AccessGate({ role, message }) {
  let finalMessage = 'You don\'t have access to this page';
  if (role) {
    finalMessage = `You need "${role}" role to access this page`;
  }
  if (message) {
    finalMessage = message;
  }

  return (
    <AccessGateWrapper>
      <Box center>
        <Box.Title center>{finalMessage}</Box.Title>
      </Box>
    </AccessGateWrapper>
  );
}

AccessGate.propTypes = {
  role: PropTypes.string,
  message: PropTypes.string,
};
