import React, { useState, useEffect, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { SearchAndSelectDropDown } from 'core/Search/SearchAndSelectDropDown';
import { SelectEnum } from 'sports/SelectEnum';
import { AssociationResource } from 'sports/association/resource';
import { asRem } from 'lib/css';
import { Form } from 'core/Form';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { TeamRankingList } from './Team/TeamList';
import { PlayerRankingList } from './Player/PlayerList';
import { TeamRankingResource } from './Team/resource';

const RankingListWrapper = styled.div`
  .filters {
    display: flex;
    gap: ${asRem(12)};
    align-items: center;
    justify-content: space-between;
  }
`;

export function RankingList() {
  const [association, setAssociation] = useState('');
  const [gender, setGender] = useState('');
  const [formats, setFormats] = useState('');
  const [actionPath, setActionPath] = useState('');

  const appContext = useContext(AppContext);

  const [params] = useSearchParams();
  const rankingType = params.get('rankingType');

  const getActionPath = () => {
    let createPath = `${TeamRankingResource.pageFor(TeamRankingResource, 'create').meta.url}?type=${rankingType}`;

    if (association) {
      createPath = `${createPath}&association=${association}`;
    }

    if (formats) {
      createPath = `${createPath}&format=${formats}`;
    }

    if (gender) {
      createPath = `${createPath}&gender=${gender}`;
    }

    setActionPath(createPath);
  };

  useEffect(() => {
    getActionPath();
  }, [association, formats, gender]);

  useEffect(() => {
    appContext.setLayoutWidth(AppLayoutWidth.One);
  }, []);

  return (
    <RankingListWrapper>
      <div className="filters">
        <Form>
          <div className="filters">
            <SearchAndSelectDropDown
              name="association"
              label="Association"
              onSelect={(item) => setAssociation(item?.resource?.key)}
              placeholder="Select Association"
              resourceDef={AssociationResource}
            />
            <SelectEnum
              name="gender"
              enumName="SportsGender"
              placeholder="Select Gender"
              label="Gender"
              onChange={(e) => setGender(e.target.value)}
              disableRealTime
            />
            <SelectEnum
              name="formats"
              enumName="CricketMatchFormat"
              placeholder="Select Format"
              label="Format"
              onChange={(e) => {
                setFormats(e.target.value);
              }}
              disableRealTime
            />
          </div>
        </Form>
        <div className="actions">
          <Link to={actionPath} className="primary ">
            Create
            {' '}
            {rankingType}
            Ranking
          </Link>
        </div>
      </div>
      {
        rankingType === 'team' && (
        <TeamRankingList
          association={association}
          gender={gender}
          format={formats}
        />
        )
      }
      {
        rankingType === 'player' && (
        <PlayerRankingList
          association={association}
          gender={gender}
          format={formats}
        />
        )
      }
    </RankingListWrapper>
  );
}

export default RankingList;
