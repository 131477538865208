import { Routes, Route } from 'react-router-dom';
import React from 'react';
import { Pages } from 'core/pages';
import { WizardDashBoard } from './WizardHome';

export function WizardRoutes() {
  return (
    <Routes>
      <Route path={Pages.fantasy.wizard.meta.path} element={(<WizardDashBoard />)} />
    </Routes>
  );
}
