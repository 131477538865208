import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResourceImageView } from 'core/Image';

const StyledResourceImageView = styled(ResourceImageView)`
  .rz-image-view {
    --image-color: #FF95E8;
  }
`;

export function MatchImageView({ item }) {
  return (
    <StyledResourceImageView
      name={item.resource_name ?? item.tournament?.name}
    />
  );
}

MatchImageView.propTypes = {
  item: PropTypes.object,
};
