/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { UserContext } from 'core/UserContext';
import { AccessGate } from 'core/AccessGate';

export default function AccountSearchAccessGate({ children }) {
  const user = useContext(UserContext);
  const requiredRoles = 'Super Admin / Super Staff';
  if (!user.user.roles.roles_v2.includes(1) && !user.user.roles.roles_v2.includes(50)) {
    return (<AccessGate role={requiredRoles} />);
  }
  return children;
}
