import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RealtimeContext, Interaction } from './RealtimeContext';
import { ConnectionState } from './socket';

export function RealtimeTopicBoundary({ topic, topicName, children }) {
  const realtimeContext = useContext(RealtimeContext);
  const [joined, setJoined] = useState(null);
  const [realtimeDisabled, setRealtimeDisabled] = useState(null);

  useEffect(() => {
    if ((!joined) && realtimeContext.socketState === ConnectionState.Connected) {
      realtimeContext.joinTopic(realtimeContext, { topic, topicName });
      setJoined(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joined, realtimeContext.socketState, topic, topicName]);

  useEffect(() => {
    return () => {
      realtimeContext.leaveTopic(realtimeContext, topic);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((!realtimeDisabled) && realtimeContext.socketState === ConnectionState.Disabled) {
      setRealtimeDisabled(true);
    } else if (realtimeDisabled && realtimeContext.socketState !== ConnectionState.Disabled) {
      setRealtimeDisabled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeDisabled, realtimeContext.socketState]);

  return children;
}

RealtimeTopicBoundary.propTypes = {
  topic: PropTypes.string.isRequired,
  topicName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const RealtimeEditorContext = React.createContext({ });
export const RealtimeEditorConsumer = RealtimeEditorContext.Consumer;
export function RealtimeEditorProvider({
  resourceDef,
  action,
  isMutation,
  resourceKey,
  reviewMode,
  children,
}) {
  const realtimeContext = useContext(RealtimeContext);
  const [joined, setJoined] = useState(null);
  const [topics, setTopics] = useState(null);
  const contextProps = () => ({
    realtimeData: {},
    realtimeUpdates: {},
    realtimePath: '',
    realtimeDisabled: false,
    receivedRealtimeData: false,
    formFieldState: {},
    topic: null,
    userColors: {},
    reviewMode,
    resourceKey,
  });

  const [editorContext, setEditorContext] = useState({
    ...contextProps(),
    resetContext() {
      // setJoined(null);
      // setTopics(null);
      setEditorContext((state) => ({
        ...state,
        ...contextProps(),
      }));
    },
    setFormFieldState(newState) {
      setEditorContext((state) => ({
        ...state,
        formFieldState: newState,
      }));
    },
    setReviewMode(newState) {
      setEditorContext((state) => ({
        ...state,
        reviewMode: newState,
      }));
    },
    setRealtimeDisabled(newState) {
      setEditorContext((state) => ({
        ...state,
        realtimeDisabled: newState,
      }));
    },
    resetRealtime() {
      console.log('reset');
      setEditorContext((state) => {
        console.log('resetState', state);
        const req = {
          topic: state.realtimePath,
          realtimePath: state.realtimePath,
        };
        realtimeContext.resetRealtime(req);
        return {
          ...state,
        };
      });
    },
    setFocusField(name) {
      setEditorContext((state) => {
        const interaction = {
          topic: state.realtimePath,
          realtimePath: state.realtimePath,
          interaction: Interaction({
            kind: 'edit',
            action: '',
            focus: name,
          }),
        };
        realtimeContext.updateInteraction(interaction);
        return {
          ...state,
        };
      });
    },
    updateInteraction(interaction) {
      const viewKeys = resourceDef.columnSets.map((cs) => cs.viewKey);
      const interactions = Object.keys(interaction.realtimeData || {})
        .filter((path) => {
          return viewKeys.filter((v) => path.startsWith(v)).length > 0;
        })
        .map((csPath) => {
          const data = interaction.realtimeData[csPath];
          const realtimeData = {
            data,
          };
          const csTopic = `${interaction.topic}/cs/${csPath}`;
          return {
            ...interaction,
            topic: interaction.topic,
            realtimePath: csTopic,
            realtimeData,
          };
        });

      if (interactions.length > 0) {
        // console.log('rz/rt/Update Interactions', interactions);
        realtimeContext.updateInteractions(interactions);
      }
    },
  });

  const createTopics = () => {
    const newTopics = [];
    const realtimePath = `${action.topicPath}/${resourceKey}`;
    const parentTopic = {
      topic: resourceDef.topicPath,
      topicName: resourceDef.topicName,
      interaction: Interaction({
        kind: '',
        action: action.name,
      }),
    };

    if (resourceKey) {
      parentTopic.interaction.resourceKey = resourceKey;
    }

    if (isMutation) {
      parentTopic.interaction.kind = 'edit';
      newTopics.push(
        parentTopic,
        {
          topic: realtimePath,
          topicName: action.topicName,
          realtimePath,
        },
      );

      setEditorContext((state) => {
        return {
          ...state,
          realtimePath,
        };
      });
      action.cs.forEach((viewKey) => {
        const cs = resourceDef.columnSets.find((x) => x.viewKey === viewKey);
        if (cs) {
          newTopics.push(
            {
              topic: `${realtimePath}/cs/${viewKey}`,
              topicName: `${action.topicName} - ${cs.name}`,
            },
          );
        }
      });
    } else {
      newTopics.push(
        parentTopic,
        {
          topic: `${action.topicPath}`,
          topicName: action.topicName,
        },
      );
    }
    console.log('Joining topics', newTopics);
    return newTopics;
  };

  useEffect(() => {
    const joinedKey = JSON.stringify({
      resourceKey,
      id: resourceDef.resourceId,
      actionId: action.id,
    });

    const shouldJoin = (!joined) || (joinedKey !== joined);

    if (shouldJoin && realtimeContext.socketState === ConnectionState.Connected) {
      setEditorContext((state) => ({
        ...state,
        ...contextProps(),
      }));
      setJoined(joinedKey);
      setTopics(createTopics());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joined, realtimeContext.socketState, resourceDef, resourceKey, action]);

  useEffect(() => {
    if (topics) {
      realtimeContext.joinTopics(topics);
    }
    return () => {
      if (topics) {
        realtimeContext.leaveTopics(topics);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics]);

  useEffect(() => {
    const updates = {};
    let haveUpdate = false;
    const incomingUpdates = realtimeContext.realtimeData[editorContext.realtimePath] || null;
    if (incomingUpdates !== null) {
      setEditorContext((state) => ({
        ...state,
        receivedRealtimeData: true,
      }));
      Object.keys(incomingUpdates).forEach((csPath) => {
        const csPrefix = `${editorContext.realtimePath}/cs/`;
        if (csPath.startsWith(csPrefix)) {
          haveUpdate = true;
          const viewKey = csPath.substring(csPrefix.length);
          updates[viewKey] = incomingUpdates[csPath];
        }
      });
      if (haveUpdate) {
        setEditorContext((state) => ({
          ...state,
          realtimeData: {
            ...state.realtimeData,
            ...updates,
          },
        }));
      }
    }
  }, [realtimeContext.realtimeData, editorContext.realtimePath]);

  useEffect(() => {
    const updates = {};
    const rtData = {};
    let haveUpdate = false;
    let removed = false;
    Object.keys(realtimeContext.realtimeUpdates).forEach((csPath) => {
      const csPrefix = `${editorContext.realtimePath}/cs/`;
      if (csPath.startsWith(csPrefix)) {
        haveUpdate = true;
        const viewKey = csPath.substring(csPrefix.length);
        updates[viewKey] = realtimeContext.realtimeUpdates[csPath];
        rtData[viewKey] = updates[viewKey].data;
        removed = rtData[viewKey].removed;
      }
    });
    if (removed) {
      setEditorContext((state) => {
        // state.setFocusField('');
        const newFieldState = {
          ...state.formFieldState,
        };

        Object.keys(newFieldState).forEach((path) => {
          if (newFieldState[path] && newFieldState[path].realtime) {
            newFieldState[path].realtime.use = false;
          }
        });
        return {
          ...state,
          formFieldState: newFieldState,
        };
      });
    }
    if (haveUpdate) {
      setEditorContext((state) => ({
        ...state,
        realtimeUpdates: {
          ...updates,
        },
        realtimeData: {
          ...state.realtimeData,
          ...rtData,
        },
      }));
    }
  }, [realtimeContext.realtimeUpdates, editorContext.realtimePath]);

  useEffect(() => {
    setEditorContext((state) => {
      const topicKey = realtimeContext.topics
        && Object.keys(realtimeContext.topics).find((t) => t.endsWith(state.realtimePath));
      if (topicKey) {
        const topic = realtimeContext.topics[topicKey];
        return {
          ...state,
          topic,
          userColors: topic.userColors,
        };
      }
      return {
        ...state,
      };
    });
  }, [realtimeContext.topics]);

  useEffect(() => {
    if (
      (!editorContext.realtimeDisabled)
      && realtimeContext.socketState === ConnectionState.Disabled
    ) {
      editorContext.setRealtimeDisabled(true);
    } else if (
      editorContext.realtimeDisabled
      && realtimeContext.socketState !== ConnectionState.Disabled
    ) {
      editorContext.setRealtimeDisabled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContext.realtimeDisabled, realtimeContext.socketState]);

  useEffect(() => {
    editorContext.setReviewMode(reviewMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewMode]);

  // useEffect(() => {
  //   if (editorContext.resourceKey !== resourceKey) {
  //     console.log('Reseting context');
  //     editorContext.resetContext();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [resourceKey]);

  return (
    <RealtimeEditorContext.Provider value={editorContext}>
      {children}
    </RealtimeEditorContext.Provider>
  );
}

RealtimeEditorProvider.propTypes = {
  resourceDef: PropTypes.object.isRequired,
  action: PropTypes.object,
  isMutation: PropTypes.bool,
  reviewMode: PropTypes.bool,
  resourceKey: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
