import React from 'react';
import {
  QueryAction, Resource,
  ColumnSet, MutationAction,
} from 'ants/resource';
import * as Yup from 'yup';
import {
  FantasyCricketReadQuery,
  FantasyCreditsUpdateMutation,
  FantasyRolesUpdateMutation,
  SourceTournamentMatchesListQuery,
} from './query';
import { FantasyImageView } from './ResourceImage';

export const FantasyEditorResource = Resource({
  resourceId: 'fantasyEditor',
  name: 'Fantasy Editor',
  storeId: 'FantasyMatch',
  keyVal: 'FantasyMatch',

  columnSets: [
    ColumnSet({
      name: 'CricketPlayersCredit',
      shape: Yup.object().shape({
        credits_override: Yup.string().nullable().default(''),
      }),
      viewKey: 'cricket_players_credit',
      historyKey: 'FantasyCricketPlayersCredit',
    }),

    ColumnSet({
      name: 'CricketPlayerRoles',
      shape: Yup.object().shape({
        role_override: Yup.string().nullable().default(''),
      }),
      viewKey: 'cricket_players_role',
      historyKey: 'FantasyCricketPlayersRole',
    }),
  ],

  listPrimaryActions: [
    { action: 'updateCredits', name: 'Edit Credits' },
    { action: 'updateRoles', name: 'Edit Roles' },
  ],

  pageKey: 'match',
  imageRender: (item) => (<FantasyImageView item={item} />),

  queries: {
    read: QueryAction(
      {
        query: FantasyCricketReadQuery,
        responsePath: 'fantasy_cricket_read',
        resourceNamePath: 'item.match.name',
        resourcePath: 'item.resource._hashkey',
      },
    ),
    list: QueryAction({ query: SourceTournamentMatchesListQuery }),
  },

  mutations: {
    updateCredits: MutationAction(
      {
        mutation: FantasyCreditsUpdateMutation,
        cs: ['cricket_players_credit'],
        responsePath: 'fantasy_cricket_update_credits',
        responseStatusKey: 'fantasy_cricket_update_credits.status',
      },
    ),

    updateRoles: MutationAction(
      {
        mutation: FantasyRolesUpdateMutation,
        cs: ['cricket_players_role'],
        responsePath: 'fantasy_cricket_update_role',
        responseStatusKey: 'fantasy_cricket_update_role.status',
      },
    ),
  },
});
