import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dataFromPath } from 'lib/utils';
import { useMutation } from '@apollo/client';
import { ReactComponent as DoneIcon } from 'assets/icons/icon-done.svg';
import { Text14Medium, Text14Regular } from 'style/typography';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { StatusError } from './Status';
import { ConfirmDailog } from './Dailog';
import { useFetch } from './hooks';
import Loader from './Loader';

export function MutationButton({
  mutation,
  responseKey,
  name,
  variables,
  okayKey = 'okay',
  errorKey = 'error',
  loadingText = 'loading...',
  prepareVariables = null,
  onSuccess = null,
  onError = null,
  renderTitle,
  onGettingResponse = null,
  reqConfirm = false,
  confirmText = 'Are you sure?',
  confirmButtonClass,
  onReqHandled,
  disabled,
  refetchQueries = null,
  confirmDisabled = false,
  warningMsg = '',
  ...buttonParams
}) {
  const [responseError, setResponseError] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [
    doSave,
    { loading, error },
  ] = useMutation(mutation);

  const handleSave = () => {
    let params = variables || {};
    if (prepareVariables) {
      const moreParams = prepareVariables();
      if (moreParams) {
        params = {
          ...params,
          ...moreParams,
        };
      }
    }

    setResponseError(null);
    doSave({ variables: params, refetchQueries }).then((resp) => {
      const respData = resp && resp.data && dataFromPath(resp.data, responseKey);
      if (respData) {
        if (dataFromPath(respData, okayKey)) {
          if (onGettingResponse) {
            onGettingResponse();
            window.location.reload();
          }
          setTimeout(() => setShowConfirm(false), 500);
          if (onReqHandled) {
            onReqHandled();
          }

          if (onSuccess) {
            onSuccess(respData);
          }
        } else {
          const errors = dataFromPath(respData, errorKey);
          if (errors) {
            setResponseError(errors[0]);
          } else {
            console.log('Resp', respData);
            setResponseError({ message: 'Something went wrong' });
          }
          if (onError) {
            onError(errors);
          }
        }
      } else if (resp.errors && onError) {
        onError(resp.errors);
      }
    });
  };

  const initSave = () => {
    if (reqConfirm) {
      setShowConfirm(true);
      if (onReqHandled) {
        onReqHandled();
      }
    } else {
      handleSave();
    }
  };

  const finalError = error || responseError;

  let displayName = name;
  if (loading) {
    displayName = buttonParams.showLoadingIcon ? <Loader /> : loadingText || 'loading...';
  }
  if (finalError) {
    displayName = 'Error. Try again';
  }
  return (
    <>
      <ConfirmDailog
        onConfirm={handleSave}
        onCancel={() => setShowConfirm(false)}
        message={confirmText}
        open={showConfirm}
        error={finalError}
        showLoadingState
        confirmButtonClass={confirmButtonClass}
        stateText={(name !== displayName) ? displayName : null}
        loading={loading}
        buttonDisabled={loading}
        confirmDisabled={confirmDisabled}
      />
      {finalError && (<StatusError error={finalError} />)}
      <button
        type="button"
        {...buttonParams}
        disabled={loading || disabled}
        onClick={initSave}
      >
        {renderTitle ? renderTitle(loading)
          : (
            <span>
              {displayName}
            </span>
          )}
      </button>
      {warningMsg && (<Text14Regular className="warning">{warningMsg}</Text14Regular>)}
    </>
  );
}

MutationButton.propTypes = {
  mutation: PropTypes.object.isRequired,
  responseKey: PropTypes.string.isRequired,
  variables: PropTypes.object,
  refetchQueries: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.any,
  okayKey: PropTypes.string,
  errorKey: PropTypes.string,
  loadingText: PropTypes.string,
  prepareVariables: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onGettingResponse: PropTypes.func,
  reqConfirm: PropTypes.bool,
  disabled: PropTypes.bool,
  onReqHandled: PropTypes.func,
  renderTitle: PropTypes.func,
  confirmText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  confirmButtonClass: PropTypes.string,
  icon: PropTypes.element,
  confirmDisabled: PropTypes.bool,
  warningMsg: PropTypes.string,
};

export function PostButton({
  path,
  responseKey,
  name,
  renderTitle,
  variables,
  okayKey = 'okay',
  errorKey = 'errors',
  loadingText = 'loading...',
  prepareVariables = null,
  onSuccess = null,
  reqConfirm = false,
  confirmText = 'Are you sure?',
  ...buttonParams
}) {
  const [responseError, setResponseError] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [
    doSave,
    { loading, error },
  ] = useFetch(path);

  const handleSave = () => {
    setShowConfirm(false);
    let params = variables || {};
    if (prepareVariables) {
      const moreParams = prepareVariables();
      if (moreParams) {
        params = {
          ...params,
          ...moreParams,
        };
      }
    }

    setResponseError(null);
    doSave({ variables: params }).then((resp) => {
      const respData = resp && resp.data && dataFromPath(resp.data, responseKey);
      if (respData) {
        if (dataFromPath(respData, okayKey)) {
          onSuccess(respData);
        } else {
          const errors = dataFromPath(respData, errorKey);
          if (errors) {
            setResponseError(errors[0]);
          } else {
            console.log('Resp', respData);
            setResponseError({ message: 'Something went wrong' });
          }
        }
      }
    });
  };

  const initSave = () => {
    if (reqConfirm) {
      setShowConfirm(true);
    } else {
      handleSave();
    }
  };

  const finalError = error || responseError;

  let displayName = name;
  if (loading) {
    displayName = loadingText || 'loading...';
  }
  if (finalError) {
    displayName = 'Error. Try again';
  }
  return (
    <>
      {showConfirm && (
        <ConfirmDailog
          onConfirm={handleSave}
          onCancel={() => setShowConfirm(false)}
          message={confirmText}
        />
      )}
      {finalError && (<StatusError error={finalError} />)}
      <button
        type="button"
        {...buttonParams}
        disabled={loading}
        onClick={initSave}
      >
        {displayName}
      </button>
    </>
  );
}

PostButton.propTypes = {
  path: PropTypes.string.isRequired,
  responseKey: PropTypes.string.isRequired,
  variables: PropTypes.object,
  name: PropTypes.string,
  okayKey: PropTypes.string,
  errorKey: PropTypes.string,
  loadingText: PropTypes.string,
  prepareVariables: PropTypes.func,
  onSuccess: PropTypes.func,
  reqConfirm: PropTypes.bool,
  renderTitle: PropTypes.func,
  confirmText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

const ButtonWrapper = styled.div`
  .loader {
    border: 2px solid rgb(17 22 27 / 50%); /* Light grey */
    border-top: 2px solid rgb(17 22 27 / 100%); /* Blue */
    border-radius: 50%;
    width: ${asRem(20)};
    height: ${asRem(20)};
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .success-icon-animation {
    animation: slideup 0.5s linear;
  }

  @keyframes slideup {
    0% { transform: translateY(100%); opacity: 1;}
    100% { transform: translateY(0); opacity: 0;}
  }

  @keyframes show {
    0% { opacity: 0;}
    100% {opacity: 1;}
  }

  .text-14-medium {
    animation: show 0.8s linear;
  }

  button {
    &.primary {
      &:disabled {
        cursor: not-allowed;
        background: var(--color-button-primary-bg);
        color: var(--color-button-text-2);
      }
    }
  }
`;

export function Button({
  disabled, state, submitForm, postVariables, title, buttonType,
  key,
}) {
  const [hideText, setHideText] = useState(null);

  useEffect(() => {
    if (state.data) {
      setHideText(state.data);
    }
  }, [state.data]);

  setTimeout(() => {
    setHideText(null);
  }, 3500);

  return (
    <ButtonWrapper>
      <button
        className={buttonType}
        type="button"
        disabled={disabled || state.loading}
        onClick={() => {
          submitForm(postVariables);
        }}
        key={key}
        id={key}
      >
        {state.loading && (!state.error) && (
        <div className="loader" />
        )}
        {!state.loading && !state.error && (
          <div>
            {hideText
              ? (
                <DoneIcon className="success-icon-animation" />
              )
              : <Text14Medium>{title}</Text14Medium>}
          </div>
        )}
      </button>
    </ButtonWrapper>
  );
}

Button.propTypes = {
  submitForm: PropTypes.func,
  disabled: PropTypes.any,
  state: PropTypes.object,
  title: PropTypes.any,
  postVariables: PropTypes.object,
  buttonType: PropTypes.string,
  key: PropTypes.string,
};
