/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { AntEditor } from 'ants/AntEditor';
import { Box } from 'core/Box';
import { KeyBox } from 'core/KeyBox';
import styled from 'styled-components';
import { Label12Medium, Text14Regular } from 'style/typography';
import { MutationButton } from 'core/Button';
import PropTypes from 'prop-types';
import { ScreenPopover } from 'core/ScreenPopover';
import { FormItem } from 'core/FormFieldItem';
import { PlayerSearchQuery } from 'sports/cricket/players/query';
import { PlayerImageView } from 'sports/cricket/players/ResourceImage';
import { GridFormItem } from 'sports/GridFormItem';
import { useLazyQuery } from '@apollo/client';
import { StatusLoading, StatusError } from 'core/Status';
import { objectAsArray } from 'lib/utils';
import { isDeletedValue, getDeletedValue } from 'core/Form';
import { asRem } from 'lib/css';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { PlayerRankingResource } from './resource';
import { RankingPopOver } from '../RankingPopOver';
import { PlayerRankingAutoFillQuery } from './query';

const PlayerRankingWrapper = styled.div`
  .full-width {
    width: 100%;
    margin-Bottom: ${asRem(16)};
  }


  table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0 ${asRem(20)};

    td {
      vertical-align: middle;

      div {
        margin: 0;
      }
    }

    tr td {
      padding: ${asRem(20)} ${asRem(6)};
    }

    .form-list-item {
      border-bottom: ${asRem(1)}  solid var(--color-border-1);
    }

    thead {
      tr {
        td {
          font-size: ${asRem(16)};
          font-weight: 500;
          text-align: left;
          padding: 0;
          padding-bottom: ${asRem(16)};
          border-bottom: 1px solid var(--color-border-1);
        }
      }
    }
  }

  .colgroup {
    width: ${asRem(30)};
  }

  .deleted-value {
    opacity: 0.4;
  }
`;

export function UpdatePlayerRanking({ values, setFieldValue, queryResponse }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState(objectAsArray(values?.rank?.players));

  const appContext = useContext(AppContext);

  const [doAutoFill, { data, loading, error }] = useLazyQuery(PlayerRankingAutoFillQuery);

  const onConfirm = (items) => {
    setShowPopup(false);
    const modifiedPlayers = items.map((item) => ({
      player: {
        hashkey: item?.resource?.hashkey,
      },
      name: item?.player?.name,
    }
    ));
    const newlyAddedValues = [];
    const prevValues = objectAsArray(values?.rank?.players).map((player) => {
      if (isDeletedValue(player)) {
        return getDeletedValue(player)?.team?.hashkey;
      }
      return player?.player?.hashkey;
    });

    modifiedPlayers.forEach((player) => {
      if (prevValues.includes(player?.player?.hashkey)) {
        const index = prevValues.indexOf(player?.player?.hashkey);
        setFieldValue(`rank.players.${index}`, player);
      } else {
        newlyAddedValues.push(player);
      }
    });
    const totalPlayers = [...objectAsArray(values?.rank?.players), ...newlyAddedValues];
    setSelectedPlayers([...totalPlayers]);
    setFieldValue('rank.players', totalPlayers);
  };

  const autoFillPlayers = () => {
    doAutoFill({
      variables: {
        association: queryResponse?.resource?.assocation,
        gender: queryResponse?.resource?.gender,
        format: queryResponse?.resource?.format,
        role: queryResponse.resource?.role,
      },
    });
  };

  useEffect(() => {
    if (data?.sports_cricket_player_rankings_auto_fill?.item) {
      const items = data?.sports_cricket_player_rankings_auto_fill?.item?.rank?.players;
      const prepareData = items.map((player) => ({
        player: {
          hashkey: player?.team?.hashkey,
        },
        matches: player?.matches,
        points: player?.points,
        rating: player?.rating,
      }));
      setFieldValue('rank.players', [...objectAsArray(values?.rank?.players), ...prepareData]);
      setSelectedPlayers([...selectedPlayers, prepareData]);
    }
  }, [data]);

  useEffect(() => {
    appContext.setLayoutWidth(AppLayoutWidth.Two);
  }, []);

  return (
    <PlayerRankingWrapper>
      {
        showPopup
        && (
          <ScreenPopover>
            <RankingPopOver
              readQuery={PlayerSearchQuery}
              title="players"
              pathName="sports_player_search"
              skipKeys={selectedPlayers}
              colgroupStyle="colgroup"
              renderImage={(value) => (
                <PlayerImageView item={value} size={12} />
              )}
              renderContent={(item) => (
                <div>
                  <Text14Regular>{item?.player?.name}</Text14Regular>
                  <Text14Regular>{item?.player?.gender}</Text14Regular>
                </div>
              )}
              renderConfirmationButton={({ selectedItems }) => (
                <button
                  type="button"
                  className="primary small full-width"
                  disabled={selectedItems.length <= 0}
                  onClick={() => onConfirm(selectedItems)}
                >
                  Confirm
                </button>
              )}
            />
          </ScreenPopover>
        )
      }
      <Box>
        <div>
          <button type="button" className="bordered plain full-width" onClick={() => setShowPopup(true)}>Add Players</button>
          <FormItem
            type="checkbox"
            name="autofill"
            label="Auto Fill"
            onChange={(e) => {
              if (e.target.checked) {
                autoFillPlayers();
              }
            }}
          />
        </div>
        {loading && <StatusLoading />}
        {error && <StatusError error={error} />}
        <GridFormItem
          name="rank.players"
          label="Player Ranking"
          showIndex={false}
          showAddNew={false}
          colgroupStyle="colgroup"
          labels={[
            { key: 'name', name: 'Player Name' },
            { key: 'rating', name: 'Rating' },
          ]}
          renderItem={(item) => {
            return (
              <div className={`${isDeletedValue(item.item) && 'deleted-value'}`}>

                {item.labelItem.key === 'name'
                 && (
                 <Text14Regular>
                   {isDeletedValue(item?.item)
                     ? getDeletedValue(item?.item)?.name : item?.item?.name}
                 </Text14Regular>
                 ) }

                {item.labelItem.key === 'rating'
                && (
                <FormItem
                  name={`rank.players.${item.index}.rating`}
                  type="text"
                  placeholder={0.00}
                  showLabel={false}
                />
                )}
              </div>
            );
          }}
        />
      </Box>
    </PlayerRankingWrapper>
  );
}

UpdatePlayerRanking.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  queryResponse: PropTypes.object,
};

const UpdatePlayerRankingControllerWrapper = styled.div``;

export function UpdatePlayerRankingController() {
  return (
    <UpdatePlayerRankingControllerWrapper>
      <AntEditor
        resourceDef={PlayerRankingResource}
        readAction={PlayerRankingResource.queries.read}
        updateAction={PlayerRankingResource.mutations.update}
        versionKey="playerRankingVersion"
        isErrorMode={() => {
          return true;
        }}
        renderSubTitle={(queryResponse) => (
          <Label12Medium>
            <span className="key-box">
              {'Key: '}
              <KeyBox resource={queryResponse.queryResponse.resource.hashkey} />
            </span>
          </Label12Medium>
        )}
        renderFields={({ values, setFieldValue, queryResponse }) => (
          <UpdatePlayerRanking
            values={values}
            setFieldValue={setFieldValue}
            queryResponse={queryResponse}
          />
        )}
        renderDeleteAction={(_, key, resp) => (
          <MutationButton
            mutation={PlayerRankingResource.mutations.delete.mutation}
            responseKey="sports_cricket_player_rankings_delete"
            okayKey="status.okay"
            errorKey="status.error"
            name="Delete"
            className="alert"
            variables={{
              resource: {
                _hashkey: key,
              },
              name: resp.name.name,
            }}
            onSuccess={(res) => {
              if (res.status.okay) {
                window.location.reload();
              }
            }}
          />
        )}

      />
    </UpdatePlayerRankingControllerWrapper>
  );
}

export default UpdatePlayerRankingController;
