import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ListController } from 'core/ListController';
import { ResourceListItemActions } from 'ants/ResourceList';
import { ResourceActiveUsers } from 'ants/ActiveUsers';
import { PlayerRankingSearchQuery } from './query';
import { PlayerRankingResource } from './resource';

const TeamRankingListWrapper = styled.div`

  table {
    th {
      text-align: left;
      color: var(--color-disabled-text);
    }

    td, th {
      font-size: var(--text-md-fontSize);
      line-height: var(--text-md-lineHeight);
      font-weight: var(--regular-fontWeight);
      vertical-align: middle;
    }
  }

`;

export function PlayerRankingList({
  association, format, gender, actionPath,
}) {
  const [searchValue, setSearchValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [variables, setVariables] = useState({
    search: searchValue,
    filters: {
      genders: {
        keys: gender ? [gender] : [],
      },
      associations: {
        keys: association ? [association] : [],
      },
      formats: {
        keys: format ? [format] : [],
      },
    },
  });

  useEffect(() => {
    setVariables({
      search: searchValue,
      filters: {
        genders: {
          keys: gender ? [gender] : [],
        },
        associations: {
          keys: association ? [association] : [],
        },
        formats: {
          keys: format ? [format] : [],
        },
      },
    });
  }, [association, format, gender, searchValue]);

  return (
    <TeamRankingListWrapper>
      <input type="search" name="team_ranking" placeholder="Search Team Ranking" onChange={(e) => setSearchValue(e.target.value)} />
      <ListController
        query={PlayerRankingSearchQuery}
        useTable={false}
        responseKey="sports_cricket_player_rankings_search"
        variables={variables}
        tagFilter={false}
        enableFilters={false}
        itemsKey="items"
        actionName="Create New Player Ranking"
        actionPath={actionPath}
        renderRows={(items) => {
          return (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Teams Count</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr>
                    <td>
                      {item?.name?.name}
                    </td>
                    <td>
                      {item?.rank?.players?.length}
                    </td>
                    <td>
                      <div className="list-actions-wrapper">
                        <ResourceListItemActions
                          resourceDef={PlayerRankingResource}
                          resource={item.resource}
                          buttonClasses="plain"
                        />
                        <ResourceActiveUsers
                          size="28"
                          resourceType={PlayerRankingResource.resourceId}
                          resourceKey={item.resource.hashkey}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
        useSearch={false}
      />
    </TeamRankingListWrapper>
  );
}

PlayerRankingList.propTypes = {
  association: PropTypes.string,
  format: PropTypes.string,
  gender: PropTypes.string,
  actionPath: PropTypes.string,
};

export default PlayerRankingList;
