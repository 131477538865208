import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const CompetitionCreateHintQuery = gql`
query CompetitionCreateHintQuery($name: String!) {
  sports_competition_create_hint(name: $name) {
    name
    short_name
    internal_name
    similar_items {
      resource {
        account
        sport
        association
        key
        _hashkey
      }
      competition {
        internal_name
        name
        short_name
      }
      status {
        published
        notes
      }
      cricket {
        format
      }
    }
    key_suggestions
  }
}
`;

export const CompetitionCreateMutation = gql`
mutation CompetitionCreateMutation(
  $association: AssociationInput!,
  $competition: CompetitionCompetitionInput!,
  $cricket: CricketCompetitionCricketInput!,
  $series: CompetitionSeriesInput!,
  $status: CompetitionStatusInput!,
  $key: String) {
    sports_competition_create(association: $association, competition: $competition, cricket: $cricket,
      series: $series, status: $status, key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const CompetitionSearchQuery = gql`
query CompetitionSearchQuery($search: String!, $page: PagedRequestInput) {
  sports_competition_search(search: $search, page: $page) {
    items {
      resource {
        account
        sport
        association
        key
        hashkey: _hashkey
      }
      competition {
        internal_name
        name
        short_name
      }
      status {
        published
        notes
      }
      cricket {
        format
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CompetitionReadQuery = gql`
query CompetitionReadQuery($resource: CompetitionInput!, $withHistory: Boolean) {
  sports_competition_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        sport
        association
        key
        hashkey: _hashkey
      }
      competition {
        internal_name
        name
        short_name
        enable_stats
      }
      status {
        published
        notes
      }
      bio {
        first_edition
        desc
        wiki_link
        official_website
        official_logo_url
        discontinued
      }
      coverage {
        coverage_from
      }
      series {
        one_time
        interval
        interval_unit
      }
      cricket {
        format
        mg
        is_tour
        tour_team_a
        tour_team_b
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CompetitionUpdateMutation = gql`
mutation CompetitionUpdateMutation(
  $resource: CompetitionInput!,
  $competition: CompetitionCompetitionInput!,
  $cricket: CricketCompetitionCricketInput!,
  $bio: CompetitionBioInput!,
  $series: CompetitionSeriesInput!,
  $status: CompetitionStatusInput!,
  $coverage: CompetitionCoverageInput!,
  $version: RecordStage) {
    sports_competition_update(resource: $resource, competition: $competition,
     cricket: $cricket, bio: $bio, series: $series, coverage: $coverage,
     status: $status, version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CompetitionUpdateStatusMutation = gql`
mutation CompetitionUpdateStatusMutation(
  $resource: CompetitionInput!,
  $status: CompetitionStatusInput!,
  $version: RecordStage) {
    sports_competition_update_status(resource: $resource,
     status: $status, version: $version) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CompetitionNameQuery = gql`
query CompetitionNameQuery($resource: CompetitionInput!) {
  sports_competition_name(resource: $resource) {
    name
  }
}
`;

export const CompetitionDeleteMutation = gql`
mutation CompetitionDeleteMutation(
  $resource: CompetitionInput!,
  $name: String!) {
  sports_competition_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;
