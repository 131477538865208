import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { MatchUpdateRoutes } from './update/routes';

const Dashboard = lazy(() => import('./Dashboard'));
const Create = lazy(() => import('./Create'));
const List = lazy(() => import('./List'));
const ResourcePage = lazy(() => import('./ResourcePage'));
const Version = lazy(() => import('./Version'));

export function MatchRoutes() {
  return (
    <Routes>
      <Route path={Pages.sports.cricket.match.meta.path}>
        <Route
          index
          element={(<Dashboard />)}
        />
        <Route
          path={Pages.sports.cricket.match.create.meta.path}
          element={(<Create />)}
        />
        <Route
          path={Pages.sports.cricket.match.list.meta.path}
          element={(<List />)}
        />
        <Route
          path={Pages.sports.cricket.match.read.meta.path}
          element={(<ResourcePage />)}
        />
        <Route
          path={Pages.sports.cricket.match.versions.meta.path}
          element={(<Version />)}
        />
        <Route
          path={Pages.sports.cricket.match.update.meta.starPath}
          element={(<MatchUpdateRoutes />)}
        />
      </Route>
    </Routes>
  );
}
