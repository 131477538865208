import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { StatusLoading, StatusError } from 'core/Status';
import { Text14Regular } from 'style/typography';
import { useQuery, useLazyQuery } from '@apollo/client';
import { SportsResourcePropsReadQuery } from 'sports/query';
import { getAccountKey } from 'core/localStore';
import { dataFromPath, stripKeys, capitilise } from 'lib/utils';
import { Box } from 'core/Box';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/icon-arrow-right.svg';
import CheckIcon from 'assets/icons/icon-check-black.svg';

const FilterWrapper = styled.div`
  .text-14-regular {
    padding-bottom: ${asRem(8)};
  }

  .search-block {
    display: flex;
    position: relative;
    align-items: center;
    input {
      margin-bottom: 0;
    }

    .arrow-icon {
      position: absolute;
      right: ${asRem(10)};
      top: ${asRem(18)};
      fill: var(--color-secondary-cta);
      cursor: pointer;
    }

    .arrow-wrapper {
      padding: ${asRem(13)};
      background: var(--color-border-1);
      cursor: pointer;
      border-radius: 0;
      border-top-right-radius: ${asRem(8)};
      border-bottom-right-radius: ${asRem(8)};
      svg {
        fill: var(--color-secondary-cta);
      }
    }
  }


  .filter-blocks {
    padding-bottom: ${asRem(20)};
    border-bottom: 1px solid var(--color-border-1);
    margin-bottom: ${asRem(20)};

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  
    .rz-box {
      padding: 0;
      border: none;
      margin-bottom: 0;
      border-radius: 0;
      .rz-box-title {
        padding: 0;
        div {
          flex-direction: row-reverse;
          justify-content: space-between;

          h4 {
            color: var(--color-text-1);
          }

          svg {
            fill: var(--color-label);
          }
        }
      }
      .rz-box-content , &.additional-filters {
        border: none;
        margin: 0;
        padding-top: ${asRem(20)};

        .text-14-regular {
          padding-bottom: 0;
        }

        input {
          margin-bottom: ${asRem(20)}
        }

        input[type="checkbox"] {
          width: ${asRem(15)};
          height: ${asRem(15)};
          border-radius: 50%;
          border: ${asRem(1)} solid var(--color-text-1);
          &:checked {
            border: none;
            &::after{
              content: '';
              background-color: var(--color-secondary-cta);
              background-image: url(${CheckIcon});
              background-repeat: no-repeat;
              background-position: ${asRem(3)};
              color: var(--color-button-text-1);
              border-radius: 50%;
              width: ${asRem(15)};
              height: ${asRem(15)};
              border: ${asRem(1)} solid var(--color-secondary-cta);
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        button {
          padding: 0;
          color: var(--color-label);
          margin-top: ${asRem(8)};
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          div {
            display: flex;
            align-items: center;
            gap: ${asRem(8)};

            input {
              margin-bottom: 0;
            }

            .text-14-regular {
              text-align: left;
              max-width: ${asRem(180)};
            }
            
          }
        }
      }
    }

    .additional-filters {
      display: flex;
      gap: ${asRem(8)};
      align-items: center;

      &.rz-box {
        padding: 0 !important;
      }

      input {
        margin-bottom: ${asRem(0)} !important;
      }

      &:nth-child(2) {
        margin-top: ${asRem(20)};
      }
    }
  }

  .filter-wrapper {
    margin-bottom: ${asRem(15)};
    display: flex;
    gap: ${asRem(8)};
    flex-wrap: wrap;
    border-bottom: 1px solid var(--color-border-1);
    padding-bottom: ${asRem(15)};

    &:last-child, &:empty {
      margin-bottom: 0;
      border: none;
      padding-bottom: 0;
    }

    .tags {
      padding: ${asRem(4)} ${asRem(8)};
      border-radius: ${asRem(4)};
      color: var(--color-text-2);
      border: 1px solid var(--color-border-2);
      cursor: pointer;
      transition: all 0.2s linear;

      &.active {
        background-color: var(--color-border-2);
        color: var(--color-secondary-cta);
        border-color: var(--color-border-2);
      }
    }
  }

  .wrapper {
    padding-bottom: ${asRem(15)};
    border-bottom: 1px solid var(--color-border-1);
    margin-bottom: ${asRem(15)};

    &:last-child, &:empty {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

export function Filter({
  resourceID, onTagChange,
  addedFilters, internalFilters, selectedTableContent,
  addedTableContent,
  tagFilter,
  enableFilters,
  resourceDef,
  getFilterPostVariables,
  renderAdditionalFilters,
  setAdditionalFilterVariables,
  additionalFilterVariables,
  changeChosenFilters = false,
  getChosenFilters,
}) {
  const [response, setResponse] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [filterCounts, setFilterCounts] = useState();
  const [chosenFilters, setChosenFilters] = useState({});
  const [GetTags, { loading, data, error }] = useLazyQuery(SportsResourcePropsReadQuery, {
    variables: { resource: { resource_id: resourceID, account: getAccountKey() } },
  });

  const postVariables = {
    search: '',
    filters: chosenFilters,
    ...(additionalFilterVariables && additionalFilterVariables),
  };

  const {
    loading: topCountsloading,
    data: topCounts,
    error: topCountsErrors,
    refetch,
  } = useQuery(resourceDef?.queries?.counts?.query || resourceDef?.queries?.search?.query, {
    skip: !enableFilters,
    variables: postVariables,
  });

  useEffect(() => {
    if (topCounts) {
      let values = null;
      if (resourceDef?.queries?.counts?.responsePath) {
        values = dataFromPath(topCounts, resourceDef?.queries?.counts?.responsePath);
        values = stripKeys(values);
      }
      setFilterCounts(values);
    }
  }, [enableFilters, resourceDef, topCounts]);

  useEffect(() => {
    if (tagFilter) {
      GetTags();
    }
  });

  useEffect(() => {
    if (data) {
      setResponse(data.sports_cricket_resource_props_read.resource_props);
    }
  }, [data]);

  const OnFilterChange = (type, name, isSearch, searchString, changeList = true) => {
    const variable = { ...chosenFilters };
    if (!isSearch) {
      if (type in variable && variable[type]?.keys?.includes(name)) {
        delete variable[type];
      } else {
        variable[type] = {
          keys: [name],
        };
      }
    } else {
      variable[type] = {
        search: searchValue ?? searchString,
      };
    }
    setChosenFilters(variable);
    if (!isSearch && changeList) {
      getFilterPostVariables(variable);
    }
  };

  useEffect(() => {
    if (changeChosenFilters) {
      getChosenFilters(setChosenFilters);
    }
  }, [changeChosenFilters]);

  useEffect(() => {
    refetch({
      ...chosenFilters,
      ...(additionalFilterVariables && additionalFilterVariables),
    });
  }, [chosenFilters, additionalFilterVariables]);

  const doCheck = (val, name, key) => {
    if ((chosenFilters && (chosenFilters[val]?.keys?.includes(name)
    || chosenFilters[val]?.keys?.includes(key)))) {
      return true;
    }
    return false;
  };

  return (
    <FilterWrapper>
      <div className="filter-blocks">
        {renderAdditionalFilters && renderAdditionalFilters(
          setAdditionalFilterVariables,
          OnFilterChange,
          additionalFilterVariables,
          chosenFilters,
        )}
      </div>
      {internalFilters && (
        <>
          <Text14Regular>Columns</Text14Regular>
          <div className="filter-wrapper">
            {internalFilters.map((tableContent) => (
              <Text14Regular
                className={addedTableContent.includes(tableContent) ? 'tags active' : 'tags'}
                onClick={() => selectedTableContent(tableContent)}
              >
                {tableContent}
              </Text14Regular>
            ))}
          </div>
        </>
      )}
      {tagFilter && (
        <>
          {loading && (<StatusLoading message="Hold on..." />)}
          {error && (<StatusError error={error} />)}
          {response && (
            <>
              <Text14Regular>Tags</Text14Regular>
              {response?.tags?.tagnames?.length > 0 && (
                <div className="filter-wrapper">
                  {response.tags.tagnames.map((tag) => (
                    <Text14Regular
                      className={addedFilters.includes(tag) ? 'tags active' : 'tags'}
                      onClick={() => onTagChange(tag)}
                    >
                      {tag}
                    </Text14Regular>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
      {topCountsloading && (<StatusLoading message="Hold on..." />)}
      {topCountsErrors && (<StatusError error={topCountsErrors} />)}
      {topCounts && filterCounts && (
        <>
          {Object.entries(filterCounts).map((counts) => (
            <div className="wrapper">
              {Object.values(counts[1]).length > 0 && (
                <Box.Collapsible
                  className="filter-blocks"
                  title={capitilise(counts[0])}
                  open={false}
                  repositionArrrow
                  rememberKey={`filter/${counts[0]}`}
                >
                  <div className="search-block">
                    <input
                      type="search"
                      placeholder={`Search ${counts[0]}`}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          OnFilterChange(counts[0], null, true, e.target.value);
                        }
                      }}
                    />
                    <ArrowRightIcon
                      className="arrow-icon"
                      onClick={() => {
                        OnFilterChange(counts[0], null, true, searchValue);
                      }}
                    />
                  </div>
                  {Object.values(counts[1]).map((count) => (
                    <button type="button" onClick={() => OnFilterChange(counts[0], count.key)}>
                      <div>
                        <input
                          type="checkbox"
                          checked={doCheck(counts[0], count.name, count.key)}
                        />
                        <Text14Regular>{count.name}</Text14Regular>
                      </div>
                      <Text14Regular>{count.count}</Text14Regular>
                    </button>
                  ))}
                </Box.Collapsible>
              )}
            </div>
          ))}
        </>
      )}
    </FilterWrapper>
  );
}

Filter.propTypes = {
  resourceID: PropTypes.string,
  onTagChange: PropTypes.func,
  addedFilters: PropTypes.array,
  internalFilters: PropTypes.array,
  selectedTableContent: PropTypes.any,
  addedTableContent: PropTypes.array,
  tagFilter: PropTypes.bool,
  enableFilters: PropTypes.bool,
  resourceDef: PropTypes.object,
  getFilterPostVariables: PropTypes.func,
  renderAdditionalFilters: PropTypes.func,
  setAdditionalFilterVariables: PropTypes.func,
  additionalFilterVariables: PropTypes.bool,
  changeChosenFilters: PropTypes.bool,
  getChosenFilters: PropTypes.func,
};
