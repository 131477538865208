import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FormItem } from 'core/FormFieldItem';
import { EnumValuesQuery } from 'core/query';
import { StatusError, StatusLoading } from 'core/Status';

export function SelectEnum({
  enumName, name,
  filteredValue = [],
  ...formOptions
}) {
  const { loading, data, error } = useQuery(EnumValuesQuery, { variables: { name: enumName } });

  let options;
  if (data) {
    // eslint-disable-next-line no-underscore-dangle
    options = data.__type.enumValues.map((x) => ({
      key: x.name,
      name: x.name.toUpperCase() === x.name ? x.name : x.name.split(/(?=[A-Z])/).join(' '),
    }));
  }

  if (data && options && filteredValue) {
    options = options.filter((item) => !filteredValue.includes(item.key));
  }

  if (options && !loading && !error) {
    return (
      <FormItem
        {...formOptions}
        options={options}
        type="select"
        name={name}
      />
    );
  }

  return (
    <div>
      {loading && (<StatusLoading message="Hold on..." />)}
      {error && (<StatusError error={error} />)}
    </div>
  );
}

SelectEnum.propTypes = {
  name: PropTypes.string,
  enumName: PropTypes.string,
  filteredValue: PropTypes.array,
  ...FormItem.propTypes,
};
