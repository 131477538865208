import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import {
  RegionCreateMutation, RegionSearchQuery,
  RegionReadQuery, RegionUpdateMutation,
  RegionUpdateStatusMutation,
  RegionNameQuery,
} from './query';
import { RegionImageView } from './ResourceImage';

export const RegionResource = Resource({
  resourceId: 'region',
  name: 'Region',
  storeId: 'Region',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Region',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        code: StringFieldMinMax(2, 120),
        short_code: StringFieldMinMax(1, 120),
        kind: StringFieldMinMax(3, 120),
      }),
    }),
    ColumnSet({
      name: 'Status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(true),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
    { action: 'updateStatus' },
  ],
  imageRender: (item) => (<RegionImageView item={item} />),
  queries: {
    read: QueryAction({ query: RegionReadQuery, resourceNamePath: 'item.region.name' }),
    list: QueryAction({ query: RegionSearchQuery, responsePath: 'sports_region_search' }),
    search: QueryAction({
      query: RegionSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'region.name',
    }),
    name: QueryAction({ query: RegionNameQuery, resourceNamePath: 'sports_region_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: RegionUpdateMutation, cs: ['region', 'status'] }),
    updateStatus: MutationAction({ mutation: RegionUpdateStatusMutation, cs: ['status'] }),
    create: MutationAction({ mutation: RegionCreateMutation }),
  },
});
