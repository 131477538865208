import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AccountPicker } from 'account/AccountPicker';
import { SelectSport } from 'sports/SelectSport';
import { Pricing } from 'account/Pricing';
import { UserContext } from './UserContext';
import { AccessGate, NotLoggedIn } from './AccessGate';

export function PrivateContent({ reqRole, children }) {
  const userContext = useContext(UserContext);
  if (!userContext.loggedIn) {
    return (<NotLoggedIn />);
  }

  const { user } = userContext;
  const isAdmin = user.roles.roles_v2.includes(1) || user.roles.roles_v2.includes(50);

  if (user.roles.roles_v2.includes(10000) && !isAdmin && user.roles.roles_v2.length === 1) {
    return (<h1 style={{ textAlign: 'center' }}>Oops! You don&apos;t have access our console</h1>);
  }

  if (!isAdmin) {
    if (reqRole && user.roles.roles_v2.indexOf(reqRole) === -1) {
      return (<AccessGate role={reqRole} />);
    }
  }

  return children;
}

PrivateContent.propTypes = {
  reqRole: PropTypes.string,
  children: PropTypes.element.isRequired,
};

function AccountContent({ children }) {
  const userContext = useContext(UserContext);
  if (userContext?.user?.accounts?.accounts?.length === 0
    && userContext?.user?.accounts?.invitations_count === 0
    && !userContext.accountKey) {
    return (<Pricing />);
  }
  if (!userContext.accountKey && (
    userContext?.user?.accounts?.accounts?.length > 0
    || userContext?.user?.accounts?.invitations_count > 0)) {
    return (<AccountPicker redirect={false} />);
  }
  if (!userContext.sport) {
    return (<SelectSport redirect={false} />);
  }

  return children;
}

AccountContent.propTypes = {
  children: PropTypes.element.isRequired,
};

export function PrivateAccountContent({ reqRole, children }) {
  return (
    <PrivateContent reqRole={reqRole}>
      <AccountContent>
        {children}
      </AccountContent>
    </PrivateContent>
  );
}

PrivateAccountContent.propTypes = {
  reqRole: PropTypes.string,
  children: PropTypes.element.isRequired,
};
