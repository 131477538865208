import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ThemeWrapper = styled.div`
  &.light-theme {
  }
`;
const Themes = {
  Dark: 'Dark',
  Light: 'Light',
};
export function Theme({ name, children }) {
  return (
    <ThemeWrapper className={name === Themes.Dark ? 'dark-theme' : 'light-theme'}>
      {children}
    </ThemeWrapper>
  );
}

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  name: PropTypes.string,
};
